import { Component, OnInit } from '@angular/core';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { EventoService } from '../services/evento/evento.service';
import { NavigatorService } from '../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { EventoDTO } from '../shared/dto/evento/evento';
import { PartecipanteEventoService } from '../services/evento/partecipante-evento.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { UrlService } from '../commons/calendar/prevUrl.service';

@Component({
  selector: 'app-partecipante-evento',
  templateUrl: './partecipante-evento.component.html',
  styleUrls: ['./partecipante-evento.component.scss']
})
export class PartecipanteEventoComponent extends GenericListComponent implements OnInit {

  collaboratori: CollaboratoreDTO[];
  eventi: EventoDTO[];

  currentCollaboratore = new CollaboratoreDTO();

  eventId: any;
  previousUrl: string;

  defaultSortField = 'id';
  defaultSortDirection = 'asc';

  constructor(private eventoService: EventoService,
    private urlService: UrlService,
    private collaboratoreService: CollaboratoreService,
    private partecipanteEventoService: PartecipanteEventoService,
    private activatedRoute: ActivatedRoute,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);

    this.displayedColumns = ['id', 'evento.descrizione', 'collaboratore.nome', 'spettatore', 'numOspiti', 'detail'];

    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      evento: null,
      spettatore: false,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.partecipanteEventoService.partecipanteEventoListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.evento,
      this.parameters.spettatore
    ).subscribe({
      next: response => {
        this.parameters.dataSource = response.content;
        this.parameters.pageNumber = response.pageNumber;
        this.parameters.pageSize = response.pageSize;
        this.parameters.showList = true;
        this.parameters.length = response.totalElements;
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.eventId = this.activatedRoute.snapshot.queryParamMap.get('id');

    if (this.eventId != null && !isNaN(this.eventId)) {
      this.eventoService.read(Number(this.eventId)).subscribe({
        next: event => {
          this.parameters.evento = event;

          this.list();
        }
      });
    }

    this.navigatorService.collaboratore.subscribe({
      next: collaboratore => {
        this.currentCollaboratore = collaboratore;
      }
    });

    this.collaboratoreService.collaboratoreList(0, 9999, 'ASC', 'nome', '').subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      }
    );

    this.eventoService.eventoListByList(0, 9999, 'ASC', 'id').subscribe(
      (res: ResponseQueryByCriteria<EventoDTO>) => {
        this.eventi = res.content;
      }
    );
  }

  public backToEvent() {
    if (this.eventId)
      this.router.navigateByUrl(`/evento/detail/${this.eventId}`);
    else
      this.router.navigate(['/evento']);
  }

  public isEventFull() {
    return this.parameters.evento != null && this.parameters.evento.maxPartecipanti != null && this.parameters.length == this.parameters.evento.maxPartecipanti;
  }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettaglio rimborso piè di lista</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <form [formGroup]="form" fxLayout="column">
                <!-- Id -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Rimborso Id" readonly="true" />
                </mat-form-field>

                <!-- Collaboratore -->
                <mat-form-field>
                    <mat-select name="collaboratore" formControlName="collaboratore"
                        placeholder="Scegli il collaboratore" [compareWith]="compareDTO"
                        [disabled]="!isJappTenantAdmin()">
                        <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                            {{ (collaboratore.nome + ' ' + collaboratore.cognome) | titlecase }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Responsive -->
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- Data Emissione -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput [matDatepicker]="pickerDataEmissione" formControlName="dataEmissione"
                            placeholder="Scegli la data di emissione" readonly="true"
                            (click)="pickerDataEmissione.open()" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="pickerDataEmissione"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDataEmissione></mat-datepicker>
                        <button mat-button *ngIf="form.get('dataEmissione').value" matSuffix mat-icon-button
                            aria-label="Clear" (click)="form.get('dataEmissione').setValue(null)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('dataEmissione').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <!-- Importo -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <span matPrefix> € &nbsp; </span>
                        <input type="number" matInput formControlName="importo" placeholder="Importo" />
                        <mat-error *ngIf="form.get('importo').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                        <mat-error *ngIf="form.get('importo').errors?.min">
                            L'importo non può essere minore di 0,01
                        </mat-error>
                        <mat-error *ngIf="form.get('importo').errors?.max">
                            L'importo non può essere maggiore di 10.000
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Descrizione -->
                <mat-form-field>
                    <textarea cdkTextareaAutosize matInput formControlName="descrizione" placeholder="Descrizione"></textarea>
                    <mat-error *ngIf="form.get('descrizione').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('descrizione').errors?.maxlength">
                        La descrizione non può essere più lunga di 255 caratteri
                    </mat-error>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/rimborso-pie-di-lista')" color="primary">Back</button>
            <button mat-raised-button (click)="rimborsoSave()" [disabled]="form.invalid || !form.dirty || rimborso.fatto == true"
                *ngIf="rimborso?.deleteDate==null">Save</button>
            <button mat-raised-button (click)="rimborsoDelete()" [disabled]="rimborso?.id==null || rimborso.fatto == true"
                *ngIf="rimborso?.deleteDate==null">Delete</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Gestione categorie</h2>
  
      <mat-card-actions>
        <!-- <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button> -->
        <button *ngIf="isJappTenantAdmin()" mat-raised-button id="new-button" color="primary" [routerLink]="['/categoria-evento/detail/0']">New</button>
      </mat-card-actions>
  
      <br>
  
      <div *ngIf="parameters.showList" fxLayout="column">
        <table mat-table fxFlex [dataSource]="parameters.dataSource" matSort (matSortChange)="setSort($event)"
          matSortDisableClear [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection">
  
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let categoria"> {{categoria.id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organizzatore </th>
            <td mat-cell *matCellDef="let categoria"> {{categoria.nome}} </td>
          </ng-container>
  
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let categoria">
              <button mat-mini-fab color="primary" [routerLink]="['/categoria-evento/detail/', categoria.id]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
  
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-paginator #paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
            [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
          </mat-paginator>
        </div>
      </div>
  
    </mat-card-content>
  </mat-card>
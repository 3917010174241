import { ResponseFail } from './../shared/dto/responseFail';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from '../services/navigator.service';
import { ChiusuraService } from '../services/assenza/chiusura.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChiusuraDTO } from '../shared/dto/assenza/chiusura';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chiusura-detail',
  templateUrl: './chiusura-detail.component.html',
  styleUrls: ['./chiusura-detail.component.scss']
})
export class ChiusuraDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  chiusuraId: number;
  chiusura: ChiusuraDTO;
  dataMax: Date;
  dataMin: Date;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string

  constructor(
    private urlService: UrlService,
    private chiusuraService: ChiusuraService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      route,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      dataDa: new FormControl('', Validators.required),
      dataA: new FormControl('', Validators.required),
      descrizione: new FormControl('', Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });
  }



  ngOnInit() {
    this.chiusuraId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.chiusuraId != 0 && this.chiusuraId != null) {
      this.chiusuraRead();
    } else {
      this.chiusura = new ChiusuraDTO();
    }
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  setMinDate() {
    this.dataMin = this.form.get("dataDa").value;
  }

  setMaxDate() {
    this.dataMax = this.form.get("dataA").value;
  }

  chiusuraSave() {
    if (this.chiusura.id == null) {
      this.chiusuraCreate();
    } else {
      this.chiusuraUpdate();
    }
  }

  chiusuraCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.chiusuraService.create(this.chiusura).subscribe(
        (res: ChiusuraDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.chiusura = res;
          this.chiusuraId = this.chiusura.id;
          this.dtoToForm();
        }, (err: any) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidChiusuraException_CHIUSURA_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già una chiusura nel periodo di date indicato.", null, { duration: 3000 });
          } else {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
  }

  chiusuraUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la chiusura?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.chiusuraService.update(this.chiusura).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.chiusuraRead();
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidChiusuraException_CHIUSURA_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già una chiusura nel periodo di date indicato.", null, { duration: 3000 });
              } else {
                this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  chiusuraDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la chiusura?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.chiusuraService.delete(this.chiusura.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/chiusura"]);
            }
          );
        }
      });
    }
  }

  chiusuraRead() {
    this.chiusuraService.read(this.chiusuraId).subscribe(
      (res: ChiusuraDTO) => {
        console.log("response : " + res);
        this.chiusura = res;
        this.chiusuraId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.chiusura.dataDa = this.form.get("dataDa").value;
    this.chiusura.dataA = this.form.get("dataA").value;
    this.chiusura.descrizione = this.form.get("descrizione").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.chiusura.id);
    this.form.get("dataDa").setValue(this.chiusura.dataDa);
    this.form.get("dataA").setValue(this.chiusura.dataA);
    this.form.get("descrizione").setValue(this.chiusura.descrizione);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.chiusura.insertDate));
    this.form.get("insertUser").setValue(this.chiusura.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.chiusura.updateDate));
    this.form.get("updateUser").setValue(this.chiusura.updateUser);
    this.dataMin = this.chiusura.dataDa;
    this.dataMax = this.chiusura.dataA;
    this.form.markAsPristine();
  }

  ngOnDestroy() { }
}
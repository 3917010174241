import { CreditoDTO } from './credito';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CatalogoDTO } from './catalogo';

export class MovimentoDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    credito: CreditoDTO;
    catalogo: CatalogoDTO;
    totaleCrediti: number;
    qtaProdotti: number;
    data: Date;
}
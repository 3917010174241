<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Gestione attività</h2>
      <p>Imposta i filtri di ricerca</p>
      <!-- Collaboratore field -->
      <div fxLayout="column" class="responsive-mat-form">
        <mat-form-field>
          <mat-label>Collaboratori</mat-label>
          <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO"
            [disabled]="!isJappTenantAdmin()">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--Descrizione field-->
      <div fxLayout="column" class="responsive-mat-form">
        <mat-form-field>
          <input matInput [(ngModel)]="parameters.filter" placeholder="Filter"/>
        </mat-form-field>
      </div>
  
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search"
          color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/attivita/detail/0']">New</button>
      </mat-card-actions>
      <br />
      <div *ngIf="parameters.showList" fxLayout="column">
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection" matSortDisableClear
          [dataSource]="parameters.dataSource">
  
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let attivita"> {{attivita.id }} </td>
          </ng-container>
  
          <!-- Collaboratore Column -->
          <ng-container matColumnDef="collaboratore" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Collaboratore </th>
            <td mat-cell *matCellDef="let attivita" [hidden]="!isJappTenantAdmin()"> {{attivita.collaboratore?.nome}} {{attivita.collaboratore?.cognome}}
            </td>
          </ng-container>
  
          <!-- Descrizione Column -->
          <ng-container matColumnDef="descrizione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
            <td mat-cell *matCellDef="let attivita"> {{attivita.descrizione}} </td>
          </ng-container>
         <!-- Da iniziare a modificare  -->
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let attivita">
              <button mat-mini-fab color="primary" [routerLink]="['/attivita/detail/', attivita.id]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-actions>
            <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'attivita'})">Excel</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'attivita'})">Csv</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'attivita'})">Json</button>
          </mat-card-actions>
          <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
            [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { CollaboratoreAmService } from './../services/turno-am/collaboratore-am.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit } from '@angular/core';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { CollaboratoreAMDTO } from '../shared/dto/turno-am/collaboratore-am';

@Component({
  selector: 'app-collaboratore-am',
  templateUrl: './collaboratore-am.component.html',
  styleUrls: ['./collaboratore-am.component.scss']
})
export class CollaboratoreAmComponent extends GenericListComponent implements OnInit {

  constructor(
    private collaboratoreAMService: CollaboratoreAmService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['id', 'email', 'username', 'nome', 'cognome', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        filter: null,
        sortField: null,
        sortDirection: null,
        pageNumber: 0,
        pageSize: 50,
        length: 0
      }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  list(){
    this.collaboratoreAMService.collaboratoreAMListByList(
      this.parameters.filter,
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField
    ).subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreAMDTO>) => {
        console.log("response" + res);
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      }
    )
  }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli template</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Template Id" readonly="true" />
                </mat-form-field>

                <!-- CodTemplate field -->
                <mat-form-field>
                    <mat-label>Funzione</mat-label>
                    <mat-select formControlName="codTemplate">
                        <mat-option *ngFor="let function of functions" [value]="function">
                            {{ function }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Descrizione field -->
                <mat-form-field>
                    <input matInput type="text" formControlName="descrizione" placeholder="Descrizione">
                    <mat-error *ngIf="form.get('descrizione').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Template field -->
                <mat-form-field>
                    <textarea cdkTextareaAutosize matInput formControlName="template"
                        placeholder="Template FreeMarker"></textarea>
                    <mat-error *ngIf="form.get('descrizione').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                    <mat-error *ngIf="form.get('descrizione').errors?.maxlength">
                        La descrizione non può essere piò lunga di 255 caratteri
                    </mat-error>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/gestione-template')" color="primary">Back</button>
            <button mat-raised-button (click)="templateSave()" [disabled]="form.invalid || !form.dirty"
                *ngIf="template?.deleteDate==null">Save</button>
            <button mat-raised-button (click)="templateDelete()" [disabled]="template?.id==null"
                *ngIf="template?.deleteDate==null">Delete</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
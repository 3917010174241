<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli prenotazione</h2>
    <div fxLayout="column">
      <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Prenotazione Id" readonly="true" />
        </mat-form-field>

        <!-- Richiedente field -->
        <mat-form-field>
          <mat-select name="richiedente" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
            formControlName="richiedente" placeholder="Scegli il richiedente">
            <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('richiedente').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- DataDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker.open()" [min]="today" [max]="dataMax"
              (dateChange)="setMinDate()" [matDatepicker]="picker" formControlName="dataDa"
              placeholder="Scegli data da">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataDa').value" matSuffix mat-icon-button
              aria-label="Clear" (click)="form.get('dataDa').setValue(null)" (click)="dataMin=null">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataDa').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker2.open()" [min]="dataMin"
              (dateChange)="setMaxDate()" [matDatepicker]="picker2" formControlName="dataA" placeholder="Scegli data a">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataA').value" matSuffix mat-icon-button
              aria-label="Clear" (click)="form.get('dataA').setValue(null)" (click)="dataMax=null">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataA').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- Stato field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <mat-select name="stato" [disabled]="!isJappTenantAdmin()" formControlName="stato"
              placeholder="Scegli lo stato della richiesta">
              <mat-option *ngFor="let option of statoValues" [value]="option.value">
                {{option.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('stato').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- Luogo field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput type="text" formControlName="luogo" placeholder="Luogo">
            <mat-error *ngIf="form.get('luogo').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- Riferimento field -->
          <mat-form-field fxFlex>
            <input matInput type="text" formControlName="riferimento" placeholder="Riferimento">
            <mat-error *ngIf="form.get('riferimento').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- Nota field -->
          <mat-form-field fxFlex>
            <textarea cdkTextareaAutosize matInput formControlName="nota" placeholder="Nota"></textarea>
          </mat-form-field>
        </div>
        
        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/gestione-prenotazione')" color="primary">Back</button>
      <button mat-raised-button (click)="prenotazioneSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="prenotazione?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="prenotazioneDelete()" [disabled]="prenotazione?.id==null"
        *ngIf="prenotazione?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
import { LogicalDeleteDTO } from "../basic/logicalDeleteDTO";
import { TenantDTO } from "../domain/tenant";
import { TurnoServiziReperibilitaDTO } from "./TurnoServizioReperibilitaDTO";

export class InterventoTurnoReperibilitaDTO extends LogicalDeleteDTO{
	
	id: number;
    tenant: TenantDTO;
    turno: TurnoServiziReperibilitaDTO;
    dataDa: Date
    dataA:Date
    descrizione: string
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { FerieService } from '../services/gestione-ferie/ferie.service';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { FeriePermessiDTO } from '../shared/dto/gestioneferie/ferie';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';


@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-gestione-ferie',
    templateUrl: './ferie.component.html',
    styleUrls: ['./ferie.component.scss']
  })

  export class FerieComponent extends GenericListComponent implements OnInit, OnDestroy {

    public pdf: File;
    collaboratori: CollaboratoreDTO[];
    feriePermessi: FeriePermessiDTO;
    dataA: Date;
    defaultSortField = 'dataA';
    defaultSortDirection = 'asc';

    constructor(
      private ferieService: FerieService,
      private collaboratoreService: CollaboratoreService,
      componentCacheService: ComponentCacheService,
      navigatorService: NavigatorService,
      dateAdapter: DateAdapter<Date>,
      router: Router,
      snackBar: MatSnackBar,
      timestampFormatPipe: TimestampFormatPipe
    ) { 
      super(navigatorService,
        componentCacheService,
        dateAdapter,
        router,
        snackBar,
        timestampFormatPipe);
      this.displayedColumns = ['id', 'dataA', 'collaboratore', 'residuoFerie', 'maturabiliFerie', 'daFruireFerie', 'residuoPermessi', 'maturabiliPermessi', 'daFruirePermessi', 'residuoROL', 'maturabiliROL', 'daFruireROL', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        collaboratore: null,
        collaboratoreChanged: false,
        initDataDa: true,
        dataA: null,
        totaleOre: null,
        giorni: null,
        sortField: 'collaboratore',
        sortDirection: 'ASC',
        pageNumber: 0,
        pageSize: 50,
        length: 0
      }
      
    }

    ngOnInit() {
      super.ngOnInit();
      if (!this.parameters.collaboratoreChanged) {
        this.navigatorService.collaboratore.subscribe(
          (res: CollaboratoreDTO) => {
            this.parameters.collaboratore = res;
          }
        );
      }
      this.collaboratoreService.collaboratoreList(
        0,
        9999,
        'ASC',
        'nome',
        '').subscribe(
          (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
            console.log("response : " + res);
            this.collaboratori = res.content;
          }
        );
    }
    list() {
      this.ferieService.feriePermessiList(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
        (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
        this.parameters.collaboratore,
        this.parameters.dataA)
        .subscribe((res: ResponseQueryByCriteria<FeriePermessiDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;

        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_FERIE_NOT_FOUND") {
            this.snackBar.open("Attenzione! Ferie non trovate.", null, { duration: 4000 });
          } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreException_COLLABORATORE_NOT_FOUND") {
            this.snackBar.open("Attenzione! Questo collaboratore non ha ferie.", null, { duration: 4000 });
          } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_FERIE_BEFORE_WITH_COLLABORATORE_DATE_NOT_FOUND") {
            this.snackBar.open("Attenzione! Questo collaboratore prima di questa data non ha ferie", null, { duration: 4000 });
          } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_FERIE_BEFORE_DATE_NOT_FOUND") {
            this.snackBar.open("Attenzione! Ferie non trovate prima di questa data", null, { duration: 4000 });
          }
        })
    }
    fileChange(event: Event): void {
      this.pdf = (event.target as HTMLInputElement).files[0];
      this.ferieService.upload(this.pdf).subscribe(() =>{
        this.snackBar.open("File caricato con successo!", null, { duration: 4000 });
        this.list();
      }, (err: HttpErrorResponse) => {
        if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_FERIE_ALREADY_EXIST") {
          this.snackBar.open("Errore di caricamento del file pdf: presenti ferie già esistenti.", null, { duration: 4000 });
        } else if (err.status == 500 && (<ResponseFail>err.error).reasonBundleKey == "generic_error") {
          this.snackBar.open("Errore di caricamento del file pdf: file selezionato non corretto/file non selezionato.", null, { duration: 4000 });
        }

        });
    }

    clickButton(): void {
      let button = document.getElementById('change-input');
      button.addEventListener('click', function handleClick(event) {
      })
    }
    ngOnDestroy(): void {
      super.ngOnDestroy();
    }
  }
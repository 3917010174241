<div fxLayout="column" fxFlex id="main" *ngIf="isLoggedIn()">
  <!-- l'id main è necessario per agganciare gli enventi cordova a quelli angular -->
  <mat-toolbar class="mat-elevation-z5" color="primary" style="z-index: 100!important;">
    <button mat-button (click)="menu.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span fxFlex fxHide.xs>&nbsp;{{tenant?.description}}</span>
    <span fxFlex fxHide fxShow.xs>&nbsp;{{tenant?.mobileDesc}}</span>
    <a [routerLink]="['']" fxShow fxHide.xs><img fxFlex="none" src="./assets/img/logo-jenia-tondo.png"
        style="height: 48px;" /></a>
    <button mat-button fxHide fxShow.xs [routerLink]="['']">
      <mat-icon>home</mat-icon>
    </button>
  </mat-toolbar>
  <div fxLayout="row" fxFlex>
    <mat-sidenav-container fxFlex fxLayout="row" hasBackdrop="true" >
      <mat-sidenav #menu mode="over" opened="false">
        <mat-accordion>
          <mat-list fxLayoutAlign="space-around left" fxLayout="column" >
            <mat-list-item>
              <button mat-button fxFlex fxLayoutAlign="start center" [routerLink]="['']" (click)="menu.toggle()">
                <mat-icon>home</mat-icon>&nbsp;Home
              </button>
            </mat-list-item>

            <mat-list-item *ngIf="isLoggedIn()">
              <mat-form-field class="example-full-width">
                <div fxLayout="row" fxLayoutAlign="space-around center">
                  <mat-icon class="material-icons">search</mat-icon>
                  <input type="text" matInput [formControl]="filter" [matAutocomplete]="servizioAutocomplete">
                  <mat-autocomplete autoActiveFirstOption #servizioAutocomplete="matAutocomplete"
                    (optionSelected)="menu.toggle()" (optionSelected)="redirect($event.option.value)">
                    <mat-option *ngFor="let servizio of filteredServizio | async" [value]="servizio.nome">
                      <button mat-button fxFlex fxLayoutAlign="start center">{{servizio?.nome}}</button>
                    </mat-option>
                  </mat-autocomplete>
                  <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="filter.setValue(null)">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </mat-list-item>


            <div *ngFor="let menuOne of menuList$ | async; index as i;" >
              <div *ngIf="menuOne.nome!='Altro'">
                  <mat-expansion-panel style="width:100%;box-shadow: none;" >
                  <mat-expansion-panel-header >{{menuOne.nome}}</mat-expansion-panel-header>
                    <mat-list-item *ngFor="let funzionalitaOne of menuOne.listaFunzionalita" >
                    <button mat-button fxFlex fxLayoutAlign="start center" 
                      (click)="navigateTo(funzionalitaOne);menu.toggle()">
                      <mat-icon *ngIf="funzionalitaOne.nomeIcona.startsWith('fas ')" [class]="funzionalitaOne.nomeIcona"></mat-icon>
                      <mat-icon *ngIf="!funzionalitaOne.nomeIcona.startsWith('fas ')" class="icon-font-size">{{funzionalitaOne.nomeIcona}}</mat-icon>
                      {{funzionalitaOne.nomeFunzionalitaPrint}}
                    </button>
                    </mat-list-item>
                </mat-expansion-panel>
              </div>
              <!-- Menu 'Altro' -->
              <div *ngIf="isLoggedIn() && menuOne.nome == 'Altro'">
                <div *ngFor="let funzionalitaOne of menuOne.listaFunzionalita" >
                  <mat-list-item *ngIf="funzionalitaOne.nomeFunzionalitaPrint!='Logout'">
                    <button mat-button fxFlex fxLayoutAlign="start center" 
                            (click)="navigateTo(funzionalitaOne);menu.toggle()">
                      <mat-icon *ngIf="funzionalitaOne.nomeIcona.startsWith('fas ')" [class]="funzionalitaOne.nomeIcona"></mat-icon>
                      <mat-icon *ngIf="!funzionalitaOne.nomeIcona.startsWith('fas ')" class="icon-font-size">{{funzionalitaOne.nomeIcona}}</mat-icon>
                      {{funzionalitaOne.nomeFunzionalitaPrint}}
                    </button>
                  </mat-list-item>
                  <!-- 'Logout' fatto cosi perche ha un funzionamento diverso dal solito -->
                  <mat-list-item *ngIf="funzionalitaOne.nomeFunzionalitaPrint=='Logout'">
                    <button mat-button fxFlex fxLayoutAlign="start center" (click)="logout()">
                      <mat-icon>exit_to_app</mat-icon>&nbsp;Logout
                    </button>
                  </mat-list-item>
                </div>
              </div>
            </div>
          </mat-list>
        </mat-accordion>
      </mat-sidenav>


      <mat-sidenav-content fxFlex="100" fxLayout="row">
        <mat-card fxLayout="column" fxFlex class="mainContent">
          <mat-card-content fxLayout="column" fxFlex>
            <div class="mainContentBody"
              [ngStyle]="{'width.px': screenWidth, 'min-width.px': screenWidth, 'max-width.px': screenWidth}">
              <router-outlet></router-outlet>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <mat-toolbar fxLayout="row" ngClass-xs="footerToolbarXs footerToolbarTextXs" ngClass="footerToolbar footerToolbarText"
    color="primary">
    Japp &copy; &nbsp;&nbsp;<a href="https://www.jenia.it" style="color:white;">JENIA Software</a>
  </mat-toolbar>
</div>
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-collaboratore',
  templateUrl: './collaboratore.component.html',
  styleUrls: ['./collaboratore.component.scss']
})

export class CollaboratoreComponent extends GenericListComponent implements OnInit, OnDestroy {

  constructor(
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'email', 'userName', 'codiceCollaboratore', 'nome', 'cognome', 'compleannoPersonale','jeniaCompleanno', 'telefonoReperibilita', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.collaboratoreService.collaboratoreList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.filter).subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnInit() {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

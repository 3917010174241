import { ChiaviDTO } from './../shared/dto/gestioneChiavi/chiavi';
import { StoriaChiaviService } from './../services/gestione-chiavi/storia-chiavi.service';
import { StoriaChiaviDTO } from './../shared/dto/gestioneChiavi/storiaChiavi';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';


@Component({
    selector: 'app-lascia-chiavi',
    templateUrl: './lasciaChiavi.component.html',
    styleUrls: ['./lasciaChiavi.component.scss']
})

export class lasciaChiaviComponent extends GenericDetailComponent implements OnInit, OnDestroy {

    parameters: any;
    storiaChiavi: StoriaChiaviDTO;
    storiaChiaviId: number;
    chiavi: ChiaviDTO;

    constructor(
        private storiaChiaviService: StoriaChiaviService,
        activeRoute: ActivatedRoute,
        dialog: MatDialog,
        snackBar: MatSnackBar,
        timestampFormatPipe: TimestampFormatPipe,
        router: Router,
        navigatorService: NavigatorService,
        dateAdapter: DateAdapter<Date>) {
        super(
            navigatorService,
            dialog,
            router,
            dateAdapter,
            activeRoute,
            snackBar,
            timestampFormatPipe);
        this.form = new FormGroup({
            id: new FormControl({ value: '', disabled: true }),
            chiavi: new FormControl({ value: '', disabled: true }),
            dataDa: new FormControl({ value: '', disabled: true }),
            dataA: new FormControl('', Validators.required),
            deleteDate: new FormControl({ value: '', disabled: true }),
            deleteUser: new FormControl({ value: '', disabled: true }),
            insertDate: new FormControl({ value: '', disabled: true }),
            insertUser: new FormControl({ value: '', disabled: true }),
            updateDate: new FormControl({ value: '', disabled: true }),
            updateUser: new FormControl({ value: '', disabled: true }),
            optLock: new FormControl({ value: '', disabled: true }),
        });
        this.parameters = {
            dataSource: [],
            sortField: null,
            sortDirection: null,
            dataDa: null,
            dataDaPlaceHolder: null,
            dataA: null,
            pageNumber: 0,
            pageSize: 50,
            length: 0
        }
    }

    storiaChiaviUpdate() {
        if (this.form.valid && this.form.dirty) {
            this.confirm("Sei sicuro di voler lasciare le chiavi nel giorno selezionato?").subscribe(result => {
                if (result) {
                    this.formToDto();
                    this.storiaChiaviService.update(this.storiaChiavi).subscribe(
                        (res) => {
                            this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
                            this.storiaChiaviId = this.storiaChiavi.id;
                            this.form.markAsPristine();
                            this.router.navigate(["/gestione-chiavi"]);
                        }, (err: any) => {
                            
                        }
                    );
                }
            });
        }
    }

    private formToDto(): void {
        this.storiaChiavi.dataA = this.form.get("dataA").value;
    }


    ngOnInit(): void {

        this.navigatorService.collaboratore.subscribe(
            (res: CollaboratoreDTO) => {
                this.storiaChiaviService.selectByChiavi(res.id, Number(this.activeRoute.snapshot.paramMap.get("idChiavi")))
                    .subscribe(res2 => {
                        this.storiaChiavi = res2;
                        this.form.get("id").setValue(res2.id);
                        this.form.get("chiavi").setValue(res2.chiavi.descrizione);
                        this.parameters.dataDa = new Date(res2.dataDa);
                        this.parameters.dataDaPlaceHolder = this.timestampFormatPipe.transform(res2.dataDa, "dd/MM/yyyy");
                    });
            }
        );
    }

    ngOnDestroy(): void {
    }
}
import { Injectable } from '@angular/core';
import { CorsiUdemyDTO } from 'src/app/shared/dto/corsi-udemy/corsiUdemy';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';

@Injectable({
  providedIn: 'root'
})
export class CorsiUdemyService extends GenericService<CorsiUdemyDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/corsoudemy';
   }

   public corsiUdemyList(pageNumber: number, pageSize: number, direction: string, sortField: string, filter: string): Observable<ResponseQueryByCriteria<CorsiUdemyDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    return this.http.get<ResponseQueryByCriteria<CorsiUdemyDTO>>(`${this.url}/courseList`, {
      params: params
    });
  }

}
 
import { GenericService } from '../../shared/GenericService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs/internal/Observable';

import { Stampante3DDTO } from 'src/app/shared/dto/stampante3D/stampante3D';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { SedeDTO } from 'src/app/shared/dto/sede/sede';
import { PrenotazioneStampante3DDTO } from 'src/app/shared/dto/prenotazione-stampante3D/prenotazione-stampante3D';



@Injectable({
  providedIn: 'root'
})
export class PrenotazioneStampante3DService extends GenericService<PrenotazioneStampante3DDTO, number> {

  url: String

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/prenotazionestampante3d';
  }

  public prenotazionestampante3DSearchByList(pageNumber: number, pageSize: number, direction: string, sortFields: string[], collaboratore: CollaboratoreDTO, stampante3D: Stampante3DDTO, sede: SedeDTO, dataDa: Date, dataA: Date): Observable<ResponseQueryByCriteria<PrenotazioneStampante3DDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortFields != null) params["sortFields"] = sortFields;
    if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
    if (stampante3D != null) params["idStampante3D"] = stampante3D.id;
    if (sede != null) params["idSede"] = sede.id;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    return this.http.get<ResponseQueryByCriteria<PrenotazioneStampante3DDTO>>(`${this.url}/prenotazionestampante3DList`, {
      params: params
    });
  }
}    
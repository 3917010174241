import { GenericService } from './../../shared/GenericService';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CreditoDTO } from '../../shared/dto/credito/credito';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreditoService extends GenericService<CreditoDTO, number> {
  
  constructor(http: HttpClient) { 
    super(http);
    this.url = environment.urlPrefix+'/services/rest/json/credito';
  }

  public creditoSearchByCollaboratore(idCollaboratore: number) : Observable<CreditoDTO>{
    let params = {};
    if (idCollaboratore!=null) params["idCollaboratore"] = idCollaboratore;
    return this.http.get<CreditoDTO>(`${this.url}/creditoSearchByCollaboratore`, {
      params : params
    });
  }

}

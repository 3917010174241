import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericService } from "src/app/shared/GenericService";
import { CollaboratoreDTO } from "src/app/shared/dto/domain/collaboratore";
import { InterventoTurnoReperibilitaDTO } from "src/app/shared/dto/gestione-servizi-reperibilita/InterventoTurnoReperibilitaDTO";
import { ServiziReperibilitaDTO } from "src/app/shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { ResponseQueryByCriteria } from "src/app/shared/dto/responseQueryByCriteria";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })

export class InterventiReperibilitaService extends GenericService<InterventoTurnoReperibilitaDTO, number> {

    constructor(http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/services/rest/json/intervento-turno-reperibilita';

    }
  

    list(pageNumber: number,
        pageSize: number,
        sortDirection: string,
        sortField: any,
        collaboratore: CollaboratoreDTO,
        servizio: ServiziReperibilitaDTO,
        dataDa: any,
        dataA: any) {
        let params = {};
        if (pageNumber != null) {
            params["pageNumber"] = String(pageNumber);
        }
        if (pageSize != null) {
            params["pageSize"] = String(pageSize);
        }
        if (sortDirection != null) {
            params["direction"] = sortDirection;
        }
        if (sortField != null) {
            params["sortField"] = sortField;
        }
        if (collaboratore != null) {
            params["collaboratore"] = collaboratore.id;
        }
        if (servizio != null) {
            params["servizio"] = servizio.id;
        }
        if (dataDa != null) {
            params["dataDa"] = dataDa.toISOString();
        }
        if (dataA != null) {
            params["dataA"] = dataA.toISOString();
        }
        return this.http.get<ResponseQueryByCriteria<InterventoTurnoReperibilitaDTO>>(`${this.url}/interventiListByServizio`, { params: params });


    }




}
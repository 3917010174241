import { LogicalDeleteDTO } from "../basic/logicalDeleteDTO";
import { TenantDTO } from "../domain/tenant";
import { SedeDTO } from "../sede/sede";

export class Stampante3DDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    codice: string;
    modello: string;
    sede: SedeDTO;
    colore: string;
}
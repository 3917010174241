import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PresenzaDTO } from '../shared/dto/presenze/presenza';
import { PresenzaService } from '../services/presenze/presenza.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { LocationDTO } from '../shared/dto/presenze/location';
import { LocationService } from '../services/presenze/location.service';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { MatSelectChange } from '@angular/material/select';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { GreenPassService } from '../services/green-pass/green-pass.service';
import { RapportinoFinaleService } from '../services/rapportino/rapportino-finale.service';
import { RapportinoFinaleDTO } from '../shared/dto/rapportino/rapportinoFinale';

@Component({
  selector: 'app-gestionePresenza',
  templateUrl: './gestionePresenzaDetail.component.html',
  styleUrls: ['./gestionePresenzaDetail.component.scss']
})
export class GestionePresenzaDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  presenzaId: number;
  presenza: PresenzaDTO;
  locations: LocationDTO[];
  collaboratori: CollaboratoreDTO[];
  index: number[];
  today: Date;
  minDate: Date;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;
  scanGreenPass: boolean;
 
  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  audioOk = new Audio();

  audioError = new Audio();
  
  barcodeReading: boolean;

  constructor(
    private urlService: UrlService,
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private presenzaService: PresenzaService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private locationService: LocationService,
    private collaboratoreService: CollaboratoreService,
    private rapportinoFinaleService: RapportinoFinaleService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>,
    private greenPassService: GreenPassService) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      location: new FormControl(null, Validators.required),
      dataPresenza: new FormControl('', Validators.required),
      numeroDiOre: new FormControl('', [Validators.required, Validators.min(1), Validators.max(8)]),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.minDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate() - 1,
    );
    this.today = new Date();
    this.scanGreenPass = false;
    this.audioOk.src = "../../assets/beep.wav";
    this.audioOk.load();
    this.audioError.src = "../../assets/Error.wav";
    this.audioError.load();
  }

  ngOnInit() {
    this.presenzaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.presenzaId != null && this.presenzaId != 0) {
      this.presenzaRead();
    } else {
      this.presenzaInit();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.locationService.locationList(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<LocationDTO>) => {
          console.log("response : " + res);
          this.locations = res.content;
        }
      );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
    this.barcodeReading = false;
    this.miaFunzione(
      () => {
        console.log("hello world");
      }
    );
  }

  presenzaSave() {
    if (this.presenza.id == null) {
      this.presenzaCreate();
    } else {
      this.presenzaUpdate();
    }
  }

  presenzaCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.presenzaService.create(this.presenza).subscribe(
        (res: PresenzaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.presenza = res;
          this.presenzaId = this.presenza.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPresenzaException_PRESENZA_TOO_MANY_HOURS") {
            this.snackBar.open("Attenzione! Non puoi inserire più di 8 ore di presenza al giorno.", null, { duration: 4000 });
          }
        });
    }
  }

  presenzaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la presenza?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.presenzaService.update(this.presenza).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.presenzaRead();
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPresenzaException_PRESENZA_TOO_MANY_HOURS") {
                this.snackBar.open("Attenzione! Non puoi inserire più di 8 ore di presenza al giorno.", null, { duration: 4000 });
              }
            });
        }
      });
    }
  }

  presenzaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la presenza?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.presenzaService.delete(this.presenza.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/gestione-presenza"]);
            }
          );
        }
      });
    }
  }

  presenzaRead() {
    this.presenzaService.read(this.presenzaId).subscribe(
      (res: PresenzaDTO) => {
        console.log("response : " + res);
        this.presenza = res;
        this.presenzaId = res.id;
        this.dtoToForm();
      }
    );
  }

  presenzaInit() {
    this.presenza = new PresenzaDTO();
    this.form.get("numeroDiOre").setValue(8);
    this.form.get("dataPresenza").setValue(new Date());
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
  }

  private formToDto(): void {
    this.presenza.collaboratore = this.form.get("collaboratore").value;
    this.presenza.location = this.form.get("location").value;
    this.presenza.dataPresenza = this.form.get("dataPresenza").value;
    this.presenza.numeroDiOre = this.form.get("numeroDiOre").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.presenza.id);
    this.form.get("collaboratore").setValue(this.presenza.collaboratore);
    this.form.get("location").setValue(this.presenza.location);
    this.form.get("dataPresenza").setValue(this.presenza.dataPresenza);
    this.form.get("numeroDiOre").setValue(this.presenza.numeroDiOre);
    this.form.get("deleteDate").setValue(this.presenza.deleteDate);
    this.form.get("deleteUser").setValue(this.presenza.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.presenza.insertDate));
    this.form.get("insertUser").setValue(this.presenza.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.presenza.updateDate));
    this.form.get("updateUser").setValue(this.presenza.updateUser);
    this.form.get("optLock").setValue(this.presenza.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }

  locationChanged(event: MatSelectChange) {
    console.log(event);
    this.scanGreenPass = (event.value as LocationDTO).greenPassCheckNeeded;
    if (this.scanGreenPass) {
      this.scanner.restart();
    } else {
      if (this.scanner!=null) {
        this.scanner.reset();
      }
      this.presenza.greenPassContent = null; // da togliere????
    }
  }

  qrcodeReaded(qrcode : string) {
    console.log("qrcode readed : " + qrcode);

    if (this.barcodeReading || this.presenza.greenPassContent!=null) return;
    this.barcodeReading=true;

    if (qrcode.startsWith("HC1:")) {
      let d = new Date(this.form.get("dataPresenza").value);
      if (d.toString() == 'Invalid Date') {
        d = new Date();
      }
      this.greenPassService.check(d, this.form.get("collaboratore").value.id, qrcode).subscribe(
        (res) => {
          console.log("green pass ok");
          this.audioOk.play();
          this.presenza.greenPassContent = qrcode;
          this.barcodeReading=false;
        }, (err: HttpErrorResponse) => {
          console.log("green pass ko");
          console.log(err);
          let message : string;
          if (err.error!= null && err.error.reasonBundleKey!=undefined) {
            let fail : ResponseFail = err.error;
            message = fail.reason, 'Error';
          } else {
            if(err.status == 403){
              message = "L'utente non ha i permessi necessari per svolgere questa operazione.";
            } else {
              message = 'Generic error contacting server! status : ' + err.status + ', message : ' + err.message;
            }
          }
          this.audioError.play();
          this.presenza.greenPassContent = null;
          this.snackBar.open(message, null, { duration: 3000 });
          this.barcodeReading=false;
        }
      );
    } else {
      this.audioError.play();
      this.barcodeReading=false;
    }
  }

  scannerStarted(event?: any) {
    console.log("scannerStarted()");
  }

  scannerCamerasFound(devices : MediaDeviceInfo[]) {
    console.log("scannerCamerasFound()");
  }

  miaFunzione(callback: any) {
    console.log("robe");
    callback();
  }

  openGPCheck(){
    this.scanGreenPass = (this.form.get("location").value as LocationDTO)?.greenPassCheckNeeded;
    if (this.scanGreenPass) {
      this.presenza.greenPassCheck = null;
    } else {
      if (this.scanner!=null) {
        this.scanner.reset();
      }
      this.presenza.greenPassContent = null;
    }
  }

  isNotNewCreation():boolean {
    return this.presenzaId==0;
  }

  btnClick() {
    let oggi = new Date();
    this.rapportinoFinaleService.rapportinoFinaleList(
      0,
      9999,
      null,
      null,
      this.presenza.collaboratore,
      null,
      null,
      null
    ).subscribe(
      (res: ResponseQueryByCriteria<RapportinoFinaleDTO>) => {
        let idRapportino = this.searchRapportinoByPresenza(res);
        if (idRapportino == null) {
          this.router.navigateByUrl('/rapportino-finale');
        } else {
          this.router.navigateByUrl('/rapportino-finale/detail/'+idRapportino);
        }
      })
  };

  searchRapportinoByPresenza(res: ResponseQueryByCriteria<RapportinoFinaleDTO>){
    let dataPresenza = new Date(this.presenza.dataPresenza);
          return res.content.find((r: RapportinoFinaleDTO) => {
          let dataRapportino = new Date(r.data);
          return dataRapportino.getMonth() + 1 === dataPresenza.getMonth() + 1
              && dataRapportino.getFullYear() === dataPresenza.getFullYear()
          })?.id;
        return null;
  }
}
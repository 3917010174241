import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { NavigatorService } from '../services/navigator.service';
import { LocationService } from '../services/presenze/location.service';
import { LocationDTO } from '../shared/dto/presenze/location';
import { GenericComponent } from '../shared/GenericComponent';

@Component({
  selector: 'app-location',
  templateUrl: './locationPrint.component.html',
  styleUrls: ['./locationPrint.component.scss']
})

export class LocationPrintComponent extends GenericComponent implements OnInit, OnDestroy {

  locationId: number;
  location: LocationDTO;

  constructor(
    private locationService: LocationService,
    private activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      router,
      dateAdapter,
      snackBar,
      timestampFormatPipe);
  }

  ngOnInit() {
    this.locationId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.locationId != null && this.locationId != 0) {
      this.locationRead();
    } else {
      this.snackBar.open("Attenzione! location non valida.", null, { duration: 5000 });
    }
  }

  locationRead() {
    this.locationService.read(this.locationId).subscribe(
      (res: LocationDTO) => {
        console.log("response : " + res);
        this.location = res;
        this.locationId = res.id;
      }
    );
  }

  ngOnDestroy(): void {
  }
}

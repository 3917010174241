import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';

export class LocationDTO extends LogicalDeleteDTO {
    
    id: number;
    tenant: TenantDTO;
    descrizione: string;
    emailList: String;
    greenPassCheckNeeded: boolean;
}
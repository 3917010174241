<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Cambio password</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- password attuale -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="oldPassword" placeholder="Password attuale" type="password" />
          <mat-error *ngIf="form.get('oldPassword').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- password nuova -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="newPassword" placeholder="Nuova password" type="password" />
          <mat-error *ngIf="form.get('newPassword').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- verifica password nuova -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="newPasswordVerify" placeholder="Verifica nuova password" type="password" />
          <mat-error *ngIf="form.get('newPasswordVerify').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="passwordUpdate()" [disabled]="form.invalid || !form.dirty">Save</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
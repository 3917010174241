import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { PresenzaDTO } from '../presenze/presenza';
import { AssenzaRapportinoDTO } from './assenzaRapportino';
import { MalattiaRapportinoDTO } from './malattiaRapportino';
import { RapportinoDTO } from './rapportino';

export class PresenzaRapportini extends LogicalDeleteDTO {
    presenza: PresenzaDTO | AssenzaRapportinoDTO | MalattiaRapportinoDTO;
    rapportini: RapportinoDTO[] = [];
    oreCensite: number;
}
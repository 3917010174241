import { TemplateDTO } from './../shared/dto/domain/template';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from './../services/navigator.service';
import { TemplateService } from './../services/domain/template.service';
import { Component, OnInit } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { Router, ActivatedRoute } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-gestione-template-detail',
  templateUrl: './gestione-template-detail.component.html',
  styleUrls: ['./gestione-template-detail.component.scss']
})
export class GestioneTemplateDetailComponent extends GenericDetailComponent implements OnInit {

  functions: String[];
  template: TemplateDTO;
  templateId: number;
  
  constructor(
    private templateService: TemplateService,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl(''),
      codTemplate: new FormControl('', Validators.required),
      descrizione: new FormControl('', Validators.required),
      template: new FormControl('', Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    })
  }

  private formToDto() {
    this.template.codTemplate = this.form.get('codTemplate').value;
    this.template.descrizione = this.form.get('descrizione').value;
    this.template.template = this.form.get('template').value;
  }

  private dtoToForm() {
    this.form.get('id').setValue(this.template.id);
    this.form.get('codTemplate').setValue(this.template.codTemplate);
    this.form.get('descrizione').setValue(this.template.descrizione);
    this.form.get('template').setValue(this.template.template);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.template.insertDate));
    this.form.get('insertUser').setValue(this.template.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.template.updateDate));
    this.form.get('updateUser').setValue(this.template.updateUser);
  }

  templateRead() {
    this.templateService.read(this.templateId).subscribe(
      (res: TemplateDTO) => {
        this.template = res;
        this.templateId = res.id;
        this.dtoToForm();
      }
    );
  }

  templateSave() {
    if(this.template.id == null) {
      this.templateCreate();
    }
    else {
      this.templateUpdate();
    }
  }

  templateCreate() {
    if(this.form.valid) {
      this.formToDto();
      this.templateService.create(this.template).subscribe(
        (res: TemplateDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.template = res;
          this.templateId = res.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  templateUpdate() {
    if(this.form.valid && this.form.dirty) {
      this.formToDto();
      this.templateService.update(this.template).subscribe(
        (res) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.templateRead();
          this.form.markAsPristine();
        }, (err: any) => {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  templateDelete() {
    if(this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare il template?').subscribe(result => {
        if(result) {
          this.formToDto();
          this.templateService.delete(this.template.id).subscribe(
            (res) => {
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/gestione-template"]);
            }
          );
        }
      });
    }
  }

  ngOnInit() {
    this.templateId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if(this.templateId != null && this.templateId != 0) {
      this.templateRead();
    }
    else {
      this.template = new TemplateDTO;
    }
    this.templateService.templateFunctionList().subscribe(
      (res: String[]) => {
        this.functions = res;
      }
    )
  }
}
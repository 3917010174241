<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettagli ferie</h2>
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Ferie ID" readonly="true" />
          </mat-form-field>
  
          <!-- Collaboratore field -->
          <mat-form-field fxLayout="column">
            <mat-label>Collaboratori</mat-label>
            <mat-select formControlName="collaboratore" [compareWith]="compareDTO"
              [disabled]="!isJappTenantAdmin()">
              <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
               {{collaboratore.codiceCollaboratore}} - {{collaboratore.nome}} {{collaboratore.cognome}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('collaboratore').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
  
          <!-- Responsive -->
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            
            <!-- DataA field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput [matDatepicker]="picker" formControlName="dataA"
                placeholder="Data fine periodo" (click)="picker.open()" readonly="true" [disabled]="!isJappTenantAdmin()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <button mat-button *ngIf="form.get('dataA').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('dataA').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('dataA').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            <!-- ResiduoFerie field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="residuoFerie" [max]="999.99999"
                placeholder="Digita valore residuo ferie" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('residuoFerie').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('residuoFerie').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('residuoFerie').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>

            <!-- MaturabiliFerie field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="maturabiliFerie" [max]="999.99999"
                placeholder="Digita valore ferie maturabili" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('maturabiliFerie').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('maturabiliFerie').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('maturabiliFerie').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>

            <!-- DaFruireFerie field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="daFruireFerie" [max]="999.99999"
                placeholder="Digita valore ferie da fruire" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('daFruireFerie').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('daFruireFerie').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('daFruireFerie').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            <!-- ResiduoPermessi field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="residuoPermessi" [max]="999.99999"
                placeholder="Digita valore residuo permessi" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('residuoPermessi').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('residuoPermessi').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('residuoPermessi').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>

            <!-- MaturabiliPermessi field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="maturabiliPermessi" [max]="999.99999"
                placeholder="Digita valore permessi maturabili" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('maturabiliPermessi').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('maturabiliPermessi').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('maturabiliPermessi').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>

            <!-- DaFruirePermessi field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="daFruirePermessi" [max]="999.99999"
                placeholder="Digita valore permessi da fruire" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('daFruirePermessi').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('daFruirePermessi').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('daFruirePermessi').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            <!-- ResiduoROL field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="residuoROL" [max]="999.99999"
                placeholder="Digita valore residuo ROL" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('residuoROL').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('residuoROL').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('residuoROL').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>

            <!-- MaturabiliROL field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="maturabiliROL" [max]="999.99999"
                placeholder="Digita valore permessi maturabili" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('maturabiliROL').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('maturabiliROL').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('maturabiliROL').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>

            <!-- DaFruirePermessi field -->
            <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="daFruireROL" [max]="999.99999"
                placeholder="Digita valore ROL da fruire" readonly="false" maxlength="9">
              <button mat-button *ngIf="form.get('daFruireROL').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.get('daFruireROL').setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="form.get('daFruireROL').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>
          </div>

          
  
          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>
  
              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>
  
              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions style="display: flex;">
        <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
        <button mat-raised-button (click)=" ferieSave()" #saved [disabled]="!form.dirty || form.invalid"
          *ngIf="ferie?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="ferieDelete()" *ngIf="ferie?.deleteDate==null && isJappTenantAdmin()">Delete</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
import { LogicalDeleteDTO } from "../basic/logicalDeleteDTO";
import { CollaboratoreDTO } from "../domain/collaboratore";
import { TenantDTO } from "../domain/tenant";
import { CorsiUdemyDTO } from "./corsiUdemy";


export class FrequentaCorsoDTO extends LogicalDeleteDTO{
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    corsoUdemy: CorsiUdemyDTO;
    dataInizio: Date;
    dataFine: Date;
}
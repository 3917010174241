import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { CreditoDTO } from '../shared/dto/credito/credito';
import { CreditoService } from '../services/credito/credito.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CatalogoDTO } from '../shared/dto/credito/catalogo';
import { CatalogoService } from '../services/credito/catalogo.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-gestione-credito',
  templateUrl: './gestione-credito.component.html',
  styleUrls: ['./gestione-credito.component.scss']
})
export class GestioneCreditoComponent implements OnInit, OnDestroy{

  readonly displayedColumns: string[] = ['descrizione', 'totaleCrediti'];

  parameters = {
    dataSource: [],
    showList: false,
    filter: null,
    collaboratore : null,
    credito : null,
    descrizione: null,
    sortField: null,
    sortDirection: null,
    pageNumber: 0,
    pageSize: 50,
    length: 0
  }

  credito : CreditoDTO;
  creditoId: number;
  caffeDisp: number;
  creditodisp: number;
  resto : number;
  collaboratore: CollaboratoreDTO;
  collaboratori : CollaboratoreDTO[];

  
    constructor(
      private creditoService: CreditoService, 
      private componentCacheService: ComponentCacheService,
      private navigatorService : NavigatorService,
      private catalogoService : CatalogoService,
    ) {
      this.credito = new CreditoDTO();
    }
  
  
    creditoList(){
      this.creditoService.creditoSearchByCollaboratore(this.collaboratore.id).subscribe(
        (res: CreditoDTO) => {
          console.log("response : " + res);
          this.credito = res;
          this.creditoId = res.id;
          this.creditodisp = res.credito;
        }
      );
    }

    catalogoList() {
      this.catalogoService.catalogoSearchByDescrizione(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        this.parameters.sortDirection,
        this.parameters.sortField,
        this.parameters.descrizione).subscribe(
          (res: ResponseQueryByCriteria<CatalogoDTO>) => {
            console.log("response : " + res);
            this.parameters.dataSource = res.content;
            this.parameters.pageNumber = res.pageNumber;
            this.parameters.length = res.totalElements;
            this.parameters.showList = true;
          }
        )
    }

    compareCollaboratore(o1: CollaboratoreDTO, o2: CollaboratoreDTO): boolean{
      if (o1==null || o2==null) return false;
      return o1.id === o2.id;
      }
  
    setSort(sort: Sort) {
        this.parameters.sortDirection = sort.direction;
        this.parameters.sortField = sort.active;
        this.catalogoList();
    }

    setPaginator(pagination: PageEvent) {
        this.parameters.pageSize = pagination.pageSize;
        this.parameters.pageNumber = pagination.pageIndex;
        this.catalogoList();
    }

    ngOnInit() {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.collaboratore = res;
        }
        );
        if (this.componentCacheService.has(GestioneCreditoComponent.name)) {
          this.parameters = this.componentCacheService.get(GestioneCreditoComponent.name);
          if (this.parameters.showList) {
            this.creditoList();
          }
        }
        this.creditoList();
        this.catalogoList();
    }
  
    ngOnDestroy(): void {
      this.componentCacheService.set(GestioneCreditoComponent.name, this.parameters);
    }

}

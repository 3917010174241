import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ComunicazioneDTO } from '../shared/dto/comunicazioni/comunicazione';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigatorService } from '../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ComunicazioneService } from '../services/comunicazioni/comunicazione.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-comunicazioneDetail',
  templateUrl: './comunicazioneDetail.component.html',
  styleUrls: ['./comunicazioneDetail.component.scss']
})
export class ComunicazioneDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  comunicazioneId: number;
  comunicazione: ComunicazioneDTO;
  collaboratori: CollaboratoreDTO[];
  dataMax: Date;
  dataMin: Date;

  constructor(
    private comunicazioneService: ComunicazioneService,
    private collaboratoreService: CollaboratoreService,
    navigatorService: NavigatorService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null),
      url: new FormControl('', [Validators.required, Validators.pattern(this.URL_REGEXP)]),
      titolo: new FormControl('', Validators.required),
      descrizione: new FormControl('', Validators.required),
      inizioValid: new FormControl(''),
      fineValid: new FormControl(''),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.comunicazioneId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.comunicazioneId != null && this.comunicazioneId != 0) {
      this.comunicazioneRead();
    } else {
      this.comunicazione = new ComunicazioneDTO();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }
  setMinDate() {
    this.dataMin = this.form.get("inizioValid").value;
  }


  setMaxDate() {
    this.dataMax = this.form.get("fineValid").value;
  }

  comunicazioneSave() {
    if (this.comunicazione.id == null) {
      this.comunicazioneCreate();
    } else {
      this.comunicazioneUpdate();
    }
  }

  comunicazioneCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.comunicazioneService.create(this.comunicazione).subscribe(
        (res: ComunicazioneDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.comunicazione = res;
          this.comunicazioneId = this.comunicazione.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidComunicazioneException_COMUNICAZIONE_INAVLID_INIZIO_EQUALS_FINE") {
            this.snackBar.open("Attenzione! Impossibile creare una comunicazione che ha come inizio validita uguale alla fine validita", null, { duration: 4500 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidComunicazioneException_COMUNICAZIONE_INVALID_FINE") {
            this.snackBar.open("Attenzione! Impossibile creare una comunicazione che ha come fine validita una data minore di oggi", null, { duration: 4500 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidComunicazioneException_COMUNICAZIONE_INVALID_INIZIO") {
            this.snackBar.open("Attenzione! Impossibile creare una comunicazione che ha come inizio validita una data minore di oggi", null, { duration: 4500 });
          }
        }
      );
    }
  }

  comunicazioneUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la comunicazione?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.comunicazioneService.update(this.comunicazione).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.comunicazioneRead();
              this.form.markAsPristine();
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidComunicazioneException_COMUNICAZIONE_INAVLID_INIZIO_EQUALS_FINE") {
                this.snackBar.open("Attenzione! Impossibile creare una comunicazione che ha come inizio validita uguale alla fine validita", null, { duration: 4500 });
              }
            }
          );
        }
      });
    }
  }

  comunicazioneDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la comunicazione?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.comunicazioneService.delete(this.comunicazione.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/comunicazione"]);
            }
          );
        }
      });
    }
  }

  comunicazioneRead() {
    this.comunicazioneService.read(this.comunicazioneId).subscribe(
      (res: ComunicazioneDTO) => {
        console.log("response : " + res);
        this.comunicazione = res;
        this.comunicazioneId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.comunicazione.collaboratore = this.form.get("collaboratore").value;
    this.comunicazione.titolo = this.form.get("titolo").value;
    this.comunicazione.url = this.form.get("url").value;
    this.comunicazione.descrizione = this.form.get("descrizione").value;
    this.comunicazione.inizioValid = this.form.get("inizioValid").value;
    console.log(this.form.get("fineValid").value);
    this.comunicazione.fineValid = this.form.get("fineValid").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.comunicazione.id);
    this.form.get("collaboratore").setValue(this.comunicazione.collaboratore);
    this.form.get("url").setValue(this.comunicazione.url);
    this.form.get("titolo").setValue(this.comunicazione.titolo);
    this.form.get("descrizione").setValue(this.comunicazione.descrizione);
    if (this.comunicazione.inizioValid != null) {
      this.form.get("inizioValid").setValue(new Date(this.comunicazione.inizioValid));
    }
    if (this.comunicazione.fineValid != null) {
    this.form.get("fineValid").setValue(new Date(this.comunicazione.fineValid));
    }
    this.dataMin = this.comunicazione.inizioValid;
    this.dataMax = this.comunicazione.fineValid;
    this.form.get("deleteDate").setValue(this.comunicazione.deleteDate);
    this.form.get("deleteUser").setValue(this.comunicazione.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.comunicazione.insertDate));
    this.form.get("insertUser").setValue(this.comunicazione.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.comunicazione.updateDate));
    this.form.get("updateUser").setValue(this.comunicazione.updateUser);
    this.form.get("optLock").setValue(this.comunicazione.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}
import { Component, OnDestroy, OnInit } from "@angular/core";
import { GenericListComponent } from "../shared/GenericListCoumponent";
import { ServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { ComponentCacheService } from "../services/component-cache.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { NavigatorService } from "../services/navigator.service";
import { DateAdapter } from "@angular/material/core";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { ServiziRepService } from "../services/gestione-servizi-reperibilita/serviziRep.service";
import { NumeroReperibilitaDTO } from "../shared/dto/reperibilità/NumeroReperibilita";
import { NumeroService } from "../services/reperibilità/numero.service";






@Component({
    selector: 'app-gestione-servizi-rep',
    templateUrl: './gestione-servizi-rep.component.html',
    styleUrls: ['./gestione-servizi-rep.component.scss']
  })
  export class GestioneServiziComponent extends GenericListComponent implements OnInit, OnDestroy{
    numeriReperibilita: NumeroReperibilitaDTO[]
    readonly EMAIL_SEPARATOR=";"

  

    constructor(
      private numeroService: NumeroService,
      private serviziRepService: ServiziRepService,
      componentCacheService: ComponentCacheService,
      navigatorService: NavigatorService,
      dateAdapter: DateAdapter<Date>,
      router: Router,
      snackBar: MatSnackBar,
      timestampFormatPipe: TimestampFormatPipe
    ) {
      super(
        navigatorService,
        componentCacheService,
        dateAdapter,
        router,
        snackBar,
        timestampFormatPipe);
      this.displayedColumns = ['id', 'descrizione',"numeroReperibilita.mainNumber" ,'email','detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        descrizione: null,
        numeroReperibilita:null,
        sortField: null,
        sortDirection: null,
        pageNumber: 0,
        pageSize: 50,
        length: 0
      } 
    }
  
    list() {
      
      this.serviziRepService.serviziList(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        this.parameters.sortDirection,
        this.parameters.sortField,
        this.parameters.descrizione,
        this.parameters.email,
        this.parameters.numeroReperibilita
      ).subscribe(
          (res: ResponseQueryByCriteria<ServiziReperibilitaDTO>) => {
            console.log("response : " + res);
            this.parameters.dataSource = res.content;
            this.parameters.pageNumber = res.pageNumber;
            this.parameters.length = res.totalElements;
            this.parameters.showList = true;
          }
        )
    }
  
    ngOnInit() {
      super.ngOnInit();
      this.numeroService.numeriList(
        0,
        9999,
        'ASC',
        'descrizione',
        '').subscribe(
          (res: ResponseQueryByCriteria<NumeroReperibilitaDTO>) => {
            this.numeriReperibilita = res.content;
          },
        );
    }
  
    ngOnDestroy(): void {
      super.ngOnDestroy();
    }

    parseEmail(email:String){
      return email.split(this.EMAIL_SEPARATOR)

    }
  
  
  
  
  
  
  
    
  }







    
  
  
  
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { Stampante3DDTO } from '../shared/dto/stampante3D/stampante3D';
import { Stampante3DService } from '../services/stampante3D/stampante3D.service';
import { SedeService } from '../services/sede/sede.service';
import { SedeDTO } from '../shared/dto/sede/sede';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: "app-stampante3D-detail",
  templateUrl: "./stampante3D-detail.component.html",
  styleUrls: ["./stampante3D-detail.component.scss"],
})
export class Stampante3DDetailComponent
  extends GenericDetailComponent
  implements OnInit, OnDestroy {
  idStampante3D: number;
  stampante3D: Stampante3DDTO;
  filteredSedi: SedeDTO[];

  constructor(
    private stampante3DService: Stampante3DService,
    private sedeService: SedeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: "", disabled: true }),
      codice: new FormControl("", Validators.required),
      modello: new FormControl("", Validators.required),
      sede: new FormControl("", Validators.required),
      colore: new FormControl("", Validators.required),
      deleteDate: new FormControl({ value: "", disabled: true }),
      deleteUser: new FormControl({ value: "", disabled: true }),
      insertDate: new FormControl({ value: "", disabled: true }),
      insertUser: new FormControl({ value: "", disabled: true }),
      updateDate: new FormControl({ value: "", disabled: true }),
      updateUser: new FormControl({ value: "", disabled: true }),
      optLock: new FormControl({ value: "", disabled: true }),
    });
  }

  ngOnInit() {
    this.idStampante3D = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.idStampante3D != null && this.idStampante3D != 0) {
      this.stampante3DRead();
    } else {
      this.stampante3D = new Stampante3DDTO();
    }

    this.sedeService
      .sedeListByDescrizione(0, 9999, "ASC", "descrizione", "")
      .subscribe((res: ResponseQueryByCriteria<SedeDTO>) => {
        this.filteredSedi = res.content;
      });
  }

  stampante3DSave() {
    if (this.stampante3D.id == null) {
      this.stampante3DCreate();
    } else {
      this.stampante3DUpdate();
    }
  }

  stampante3DCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.stampante3DService
        .create(this.stampante3D)
        .subscribe((res: Stampante3DDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, {
            duration: 3000,
          });
          this.stampante3D = res;
          this.idStampante3D = this.stampante3D.id;
          this.dtoToForm();
          this.router.navigate(["/stampante3d"]);
        },
        (err: HttpErrorResponse) => {
          if (
            err.status == 400 &&
            (<ResponseFail>err.error).reasonBundleKey ==
              "InvalidStampante3DException_STAMPANTE_3D_ALREADY_EXIST"
          ) {
            this.snackBar.open(
              "Attenzione! Esiste già una stampante con quel codice o colore",
              null,
              { duration: 3000 }
            );
          }
        });
    }
  }

  stampante3DUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm(
        "Sei sicuro di voler sovrascrivere la stampante 3D?"
      ).subscribe((result) => {
        if (result) {
          this.formToDto();
          this.stampante3DService.update(this.stampante3D).subscribe((res) => {
            this.snackBar.open("Salvataggio avvenuto con successo!", null, {
              duration: 3000,
            });
            this.router.navigate(["/stampante3d"]);
          },
          (err: HttpErrorResponse) => {
            if (
              err.status == 400 &&
              (<ResponseFail>err.error).reasonBundleKey ==
                "InvalidStampante3DException_STAMPANTE_3D_ALREADY_EXIST"
            ) {
              this.snackBar.open(
                "Attenzione! Esiste già una stampante con quel codice o colore",
                null,
                { duration: 3000 }
              );
            }
          });
        }
      });
    }
  }

  stampante3DDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la stampante 3D?").subscribe(
        (result) => {
          if (result) {
            this.formToDto();
            this.stampante3DService.delete(this.stampante3D.id).subscribe((res) => {
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/stampante3d"]);
            });
          }
        }
      );
    }
  }

  stampante3DRead() {
    this.stampante3DService
      .read(this.idStampante3D)
      .subscribe((res: Stampante3DDTO) => {
        this.stampante3D = res;
        this.idStampante3D = res.id;
        this.dtoToForm();
      });
  }

  isValidFormSede(): boolean {
    if (
      typeof this.form.get("sede").value === "string" ||
      this.form.get("sede").value == null
    ) {
      return false;
    }
    return true;
  }

  private formToDto(): void {

    this.stampante3D.codice = this.form.get("codice").value;
    this.stampante3D.modello = this.form.get("modello").value;
    this.stampante3D.sede = this.form.get("sede").value;

    let coloreValue = this.form.get("colore").value;
    if (coloreValue && coloreValue.hex) {
      this.stampante3D.colore = "#" + coloreValue.hex;
    } else if (this.stampante3D.colore) {
      // Se il campo colore non è stato modificato, mantieni il valore corrente
      this.stampante3D.colore = this.stampante3D.colore;
    }
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.stampante3D.id);
    this.form.get("codice").setValue(this.stampante3D.codice);
    this.form.get("modello").setValue(this.stampante3D.modello);
    this.form.get("sede").setValue(this.stampante3D.sede);
    this.form.get("colore").setValue(this.stampante3D.colore);
    this.form.get("deleteDate").setValue(this.stampante3D.deleteDate);
    this.form.get("deleteUser").setValue(this.stampante3D.deleteUser);
    this.form.get("insertDate").setValue(
      this.timestampFormatPipe.transform(this.stampante3D.insertDate)
    );
    this.form.get("insertUser").setValue(this.stampante3D.insertUser);
    this.form.get("updateDate").setValue(
      this.timestampFormatPipe.transform(this.stampante3D.updateDate)
    );
    this.form.get("updateUser").setValue(this.stampante3D.updateUser);
    this.form.get("optLock").setValue(this.stampante3D.optLock);
  }

  ngOnDestroy(): void { }
}


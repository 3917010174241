<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli Stampa</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id Stampa" readonly="true" />
                </mat-form-field>

                <!-- Collaboratore field -->
                <mat-form-field>
                    <mat-select name="collaboratore" [compareWith]="compareDTO" formControlName="collaboratore"
                        placeholder="Scegli il richiedente">
                        <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                            {{collaboratore.nome}} {{collaboratore.cognome}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Stampante 3D field -->
                <mat-form-field>
                    <mat-select name="stampante3D" [compareWith]="compareDTO" formControlName="stampante3D"
                        placeholder="Stampante 3D">
                        <mat-option *ngFor="let stampante3D of filteredStampanti3D" [value]="stampante3D">
                            {{stampante3D.codice}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('stampante3D').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>


                <!-- Responsive -->
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- Data field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput readonly="true" (click)="picker.open()" [matDatepicker]="picker"
                            formControlName="data" placeholder="Scegli data">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <button mat-button *ngIf="form.get('data').value" matSuffix mat-icon-button aria-label="Clear"
                         [disabled]="isFieldsDisabled()" (click)="form.get('data').setValue(null)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('data').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <!-- Responsive -->

                    <!-- Orario Inizio Stampa field -->
                    <mat-form-field fxFlex="25%" fxFlex.md="25%" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput readonly="true" [min]="'00:00'" [max]="'23:00'"
                            [ngxTimepicker]="toggleTimepickerA" [format]="24" formControlName="orarioInizioStampa"
                            placeholder="Inizio stampa">
                        <ngx-material-timepicker #toggleTimepickerA [preventOverlayClick]="false" hoursOnly="true">
                        </ngx-material-timepicker>
                        <mat-error *ngIf="form.get('orarioInizioStampa').errors?.required">
                            Campo obbligatorio
                        </mat-error>

                    </mat-form-field>

                    <!-- Ore field -->
                    <mat-form-field fxFlex="25%" fxFlex.md="25%" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput type="number" formControlName="ore" placeholder="Numero di ore">
                        <mat-error *ngIf="form.get('ore').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field>
                    <input matInput formControlName="url" placeholder="Link sorgente" />
                </mat-form-field>

                <mat-form-field>
                    <input matInput formControlName="info" placeholder="Info" />
                </mat-form-field>


                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/prenotazionestampante3d')" color="primary">Back</button>
            <button mat-raised-button (click)="prenotazionestampante3DSave()"
                [disabled]="form.invalid || !form.dirty || !isValidFormStampante3D()"
                *ngIf="prenotazionestampa?.deleteDate==null">Save</button>
            <button mat-raised-button (click)="prenotazionestampante3DDelete()"
                [disabled]="prenotazionestampa?.id==null || isFieldsDisabled()" *ngIf="prenotazionestampa?.deleteDate==null">Delete</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
import { Component, OnInit } from '@angular/core';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { NavigatorService } from '../services/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TurnoAmService } from '../services/turno-am/turno-am.service';
import { TurnoAMDTO } from '../shared/dto/turno-am/turno-am';
import { FormControl } from '@angular/forms';
import { CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreAMDTO } from '../shared/dto/turno-am/collaboratore-am';
import {
  isSameDay, isSameMonth
} from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-turno-am-swap',
  templateUrl: './turno-am-swap.component.html',
  styleUrls: ['./turno-am-swap.component.scss']
})
export class TurnoAmSwapComponent extends GenericDetailComponent implements OnInit {

  turnoAMId: number;
  selected: TurnoAMDTO;

  selectedCollaboratoreControl = new FormControl();
  selectedTipoTurnoControl = new FormControl();
  selectedDataTurnoControl = new FormControl();

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  turniMese: CalendarEvent<TurnoAMDTO>[];
  activeDayIsOpen: boolean = false;
  locale: string = 'it';

  events: CalendarEvent[];

  parameters;

  ordine = "collaboratoreAM.collaboratore.nome";

  constructor(
    private breakpointObserver: BreakpointObserver,
    private turnoAmService: TurnoAmService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.parameters = {
      dataSource: [],
      view: CalendarView.Month,
      viewDate: new Date(),
      dataDaCalendario: null,
      dataACalendario: null
    }
  }

  ngOnInit(): void {
    this.turnoAMId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    this.turnoAmService.read(this.turnoAMId).subscribe(
      (res: TurnoAMDTO) => {
        this.selected = res;
        this.selectedCollaboratoreControl.setValue(this.selected.collaboratoreAM.collaboratore.nome + " " + this.selected.collaboratoreAM.collaboratore.cognome);
        this.selectedTipoTurnoControl.setValue(this.selected.tipoTurno.nome + " (" + this.selected.tipoTurno.orario + ")");
        this.selectedDataTurnoControl.setValue(new Date(this.selected.dataTurno).toLocaleDateString());
      }
    )

  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  estrazionePeriodoVistaMensile(event: CalendarMonthViewBeforeRenderEvent) {
    if ((this.parameters.dataDaCalendario == null || event.period.start.getTime() != this.parameters.dataDaCalendario.getTime()) || (this.parameters.dataACalendario == null || event.period.end.getTime() != this.parameters.dataACalendario.getTime())) {
      this.fetchEvents(null, event.period.start, event.period.end);
    }
  }

  fetchEvents(collaboratore: CollaboratoreAMDTO, dataDa: Date, dataA: Date): void {
    this.turnoAmService.turnoAMListByList(
      collaboratore,
      null,
      dataDa,
      dataA,
      0,
      9999,
      'ASC',
      this.ordine
    ).subscribe((response: ResponseQueryByCriteria<TurnoAMDTO>) => {
      this.parameters.dataDaCalendario = dataDa;
      this.parameters.dataACalendario = dataA;
      let res = response.content;

      this.turniMese = res.map((turno: TurnoAMDTO) => {
        if (turno.id != this.turnoAMId && turno.collaboratoreAM.id != this.selected.collaboratoreAM.id && !turno.tipoTurno.sovrapponibile) {
          let colore = this.hexToRgb(turno.tipoTurno.colore);
          return {
            id: "turno",
            title: turno.collaboratoreAM.collaboratore.nome + " " + turno.collaboratoreAM.collaboratore.cognome + " - " + turno.tipoTurno.nome + " (" + turno.tipoTurno.orario + ")",
            start: new Date(turno.dataTurno),
            cssClass: 'custom-template-cal',
            color: {
              primary: turno.tipoTurno.colore,
              secondary: "rgb(" + colore.r + " " + colore.g + " " + colore.b + " / 10%)",
            },
            meta: {
              turno
            },
          } as CalendarEvent
        }
      })
      this.events = [...this.turniMese.filter((x): x is CalendarEvent => x !== undefined)];
    })
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ turno: TurnoAMDTO }>[];
  }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.parameters.viewDate = date;
      }
    }
  }

  eventClicked(event: CalendarEvent): void {
    this.confirm("Sei sicuro di voler scambiare i turni?").subscribe(result => {
      if (result) {
        this.turnoAmService.turnoAMSwap(this.selected.id, event.meta.turno.id).subscribe(
          (res) => {
            console.log("response : " + res);
            this.snackBar.open("Scambio avvenuto con successo!", null, { duration: 3000 });
            this.router.navigate(["/turno-am"]);
          }, (err: any) => {
            if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidTurnoAMException_TURNO_ALREADY_EXIST") {
              this.snackBar.open("Attenzione! Esiste già un turno per questo collaboratore in questa data.", null, { duration: 3000 });
            }
          }
        );
      }
    });
  }

  hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

}

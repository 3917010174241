import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { endOfDay, isSameDay, isSameMonth, startOfDay } from 'date-fns';
import { CustomDateFormatter } from '../commons/calendar/custom-calendar-date-formater';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { AssenzaService } from '../services/assenza/assenza.service';
import { MalattiaService } from '../services/malattia/malattia.service';
import { ChiusuraService } from '../services/assenza/chiusura.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { NavigatorService } from '../services/navigator.service';
import { PresenzaService } from '../services/presenze/presenza.service';
import { AssenzaDTO } from '../shared/dto/assenza/assenza';
import { MalattiaDTO } from '../shared/dto/malattia/malattia';
import { ChiusuraDTO } from '../shared/dto/assenza/chiusura';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { PresenzaDTO } from '../shared/dto/presenze/presenza';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { CompleanniService } from '../services/compleanni/compleanni.service';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
  ],
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent extends GenericListComponent implements OnInit {

  constructor(
    private breakpointObserver: BreakpointObserver,
    private collaboratoreService: CollaboratoreService,
    private presenzaService: PresenzaService,
    private assenzaService: AssenzaService,
    private malattiaService: MalattiaService,
    private chiusuraService: ChiusuraService,
    private compleanniService: CompleanniService,
    navigatorService: NavigatorService,
    router: Router,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService, componentCacheService, dateAdapter, router, snackBar, timestampFormatPipe);
    this.parameters = {
      collaboratore: null,
      collaboratoreChanged: false,
      dataDaCalendario: null,
      dataACalendario: null,
      view: CalendarView.Month,
      viewDate: new Date(),
    }
  }

  collaboratoreDTO: CollaboratoreDTO;

  collaboratori: CollaboratoreDTO[];

  eventsPresenza: CalendarEvent<PresenzaDTO>[];

  eventsJeniaCompleanni : CalendarEvent<CollaboratoreDTO>[];

  eventsCompleanniPersonali : CalendarEvent<CollaboratoreDTO>[];

  eventsAssenza: CalendarEvent<AssenzaDTO>[];

  eventsMalattia: CalendarEvent<MalattiaDTO>[];

  eventsChiusureAziendali: CalendarEvent<ChiusuraDTO>[];

  locale: string = 'it';

  activeDayIsOpen: boolean = false;

  dataDaCalendario: Date;

  dataACalendario: Date;

  events: CalendarEvent[];

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;


  estrazionePeriodoVistaMensile(event: CalendarMonthViewBeforeRenderEvent) {
    if ((this.parameters.dataDaCalendario == null || event.period.start.getTime() != this.parameters.dataDaCalendario.getTime()) || (this.parameters.dataACalendario == null || event.period.end.getTime() != this.parameters.dataACalendario.getTime())) {
      this.fetchEvents(this.parameters.collaboratore, event.period.start, event.period.end);
    }
  }

  fetchEvents(collaboratore: CollaboratoreDTO, dataDa: Date, dataA: Date): void {

    //PRESENZE
    this.presenzaService.presenzaByMese(
      collaboratore,
      dataDa,
      dataA
    ).subscribe((res: PresenzaDTO[]) => {
      this.parameters.dataDaCalendario = dataDa;
      this.parameters.dataACalendario = dataA;
      this.eventsPresenza = res.map((presenza: PresenzaDTO) => {
        return {
          id: "presenza",
          title: presenza.location.descrizione + " " + presenza.numeroDiOre,
          start: new Date(presenza.dataPresenza),
          cssClass: 'custom-template-cal',
          color: {
            primary: '#1e90ff',
            secondary: '#D1E8FF',
          },
          end: new Date(new Date(presenza.dataPresenza).setHours(new Date(presenza.dataPresenza).getHours() + presenza.numeroDiOre)),
          meta: {
            presenza
          },
        } as CalendarEvent
      })
       //JENIA COMPLEANNI
      this.compleanniService.ottieniJeniaCompleanniByRange(
        dataDa, 
        dataA
      ).subscribe((res: CollaboratoreDTO[]) => {
        let date: Date = new Date();
        let year: number = date.getFullYear();
        this.eventsJeniaCompleanni = res.map((JC: CollaboratoreDTO) => {
          let start: Date =  startOfDay(new Date(date.getFullYear(),new Date(JC.jeniaCompleanno).getMonth(), new Date(JC.jeniaCompleanno).getDate()));
          return {
            id: "jenia compleanno",
            title: JC.nome + " " + JC.cognome + ": Jenia Compleanno",
            allDay: true,
            start: startOfDay(new Date(year,new Date(JC.jeniaCompleanno).getMonth(), new Date(JC.jeniaCompleanno).getDate())),
            cssClass: 'custom-template-cal',
            color: {
              primary: '#1e90ff',
              secondary: '#D1E8FF',
            },
            end: endOfDay(new Date(year,new Date(JC.jeniaCompleanno).getMonth(), new Date(JC.jeniaCompleanno).getDate())),
            meta: {
              JC
            },
          } as CalendarEvent
        })
      })

      //COMPLEANNI PERSONALi
      this.compleanniService.ottieniCompleanniPersonaliByRange(
        dataDa, 
        dataA
      ).subscribe((res: CollaboratoreDTO[]) => {
        let date: Date = new Date();
        this.eventsCompleanniPersonali = res.map((C: CollaboratoreDTO) => {
          return {
            id: "compleanno personale",
            title: C.nome + " " + C.cognome + ": compleanno",
            allDay: true,
            start: startOfDay(new Date(date.getFullYear(),new Date(C.compleannoPersonale).getMonth(), new Date(C.compleannoPersonale).getDate())),
            cssClass: 'custom-template-cal',
            color: {
              primary: '#1e90ff',
              secondary: '#D1E8FF',
            },
            end: endOfDay(new Date(date.getFullYear(),new Date(C.compleannoPersonale).getMonth(), new Date(C.compleannoPersonale).getDate())),
            meta: {
              C
            },
          } as CalendarEvent
        })
      })
      //EVENTO MALATTIA
      this.malattiaService.malattiaListByMese(
        collaboratore,
        dataDa,
        dataA
      ).subscribe((res: MalattiaDTO[]) => {
        this.eventsMalattia = res.map((malattia: MalattiaDTO) => {
          return {
            id: "malattia",
            title: "Assente per malattia",
            start: new Date(malattia.dataDa),
            cssClass: 'custom-template-cal',
            color: {
              primary: '#ff0000',
              secondary: '#ff4d4d',
            },
            end: new Date(malattia.dataA),
            meta: {
              malattia
            },
          } as CalendarEvent
        })

        //ASSENZE
        this.assenzaService.assenzaListByMese(
          collaboratore,
          dataDa,
          dataA
        ).subscribe((res: AssenzaDTO[]) => {
          this.eventsAssenza = res.map((assenza: AssenzaDTO) => {
            return {
              id: "assenza",
              title: assenza.tipoRichiesta + " " + (assenza.ore > 0 ? (" da " + assenza.ore + (assenza.ore == 1 ? " ora" : " ore")) : "") + " " + assenza.statoRichiesta.replace("_", " "),
              start: new Date(assenza.dataDa),
              allDay: assenza.tipoRichiesta == "FERIE" ? true : false,
              cssClass: 'custom-template-cal',
              color: {
                primary: '#1eff61',
                secondary: '#c9ffd9',
              },
              end: new Date(assenza.dataA),
              meta: {
                assenza
              },
            } as CalendarEvent
          })

          //CHIUSURE AZIENDALI
          this.chiusuraService.chiusuraListByMese(
            dataDa,
            dataA
          ).subscribe((res: ChiusuraDTO[]) => {
            this.eventsChiusureAziendali = res.map((chiusura: ChiusuraDTO) => {
              return {
                id: "chiusura",
                title: chiusura.descrizione,
                start: new Date(chiusura.dataDa),
                cssClass: 'custom-template-cal',
                color: {
                  primary: '#ff5e1e',
                  secondary: '#ffc8b3',
                },
                allDay: true,
                end: new Date(chiusura.dataA),
                meta: {
                  chiusura
                },
              } as CalendarEvent
            })
            this.events = [...this.eventsPresenza, ...this.eventsAssenza, ...this.eventsChiusureAziendali, ...this.eventsMalattia, ...this.eventsJeniaCompleanni, ...this.eventsCompleanniPersonali];
          })
        })
      })
    })   
  }

  list() {
    this.fetchEvents(this.parameters.collaboratore, this.parameters.dataDaCalendario, this.parameters.dataACalendario);
  }


  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  eventClicked(event: CalendarEvent): void {
    if (event.id == "presenza") {
      this.router.navigate(["/gestione-presenza/detail/" + event.meta.presenza.id]);
    }
    if (event.id == "assenza") {
      this.router.navigate(["/registra-assenza/detail/" + event.meta.assenza.id]);
    }
    if (event.id == "chiusura") {
      this.router.navigate(["/chiusura/detail/" + event.meta.chiusura.id]);
    }
    if (event.id == "malattia") {
      this.router.navigate(["/registra-malattia/detail/" + event.meta.malattia.id]);
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else if (this.isMobile) {
        this.activeDayIsOpen = true;
      }
      this.parameters.viewDate = date;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe((res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      });

    if (this.parameters.dataDaCalendario != null && this.parameters.dataACalendario != null) {
      this.fetchEvents(this.parameters.collaboratore, this.parameters.dataDaCalendario, this.parameters.dataACalendario);
    }
  }


}

import { GenericService } from './../../shared/GenericService';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { CatalogoDTO } from 'src/app/shared/dto/credito/catalogo';

@Injectable({
  providedIn: 'root'
})
export class CatalogoService extends GenericService<CatalogoDTO, number>{
  
  constructor(http: HttpClient) { 
    super(http);
    this.url = environment.urlPrefix+'/services/rest/json/catalogo';
  }

  public catalogoSearchByDescrizione(pageNumber: number, pageSize: number, direction: string, sortField: string ,descrizione?: String) : Observable<ResponseQueryByCriteria<CatalogoDTO>>{
    let params = {};
    if (pageNumber!=null) params["pageNumber"] = String(pageNumber);
    if (pageSize!=null) params["pageSize"] =  String(pageSize);
    if (direction!=null) params["direction"] = direction;
    if (sortField!=null) params["sortField"] = sortField;
    if (descrizione!=null) params["descrizione"] = descrizione;
    return this.http.get<ResponseQueryByCriteria<CatalogoDTO>>(`${this.url}/catalogoSearchByDescrizione`, {
      params : params
    });
  }

}

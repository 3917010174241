<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Cosa si mangia oggi?</h2>
        <div fxLayout="column" class="responsive-mat-form"></div>
        <mat-card class="mat-elevation-z0" style="background-color: #64dd17; margin-bottom: 5px;" *ngIf="buttonAbilitato == true && !votoPresente && !propostaPresente">
          <mat-card-content>
            <mat-card-subtitle><b>La sessione di voto partirà alle 11:00, proponi cosa mangiare prima che inizi.</b></mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z0" style="background-color: #00e5ff; margin-bottom: 5px;" *ngIf="buttonAbilitato == true && propostaPresente && !votoPresente && showContent">
          <mat-card-content>
            <mat-card-subtitle><b>Hai creato la tua proposta, alle ore 11:00 inizierà la sessione di voto.</b></mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z0" style="background-color: #f9a43f; margin-bottom: 5px;" *ngIf="buttonAbilitato == false && !votoPresente">
          <mat-card-content>
            <mat-card-subtitle><b>La sessione di voto è inziata, esprimi la tua preferenza prima delle 12:00.</b></mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z0" style="background-color: #cc0000; margin-bottom: 5px;" *ngIf="buttonAbilitato == false && votoPresente && showContent">
          <mat-card-content>
            <mat-card-subtitle style="color:white"><b>Hai espresso la tua preferenza, la sessione di voto si concluderà alle 12:00.</b></mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z0" style="background-color: #b0bec5; margin-bottom: 5px;" *ngIf="buttonAbilitato == true && votoPresente && showContent">
          <mat-card-content>
            <mat-card-subtitle><b>Il sondaggio è concluso.</b></mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <mat-card-actions>
          <button mat-raised-button id="new-button" [disabled]="buttonAbilitato == false || votoPresente" [routerLink]="['/proposta/detail/0']">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let proposta"> {{proposta.id}} </td>
            </ng-container>

            <!-- Collaboratore Column -->
            <ng-container matColumnDef="creatore">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Creatore </th>
              <td mat-cell *matCellDef="let proposta"> {{proposta.creatore?.nome}} {{proposta.creatore?.cognome}} </td>
            </ng-container>

            <!-- Domanda Column -->
            <ng-container matColumnDef="domanda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Domanda </th>
              <td mat-cell *matCellDef="let proposta"> {{proposta.domanda}} </td>
            </ng-container>

            <!-- VoteList Column -->
            <ng-container matColumnDef="voteList">
              <th mat-header-cell *matHeaderCellDef> Voti </th>
              <td mat-cell *matCellDef="let proposta"> {{proposta.voteList?.length}} 
              </td>
            </ng-container>

            <!-- Votazione Column -->
            <ng-container matColumnDef="votazione">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let proposta">
                <button mat-mini-fab color="primary" (click)="votoCreate(proposta)" [disabled]="votoPresente || buttonAbilitato"><mat-icon>thumb_up</mat-icon></button>
              </td>
            </ng-container>

            <!-- Check Column -->
            <ng-container matColumnDef="check">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let proposta">
                <mat-icon *ngIf="proposta == propostaVotata" style="color: green;">done</mat-icon>
              </td>
            </ng-container>

            <!-- Info Column -->
            <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let proposta">
                  <button mat-mini-fab color="primary" *ngIf="buttonAbilitato == true && !votoPresente" [routerLink]="['/proposta/detail/', proposta.id]"><mat-icon>search</mat-icon></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-actions>
              <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'proposte'})">Excel</button>
              <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'proposte'})">Csv</button>
              <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'proposte'})">Json</button>
            </mat-card-actions>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
          </div>
        </div>
    </mat-card-content>
  </mat-card>
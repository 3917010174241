import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { ColloquioService } from '../services/colloquio/colloquio.service';
import { ColloquioDTO } from '../shared/dto/colloquio/colloquio';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-colloquio',
  templateUrl: './colloquio.component.html',
  styleUrls: ['./colloquio.component.scss']
})

export class ColloquioComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];

  maxDate: Date;
  minDate: Date;

  constructor(
    private colloquioService: ColloquioService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'collaboratore', 'dataColloquio', 'titolo', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      collaboratore: null,
      dateFrom: null,
      dateTo: null,
      sortField: 'dataColloquio',
      sortDirection: 'DESC',
      pageNumber: 0,
      pageSize: 50,
      length: 0
    };
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.parameters.collaboratore = res;
      }
    );
    this.minDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate() - 1,
    );
    this.maxDate = new Date();
  }

  ngOnInit() {
    if(!this.isJappTenantAdmin()){
      console.log("ciao!");
      this.snackBar.open("Non è possibile entrare nella pagina dei colloqui se non si è admin!", null, { duration: 3000 });
      this.router.navigate(["/"]);
    }
    super.ngOnInit();
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }

  list() {
    this.colloquioService.colloquioList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.filter,
      this.parameters.collaboratore,
      this.parameters.dateFrom,
      this.parameters.dateTo).subscribe(
        (res: ResponseQueryByCriteria<ColloquioDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }, (err: HttpErrorResponse) => {
          //403 Forbidden
          if (err.status == 403) {
            this.snackBar.open("Attenzione! Non è possibile richiedere le informazioni sui colloqui se non si è admin!", null, { duration: 4500 });
          }
        }
      )
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

import { NavigatorService } from './../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';

export class GenericComponent {

    constructor(
        protected navigatorService: NavigatorService,
        protected router: Router,
        protected dateAdapter: DateAdapter<Date>,
        protected snackBar: MatSnackBar,
        protected timestampFormatPipe: TimestampFormatPipe
    ) { 
        this.dateAdapter.setLocale('Italy');
    }

    isJappTenantAdmin(): boolean {
        return this.navigatorService.isJappTenantAdmin();
    }

    isActive(value: string) : boolean {
        return this.navigatorService.isActive(value);
    }
    
    compareDTO(o1: any, o2: any): boolean {
        if (o1 == null || o2 == null) {
            return false;
        }
        if (typeof o1 !== typeof o2) {
            return false;
        }
        return o1.id === o2.id;
    }
 
    back(url: String) {
      this.router.navigate([url]);
    }

}
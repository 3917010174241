import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';

export class TipoTurnoAMDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    nome: string;
    orario: string;
    colore: string;
    ordine: number;
    sovrapponibile: boolean;
    descrizione: string;
}
import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';

export class SedeDTO extends LogicalDeleteDTO {
    
    id: number;
    
    tenant: TenantDTO;
    
    descrizione: string;

    postazioniMassime: number;

    pathImmagine: string;
}
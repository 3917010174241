<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli presenza</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Presenza ID" readonly="true" />
        </mat-form-field>

        <!-- Collaboratore field -->
        <mat-form-field fxLayout="column">
          <mat-label>Collaboratori</mat-label>
          <mat-select formControlName="collaboratore" [compareWith]="compareDTO"
            [disabled]="!isJappTenantAdmin()">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('collaboratore').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- Location field -->
          <mat-form-field fxFlex="55" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <mat-label>Locations</mat-label>
            <mat-select formControlName="location" [compareWith]="compareDTO" (selectionChange)="locationChanged($event)">
              <mat-option *ngFor="let location of locations" [value]="location">
                {{ location.descrizione }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('location').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- DatePresenza field -->
          <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [min]="minDate" [max]="today" [matDatepicker]="picker" formControlName="dataPresenza"
              placeholder="Seleziona data" (click)="picker.open()" readonly="true" (dateChange)="openGPCheck()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="form.get('dataPresenza').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="form.get('dataPresenza').setValue(null)">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataPresenza').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- NumeroDiOre field -->
          <mat-form-field fxFlex="15" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput type="number" formControlName="numeroDiOre" placeholder="Presenza Ore" (change)="openGPCheck()"/>
            <mat-error *ngIf="form.get('numeroDiOre').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

        </div>

        <div *ngIf="this.presenza?.greenPassContent!=null || this.presenza?.greenPassCheck" fxLayout="row" fxLayoutAlign="center center" >
          <mat-icon style="color:green">check_circle_outline</mat-icon>
          <p>Green Pass verificato!</p>
        </div>
          <!-- lettura green pass -->
        <div *ngIf="!form.pristine && scanGreenPass && this.presenza.greenPassContent==null">
          <div fxLayout="row" fxLayoutAlign="center center" >
            <mat-icon style="color:green">qr_code_scanner</mat-icon>
            <p>Inquadra il Green Pass</p>
          </div>
          <zxing-scanner #scanner 
          [formats]="['QR_CODE']" 
          (scanSuccess)="qrcodeReaded($event)"
          (autostarted)="scannerStarted($event)" 
          (camerasFound)="scannerCamerasFound($event)" 
          class="qrcodeScanner">
        </zxing-scanner>
        </div>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions style="display: flex;">
      <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
      <button mat-raised-button (click)=" presenzaSave()" #saved [disabled]="(scanGreenPass && this.presenza.greenPassContent==null) || !form.dirty || form.invalid"
        *ngIf="presenza?.deleteDate==null">Save</button>
    <button mat-raised-button (click)="presenzaDelete()" [disabled]="presenza?.id==null"
        *ngIf="presenza?.deleteDate==null && isJappTenantAdmin()">Delete</button>
      <button mat-raised-button
        [disabled] = "isNotNewCreation()" (click)="btnClick()">Rapportino mensile</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
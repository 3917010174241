import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { ColloquioDTO } from 'src/app/shared/dto/colloquio/colloquio';


@Injectable({
  providedIn: 'root'
})

export class ColloquioService extends GenericService<ColloquioDTO, number> {

  constructor(http: HttpClient) { //private?
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/colloquio';
  }

  public colloquioList(pageNumber: number, pageSize: number, direction: string, sortField: string,  filter?: string, collaboratore?: CollaboratoreDTO, dateFrom?: Date, dateTo?: Date): Observable<ResponseQueryByCriteria<ColloquioDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (filter != null) params["filter"] = filter;
    if (dateFrom != null) params["dateFrom"] = dateFrom.toISOString();
    if (dateTo != null) params["dateTo"] = dateTo.toISOString();
    return this.http.get<ResponseQueryByCriteria<ColloquioDTO>>(`${this.url}/colloquioList`, {
      params: params
    });
  }

}
import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { MalattiaDTO } from '../../shared/dto/malattia/malattia';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';


@Injectable({
  providedIn: 'root'
})
export class MalattiaService extends GenericService<MalattiaDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/malattia'
  }

  public malattiaListByList(
    collaboratore?: CollaboratoreDTO,
    dataDa?: Date,
    dataA?: Date,
    codice?: String,
    pageNumber?: number,
    pageSize?: number,
    direction?: string,
    sortField?: string
  ): Observable<ResponseQueryByCriteria<MalattiaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratore"] = collaboratore.id;
    if (dataA != null) params["dataA"] = dataA.toISOString();
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (codice != null) params["codice"] = codice;


    return this.http.get<ResponseQueryByCriteria<MalattiaDTO>>(`${this.url}/malattiaListByList`, {
      params: params
    });
  }
  //FUNZIONALITÀ PER IL CALENDARIO 
  public malattiaListByMese(collaboratore: CollaboratoreDTO, dataDa: Date, dataA: Date): Observable<MalattiaDTO[]> {
    let params = {};
    if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
    if (dataDa != null) params["dataDa"] = new Date(dataDa.getTime() - (dataDa.getTimezoneOffset() * 60000)).toISOString();
    if (dataA != null) params["dataA"] = new Date(dataA.getTime() - (dataA.getTimezoneOffset() * 60000)).toISOString();
    return this.http.get<MalattiaDTO[]>(`${this.url}/malattiaByMese`, {
      params: params
    });
  }

  //FUNZIONALITÀ PER IL CALENDARIO DELL'AMMINISTRAZIONE
  public searchAllByday(data: Date): Observable<MalattiaDTO[]> {
    let params = {};
    if (data != null) params["data"] = data.toISOString();
    return this.http.get<MalattiaDTO[]>(`${this.url}/searchAllByday`, {
      params: params
    });
  }
}

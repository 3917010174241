import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from './../services/navigator.service';
import { ComponentCacheService } from './../services/component-cache.service';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrenotazioneService } from '../services/prenotazione/prenotazione.service';
import { PrenotazioneDTO } from '../shared/dto/prenotazione/prenotazione';

@Component({
  selector: 'app-gestionePrenotazione',
  templateUrl: './gestionePrenotazione.component.html',
  styleUrls: ['./gestionePrenotazione.component.scss']
})
export class GestionePrenotazioneComponent extends GenericListComponent implements OnInit, OnDestroy {

  statoValues: selectedValue[]
  tipoRichiestaValues: selectedValue[]
  collaboratori: CollaboratoreDTO[];
  collaboratore: CollaboratoreDTO;

  constructor(
    private prenotazioneService: PrenotazioneService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'richiedente', 'dataDa', 'dataA', 'stato', 'luogo', 'riferimento', 'nota', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      richiedente: null,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      luogo: null,
      riferimento: null,
      stato: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.statoValues = [
      { value: null, viewValue: '---Seleziona---' },
      { value: true, viewValue: 'Pagato' },
      { value: false, viewValue: 'In lavorazione' },
    ];
  }

  trascodificaStato(stato: boolean): String {
    var msg: String;
    if (stato == true) {
      msg = 'Pagata';
    } else if (stato == false) {
      msg = 'In lavorazione';
    }
    return msg;
  }

  list() {
    this.prenotazioneService.prenotazioneListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.richiedente,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.luogo,
      this.parameters.riferimento,
      this.parameters.stato).subscribe(
        (res: ResponseQueryByCriteria<PrenotazioneDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnInit() {
    super.ngOnInit();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        this.parameters.richiedente = this.collaboratore;
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );

      if (this.parameters.dataDa == null && this.parameters.initDataDa) {
        this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

export interface selectedValue {
  value: boolean;
  viewValue: string;
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettaglio tenant</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Tenant Id" readonly="true" />
        </mat-form-field>

        <!-- TenantCode field -->
        <mat-form-field>
          <input matInput formControlName="tenantCode" placeholder="Tenant Code" />
          <mat-error *ngIf="form.get('tenantCode').errors?.required">
            Campo obbligatorio
          </mat-error>
          <mat-error *ngIf="form.get('tenantCode').errors?.maxlength">
            Campo troppo lungo
          </mat-error>
        </mat-form-field>

        <!-- Descrizione field -->
        <mat-form-field>
          <input matInput formControlName="description" placeholder="Tenant Description" />
          <mat-error *ngIf="form.get('description').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Mobile Descrizione field -->
        <mat-form-field>
          <input matInput formControlName="mobileDesc" placeholder="Tenant Mobile Description" />
          <mat-error *ngIf="form.get('mobileDesc').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- HostName field -->
        <mat-form-field>
          <input matInput formControlName="hostName" placeholder="Host Name" />
          <mat-error *ngIf="form.get('hostName').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- JwkProviderUrl field -->
        <mat-form-field>
          <input matInput formControlName="jwkProviderUrl" placeholder="Openid JWK Provider URL" />
          <mat-error *ngIf="form.get('jwkProviderUrl').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- ClientId field -->
        <mat-form-field>
          <input matInput formControlName="clientId" placeholder="Openid client id" />
          <mat-error *ngIf="form.get('clientId').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Issuer field -->
        <mat-form-field>
          <input matInput formControlName="issuer" placeholder="Openid issuer" />
          <mat-error *ngIf="form.get('issuer').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- RolesClaim field -->
        <mat-form-field>
          <input matInput formControlName="rolesClaim" placeholder="Openid roles claim filed" />
          <mat-error *ngIf="form.get('rolesClaim').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>
        
        <!-- Campo Amministrazione E-mail list -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="amministrazioneEmailList" placeholder="Indirizzi e-mail amministrazione" />
          <mat-error *ngIf="form.get('amministrazioneEmailList').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/tenant')" color="primary">Back</button>
      <button mat-raised-button (click)="tenantSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="tenant?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="tenantDelete()" [disabled]="tenant?.id==null"
        *ngIf="tenant?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CryptoPriceService {

  private readonly url: String = "https://api.coincap.io/v2/";
  private http: HttpClient;

  constructor(private bk: HttpBackend) { 
    this.http = new HttpClient(bk);
  }

  public cryptoCurrencyRateRead(nome: String): Observable<{}> {
    let option: { };
    Option["headers"] = new HttpHeaders('Content-type: text/html Accept: text/plain');
    return this.http.get<{}>(`${this.url}assets/${nome}`, option);
  }

  public euroPriceRead() {
    let option: { };
    Option["headers"] = new HttpHeaders('Content-type: text/html Accept: text/plain');
    return this.http.get<{}>(`${this.url}rates/euro`, option);
  }
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione catalogo</h2>
    <p>Imposta i filtri di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">

      <mat-form-field>
        <input matInput [(ngModel)]="parameters.descrizione" placeholder="Descrizione">
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search"
        color="primary">Search</button>
      <button mat-raised-button id="new-button" [disabled]="!isJappTenantAdmin()"
        [routerLink]="['/gestione-catalogo/detail/0']">New</button>
    </mat-card-actions>
    <br />
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
        matSort (matSortChange)="setSort($event)" matSortDisableClear
        [dataSource]="parameters.dataSource">

        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id prodotto </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let catalogo"> {{catalogo.id}} </td>
        </ng-container>

        <ng-container matColumnDef="descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
          <td mat-cell *matCellDef="let catalogo"> {{catalogo.descrizione}} </td>
        </ng-container>

        <ng-container matColumnDef="totaleCrediti">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo </th>
          <td mat-cell *matCellDef="let catalogo"> {{catalogo.totaleCrediti}} </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let catalogo">
            <button mat-mini-fab color="primary" [disabled]="!isJappTenantAdmin()"
              [routerLink]="['/gestione-catalogo/detail/' , catalogo.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="print">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef></th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let catalogo">
            <button mat-mini-fab color="primary" [disabled]="!isJappTenantAdmin()"
              [routerLink]="['/gestione-catalogo/print/' , catalogo.id]">
              <mat-icon>print</mat-icon>
            </button>
          </td>
        </ng-container>
  
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'catalogo'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'catalogo'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'catalogo'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
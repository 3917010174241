import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { ChiaviDTO } from './chiavi';
import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';

export class StoriaChiaviDTO extends LogicalDeleteDTO{
	
    chiavi: ChiaviDTO;
    collaboratore: CollaboratoreDTO;
    dataDa: Date;
    dataA: Date;
    id: number;
    tenant: TenantDTO;

}
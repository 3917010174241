import { ResponseFail } from './../../shared/dto/responseFail';
import { TimestampFormatPipe } from './../../commons/timestampFormatPipe';
import { NavigatorService } from './../../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogoService } from './../../services/credito/catalogo.service';
import { CatalogoDTO } from './../../shared/dto/credito/catalogo';
import { GenericDetailComponent } from './../../shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-gestioneCatalogoDetail',
  templateUrl: './gestioneCatalogoDetail.component.html',
  styleUrls: ['./gestioneCatalogoDetail.component.scss']
})
export class GestioneCatalogoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  catalogoId: number;
  catalogo: CatalogoDTO;

  constructor(
    private catalogoService: CatalogoService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      route,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      descrizione: new FormControl('', Validators.required),
      totaleCrediti: new FormControl('',[ Validators.required, Validators.pattern('^[0-9]*$')]),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });
  }



  ngOnInit() {
    this.catalogoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.catalogoId != 0 && this.catalogoId != null) {
      this.catalogoRead();
    } else {
      this.catalogo = new CatalogoDTO();
    }
  }

  catalogoSave() {
    if (this.catalogo.id == null) {
      this.catalogoCreate();
    } else {
      this.catalogoUpdate();
    }
  }

  catalogoCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.catalogoService.create(this.catalogo).subscribe(
        (res: CatalogoDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.catalogo = res;
          this.catalogoId = this.catalogo.id;
          this.dtoToForm();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCatalogoException_CATALOGO_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già un prodotto con questa descrizione.", null, { duration: 3000 });
          } else {
              this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
  }

  catalogoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il prodotto?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.catalogoService.update(this.catalogo).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.catalogoRead();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCatalogoException_CATALOGO_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già un prodotto con questa descrizione.", null, { duration: 3000 });
              } else {
                  this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  catalogoDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il prodotto?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.catalogoService.delete(this.catalogo.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/gestione-catalogo"]);
            }
          );
        }
      });
    }
  }

  catalogoRead() {
    this.catalogoService.read(this.catalogoId).subscribe(
      (res: CatalogoDTO) => {
        console.log("response : " + res);
        this.catalogo = res;
        this.catalogoId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.catalogo.descrizione = this.form.get("descrizione").value;
    this.catalogo.totaleCrediti = this.form.get("totaleCrediti").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.catalogo.id);
    this.form.get("descrizione").setValue(this.catalogo.descrizione);
    this.form.get("totaleCrediti").setValue(this.catalogo.totaleCrediti);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.catalogo.insertDate));
    this.form.get("insertUser").setValue(this.catalogo.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.catalogo.updateDate));
    this.form.get("updateUser").setValue(this.catalogo.updateUser);
    this.form.markAsPristine();
  }

  ngOnDestroy() { }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Turni AM</h2>

        <div fxLayout="column" class="responsive-mat-form">
            <!-- Collaboratore field -->
            <mat-form-field>
                <mat-select name="collaboratore" [(ngModel)]="parameters.collaboratore" [compareWith]='compareDTO'
                    placeholder="Scegli il collaboratore" (selectionChange)="fetchEventsEmpty()">
                    <mat-option [value]="null">
                        Tutti
                    </mat-option>
                    <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                        {{collaboratore.collaboratore.nome}} {{collaboratore.collaboratore.cognome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Tipo field -->
            <mat-form-field>
                <mat-select name="tipoTurno" [(ngModel)]="parameters.tipoTurno" [compareWith]='compareDTO'
                    placeholder="Scegli il tipo turno" (selectionChange)="fetchEventsEmpty()">
                    <mat-option [value]="null">
                        Tutti
                    </mat-option>
                    <mat-option *ngFor="let tipoTurno of tipiTurno" [value]="tipoTurno"
                        [matTooltip]="tipoTurno.descrizione" matTooltipPosition="after" matTooltipShowDelay="600">
                        {{tipoTurno.nome}} ({{tipoTurno.orario}})
                    </mat-option>
                    <mat-option [value]="'FERIE'" *ngIf="!vistaData">
                        Ferie
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Responsive -->
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form" *ngIf="vistaData" >

                <!-- DataDa field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
                        [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataDa=null" (click)="parameters.initDataDa=false">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <!-- DataA field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
                        [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataA=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <!--Bottoni-->
        <mat-card-actions fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button id="search-button" *ngIf="vistaData" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button id="new-button" [routerLink]="['/turno-am/detail/0']">New</button>
            <button mat-raised-button id="copy-button" [routerLink]="['/turno-am/copy']">Copy</button>
            <mat-form-field appearance="fill" *ngIf="!vistaData" [ngStyle]="{'padding-left': 2 + '%'}">
                <mat-label>Ordina per</mat-label>
                <mat-select [(value)]="ordine" (selectionChange)="fetchEventsEmpty()">
                    <mat-option value="collaboratoreAM.collaboratore.nome">Collaboratore</mat-option>
                    <mat-option value="tipoTurno.ordine">Tipo Turno</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-actions>
        <br />


        <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Calendario">

                <div>
                    <mwl-utils-calendar-header
                      [calendarioPresenze]="false" 
                      [weekStartsOn]="weekStartsOn"
                      [(view)]="this.parameters.view"
                      [(viewDate)]="this.parameters.viewDate">
                    </mwl-utils-calendar-header>
                </div>
                <ng-template #customHeaderTemplate let-day="day" let-locale="locale">
                <div role="row" class="cal-cell-row cal-header">
                    <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> LUN </div>
                    <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> MAR </div>
                    <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> MER </div>
                    <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> GIO </div>
                    <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> VEN </div>
                    <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> SAB </div>
                    <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> DOM </div>
                </div>
                </ng-template>
                <ng-template
                    #customTemplate
                    let-day="day"
                    let-openDay="openDay"
                    let-locale="locale"
                    let-eventClicked="eventClicked"
                    let-highlightDay="highlightDay"
                    let-unhighlightDay="unhighlightDay">
                <div class="cal-cell-top custom-cell-top" fxFlex="row" fxLayoutAlign="center" fxLayoutAlign.gt-md="end">
                    <span 
                    ngClass="cal-day-number custom-day-top-number-mobile" 
                    [ngClass.gt-md]="{'cal-day-number':true, 'custom-day-top-number-mobile':false }">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                </div>
                <!--Gestione eventi-->
                <div class="cal-events custom-cal-events" *ngIf="day.events.length > 0" fxLayout="column" fxLayout.lt-md="row" fxLayoutAlign="space-around start" fxLayoutAlign.lt-md="start">
                    <div *ngFor="let event of day.events" fxLayout="row" fxLayoutAlign="space-around center">
                    <span fxFlex="1 0 auto"
                        class="cal-event"
                        [style.backgroundColor]="event.color?.primary"
                        [ngClass]="event?.cssClass"
                        (mouseenter)="highlightDay.emit({event: event})"
                        (mouseleave)="unhighlightDay.emit({event: event})"
                        (mwlClick)="eventClicked.emit({ event: event })"></span>
                        <div 
                            [ngClass]="event?.cssClass"
                            (mouseenter)="highlightDay.emit({event: event})"
                            (mouseleave)="unhighlightDay.emit({event: event})"
                            (mwlClick)="eventClicked.emit({ event: event })" 
                            *ngIf="!isMobile">
                            <div *ngIf="event.meta.turno != null"
                                [ngClass]="{'mat-body-2': event.meta.turno.collaboratoreAM.collaboratore.id == this.currentCollaboratore.id,
                                            'mat-body-1': event.meta.turno.collaboratoreAM.collaboratore.id != this.currentCollaboratore.id,
                                            'error': event.meta.assenza != null}">
                                    {{ event.title }}
                            </div>
                            <div *ngIf="event.meta.assenza != null && event.meta.turno == null"
                                class="mat-body-1">
                                    {{ event.title }}
                            </div>
                        </div> 
                    </div>
                </div>

                </ng-template>
                <div>
                  <mwl-calendar-month-view
                    (beforeViewRender)="estrazionePeriodoVistaMensile($event)"
                    [events]="events" 
                    [cellTemplate]="customTemplate"
                    [headerTemplate]="customHeaderTemplate"
                    [(viewDate)]="this.parameters.viewDate" 
                    [locale]="locale" 
                    [weekStartsOn]="weekStartsOn"
                    [activeDayIsOpen]="activeDayIsOpen" 
                    (dayClicked)="dayClicked($event.day)" 
                    (eventClicked)="eventClicked($event.event)">
                  </mwl-calendar-month-view>
                </div>

            </mat-tab>
            <mat-tab label="Lista">
                <div *ngIf="parameters.showList" fxLayout="column">
                    <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
                        [hiddenColumns]="hiddenColumns()" matSort (matSortChange)="setSort($event)" matSortDisableClear
                        [dataSource]="parameters.dataSource">

                        <!-- Id Column -->
                        <ng-container matColumnDef="id">
                            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Turno </th>
                            <td fxHide fxShow.gt-md mat-cell *matCellDef="let turno"> {{turno.id}} </td>
                        </ng-container>

                        <!-- Collaboratore Column -->
                        <ng-container matColumnDef="collaboratoreAM">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore
                            </th>
                            <td mat-cell *matCellDef="let turno">
                                {{turno.collaboratoreAM.collaboratore?.nome}}
                                {{turno.collaboratoreAM.collaboratore?.cognome}} </td>
                        </ng-container>

                        <!-- Tipo turno Column -->
                        <ng-container matColumnDef="tipoTurno">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Turno
                            </th>
                            <td mat-cell *matCellDef="let turno" [matTooltip]="turno.tipoTurno.descrizione"
                                matTooltipPosition="left" matTooltipShowDelay="600">
                                {{turno.tipoTurno.nome}}
                                ({{turno.tipoTurno.orario}}) </td>
                        </ng-container>

                        <!-- Data turno Column -->
                        <ng-container matColumnDef="dataTurno">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Turno </th>
                            <td mat-cell *matCellDef="let turno"> {{turno.dataTurno | date :'dd/MM/yyyy'}} </td>
                        </ng-container>

                        <!-- Info Column -->
                        <ng-container matColumnDef="detail">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let turno">
                                <button mat-mini-fab color="primary" [routerLink]="['/turno-am/detail/' , turno.id]">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>



                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-card-actions>
                            <button mat-raised-button
                                (click)="exporter.exportTable('xlsx', {fileName:'turni_am'})">Excel</button>
                            <button fxHide fxShow.gt-md mat-raised-button
                                (click)="exporter.exportTable('csv', {fileName:'turni_am'})">Csv</button>
                            <button fxHide fxShow.gt-md mat-raised-button
                                (click)="exporter.exportTable('json', {fileName:'turni_am'})">Json</button>
                        </mat-card-actions>
                        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                            [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                        </mat-paginator>
                    </div>


                </div>
            </mat-tab>
        </mat-tab-group>




    </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettaglio categoria</h2>
  
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column">
  
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Categoria ID" readonly="true" />
          </mat-form-field>
  
          <mat-form-field>
            <input matInput placeholder="Nome" formControlName="nome">
            <mat-error *ngIf="form.get('nome').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
  
        </form>
      </div>
  
      <mat-card-actions>
        <button mat-raised-button (click)="categoriaSave()" id="save-button" name="Save" color="primary"
          [disabled]="form.invalid || !form.dirty">Save</button>
        <button mat-raised-button (click)="categoriaDelete()" id="delete-button" name="Delete"
          [disabled]="categoriaId == 0">Delete</button>
        <button mat-raised-button [routerLink]="['/categoria-evento']" id="back-button" name="Back">Back</button>
      </mat-card-actions>
  
    </mat-card-content>
  </mat-card>
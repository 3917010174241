import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TimestampFormatPipe } from 'src/app/commons/timestampFormatPipe';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { DispositivoService } from '../services/dispositivo/dispositivo.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { MatDialog } from '@angular/material/dialog';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Observable } from 'rxjs';
import { LocationDTO } from '../shared/dto/presenze/location';
import { DispositivoDTO } from '../shared/dto/dispositivo/dispositivo';

@Component({
  selector: 'app-dispositivo-detail',
  templateUrl: './dispositivo-detail.component.html',
  styleUrls: ['./dispositivo-detail.component.scss']
})
export class DispositivoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  hasSaved: boolean = true;
  dispositivoId: number;
  dispositivo: DispositivoDTO;
  locations: LocationDTO[];
  collaboratori: CollaboratoreDTO[];
  index: number[];
  today: Date;
  minDate: Date;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;
  scanGreenPass: boolean;
 
  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  audioOk = new Audio();

  audioError = new Audio();



  constructor(
    private urlService: UrlService,
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private dispositivoService: DispositivoService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      codice : new FormControl('', Validators.required),
      descrizione : new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.dispositivoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.dispositivoId != null && this.dispositivoId != 0) {
      this.dispositivoRead();
    } else {
      this.dispositivoInit();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response QUESTA: ", res);
          this.collaboratori = res.content;
        }
      );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  
  ngOnDestroy(): void {
  }

  dispositivoSave() {
    if (this.dispositivo.id == null) {
      this.dispositivoCreate();
    } else {
      this.dispositivoUpdate();
    }
  }

  dispositivoCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.dispositivoService.create(this.dispositivo).subscribe(
        (res: DispositivoDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.dispositivo = res;
          this.dispositivoId = this.dispositivo.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }
      )
    }
  }

  dispositivoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il dispositivo?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.dispositivoService.update(this.dispositivo).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.dispositivoRead();
              this.form.markAsPristine();
            });
        }
      });
    }
  }

  dispositivoDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la dispositivo?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.dispositivoService.delete(this.dispositivo.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/dispositivi"]);
            }
          );
        }
      });
    }
  }

  dispositivoRead() {
    this.dispositivoService.read(this.dispositivoId).subscribe(
      (res: DispositivoDTO) => {
        console.log("response : " + res);
        this.dispositivo = res;
        this.dispositivoId = res.id;
        this.dtoToForm();
      }
    );
  }

  dispositivoInit() {
    this.dispositivo = new DispositivoDTO();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.dispositivo.id);
    this.form.get("collaboratore").setValue(this.dispositivo.collaboratore);
    this.form.get("codice").setValue(this.dispositivo.codice);
    this.form.get("descrizione").setValue(this.dispositivo.descrizione);
    this.form.get("deleteDate").setValue(this.dispositivo.deleteDate);
    this.form.get("deleteUser").setValue(this.dispositivo.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.dispositivo.insertDate));
    this.form.get("insertUser").setValue(this.dispositivo.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.dispositivo.updateDate));
    this.form.get("updateUser").setValue(this.dispositivo.updateUser);
    this.form.get("optLock").setValue(this.dispositivo.optLock);
    this.form.markAsPristine();
  }

  private formToDto(): void {
    this.dispositivo.collaboratore = this.form.get("collaboratore").value;
    this.dispositivo.codice = this.form.get("codice").value;
    this.dispositivo.descrizione = this.form.get("descrizione").value;
  }

  backConfirm(url: String) {
    const M: string = "Proseguendo, tutte le modifiche andranno perse. Sei sicuro di voler tornare alla pagina precedente?";
    if (this.form.dirty) {
        this.confirm(M).subscribe(result => {
            if (result) {
                this.back(url);
            }
        });
    }
    else {
        this.back(url);
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { TorneoService } from '../services/torneo/torneo.service';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { TorneoDTO } from '../shared/dto/torneo/torneo';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';


@Component({
  selector: 'torneo',
  templateUrl: './torneo.component.html',
  styleUrls: ['./torneo.component.scss']
})
export class TorneoComponent extends GenericListComponent implements OnInit, OnDestroy {
  
  torneo: TorneoDTO;
  statiTorneo: selectedValue[];
  selectedStatoValue: string[];

  defaultSortField = 'dataPresenza';
  defaultSortDirection = 'asc';

  constructor(
    private torneoService: TorneoService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ){
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['insertDate', 'descrizione', 'status', 'numeroGiocatori', 'detail'];
      this.parameters = {
        selectedStato: [],
        stato: [],
        dataSource: [],
        showList: false,
        status: [],
        initDataDa: true,
        sortField: 'insertDate',
        sortDirection: 'DESC',
        pageNumber: 0,
        pageSize: 50,
        length: 0
      }
      this.statiTorneo = [
        { value: 'IN_CORSO', viewValue: 'In Corso' },
        { value: 'ISCRIZIONI_APERTE', viewValue: 'Iscrizioni Aperte' },
        { value: 'CONCLUSO', viewValue: 'Concluso' }
      ];
  };

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  
  list() {
    if (this.parameters.selectedStato.length != 0) {
      let i = 0;
      this.parameters.selectedStatoValue = [];
      this.parameters.selectedStato.forEach( status => {
        this.parameters.selectedStatoValue[i] = status.value;
        i++;
      });
    } else {
      this.parameters.selectedStatoValue = [];
    }
    this.torneoService.torneoListByStatus(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
      this.parameters.selectedStatoValue).subscribe(
        (res: ResponseQueryByCriteria<TorneoDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }
}
export interface selectedValue {
  value: string;
  viewValue: string;
}
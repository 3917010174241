import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { CategoriaEventoDTO } from 'src/app/shared/dto/evento/categoria-evento';
import { EventoDTO } from 'src/app/shared/dto/evento/evento';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriaEventoService extends GenericService<CategoriaEventoDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/categoriaEvento';
  }

  public categoriaEventoList(pageNumber: number, pageSize: number, direction: string, sortField: string): Observable<ResponseQueryByCriteria<CategoriaEventoDTO>> {
    let params = {};
    params["pageNumber"] = String(pageNumber);
    params["pageSize"] = String(pageSize);
    params["direction"] = direction;
    params["sortField"] = sortField;

    return this.http.get<ResponseQueryByCriteria<CategoriaEventoDTO>>(`${this.url}/categoriaEventoList`, {
      params: params
    });
  }

  public categoriaEventoGetAll(): Observable<CategoriaEventoDTO[]> {
    return this.http.get<CategoriaEventoDTO[]>(`${this.url}/categoriaEventoGetAll`);
  }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettaglio rapportino mensile</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Rapportino Mensile ID" readonly="true" />
        </mat-form-field>

        <!-- Collaboratore field -->
        <mat-form-field fxLayout="column">
          <mat-label>Collaboratori</mat-label>
          <mat-select formControlName="collaboratore" [compareWith]="compareDTO">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Mese field -->
        <div fxLayout="row">
          <mat-form-field fxLayout="column" fxFlex="59%" [style.overflow]="'auto'">
            <mat-label>Mese</mat-label>
            <mat-select formControlName="mese">
              <mat-option *ngFor="let mese of mesi" [value]="mese">
                {{mese}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div fxLayout="column" fxFlex="2%" [style.overflow]="'auto'"></div>

          <!-- Anno field -->
          <mat-form-field>
            <input matInput formControlName="anno" placeholder="anno" />
            <mat-error *ngIf="form.get('anno').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Stato field -->
        <mat-form-field fxLayout="column">
          <mat-label>Stato</mat-label>
          <mat-select formControlName="stato">
            <mat-option *ngFor="let stato of statoRapportinoFinale" [value]="stato.value">
              {{stato.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/rapportino-finale')" color="primary">Back</button>
      <button mat-raised-button (click)="rapportinoFinaleSave()"
        [disabled]="form.invalid || (this.lettura && !form.dirty) || (!isJappTenantAdmin() && (this.rapportinoFinale.statoRapportinoFinale == 'CLOSED' || this.rapportinoFinale.statoRapportinoFinale == 'DONE')) || controlloOre()"
        *ngIf="rapportinoFinale?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="rapportinoFinaleDelete()"
        [disabled]="rapportinoFinale?.id==null || (!isJappTenantAdmin() && this.rapportinoFinale.statoRapportinoFinale == 'CLOSED')"
        *ngIf="rapportinoFinale?.deleteDate==null">Delete</button>
    </mat-card-actions>
    <mat-tab-group  (selectedTabChange)="drawChart($event)" *ngIf="this.lettura == true">
      <mat-tab label="Compilazione">
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel #presenzaEP *ngFor="let presenzaAssenza of presenzaRapportini" 
          [disabled]="isMalattia(presenzaAssenza) || isAssenza(presenzaAssenza)" (click)="chiudi(presenzaEP, presenzaAssenza)">
            <mat-expansion-panel-header fxLayout="row">
              <mat-panel-title fxHide fxShow.gt-xs fxLayout="column" fxFlex="35%" fxLayoutAlign="space-evenly none">
                {{ presenzaAssenza.presenza.collaboratore.nome }} {{ presenzaAssenza.presenza.collaboratore.cognome }}
              </mat-panel-title>
              <mat-panel-description *ngIf="isAssenza(presenzaAssenza) == true">
                <div fxLayout="column" fxFlex="35%" fxHide fxShow.gt-sm>
                  {{ presenzaAssenza.presenza.dataPresenza | date :'dd MMMM yyyy':'':locale | titlecase }} - {{ presenzaAssenza.presenza.tipo }}
                </div>
                <div fxLayout="column" fxFlex="60%" fxShow fxHide.gt-sm>
                  {{ presenzaAssenza.presenza.dataPresenza | date :'dd/MM/yyyy' }} - {{ presenzaAssenza.presenza.tipo }}
                </div>
                <div fxLayout="column" fxFlex="35%" fxFlex.xs="45%">
                  <div fxLayout="row" fxLayoutAlign="end center">
                    <div *ngIf="presenzaAssenza.presenza.tipo == 'PERMESSO'" class="completo">{{ presenzaAssenza.presenza.oreCensite }} / {{
                      presenzaAssenza.presenza.numeroDiOre }} </div>
                    <div fxLayout="column" fxFlex="5%"></div>
                  </div>
                </div>
              </mat-panel-description>
              <mat-panel-description *ngIf="isAssenza(presenzaAssenza) == false">
                <div *ngIf="!isMalattia(presenzaAssenza)" fxLayout="column" fxFlex="35%" fxHide fxShow.gt-sm>
                  {{ presenzaAssenza.presenza.dataPresenza | date :'dd MMMM yyyy':'':locale | titlecase }} - {{
                    presenzaAssenza.presenza.location.descrizione }}
                </div>
                <div *ngIf="!isMalattia(presenzaAssenza)" fxLayout="column" fxFlex="60%" fxShow fxHide.gt-sm>
                  {{ presenzaAssenza.presenza.dataPresenza | date :'dd/MM/yyyy' }} - {{
                     presenzaAssenza.presenza.location.descrizione }}
                </div>  
                <div *ngIf="isMalattia(presenzaAssenza)" fxLayout="column" fxFlex="60%" fxShow>
                  {{ presenzaAssenza.presenza.dataPresenza | date :'dd MMMM yyyy':'':locale | titlecase }} - {{
                    presenzaAssenza.presenza.dataA | date :'dd MMMM yyyy':'':locale | titlecase }} - Malattia
                </div>  
                <div fxLayout="column" fxFlex="35%" fxFlex.xs="45%">
                  <div fxLayout="row" fxLayoutAlign="end center">
                    <div *ngIf="!isMalattia(presenzaAssenza)" [ngClass]="{
                      'incompleto': presenzaAssenza.presenza.oreCensite < presenzaAssenza.presenza.numeroDiOre,
                      'completo': presenzaAssenza.presenza.oreCensite >= presenzaAssenza.presenza.numeroDiOre}"
                    >{{ presenzaAssenza.presenza.oreCensite }} / {{ presenzaAssenza.presenza.numeroDiOre }}</div>
                    <div fxLayout="column" fxFlex="5%"></div>
                    <button *ngIf="!isMalattia(presenzaAssenza)" mat-raised-button (click)="nuovoRapportino(presenzaEP)" color="primary"
                      [disabled]="(!isJappTenantAdmin() && this.rapportinoFinale.statoRapportinoFinale == 'DONE') 
                      || (this.rapportinoFinale.statoRapportinoFinale == 'CLOSED')
                      || (isMalattia(presenzaAssenza))">New</button>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="statoNuovoRapportino">
              <form [formGroup]="formRapportini" fxLayout="row">
                <mat-form-field fxLayout="column" fxFlex="29%" fxFlex.gt-sm="14%" [style.overflow]="'auto'">
                  <input type="text" matInput formControlName="cliente"
                    (input)="clienteDescrizioneChanged($event.target.value, true)"
                    (click)="clienteDescrizioneChanged($event.target.value, true)"
                    [matAutocomplete]="descrizioneAutocompleteCliente" placeholder="Cliente">
                  <mat-autocomplete autoActiveFirstOption #descrizioneAutocompleteCliente="matAutocomplete"
                    [displayWith]="inizializzaDescrizioneCliente()">
                    <mat-option *ngFor="let cliente of filteredCliente" [value]="cliente">
                      {{cliente.descrizione}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="formRapportini.get('cliente').errors?.required">
                    Campo obbligatorio
                  </mat-error>
                  <mat-error *ngIf="formRapportini.get('cliente').errors?.invalidCliente">
                    Cliente non valido
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxLayout="column" fxFlex="6%" [style.overflow]="'auto'">
                  <mat-label>Numero Ore</mat-label>
                  <input formControlName="nOre" matInput type="number">
                  <mat-error *ngIf="formRapportini.get('nOre').errors?.required">
                    Campo Obbligatorio
                  </mat-error>
                  <mat-error *ngIf="formRapportini.get('nOre').errors?.min">
                    Ore minime: 1
                  </mat-error>
                  <mat-error *ngIf="formRapportini.get('nOre').errors?.max">
                    Ore massime: 24
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="40%" fxFlex.gt-sm="45%" fxLayout="column" [style.overflow]="'auto'">
                  <input pattern=".{5,}" type="text" matInput formControlName="attivitaControl"
                    (input)="descrizioneChanged($event.target.value, true)" [matAutocomplete]="descrizioneAutocomplete"
                    (click)="descrizioneChanged($event.target.value, true)" placeholder="Attivita">
                  <mat-autocomplete autoActiveFirstOption #descrizioneAutocomplete="matAutocomplete"
                    [displayWith]="inizializzaDescrizione()">
                    <mat-option *ngFor="let attivita of filteredAttivita" [value]="attivita">
                      {{attivita.descrizione}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="formRapportini.get('attivitaControl').errors?.required">
                    Campo obbligatorio
                  </mat-error>
                  <mat-error *ngIf="formRapportini.get('attivitaControl').errors?.pattern">
                    Lunghezza minima: 5
                  </mat-error>
                </mat-form-field>
                <mat-form-field fxLayout="column" fxFlex="35%" fxFlex.gt-sm="35%" [style.overflow]="'auto'">
                  <mat-label>Cliente Finale</mat-label>
                  <input formControlName="clienteFinale" matInput type="string">
                  <mat-error *ngIf="formRapportini.get('clienteFinale').errors?.required">
                    Campo obbligatorio
                  </mat-error>
                </mat-form-field>
              </form>
              <mat-action-row>
                <button mat-button color="primary" (click)="negaStatoRapportinoNuovo(presenzaEP)">Annulla</button>
                <button mat-button color="primary"
                  [disabled]=" !isValidCliente() || formRapportini.invalid || !formRapportini.dirty"
                  (click)="rapportinoCreate(presenzaAssenza.presenza)">Salva</button>
              </mat-action-row>
            </div>
            <div fxLayout="column" *ngIf="presenzaAssenza.rapportini.length > 0">
              <div fxLayout="row">
                <div class="table-rapportini-header" fxFlex="29%" fxFlex.gt-sm="14%">Cliente</div>
                <div class="table-rapportini-header" fxFlex="11px"></div>
                <div class="table-rapportini-header" fxFlex="6%">N° Ore</div>
                <div class="table-rapportini-header" fxFlex="11px"></div>
                <div class="table-rapportini-header" fxFlex="40%" fxFlex.gt-sm="45%">Attivita</div>
                <div class="table-rapportini-header" fxFlex="11px"></div>
                <div class="table-rapportini-header" fxFlex="25%" fxFlex.gt-sm="25%">Cliente Finale</div>
                <div class="table-rapportini-header" fxFlex="10%"></div>
              </div>
              <div fxLayout="row" *ngFor="let rapportino of presenzaAssenza.rapportini">
                <div class="column" fxLayoutAlign="start center" fxFlex="29%" fxFlex.gt-sm="14%">{{ rapportino.cliente.descrizione }}</div>
                <div class="column" fxLayoutAlign="start center" fxFlex="11px"></div>
                <div class="column" fxLayoutAlign="start center" fxFlex="6%">{{ rapportino.numeroOre }}</div>
                <div class="column" fxLayoutAlign="start center" fxFlex="11px"></div>
                <div class="column" fxLayoutAlign="start center" fxFlex="40%" fxFlex.gt-sm="45%">{{ rapportino.attivita.descrizione }}</div>
                <div class="column" fxLayoutAlign="start center" fxFlex="11px"></div>
                <div class="column" fxLayoutAlign="start center" fxFlex="25%" fxFlex.gt-sm="25%">{{ rapportino.clienteFinale }}</div>
                <div class="column" fxLayoutAlign="start center" fxFlex="10%">
                  <button mat-mini-fab color="primary" (click)="rapportinoDelete(rapportino, presenzaEP)"
                    [disabled]="(rapportino.attivita.descrizione == 'PERMESSO') || (!isJappTenantAdmin() && this.rapportinoFinale.statoRapportinoFinale == 'DONE') || (this.rapportinoFinale.statoRapportinoFinale == 'CLOSED')">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div fxLayout="column">
          <table mat-table [hidden]="true" fxFlex matTableExporter #exporter="matTableExporter"
            [hiddenColumns]="hiddenColumns()"
            [dataSource]="vistaTabellare">

            <!-- Collaboratore Column -->
            <ng-container matColumnDef="collaboratore">
              <th mat-header-cell *matHeaderCellDef> Collaboratore
              </th>
              <td mat-cell *matCellDef="let rapportino">
                {{rapportino.collaboratore?.nome}} {{rapportino.collaboratore?.cognome}}
              </td>
            </ng-container>

            <!-- Data Column -->
            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef> Data
              </th>
              <td mat-cell *matCellDef="let rapportino">
                {{ rapportino.data | date :'dd/MM/yyyy' }}
              </td>
            </ng-container>

            <!-- Location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef> Location </th>
              <td mat-cell *matCellDef="let rapportino">
                {{ rapportino.location }}
              </td>
            </ng-container>

            <!-- Cliente Column -->
            <ng-container matColumnDef="cliente">
              <th mat-header-cell *matHeaderCellDef> Cliente </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.cliente }} </td>
            </ng-container>
            
            <!-- Numero Ore Column -->
            <ng-container matColumnDef="numeroOre">
              <th mat-header-cell *matHeaderCellDef> Numero Ore </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.numeroOre }} </td>
            </ng-container>
            
            <!-- Attivita Column -->
            <ng-container matColumnDef="attivita">
              <th mat-header-cell *matHeaderCellDef> Attivita </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.attivita }} </td>
            </ng-container>

            <!-- Cliente Finale Column -->
            <ng-container matColumnDef="clienteFinale">
              <th mat-header-cell *matHeaderCellDef> Cliente Finale </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.clienteFinale }} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="vistaTabellare.length > 0">
            <mat-card-actions>
              <button mat-raised-button
                (click)="generateExcel()">Excel</button>
              <button fxHide fxShow.gt-md mat-raised-button
                (click)="exporter.exportTable('csv', {fileName:'rapportinoFinale'})">Csv</button>
            </mat-card-actions>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Tabella">
        <div fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
            [hiddenColumns]="hiddenColumns()"
            [dataSource]="vistaTabellare">

            <!-- Collaboratore Column -->
            <ng-container matColumnDef="collaboratore">
              <th mat-header-cell *matHeaderCellDef [hidden]="true"> Collaboratore
              </th>
              <td mat-cell *matCellDef="let rapportino" [hidden]="true">
                {{rapportino.collaboratore?.nome}} {{rapportino.collaboratore?.cognome}}
              </td>
            </ng-container>

            <!-- Data Column -->
            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef> Data
              </th>
              <td mat-cell *matCellDef="let rapportino">
                {{ rapportino.data | date :'dd/MM/yyyy' }}
              </td>
            </ng-container>

            <!-- Location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef> Location </th>
              <td mat-cell *matCellDef="let rapportino">
                {{ rapportino.location }}
              </td>
            </ng-container>

            <!-- Cliente Column -->
            <ng-container matColumnDef="cliente">
              <th mat-header-cell *matHeaderCellDef> Cliente </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.cliente }} </td>
            </ng-container>
            
            <!-- Numero Ore Column -->
            <ng-container matColumnDef="numeroOre">
              <th mat-header-cell *matHeaderCellDef> Numero Ore </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.numeroOre }} </td>
            </ng-container>

            <!-- Attivita Column -->
            <ng-container matColumnDef="attivita">
              <th mat-header-cell *matHeaderCellDef> Attivita </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.attivita }} </td>
            </ng-container>

            <!-- Cliente Finale Column -->
            <ng-container matColumnDef="clienteFinale">
              <th mat-header-cell *matHeaderCellDef> Cliente Finale </th>
              <td mat-cell *matCellDef="let rapportino"> {{ rapportino.clienteFinale }} </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="vistaTabellare.length > 0">
            <mat-card-actions>
              <button mat-raised-button
                (click)="generateExcel()">Excel</button>
              <button fxHide fxShow.gt-md mat-raised-button
                (click)="exporter.exportTable('csv', {fileName:'rapportinoFinale'})">Csv</button>
            </mat-card-actions>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Grafico" >
        <div [fxLayout]="isMobile ? 'column' : 'row'" [fxLayoutAlign]="isMobile ? 'space-between stretch' : 'space-around center'">
          
          <div id="chartCard"  style="height: 400px; width: 100%;"></div>
          
          <div id="chartCardCliente" style="height: 400px; width: 100%;"></div>

          <div id="chartDiv" style="height: 400px; width: 100%;"></div>
        </div>
      </mat-tab>
      <mat-tab label="Settimana">
        <div fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
                 [hiddenColumns]="hiddenColumns()"
                 [dataSource]="tabellaRaggruppata">

            <!-- Collaboratore Column -->
              <ng-container matColumnDef="collaboratore">
                <th mat-header-cell *matHeaderCellDef [hidden]="true"> Collaboratore
                </th>
                <td mat-cell *matCellDef="let rapportino" [hidden]="true"> {{rapportino.collaboratore?.nome}} {{rapportino.collaboratore?.cognome}} </td>
              </ng-container>

              <!-- Data Column -->
              <ng-container matColumnDef="data">
                <th mat-header-cell *matHeaderCellDef> Data
                </th>
                <td mat-cell *matCellDef="let rapportino">
                  <ng-container *ngIf="isString(rapportino.settimana)">
                    {{ rapportino.settimana }}
                  </ng-container>
                  <ng-container *ngIf="isNumber(rapportino.totale)">
                    TOTALE ORE {{ rapportino.clienteTotale }}
                  </ng-container>
                  {{ rapportino.data | date :'dd/MM/yyyy' }}
                </td>
              </ng-container>

              <!-- Location Column -->
              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef> Location </th>
                <td mat-cell *matCellDef="let rapportino"> {{ rapportino.location }} </td>
              </ng-container>

              <!-- Cliente Column -->
              <ng-container matColumnDef="cliente">
                <th mat-header-cell *matHeaderCellDef> Cliente </th>
                  <td mat-cell *matCellDef="let rapportino"> {{ rapportino.cliente }} </td>
              </ng-container>

              <!-- Numero Ore Column -->
              <ng-container matColumnDef="numeroOre">
                <th mat-header-cell *matHeaderCellDef> Numero Ore </th>
                  <td mat-cell *matCellDef="let rapportino">
                    <ng-container *ngIf="isNumber(rapportino.totale)">
                      {{ rapportino.totale }}
                    </ng-container>
                    {{ rapportino.numeroOre }}
                  </td>
              </ng-container>

              <!-- Attivita Column -->
              <ng-container matColumnDef="attivita">
                <th mat-header-cell *matHeaderCellDef> Attivita </th>
                  <td mat-cell *matCellDef="let rapportino"> {{ rapportino.attivita }} </td>
              </ng-container>

              <!-- Cliente Finale Column -->
              <ng-container matColumnDef="clienteFinale">
                <th mat-header-cell *matHeaderCellDef> Cliente Finale </th>
                  <td mat-cell *matCellDef="let rapportino"> {{ rapportino.clienteFinale }} </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="rowClass(row)"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>

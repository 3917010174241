import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QrcodeService {

  private readonly urlPrefix = environment.urlPrefix+'/services/rest/json/';

  constructor(private http: HttpClient) { 
  }

  public qrcodeRead<T>(qrcode : string) : Observable<T>{ 
    return this.http.get<T>(`${this.urlPrefix}` + qrcode);
  }

}

import { LogicalDeleteDTO } from "../basic/logicalDeleteDTO";
import { CollaboratoreDTO } from "../domain/collaboratore";
import { TenantDTO } from "../domain/tenant";
import { ServiziReperibilitaDTO } from "./ServizioReperibilitaDTO";

export class TurnoServiziReperibilitaDTO extends LogicalDeleteDTO{
	
	id: number;
    tenant: TenantDTO;
    servizio: ServiziReperibilitaDTO;
    dataDa: Date;
    dataA: Date;
    collaboratore: CollaboratoreDTO;
    
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Prendi chiavi</h2>

        <div fxLayout="column" class="responsive-mat-form">
            <form [formGroup]="form" fxLayout="column">
                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Id" readonly="true" />
                </mat-form-field>

                <!-- Chiavi field -->

                <mat-form-field>
                    <input matInput formControlName="chiavi" placeholder="Chiavi" readonly="true" />
                </mat-form-field>


                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- DataDa field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input [(ngModel)]="parameters.dataDaPlaceHolder" matInput formControlName="dataDa"
                            readonly="true" placeholder="Data da" />

                    </mat-form-field>

                    <!-- DataA field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
                            [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a"
                            formControlName="dataA">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>

                <mat-card-actions>
                    <button mat-raised-button color="primary" (click)="backConfirm('/gestione-chiavi')">Back</button>

                    <button mat-raised-button (click)="storiaChiaviUpdate()"
                        [disabled]="form.invalid || !form.dirty">Save</button>
                </mat-card-actions>

            </form>
        </div>
    </mat-card-content>
</mat-card>
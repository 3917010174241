import { TemplateDTO } from './../../shared/dto/domain/template';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from 'src/app/shared/GenericService';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends GenericService<TemplateDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/template';
  }

  public templateListByTenant(pageNumber: number, pageSize: number, direction: string, sortField: string): Observable<ResponseQueryByCriteria<TemplateDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;  
    return this.http.get<ResponseQueryByCriteria<TemplateDTO>>(`${this.url}/templateList`, {
      params: params
    });
  }

  public templateFunctionList(): Observable<String[]> {
    return this.http.get<String[]>(`${this.url}/templateFunction`);
  }
}
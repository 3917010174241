import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { Injectable } from '@angular/core';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { FeriePermessiDTO } from 'src/app/shared/dto/gestioneferie/ferie';

@Injectable({
    providedIn: 'root'
})
export class FerieService extends GenericService<FeriePermessiDTO, number> {

    constructor(http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/services/rest/json/feriePermessi';
    }

    public feriePermessiList(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO, dateTo: Date): Observable<ResponseQueryByCriteria<FeriePermessiDTO>> {
        let params = {};
        if (pageNumber != null) params["pageNumber"] = String(pageNumber);
        if (pageSize != null) params["pageSize"] = String(pageSize);
        if (direction != null) params["direction"] = direction;
        if (sortField != null) params["sortField"] = sortField;
        if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
        if (dateTo != null) params["dataA"] = dateTo.toISOString();
        return this.http.get<ResponseQueryByCriteria<FeriePermessiDTO>>(`${this.url}` + '/feriePermessiByList', {
          params: params
        });
      }
    public upload(files: File) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/pdf',
        'method': 'POST',
        'Access-Control-Allow-Origin': '*'
    });
      
    let requestOptions: Object = {
      headers: headers,
      responseType: 'json'
    };

      return this.http.post<File>(`${this.url}/upload`, files, requestOptions);
    }

}
import { TemplateDTO } from './../shared/dto/domain/template';
import { TemplateService } from './../services/domain/template.service';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { ComponentCacheService } from './../services/component-cache.service';
import { Component, OnInit } from '@angular/core';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { NavigatorService } from '../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';

@Component({
  selector: 'app-gestione-template',
  templateUrl: './gestione-template.component.html',
  styleUrls: ['./gestione-template.component.scss']
})
export class GestioneTemplateComponent extends GenericListComponent implements OnInit {

  constructor(
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    private templateService: TemplateService) { 
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'codTemplate', 'Descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.templateService.templateListByTenant(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField).subscribe(
        (res: ResponseQueryByCriteria<TemplateDTO>) => {
          console.log(res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      );
  } 

  ngOnInit() {
    this.list();
  }
}
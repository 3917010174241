import { Injectable } from "@angular/core";
import { DAYS_OF_WEEK } from "angular-calendar";

@Injectable({
    providedIn: 'root'
})
export class LocalizationService{

  private _giorni: string[];
  private giorniTradMap : Map<DAYS_OF_WEEK, string> = new Map<DAYS_OF_WEEK, string>();
  private giorniOrderMap : Map<DAYS_OF_WEEK, number> = new Map<DAYS_OF_WEEK, number>();



  constructor() {
    this._giorni = Object.keys(DAYS_OF_WEEK).filter(key => !isNaN(Number(DAYS_OF_WEEK[key])));

    this.giorniTradMap.set(DAYS_OF_WEEK.MONDAY, "Lunedì");
    this.giorniTradMap.set(DAYS_OF_WEEK.TUESDAY, "Martedì");
    this.giorniTradMap.set(DAYS_OF_WEEK.WEDNESDAY, "Mercoledì");
    this.giorniTradMap.set(DAYS_OF_WEEK.THURSDAY, "Giovedì");
    this.giorniTradMap.set(DAYS_OF_WEEK.FRIDAY, "Venerdì");
    this.giorniTradMap.set(DAYS_OF_WEEK.SATURDAY, "Sabato");
    this.giorniTradMap.set(DAYS_OF_WEEK.SUNDAY, "Domenica");

    this.giorniOrderMap.set(DAYS_OF_WEEK.MONDAY, 1);
    this.giorniOrderMap.set(DAYS_OF_WEEK.TUESDAY, 2);
    this.giorniOrderMap.set(DAYS_OF_WEEK.WEDNESDAY, 3);
    this.giorniOrderMap.set(DAYS_OF_WEEK.THURSDAY, 4);
    this.giorniOrderMap.set(DAYS_OF_WEEK.FRIDAY, 5);
    this.giorniOrderMap.set(DAYS_OF_WEEK.SATURDAY, 6);
    this.giorniOrderMap.set(DAYS_OF_WEEK.SUNDAY, 7);
   }

    translateDayToIta(giorno: string): string{
        return this.giorniTradMap.get(DAYS_OF_WEEK[giorno]);
      
    }
    get giorni(): string[] {
        let ret = this._giorni.sort((a: string, b: string) => this.giorniOrderMap.get(DAYS_OF_WEEK[a]) - this.giorniOrderMap.get(DAYS_OF_WEEK[b]));
        return ret;
      }
   
}
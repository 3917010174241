<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli prenotazione della postazione</h2>
    <div fxLayout="column">
      <form [formGroup]="form" fxLayout="column" class="responsive-mat-form" style="max-width: 550px;">

        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Prenotazione Postazione Id" readonly="true" />
        </mat-form-field>
        <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="responsive-mat-form" style="max-width: 550px;">
          <!-- Richiedente field -->
          <!-- style="width: 75%;" -->
          <mat-form-field fxFlex="100" fxFlex.md="85" fxFlex.sm="75" fxFlex.xs="75" >
            <mat-select name="richiedente" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
              formControlName="richiedente" placeholder="Scegli il richiedente">
              <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                {{collaboratore.nome}} {{collaboratore.cognome}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('richiedente').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
          <!-- prenotazionePerOspite field -->
          <mat-checkbox *ngIf="isJappTenantAdmin()" [disabled]="checkIfPrenotazioneIsNew()" formControlName="prenotazionePerOspite" 
            fxFlex="15" fxFlex.md="15" fxFlex.sm="25" fxFlex.xs="25" 
            class = "checkBoxOspite"> 
            Ospite
          </mat-checkbox>
        </div>
        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form" style="max-width: 550px;">
          <!-- DataDa field -->
          <mat-form-field fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker.open()"
                            [max]="dataMax" (dateChange)="setMinDate()" [matDatepicker]="picker"
                            formControlName="dataDa" placeholder="Scegli data da">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataDa').value" matSuffix
                            mat-icon-button aria-label="Clear" (click)="form.get('dataDa').setValue(null)"
                            (click)="dataMin=null">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('dataDa').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

          <!-- DataA field -->
          <mat-form-field fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker2.open()"
                [min]="dataMin" (dateChange)="setMaxDate()" [matDatepicker]="picker2" 
                formControlName="dataA" placeholder="Data a">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataA').value" matSuffix
                            mat-icon-button aria-label="Clear" (click)="form.get('dataA').setValue(null)"
                            (click)="dataMax=null">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('dataA').errors?.required">
                            Campo obbligatorio
                        </mat-error>
          </mat-form-field>
        </div>

        <!-- Sede field -->
        <mat-form-field>
          <mat-label>Scegli la sede</mat-label>
          <mat-select [compareWith]="compareDTO" (selectionChange)="setPostazione()" formControlName="sede" >
            <mat-option *ngFor="let sede of sedi" [value]="sede">
              {{sede.descrizione}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('sede').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field> 

        <!-- descrizione delle postazioni field -->
        <!-- Autocomplete  --------------- -->
        <!-- (click)="setPostazione()" (input)="setPostazione()" -->
        <mat-form-field *ngIf="!isValidFormFiltersSelected()" fxFlex="80" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="80">
          <input (input)="postazioneChanged($event.target.value, true)" type="text" matInput formControlName="descrizione" [matAutocomplete]="descrizioneAutocomplete"
            placeholder="Scegli la postazione da prenotare">
          <mat-autocomplete autoActiveFirstOption #descrizioneAutocomplete="matAutocomplete"
            [displayWith]="inizializzaDescrizione()">
            <mat-option *ngFor="let postazione of filteredPostazioni" [value]="postazione">
              {{postazione.descrizione}} - {{postazione.ufficio.descrizione}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <!-- Note field -->
        <mat-form-field fxFlex>
          <textarea matInput formControlName="note" placeholder="Note"></textarea>
        </mat-form-field>
         
        <!-- Immagine sede -->
        <p *ngIf="numeroPostazioniPrenotabiliInSede == 0 " >
          Ci dispiace le postazioni prenotabili in sede sono finite
        </p>
        <p *ngIf="numeroPostazioniPrenotabiliInSede > 0 " >
          Le postazioni prenotabili sono {{numeroPostazioniPrenotabiliInSede}}
        </p>
        <br>
        <div class="divLegendaMappa" *ngIf="!isValidFormFiltersSelected() && form.get('sede').value.pathImmagine != '' && !isMobile">
        <span class="legendaLibera" ></span><label>Postazione Libera</label>
        <br>
        <span class="legendaPrenotata"></span><label>Postazione Prenotata</label>
        <br>
        <span class="legendaOspite"></span><label>Postazione Ospite</label>
        </div>
        <br>
        <div *ngIf="!isValidFormFiltersSelected() && form.get('sede').value.pathImmagine != '' && !isMobile"
                    style="position: relative;">
                    <!-- (change)="setPostazione()" -->
          <map name="sede">
            <area shape="rect" *ngFor="let rect of postazioniRect"
                alt="Computer" (click)="checkPrenotabileForClick(rect.isPrenotata,rect.id)" [ngStyle]="{
                    'height': rect.height + 'px',
                    'width': rect.width + 'px',
                    'top': rect.top + 'px',
                    'left': rect.left + 'px'}"
                    [matTooltip]="rect.nome + ' ' + rect.cognome"
                    [ngClass]="{'postazione':rect.discriminator == 'libera',
                                'postazionePrenotata':rect.discriminator == 'collaboratore', 
                                'postazionePrenotataOspite':rect.discriminator == 'ospite'}"> 
        </map>
        <img mat-card-image [attr.src]="form.get('sede').value.pathImmagine"
            style="height: 450px; width: 900px; margin: 0;" usemap="#sede" />
        </div>
        
        <div *ngIf="isMobile" class="divImmagineMobile">
        <img mat-card-image [attr.src]="form.get('sede').value.pathImmagine" class="immagineMobile" usemap="#sede" /> 
        </div>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">

            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/prenotazionepostazione')" color="primary">Back</button>
      <button mat-raised-button (click)="prenotazionepostazioneSave()"
        [disabled]="form.invalid || !form.dirty || !isValidFormPostazione()"
        *ngIf="prenotazionepostazione?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="prenotazionepostazioneDelete()" [disabled]="prenotazionepostazione?.id==null"
        *ngIf="prenotazionepostazione?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
import { CollaboratoreDTO } from './../domain/collaboratore';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';

export class DispositivoDTO extends LogicalDeleteDTO {
    
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    codice: String;
    descrizione: string;
}
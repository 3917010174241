<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione malattia</h2>
    <p>Imposta i filtri di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">
      <!-- Collaboratore field -->

      <mat-form-field>
        <mat-select name="collaboratore" [(ngModel)]="parameters.collaboratore" [compareWith]='compareDTO'
          [disabled]="!isJappTenantAdmin()" placeholder="Scegli il collaboratore" (selectionChange)="this.parameters.collaboratoreChanged = true">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{collaboratore.nome}} {{collaboratore.cognome}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Responsive -->
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">

        <!-- DataDa field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
            [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataDa=null" (click)="parameters.initDataDa=false">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- DataA field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
          <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
            [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataA=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    
    <!--Bottoni-->
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/registra-malattia/detail/0']">New</button>
    </mat-card-actions>

    <!--search-->
    <br />
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()" matSort
        (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Malattia </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let malattia"> {{malattia.id}} </td>
        </ng-container>

        <!-- dataDa Column -->
        <ng-container matColumnDef="dataDa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Da </th>
          <td mat-cell *matCellDef="let malattia"> {{malattia.dataDa | date :'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- dataA Column -->
        <ng-container matColumnDef="dataA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data A </th>
          <td mat-cell *matCellDef="let malattia"> {{malattia.dataA | date :'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- Collaboratore Column -->
        <ng-container matColumnDef="collaboratore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Collaboratore </th>
          <td mat-cell *matCellDef="let malattia" [hidden]="!isJappTenantAdmin()"> {{malattia.collaboratore?.nome}}
            {{malattia.collaboratore?.cognome}} </td>
        </ng-container>

        <!-- codice Column  -->
        <ng-container matColumnDef="codice">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Codice </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let malattia"> {{malattia.codice}}</td>
        </ng-container>


        <!-- Info Column -->
        <ng-container matColumnDef="detail">
          <th  fxShow.gt-md mat-header-cell *matHeaderCellDef> Dettaglio </th>
          <td mat-cell *matCellDef="let malattia">
            <button mat-mini-fab color="primary" [routerLink]="['/registra-malattia/detail/' , malattia.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>



      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'malattie'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('csv', {fileName:'malattie'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('json', {fileName:'malattie'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>


    </div>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Gestione ferie</h2>
      <p>Imposta i filtri di ricerca</p>
         <!-- Responsive -->
        <div fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- Collaboratore field -->
          <mat-form-field>
            <mat-label>Collaboratori</mat-label>
            <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO"
              [disabled]="!isJappTenantAdmin()" (selectionChange)= "this.parameters.collaboratoreChanged = true">
              <mat-option [value]="null">
                Tutti
              </mat-option>
              <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                {{collaboratore.codiceCollaboratore}} - {{collaboratore.nome}} {{collaboratore.cognome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()"[(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data fine periodo da guardare">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
               (click)="parameters.dataA=null">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
   
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search"
          color="primary">Search</button>
          <button mat-raised-button id="search-button" (click)="fileInput.click()" color="primary" *ngIf="isJappTenantAdmin()">Carica il pdf</button>
          <input #fileInput hidden type="file" (change)="fileChange($event)" accept=".pdf">
          <button mat-raised-button id="new-button" [routerLink]="['/ferie/detail/0']" *ngIf="isJappTenantAdmin()">New</button>
      </mat-card-actions>

      

       <div *ngIf="parameters.showList" fxLayout="column">
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection" matSortDisableClear
          [dataSource]="parameters.dataSource">
  
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let ferie"> {{ferie.id}} </td>
            <td fxHide fxShow.gt-md mat-footer-cell *matFooterCellDef ></td>
          </ng-container>
  
          <!-- DataPresenza Column -->
          <ng-container matColumnDef="dataA">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Periodo </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.dataA | date :'MM/yyyy'}} </td>
            <td mat-footer-cell *matFooterCellDef > </td>
          </ng-container>
  
          <!-- Collaboratore Column -->
          <ng-container matColumnDef="collaboratore" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Collaboratore </th>
            <td mat-cell *matCellDef="let ferie" [hidden]="!isJappTenantAdmin()"> {{ferie.collaboratore?.codiceCollaboratore}} - {{ferie.collaboratore?.nome}} {{ferie.collaboratore?.cognome}}</td>
            <td mat-footer-cell *matFooterCellDef [hidden]="!isJappTenantAdmin()"></td>
          </ng-container>

          <!--Ferie Residuo Column -->
          <ng-container matColumnDef="residuoFerie" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ferie residue </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.residuoFerie}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Ferie Maturabili Column -->
          <ng-container matColumnDef="maturabiliFerie" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ferie maturabili </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.maturabiliFerie}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Ferie Da Fruire Column -->
          <ng-container matColumnDef="daFruireFerie" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ferie da fruire </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.daFruireFerie}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

           <!-- Permessi Residuo Column -->
           <ng-container matColumnDef="residuoPermessi" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Permessi residui </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.residuoPermessi}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Permessi Maturabili Column -->
          <ng-container matColumnDef="maturabiliPermessi" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Permessi maturabili </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.maturabiliPermessi}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- Permessi Da Fruire Column -->
          <ng-container matColumnDef="daFruirePermessi" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Permessi da fruire </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.daFruirePermessi}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- ROL Residuo Column -->
          <ng-container matColumnDef="residuoROL" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ROL residui </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.residuoROL}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- ROL Maturabili Column -->
          <ng-container matColumnDef="maturabiliROL" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ROL maturabili </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.maturabiliROL}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- ROL Da Fruire Column -->
          <ng-container matColumnDef="daFruireROL" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ROL da fruire </th>
            <td mat-cell *matCellDef="let ferie"> {{ferie.daFruireROL}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
  
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef [hidden]="!isJappTenantAdmin()"></th>
            <td mat-cell *matCellDef="let ferie" [hidden]="!isJappTenantAdmin()">
              <button mat-mini-fab color="primary" [routerLink]="['/ferie/detail/', ferie.id]" class="my-fab" [hidden]="!isJappTenantAdmin()">
                <mat-icon>search</mat-icon>
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef ></td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
            [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Prendi chiavi</h2>

    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">

        <!-- Chiavi field -->

        <mat-form-field>
          <input matInput formControlName="chiavi" placeholder="Chiavi" readonly="true" />
        </mat-form-field>


        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- DataDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
              [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da"
              formControlName="dataDa">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
              (click)="parameters.dataDa=null">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
              [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a"
              formControlName="dataA">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
              (click)="parameters.dataA=null">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <mat-card-actions>
          <button mat-raised-button (click)="backConfirm('/gestione-chiavi')" color="primary">Back</button>
          <button mat-raised-button (click)="storiaChiaviCreate()" [disabled]="form.invalid || !form.dirty">Save</button>
        </mat-card-actions>

      </form>
    </div>
  </mat-card-content>
</mat-card>
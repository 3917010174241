import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { Stampante3DDTO } from 'src/app/shared/dto/stampante3D/stampante3D';
import { SedeDTO } from 'src/app/shared/dto/sede/sede';

@Injectable({
  providedIn: 'root'
})
export class Stampante3DService extends GenericService<Stampante3DDTO, number>{

  url: String

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/stampante3d';
   }

  public stampante3DSearchBySede (pageNumber: number, pageSize: number, direction: string, sortField: string, filter: SedeDTO): Observable<ResponseQueryByCriteria<Stampante3DDTO>> {
      let params = {};
      if (pageNumber != null) params["pageNumber"] = String(pageNumber);
      if (pageSize != null) params["pageSize"] = String(pageSize);
      if (direction != null) params["direction"] = direction;
      if (sortField != null) params["sortField"] = sortField;
      if (filter != null) params["idSede"] = filter.id;
      return this.http.get<ResponseQueryByCriteria<Stampante3DDTO>>(`${this.url}/stampante3DList`, { params: params })
  }

  public stampante3DSearchByCodice (pageNumber: number, pageSize: number, direction: string, sortField: string, filter: string): Observable<ResponseQueryByCriteria<Stampante3DDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["codice"] = filter;
    return this.http.get<ResponseQueryByCriteria<Stampante3DDTO>>(`${this.url}/stampante3DList`, { params: params })
}

 
}
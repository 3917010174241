import { SedeDTO } from './../../shared/dto/sede/sede';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { UfficioDTO } from 'src/app/shared/dto/ufficio/ufficio';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UfficioService extends GenericService<UfficioDTO, number>{

  url: String

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/ufficio';
   }

   public ufficioListByDescrizione (pageNumber: number, pageSize: number, direction: string, sortField: string, filter: string, filterSede?: SedeDTO): Observable<ResponseQueryByCriteria<UfficioDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    if (filterSede != null) params["filterSede"] = filterSede.id;
    return this.http.get<ResponseQueryByCriteria<UfficioDTO>>(`${this.url}/ufficioList`, { params: params })
}
}

import { Component, OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';
import { ServerDTO } from '../shared/dto/config/server';
import { AppDTO } from '../shared/dto/config/app';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  server : ServerDTO;

  app : AppDTO;

  constructor(private navigatorService : NavigatorService) { }

  ngOnInit() {
    this.navigatorService.app.subscribe(
      (res: AppDTO) => {
        this.app = res;
      }
    );
    this.navigatorService.server.subscribe(
      (res: ServerDTO) => {
        this.server = res;
      }
    );
 }

}

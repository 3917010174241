import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { ComunicazioneDTO } from './comunicazione';

export class ComunicazioneNascostaDTO extends LogicalDeleteDTO{
    
    id: number;
    
    comunicazione: ComunicazioneDTO;
    
    collaboratore: CollaboratoreDTO;
}
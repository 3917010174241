<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Gestione Interventi</h2>
      <p>Imposta i filtri di ricerca</p>
      <div fxLayout="column" class="responsive-mat-form">
                 <!-- Collaboratore field -->
            <mat-form-field>
            <mat-label>Collaboratori</mat-label>
            <mat-select [(ngModel)]="parameters.collaboratore" [compareWith]="compareDTO"
                [disabled]="!isJappTenantAdmin()" >
                <mat-option [value]="null">
                Tutti
                </mat-option>
                <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                {{collaboratore.nome}} {{collaboratore.cognome}}
                </mat-option>
            </mat-select>
            </mat-form-field>



                 <!-- Servizio field -->

            <mat-form-field>
                <mat-select name="servizio" [(ngModel)]="parameters.servizio" [compareWith]='compareDTO'
                 placeholder="Scegli il servizio">
                  <mat-option [value]="null">
                    Tutte
                  </mat-option>
                  <mat-option *ngFor="let servizio of servizi" [value]="servizio">
                    {{servizio.descrizione}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
      </div>




      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
        <!-- DataDa field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
            [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataDa=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- DataA field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
          <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
            [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataA=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search"
          color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/interventi-turni-servizi-rep/detail/0']">New</button>
      </mat-card-actions>




         <!-- Tabella Column -->






      <div *ngIf="parameters.showList" fxLayout="column">
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" matSortDisableClear 
          [dataSource]="parameters.dataSource">
      
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let intervento">{{intervento.id}}</td>
          </ng-container>
          <ng-container matColumnDef="turno.collaboratore.nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore </th>
            <td mat-cell *matCellDef="let intervento">{{intervento.turno.collaboratore.nome}} {{intervento.turno.collaboratore.cognome}}</td>
          </ng-container>
          <ng-container matColumnDef="turno.servizio.descrizione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Servizio </th>
            <td mat-cell *matCellDef="let intervento">{{intervento.turno.servizio.descrizione}} </td>
          </ng-container>

          <ng-container matColumnDef="dataDa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Inizio Intervento </th>
            <td mat-cell *matCellDef="let intervento">{{intervento.dataDa | date  :'medium'}}</td>
          </ng-container>
          <ng-container matColumnDef="dataA">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fine Intervento </th>
            <td mat-cell *matCellDef="let intervento">{{intervento.dataA | date  :'medium'}}</td>
          </ng-container>
        
        
      
          <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let intervento">
                <button mat-mini-fab color="primary" [routerLink]="['/interventi-turni-servizi-rep/detail/', intervento.id]"><mat-icon>search</mat-icon></button>
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
            [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
          </mat-paginator>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Duplica Orari Lavorativi</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id Collaboratore source field -->
        <mat-form-field>
        <mat-label>Collaboratore da copiare</mat-label>
          <input matInput formControlName="collaboratoreSource" readonly="true"/>
        </mat-form-field>

        <!-- Collaboratori target field -->
        <mat-form-field>
          <mat-label>Collaboratori da configurare</mat-label>
          <mat-select formControlName="collaboratori" multiple [disabled]="!isJappTenantAdmin()">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{ collaboratore.nome }} {{ collaboratore.cognome }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>

    <mat-card-actions style="display: flex">
      <!-- Back button -->
      <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">
        Back
      </button>

      <!-- Duplica button -->
      <button mat-raised-button (click)="orariLavorativiDuplica()" [disabled]="!isJappTenantAdmin()" [disabled]="!form.dirty || form.invalid">
        Duplica
      </button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>

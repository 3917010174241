<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">

    <h2>Gestione Stampante 3D</h2>
    <div fxLayout="column" class="responsive-mat-form">

      <mat-form-field>
        <mat-select name="sede" [(ngModel)]="parameters.filter" [compareWith]='compareDTO'
          placeholder="Scegli la Sede">
          <mat-option [value]="null">
            Tutte
          </mat-option>
          <mat-option *ngFor="let sede of sedi " [value]="sede">
            {{sede.descrizione}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [disabled]="!isJappTenantAdmin()" [routerLink]="['/stampante3d/detail/0']">New</button>
    </mat-card-actions>
    <br />
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()" matSort
        (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let stampante3D"> {{stampante3D.id}} </td>
        </ng-container>

        <!-- Code Column -->
        <ng-container matColumnDef="codice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice </th>
          <td mat-cell *matCellDef="let stampante3D"> {{stampante3D.codice}} </td>
        </ng-container>

        <!-- Modello Column -->
        <ng-container matColumnDef="modello">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Modello </th>
          <td mat-cell *matCellDef="let stampante3D"> {{stampante3D.modello}} </td>
        </ng-container>

        <!-- Sede -->
        <ng-container matColumnDef="sede.descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sede </th>
          <td mat-cell *matCellDef="let stampante3D"> {{stampante3D.sede.descrizione}} </td>
        </ng-container>

        <!-- Colore -->
        <ng-container matColumnDef="colore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Colore </th>
          <td mat-cell *matCellDef="let stampante3D">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="color-preview" [style.background-color]="stampante3D.colore"></div>
              <span class="color-code">{{ stampante3D.colore }}</span>
            </div>
          </td>
        </ng-container>

        <style>
          .color-preview {
            width: 20px;
            /* Larghezza del quadratino del colore */
            height: 20px;
            /* Altezza del quadratino del colore */
            margin-right: 10px;
            /* Margine a destra per separare il quadratino dal testo */
            border: 1px solid #ccc;
            /* Bordo del quadratino */
          }

          /* Stile del testo per il codice esadecimale */
          .color-code {
            font-size: 14px;
            color: #333;
            margin-top: 5px;
          }
        </style>


        <!-- detail  -->
        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let stampante3D">
            <button mat-mini-fab color="primary"
            [disabled]="!isJappTenantAdmin()" [routerLink]="['/stampante3d/detail/', stampante3D.id]"><mat-icon>search</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'stampanti3D'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('csv', {fileName:'stampanti3D'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('json', {fileName:'stampanti3D'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';

export class PrenotazioneDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    richiedente: CollaboratoreDTO;
    dataDa: Date;
    dataA: Date;
    luogo: string;
    riferimento: string;
    stato: boolean;
    note: string;
}
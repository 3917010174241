import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChiusuraDTO } from '../../shared/dto/assenza/chiusura';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';



@Injectable({
  providedIn: 'root'
})
export class ChiusuraService extends GenericService<ChiusuraDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/chiusura';
  }

  public chiusuraListByList(pageNumber: number, pageSize: number, direction: string, sortField: string, dataDa?: Date, dataA?: Date, descrizione?: String): Observable<ResponseQueryByCriteria<ChiusuraDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    if (descrizione != null) params["descrizione"] = descrizione;
    return this.http.get<ResponseQueryByCriteria<ChiusuraDTO>>(`${this.url}/chiusuraListByList`, {
      params: params
    });
  }

  public chiusuraListByMese(dataDa: Date, dataA: Date): Observable<ChiusuraDTO[]> {
    let params = {};
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    return this.http.get<ChiusuraDTO[]>(`${this.url}/chiusuraListByMese`, {
      params: params
    });
  }

  //FUNZIONALITÀ PER IL CALENDARIO DELL'AMMINISTRAZIONE
  public searchAllByday(data : Date): Observable<ChiusuraDTO[]> {
    let params = {};
    if (data != null) params["data"] = new Date(data.getTime() - (data.getTimezoneOffset() * 60000)).toISOString();
    return this.http.get<ChiusuraDTO[]>(`${this.url}/searchAllByday`, {
      params: params
    });
  }
}

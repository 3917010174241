<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Prendi un caffe</h2>
    <div *ngIf="qrcode" fxLayout="column" fxLayoutAlign="space-around center">
      <div layout="row" fxFlex>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="end start">
          <button fxHide fxShow.gt-xs mat-fab color="primary" 
            [routerLink]="['/gestione-credito']"
            style="position: relative; margin-right: -100px; margin-top : 30px; z-index:1">
              <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <zxing-scanner  #scanner [formats]="['QR_CODE']"
                        [device]="selectedDevice" 
                        (scanSuccess)="qrcodeReaded($event)" 
                        (autostarted)="scannerStarted($event)"
                        (camerasFound)="scannerCamerasFound()"
                        class="qrcodeScanner"
                        fxFlex="50"
        ></zxing-scanner>
        <div fxFlex="100" >
          <button fxHide fxShow.gt-xs mat-fab color="primary" 
            *ngIf="manualSwitchEnabled"
            (click)="enterManually()"
            style="position: relative; margin-left : -100px; margin-top : 30px">
            <mat-icon>pan_tool</mat-icon>
          </button>
        </div>
      </div>
      <!-- Visualizzazione solo mobile -->
      <div fxLayout="row" fxLayoutAlign="space-evenly center" fxHide="false" fxShow.gt-xs="false">
        <button mat-fab color="primary" 
                [routerLink]="['/gestione-credito']">           
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-fab color="primary" 
            *ngIf="manualSwitchEnabled"
            (click)="enterManually()">
          <mat-icon>pan_tool</mat-icon>
        </button> 
      </div>
    </div>
    <div layout="row" fxFlex style="margin-top: 4%;">
      <div fxFlex="15">
      </div>
    <div fxLayoutAlign="end start" fxFlex="70">
      <mat-select (selectionChange)="onDeviceChange($event.value)"
            placeholder="Scegli la fotocamera">
            <mat-option *ngFor="let device of availableDevices" [value]="device">
            {{device.label}}
          </mat-option>
        </mat-select>
      </div>
      <div fxFlex="15">
      </div>
    </div>
    <div *ngIf="!qrcode">
      <div fxLayout="column" class="responsive-mat-form">
        <mat-form-field>
          <mat-label>Prodotto da acquistare</mat-label>
          <mat-select [(value)]="movimento.catalogo">
            <mat-option *ngFor="let catalogo of catalogos" [value]="catalogo">
            {{catalogo.descrizione}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Quantità</mat-label>
          <input matInput type="number" OnlyNumber="true" min="1" max="20" [(ngModel)]="movimento.qtaProdotti">
        </mat-form-field>
      </div>
      <mat-card-actions>
        <button mat-stroked-button (click)="movimentoSave()" [disabled]="movimento.catalogo==null || movimento.qtaProdotti == null">Save</button>
        <button mat-stroked-button (click)="scanQrcode()">
          <mat-icon>photo_camera</mat-icon>
        </button>
        <button mat-stroked-button id="back-button" [routerLink]="['/gestione-credito']">Back</button>
      </mat-card-actions>
    </div>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione uffici</h2>
        <p>Imposta i filtri di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">

        <!-- Sede field -->
        <mat-form-field>
          <mat-select name="sede" [(ngModel)]="parameters.filterSede" [compareWith]='compareDTO'
            [disabled]="!isJappTenantAdmin()" placeholder="Scegli la Sede">
            <mat-option [value]="null">
              Tutte
            </mat-option>
            <mat-option *ngFor="let sede of sedi " [value]="sede">
              {{sede.descrizione}} 
            </mat-option>
          </mat-select>
        </mat-form-field>

          <mat-form-field>
            <input matInput [(ngModel)]="parameters.filter" placeholder="Filter"/>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/ufficio/detail/0']">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let ufficio"> {{ufficio.id}} </td>
            </ng-container>
            
            <!-- Description Column -->
            <ng-container matColumnDef="descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Description </th>
              <td mat-cell *matCellDef="let ufficio"> {{ufficio.descrizione}} </td>
            </ng-container>
            
            <!-- PostazioniMassime Column -->
            <ng-container matColumnDef="postazioniMassime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Postazioni Massime </th>
              <td mat-cell *matCellDef="let ufficio"> {{ufficio.postazioniMassime}} </td>
            </ng-container>

            <!-- Sede descrizione  Column -->
            <ng-container matColumnDef="sede.descrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Descrizione Sede </th>
                <td mat-cell *matCellDef="let ufficio"> {{ufficio.sede.descrizione}} </td>
              </ng-container>
        
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let ufficio">
                  <button mat-mini-fab color="primary" [routerLink]="['/ufficio/detail/', ufficio.id]"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> 
      </div>
    </mat-card-content>
  </mat-card>

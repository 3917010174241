<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli comanda</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <form [formGroup]="form" fxLayout="column">
                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Comanda ID" readonly="true" />
                </mat-form-field>

                <!-- Collaboratore field -->
                <mat-form-field fxLayout="column">
                    <mat-label>Collaboratori</mat-label>
                    <mat-select formControlName="collaboratore" [compareWith]="compareDTO"
                        [disabled]="!isJappTenantAdmin()">
                        <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                            {{collaboratore.nome}} {{collaboratore.cognome}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <!-- DateComanda field -->
                <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput [min]="today"  [matDatepicker]="picker" formControlName="dataComanda"
                        placeholder="Seleziona data" (click)="picker.open()" readonly="true">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="form.get('dataComanda').value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="form.get('dataComanda').setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('dataComanda').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <!--Descrizione field-->
                <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <textarea maxlength="100" matInput formControlName="descrizione" placeholder="Descrizione"></textarea>
                    <mat-error *ngIf="form.get('descrizione').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <!--Email field-->
                <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input maxlength="100" matInput formControlName="email" placeholder="Email/Username Paypal">
                </mat-form-field>

                <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput formControlName="link" placeholder="Link al sito">
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('gestione-comanda')" color="primary">Back</button>
            <button mat-raised-button (click)="comandaSave()" [disabled]=" !form.dirty || form.invalid"
                *ngIf="comanda?.deleteDate==null">Save</button>
            <button mat-raised-button (click)="comandaDelete()" [disabled]="comanda?.id==null"
                *ngIf="comanda?.deleteDate==null">Delete</button>
        </mat-card-actions>
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" [matSortActive]="defaultSortField"
                [matSortDirection]="defaultSortDirection" matSortDisableClear [dataSource]="parameters.dataSource">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef > Segnato </th>
                    <td  mat-cell *matCellDef="let ordine"> <input type="checkbox"> </td>
                    <td mat-footer-cell *matFooterCellDef ></td>
                </ng-container>

                <!-- Descrizione Column -->
                <ng-container matColumnDef="descrizioneOrdine">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione
                    </th>
                    <td mat-cell *matCellDef="let ordine"> {{ordine.descrizioneOrdine}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef ></td>
                </ng-container>
                <!-- Collaboratore Column -->
                <ng-container matColumnDef="collaboratore">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore
                    </th>
                    <td mat-cell *matCellDef="let ordine">
                        {{ordine.collaboratore?.nome}} {{ordine.collaboratore?.cognome}}</td>
                        <td mat-footer-cell *matFooterCellDef ></td>
                </ng-container>

                <!-- importo dovuto Column -->
                <ng-container matColumnDef="importoDovuto">
                    <th mat-header-cell  *matHeaderCellDef mat-sort-header> Importo
                        dovuto </th>
                    <td mat-cell *matCellDef="let ordine"> {{ordine.importoDovuto}}</td>
                    <td mat-footer-cell *matFooterCellDef ><div *ngIf="hideFooterColumns()"><b>Totale: {{comanda?.totale}}</b></div></td>
                </ng-container>

                <!-- importo pagato Column -->
                <ng-container matColumnDef="importoPagato">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importo
                        pagato </th>
                    <td mat-cell *matCellDef="let ordine"> {{ordine.importoPagato}}</td>
                    <td mat-footer-cell *matFooterCellDef ></td>
                </ng-container>

                <!-- Soldi dovuti finali Column -->
                <ng-container matColumnDef="differenza" >
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef> Differenza
                    </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let ordine">
                        {{ordine.importoDovuto-ordine.importoPagato}}</td>
                        <td mat-footer-cell *matFooterCellDef ></td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef> Pagamento totale </th>
                    <td mat-cell *matCellDef="let ordine">
                        <button *ngIf="comanda.collaboratore?.id!=ordine.collaboratore?.id" mat-mini-fab color="primary"
                            (click)="pagamentoCompletato(ordine)">
                            <mat-icon>price_check</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef ></td>
                </ng-container>

                <ng-container matColumnDef="detail1">
                    <th mat-header-cell *matHeaderCellDef> Pagamento parziale </th>
                    <td mat-cell *matCellDef="let ordine">
                        <button *ngIf="comanda.collaboratore?.id!=ordine.collaboratore?.id" mat-mini-fab color="primary" [routerLink]="['/gestione-pagamento/', ordine.id]">
                            <mat-icon>attach_money</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef ></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-actions>
                    <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'ordini'})">Excel</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('csv', {fileName:'ordini'})">Csv</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('json', {fileName:'ordini'})">Json</button>
                </mat-card-actions>
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { RapportinoFinaleDTO } from './../../shared/dto/rapportino/rapportinoFinale';
import { environment } from './../../../environments/environment';
import { GenericService } from './../../shared/GenericService';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RapportinoFinaleService extends GenericService<RapportinoFinaleDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/rapportinoFinale';
  }

  public rapportinoFinaleList(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO, mese: number, anno: number, stato: string): Observable<ResponseQueryByCriteria<RapportinoFinaleDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (anno != null) params["anno"] = anno;
    if (mese != null) params["mese"] = mese;
    if (stato != null) params["stato"] = stato;
    if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<RapportinoFinaleDTO>>(`${this.url}/rapportinoFinaleList`, {
      params: params
    });
  }
}

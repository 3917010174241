<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Corsi Udemy</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field>
        <input matInput (keyup)="list()" [(ngModel)]="parameters.filter" name="filter" placeholder="Filter" />
      </mat-form-field>
    </div>
    <mat-card-actions>
      <!-- <button mat-raised-button id="search-button" name="Search" (click)="list(); disableunfilteredTable()" color="primary">Search</button> -->
      <button *ngIf="isJappTenantAdmin()" mat-raised-button id="new-button" name="New" [routerLink]="['/corsoudemy/detail/0']">New</button>
    </mat-card-actions>
    <br />

    <div *ngIf="!parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()" matSort
         matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- Id Corso -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let corsoudemy"> {{corsoudemy.id}} </td>
        </ng-container>
 
        <!-- Titolo corso -->
        <ng-container matColumnDef="titolo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Titolo </th>
          <td mat-cell *matCellDef="let corsoudemy">{{corsoudemy.titolo}}</td>
        </ng-container> 
 
        <!-- Link corso -->
        <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Link </th>
          <td mat-cell *matCellDef="let corsoudemy">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="icon-font-size corsoLink" (click)="riderect(corsoudemy?.link)">language</mat-icon>
              <div class="corsoLink" [ngStyle]="{'padding': 2 + '%'}" (click)="riderect(corsoudemy?.link)">{{corsoudemy.link}}</div>
            </div>
          </td>
        </ng-container>

        <!-- N° lezioni corso -->
        <ng-container matColumnDef="nlezioni">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> N° lezioni</th>
          <td mat-cell *matCellDef="let corsoudemy">{{corsoudemy.nlezioni}}</td>
        </ng-container>

        <!-- Durata corso -->
        <ng-container matColumnDef="ore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Durata </th>
          <td mat-cell *matCellDef="let corsoudemy"> {{corsoudemy.ore}}h {{corsoudemy.minuti}}min</td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let corsoudemy">
            <button mat-mini-fab color="primary" [routerLink]="['/corsoudemy/detail/', corsoudemy.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'corsoudemy'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('csv', {fileName:'corsoudemy'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('json', {fileName:'corsoudemy'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>



    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()" matSort
        (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- Id Corso -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let corsoudemy"> {{corsoudemy.id}} </td>
        </ng-container>
 
        <!-- Titolo corso -->
        <ng-container matColumnDef="titolo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Titolo </th>
          <td mat-cell *matCellDef="let corsoudemy">{{corsoudemy.titolo}}</td>
        </ng-container> 
 
        <!-- Link corso -->
        <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Link </th>
          <td mat-cell *matCellDef="let corsoudemy">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="icon-font-size corsoLink" (click)="riderect(corsoudemy?.link)">language</mat-icon>
              <div class="corsoLink" [ngStyle]="{'padding': 2 + '%'}" (click)="riderect(corsoudemy?.link)">{{corsoudemy.link}}</div>
            </div>
          </td>
        </ng-container>

        <!-- N° lezioni corso -->
        <ng-container matColumnDef="nlezioni">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> N° lezioni</th>
          <td mat-cell *matCellDef="let corsoudemy">{{corsoudemy.nlezioni}}</td>
        </ng-container>

        <!-- Durata corso -->
        <ng-container matColumnDef="ore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Durata </th>
          <td mat-cell *matCellDef="let corsoudemy"> {{corsoudemy.ore}}h {{corsoudemy.minuti}}min</td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let corsoudemy">
            <button mat-mini-fab color="primary" [routerLink]="['/corsoudemy/detail/', corsoudemy.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'corsoudemy'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('csv', {fileName:'corsoudemy'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('json', {fileName:'corsoudemy'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TipoTurnoAMDTO } from '../../shared/dto/turno-am/tipo-turno-am';
import { GenericService } from '../../shared/GenericService';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';

@Injectable({
  providedIn: 'root'
})
export class TipoTurnoAmService extends GenericService<TipoTurnoAMDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/tipoTurnoAM'
  }

  public tipoTurnoListByList(
    nome?: String,
    orario?: String,
    descrizione?: String,
    pageNumber?: number,
    pageSize?: number, 
    direction?: string, 
    sortField?: string 
  ): Observable<ResponseQueryByCriteria<TipoTurnoAMDTO>>{
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (nome != null) params["nome"] = nome;
    if (orario != null) params["orario"] = orario;
    if (descrizione != null) params["descrizione"] = descrizione;
    return this.http.get<ResponseQueryByCriteria<TipoTurnoAMDTO>>(`${this.url}/tipoTurnoListByList`, {
      params: params
    });
  }
}

import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MalattiaDTO } from '../shared/dto/malattia/malattia';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MalattiaService } from '../services/malattia/malattia.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-registra-malattia',
  templateUrl: './registra-malattia.component.html',
  styleUrls: ['./registra-malattia.component.scss']
})
export class RegistraMalattiaComponent  extends GenericListComponent implements OnInit, OnDestroy{

  collaboratori: CollaboratoreDTO[];
  collaboratore: CollaboratoreDTO;


  constructor(
    private malattiaService: MalattiaService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'dataDa', 'dataA',  'collaboratore', 'codice', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      collaboratore: null,
      collaboratoreChanged: false,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.collaboratore = res;
          this.parameters.collaboratore = this.collaboratore;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  list() {      
    this.malattiaService.malattiaListByList(
      this.parameters.collaboratore,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.codice,
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField

      ).subscribe(
        (res: ResponseQueryByCriteria<MalattiaDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { PreferitiService } from '../services/preferiti/preferiti.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { TenantDTO } from '../shared/dto/domain/tenant';
import { PreferitiDTO } from '../shared/dto/preferiti/preferiti';
import { MenuDTO } from "../shared/dto/menu/menu";
import { FunzionalitaDTO } from "../shared/dto/menu/funzionalita";
import { funzionalita } from "../app.component";
import { MenuService } from "../services/menu/menu.service";
import { MenuStore } from "../services/menu/menu.store";

@Component({
  selector: 'app-preferiti',
  templateUrl: './preferiti.component.html',
  styleUrls: ['./preferiti.component.scss']
})
export class PreferitiComponent implements OnInit, OnChanges {

  @Input() preferiti: any;

  @Output() lengthPreferiti = new EventEmitter<number>();

  tenant: TenantDTO;

  collaboratore: CollaboratoreDTO;

  preferitiList: PreferitiDTO[] = [];

  funzionalitaPreferite: FunzionalitaDTO[] = [];

  parameters = {
    dataSource: [],
    showList: false,
    sortField: "insertDate",
    sortDirection: "DESC",
    stato: 'APPROVATO',
    tipoRichiesta: 'FERIE',
    pageNumber: 0,
    pageSize: 5,
    length: 0
  }

  getFunzionalitaPreferiti(): FunzionalitaDTO[] {
    let funzionalitaPreferiteToSend: FunzionalitaDTO[] = [];
    this.menuService.getListMenuObservable().subscribe(menu => menu.forEach(menuOne => {
      menuOne.listaFunzionalita.forEach(funzionalitaOne => {
        if (this.getPreferito(funzionalitaOne.nomeFunzionalita)) {
          funzionalitaPreferiteToSend.push(funzionalitaOne);
        }
      })
    }))

    return funzionalitaPreferiteToSend;
  }
  constructor(private navigatorService: NavigatorService,
    private componentCacheService: ComponentCacheService,
    private preferitiService: PreferitiService,
    private menuService: MenuStore,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.navigatorService.tenant.subscribe(
      (res: TenantDTO) => {
        this.tenant = res;
      }
    );
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        this.listPreferiti();
      }
    );

  }

  listPreferiti() {
    this.preferitiService.preferitiList(
      this.collaboratore.id
    ).subscribe(
      (res: PreferitiDTO[]) => {
        console.log("ciao : " + res);
        this.preferitiList = res;
        this.lengthPreferiti.emit(this.preferitiList.length);
      }
    )
  }

  listPreferitiRimozione(servizioCod: string) {
    this.preferitiService.preferitiList(
      this.collaboratore.id
    ).subscribe(
      (res: PreferitiDTO[]) => {
        this.preferitiList = res;
        let preferitoEstratto = new PreferitiDTO();
        this.preferitiList.forEach(preferito => {
          if (preferito.servizio.codServizio == servizioCod) {
            preferitoEstratto = preferito;
          }
        })
        this.preferitiService.delete(preferitoEstratto.id).subscribe((res) => {
          console.log("Eliminato : " + res);
          this.preferiti[preferitoEstratto.servizio.codServizio] = false;
          this.listPreferiti();
        })
      }
    )
  }

  getPreferito(value: string) {
    return this.preferiti[value];
  }

  isLoggedIn(): boolean {
    return this.navigatorService.isLoggedIn();
  }

  isJappTenantAdmin(): boolean {
    return this.navigatorService.isJappTenantAdmin();
  }

  isJappAdmin(): boolean {
    return this.navigatorService.isJappAdmin();
  }

  logout() {
    this.navigatorService.logout();
  }

  isActive(value: string): boolean {
    return this.navigatorService.isActive(value);
  }

  ngOnDestroy(): void {
    this.componentCacheService.set(HomeComponent.name, this.parameters);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.funzionalitaPreferite = this.getFunzionalitaPreferiti();
  }

  aggiornaFunzionalitaPreferiti() {
    this.funzionalitaPreferite = this.getFunzionalitaPreferiti();
  }

  navigateTo(funzionalita: FunzionalitaDTO) {
    if (funzionalita.linkRisorsa.startsWith("http")) {
      window.open(funzionalita.linkRisorsa, "_blank");
    } else {
      this.router.navigateByUrl(funzionalita.linkRisorsa);
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { NavigatorService } from '../services/navigator.service';
import { RisorsaAziendaleService } from '../services/risorsa-aziendale/risorsa-aziendale.service';
import { RisorsaAziendaleDTO } from '../shared/dto/risorsa-aziendale/risorsa-aziendale';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-risorsa-aziendale-detail',
  templateUrl: './risorsa-aziendale-detail.component.html',
  styleUrls: ['./risorsa-aziendale-detail.component.scss']
})
export class RisorsaAziendaleDetailComponent  extends GenericDetailComponent implements OnInit, OnDestroy {

  risorsaId: number;
  risorsa: RisorsaAziendaleDTO;
  risorseGruppi: string[];
  private gruppoTimer: any;

  constructor(
    private risorsaAziendaleService: RisorsaAziendaleService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      gruppo: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      icona: new FormControl('', Validators.required),
      fontset: new FormControl('',),
      descrizione: new FormControl('',),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.risorsaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.risorsaId != null && this.risorsaId != 0) {
      this.risorsaAziendaleRead();
    } else {
      this.risorsa = new RisorsaAziendaleDTO();
    }

    this.risorsaAziendaleService.gruppiRisorseList('')
    .subscribe(
      (res: Array<string>) => {
        this.risorseGruppi = res;
      }
    );
  }

  gruppoChanged(newValue:string, fromUser:boolean) {
    if (fromUser) {
      clearTimeout(this.gruppoTimer);
      this.gruppoTimer = setTimeout(() => {
        this.gruppoChanged(newValue, false);
      }, 400);
    } else {
      this.risorsaAziendaleService.gruppiRisorseList(
        newValue
      )
      .subscribe(
        (res: Array<string>) => {
            this.risorseGruppi = res;
            this.risorseGruppi.forEach(g => {
              if(g == newValue){
                this.form.get("gruppo").setValue(g); 
              }
            });
        });
      }
  }

  risorsaAziendaleSave() {
    if (this.risorsa.id == null) {
      this.risorsaAziendaleCreate();
    } else {
      this.risorsaAziendaleUpdate();
    }
  }

  risorsaAziendaleCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.risorsaAziendaleService.create(this.risorsa).subscribe(
        (res: RisorsaAziendaleDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.risorsa = res;
          this.risorsaId = this.risorsa.id;
          this.dtoToForm();
          this.form.markAsPristine();
        } 
      );
    }
  }

  risorsaAziendaleUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la risorsa aziendale ?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.risorsaAziendaleService.update(this.risorsa).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.risorsaAziendaleRead();
              this.form.markAsPristine();
            } 
          );
        }
      });
    }
  }

  risorsaAziendaleDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la risorsa aziendale ?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.risorsaAziendaleService.delete(this.risorsa.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/risorsa-aziendale"]);
            }
          );
        }
      });
    }
  }

  risorsaAziendaleRead() {
    this.risorsaAziendaleService.read(this.risorsaId).subscribe(
      (res: RisorsaAziendaleDTO) => {
        console.log("response : " + res);
        this.risorsa = res;
        this.risorsaId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.risorsa.gruppo = this.form.get("gruppo").value;
    this.risorsa.url = this.form.get("url").value;
    this.risorsa.icona = this.form.get("icona").value;
    this.risorsa.fontset = this.form.get("fontset").value;
    this.risorsa.descrizione = this.form.get("descrizione").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.risorsa.id);
    this.form.get("gruppo").setValue(this.risorsa.gruppo);
    this.form.get("url").setValue(this.risorsa.url);
    this.form.get("icona").setValue(this.risorsa.icona);
    this.form.get("fontset").setValue(this.risorsa.fontset);
    this.form.get("descrizione").setValue(this.risorsa.descrizione);
    this.form.get("deleteDate").setValue(this.risorsa.deleteDate);
    this.form.get("deleteUser").setValue(this.risorsa.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.risorsa.insertDate));
    this.form.get("insertUser").setValue(this.risorsa.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.risorsa.updateDate));
    this.form.get("updateUser").setValue(this.risorsa.updateUser);
    this.form.get("optLock").setValue(this.risorsa.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

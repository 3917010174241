import { CollaboratoreDTO } from "../domain/collaboratore";

export class SostituzioneTurnoServiziReperibilitaDTO {
	
	idTurno: number;

    dataSostituzione: Date;
    
    collaboratoreSostituto: CollaboratoreDTO;
    
}
import { Component } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DAYS_OF_WEEK } from 'calendar-utils';
import { TurnoAmService } from '../services/turno-am/turno-am.service';
import { NavigatorService } from '../services/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-turno-am-copy',
  templateUrl: './turno-am-copy.component.html',
  styleUrls: ['./turno-am-copy.component.scss']
})
export class TurnoAmCopyComponent extends GenericDetailComponent {

  form = new FormGroup({
    dataDa: new FormControl('', Validators.required),
    dataA: new FormControl('', Validators.required),
  }, DateCheckerValidator);

  constructor(
    private turnoAmService: TurnoAmService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
  }

  onlyMonday(date) {
    var day = date.getDay();
    return day === 1;
  }


  turnoAMCopy() {
    let dDa: Date = this.form.get("dataDa").value;
    let dA: Date = this.form.get("dataA").value;
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler copiare i turni?").subscribe(result => {
        if (result) {
          this.turnoAmService.turnoAMCopyWeek(dDa, dA, false).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Copia avvenuto con successo!", null, { duration: 3000 });
            },
            (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidTurnoAMException_TURNI_NOT_OVERWRITEABLE") {
                this.snackBar.dismiss();
                this.confirm("Vuoi sovrascrivere i turni già esistenti?").subscribe(result => {
                  if(result){
                    this.turnoAmService.turnoAMCopyWeek(dDa, dA, true).subscribe(
                      (res) => {
                        console.log("response : " + res);
                        this.snackBar.open("Copia avvenuto con successo!", null, { duration: 3000 });
                      }
                    );
                  }
                })
              }
            }
          );
        }
      });
    }
  }

}

const DateCheckerValidator: ValidatorFn = (fg: FormGroup) => {
  const dDa: Date = (fg.get('dataDa').value == "") ? null : fg.get('dataDa').value;
  const dA: Date = (fg.get('dataA').value == "") ? null : fg.get('dataA').value;
  if (dDa !== null && dA !== null) {
    if (dDa.getTime() == dA.getTime()) {
      return { dateUguali: true };
    }
    if (dDa.getDay() != DAYS_OF_WEEK.MONDAY || dA.getDay() != DAYS_OF_WEEK.MONDAY) {
      return { giornoInvalido: true };
    }
  }
  return null;
};


<div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
    <ng-container *ngFor="let funzionalitaOne of funzionalitaPreferite; index as j;">
        <mat-card class="button-dimension" *ngIf="getPreferito(funzionalitaOne.nomeFunzionalita) == true">
            <mat-card-actions *ngIf="isLoggedIn() && getPreferito(funzionalitaOne.nomeFunzionalita) == true">
                <div fxLayout="row" fxLayoutAlign="end center" class="stella">
                    <mat-icon color="primary" (click)="listPreferitiRimozione(funzionalitaOne.nomeFunzionalita)">star</mat-icon>
                </div>
                <div>
                    <button *ngIf="funzionalitaOne.nomeFunzionalitaPrint=='Logout'" mat-button fxFlex (click)="logout()" class="customButtonPadding">
                        <mat-card-title>Logout</mat-card-title>
                        <mat-icon class="icon-font-size">exit_to_app</mat-icon>
                    </button>
                    <button *ngIf="funzionalitaOne.nomeFunzionalitaPrint!='Logout'"  mat-button fxFlex 
                        (click)="navigateTo(funzionalitaOne);" class="customButtonPadding">
                        <mat-card-title>{{funzionalitaOne.nomeFunzionalitaPrint}}</mat-card-title>
                        <mat-icon *ngIf="funzionalitaOne.nomeIcona.startsWith('fas ')" [class]="funzionalitaOne.nomeIcona"></mat-icon>
                        <mat-icon *ngIf="!funzionalitaOne.nomeIcona.startsWith('fas ')" class="icon-font-size">{{funzionalitaOne.nomeIcona}}</mat-icon>
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </ng-container>
</div>
import { UfficioDTO } from './../shared/dto/ufficio/ufficio';
import { UfficioService } from './../services/ufficio/ufficio.service';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { PostazioneService } from '../services/postazione/postazione.service';
import { PostazioneDTO } from '../shared/dto/postazione/postazione';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-postazione',
  templateUrl: './postazione.component.html',
  styleUrls: ['./postazione.component.scss']
})
export class PostazioneComponent extends GenericListComponent implements OnInit, OnDestroy {

  uffici: UfficioDTO[];

  constructor(
    private postazioneService: PostazioneService,
    private ufficioService: UfficioService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) { 
    super(
    navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'descrizione', 'ufficio.descrizione' , 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      filterUfficio: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list(){
    this.postazioneService.postazioneList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.filter,
      this.parameters.filterUfficio
    ).subscribe((response: ResponseQueryByCriteria<PostazioneDTO>) => {
      console.log("response : " + response);
      this.parameters.dataSource = response.content;
      this.parameters.pageNumber = response.pageNumber;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
      }
    )
  }

  ngOnInit() {
    super.ngOnInit();
    this.ufficioService.ufficioListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<UfficioDTO>) => {
          console.log("response : " + res);
          this.uffici = res.content;
        }
      );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

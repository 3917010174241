import { GenericService } from './../../shared/GenericService';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { MovimentoDTO } from '../../shared/dto/credito/movimento';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MovimentoService extends GenericService<MovimentoDTO, number>{
  
  constructor(http: HttpClient) { 
    super(http);
    this.url = environment.urlPrefix+ '/services/rest/json/movimento';
  }

  public movimentoListByList(pageNumber: number, pageSize: number, direction: string, sortField: string ,idCredito: number, tipo: string, dataDa?: Date, dataA?: Date) : Observable<ResponseQueryByCriteria<MovimentoDTO>>{
    let params = {};
    if (pageNumber!=null) params["pageNumber"] = String(pageNumber);
    if (pageSize!=null) params["pageSize"] =  String(pageSize);
    if (direction!=null) params["direction"] = direction;
    if (sortField!=null) params["sortField"] = sortField;
    if (idCredito!=null) params["idCredito"] = idCredito;
    if (dataDa!=null) params["dataDa"] = dataDa.toISOString();
    if (dataA!=null) params["dataA"] = dataA.toISOString();
    if (tipo!=null) params["tipo"] = tipo;
    return this.http.get<ResponseQueryByCriteria<MovimentoDTO>>(`${this.url}/movimentoListByList`, {
      params : params
    });
  }
  
}

import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {MenuDTO} from "../../shared/dto/menu/menu";
import {GenericService} from "../../shared/GenericService";
import {environment} from "../../../environments/environment";
import {shareReplay} from "rxjs/operators";
import {MenuService} from "./menu.service";

@Injectable({
    providedIn: 'root'
})
export class MenuStore extends GenericService<MenuDTO, number>{
    url: string
    private subject = new BehaviorSubject<MenuDTO[]>([]);

    menu$:Observable<MenuDTO[]> = this.subject.asObservable();
    called: boolean = false;


    constructor(
        http:HttpClient,
        public menuService: MenuService) {
        super(http);
        this.url = environment.urlPrefix + '/services/rest/json/menu';
    }

    getListMenuObservable(){
        return this.menu$;
    }


    public loadAllMenu() {
        if (this.subject.value.length < 1 && !this.called) {
            this.called = true;
            this.menuService.getListMenu().subscribe(menu=>{
                this.subject.next(menu)
            });
        }
    }

}

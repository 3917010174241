import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { ClienteDTO } from 'src/app/shared/dto/cliente/cliente';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends GenericService<ClienteDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/cliente';
  }

  public clienteList(pageNumber: number, pageSize: number, direction: string, sortField: string, filter: string): Observable<ResponseQueryByCriteria<ClienteDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    return this.http.get<ResponseQueryByCriteria<ClienteDTO>>(`${this.url}/clienteList`, {
      params: params
    });
  }

  public clienteByDescrizione(descrizione: String): Observable<ClienteDTO> {
    return this.http.get<ClienteDTO>(`${this.url}/byDescrizione/${descrizione}`);
  }
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli malattia</h2>
        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Malattia Id" readonly="true" />
                </mat-form-field>
                <!-- Collaborattore field -->
                <mat-form-field>
                    <mat-select name="collaboratore" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
                        formControlName="collaboratore" placeholder="Scegli il collaboratore">
                        <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                            {{collaboratore.nome}} {{collaboratore.cognome}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <!-- Responsive -->
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- DataDa field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker.open()"
                            [max]="dataMax" (dateChange)="setMinDate()" [matDatepicker]="picker"
                            formControlName="dataDa" placeholder="Scegli data da (compresa)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataDa').value" matSuffix
                            mat-icon-button aria-label="Clear" (click)="form.get('dataDa').setValue(null)"
                            (click)="dataMin=null">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('dataDa').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <!-- DataA field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker2.open()"
                            [min]="dataMin" (dateChange)="setMaxDate()" [matDatepicker]="picker2"
                            formControlName="dataA" placeholder="Scegli data a (compresa)">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataA').value" matSuffix
                            mat-icon-button aria-label="Clear" (click)="form.get('dataA').setValue(null)"
                            (click)="dataMax=null">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="form.get('dataA').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Codice field -->
                <mat-form-field>
                    <input matInput formControlName="codice" placeholder="Codice certificato medico (opzionale)" maxlength="20"/>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
            <button mat-raised-button (click)="malattiaSave()" [disabled]="form.invalid || !form.dirty"
                *ngIf="malattia?.deleteDate==null">Save</button>
            <button mat-raised-button (click)="malattiaDelete()" [disabled]="malattia?.id==null"
                *ngIf="malattia?.deleteDate==null">Delete</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
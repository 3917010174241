import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ComunicazioneService } from '../services/comunicazioni/comunicazione.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ComunicazioneDTO } from '../shared/dto/comunicazioni/comunicazione';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-comunicazione',
  templateUrl: './comunicazione.component.html',
  styleUrls: ['./comunicazione.component.scss']
})

export class ComunicazioneComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];

  constructor(
    private comunicazioneService: ComunicazioneService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'titolo', 'collaboratore', 'descrizione', 'url', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      collaboratore: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    };
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.parameters.collaboratore = res;
      }
    );

  }

  ngOnInit() {
    super.ngOnInit();
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }

  list() {
    this.comunicazioneService.comunicazioneList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.filter,
      this.parameters.collaboratore).subscribe(
        (res: ResponseQueryByCriteria<ComunicazioneDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

<div fxLayout="column">
    <mat-card fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="space-evenly center" fxLayoutGap="10px">
        <mat-card-header class="noRightMargin" fxFlex="50%" fxFlex.lt-md="100%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div fxFlex="0 1 25%" fxHide fxShow.gt-sm fxLayoutAlign="center center">
                <button mat-raised-button mwlCalendarToday [(viewDate)]="viewDate"
                    (viewDateChange)="viewDateChange.emit(viewDate)">
                    Oggi
                </button>
            </div>
            <div fxFlex="0 1 25%" fxLayoutAlign="center center">
                <button mat-icon-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="viewDateChange.emit(viewDate)">
                    <mat-icon>navigate_before</mat-icon>
                </button>
            </div>
            <div fxFlex="1 0 auto" fxLayoutAlign="center center">
                <span style="font-size: 1.5rem;">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn | titlecase}}</span>
            </div>
            <div fxFlex="0 1 25%" fxLayoutAlign="center center">
                <button mat-icon-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="viewDateChange.emit(viewDate)">
                    <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <mat-card-content *ngIf="calendarioPresenze" fxHide fxShow.gt-sm fxFlex="shrink nogrow" fxLayoutAlign="end center" fxLayoutGap="10px">
            <div>
                <button mat-raised-button (click)="viewChange.emit(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month">
                    Mese
                </button>
            </div>
            <div>
                <button mat-raised-button (click)="viewChange.emit(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week">
                    Settimanale
                </button>
            </div>
            <div>
                <button mat-raised-button (click)="viewChange.emit(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day">
                    Giorno
                </button>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="calendarioPresenze" fxHide fxShow.lt-md fxFlex="shrink nogrow">
        <mat-card-header class="noRightMargin" fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
            <div fxFlex="auto" fxLayoutAlign="center center">
                <button mat-raised-button (click)="viewChange.emit(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month">
                    Mese
                </button>
            </div>
            <div fxFlex="auto" fxLayoutAlign="center center">
                <button mat-raised-button (click)="viewChange.emit(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week">
                    Settimanale
                </button>
            </div>
            <div fxFlex="auto" fxLayoutAlign="center center">
                <button mat-raised-button (click)="viewChange.emit(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day">
                    Giorno
                </button>
            </div>
        </mat-card-header>
    </mat-card>
</div>
<br />
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RimborsoService } from './../services/rimborso/rimborso.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { Component, OnInit } from '@angular/core';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { NavigatorService } from '../services/navigator.service';
import { RimborsoDTO } from '../shared/dto/rimborso';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-rimborso-pie-di-lista-detail',
  templateUrl: './rimborso-pie-di-lista-detail.component.html',
  styleUrls: ['./rimborso-pie-di-lista-detail.component.scss']
})
export class RimborsoPieDiListaDetailComponent extends GenericDetailComponent implements OnInit {
  
  collaboratori: CollaboratoreDTO[];
  rimborso: RimborsoDTO;
  rimborsoId: number;
  today = new Date();

  constructor(
    private collaboratoreService: CollaboratoreService,
    navigatorService: NavigatorService,
    activeRoute: ActivatedRoute,
    private rimborsoService: RimborsoService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    timestampFormatPipe: TimestampFormatPipe) {
      super(navigatorService,
        dialog,
        router,
        dateAdapter,
        activeRoute,
        snackBar,
        timestampFormatPipe);
      this.form = new FormGroup({
        id: new FormControl(''),
        collaboratore: new FormControl('', Validators.required),
        dataEmissione: new FormControl('', Validators.required),
        importo: new FormControl('', [Validators.required, Validators.min(0.01), Validators.max(10000)]),
        descrizione: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        fatto: new FormControl(''),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
      });
    }

  private dtoToForm(): void {
    this.form.get('id').setValue(this.rimborso.id);
    this.form.get('collaboratore').setValue(this.rimborso.collaboratore);
    this.form.get('dataEmissione').setValue(this.rimborso.dataEmissione);
    this.form.get('importo').setValue(this.rimborso.importo);
    this.form.get('descrizione').setValue(this.rimborso.descrizione);
    this.form.get('fatto').setValue(this.rimborso.fatto);
    this.form.get('insertDate').setValue(this.timestampFormatPipe.transform(this.rimborso.insertDate));
    this.form.get('insertUser').setValue(this.rimborso.insertUser);
    this.form.get('updateDate').setValue(this.timestampFormatPipe.transform(this.rimborso.updateDate));
    this.form.get('updateUser').setValue(this.rimborso.updateUser);
  }

  private formToDto(): void {
    this.rimborso.collaboratore = this.form.get('collaboratore').value;
    this.rimborso.dataEmissione =  this.form.get('dataEmissione').value;
    this.rimborso.importo = this.form.get('importo').value;
    this.rimborso.descrizione = this.form.get('descrizione').value;
  }

  rimborsoRead(): void{
    this.rimborsoService.read(this.rimborsoId).subscribe(
      (res: RimborsoDTO) => {
        console.log("response : " + res);
        this.rimborso = res;
        this.rimborsoId = res.id;
        this.dtoToForm();
      }
    )
  }

  rimborsoSave() {
    if(this.rimborso.id == null) {
      this.rimborsoCreate();
    }
    else {
      this.rimborsoUpdate();
    }
  }

  rimborsoCreate() {
    if(this.form.valid) {
      this.formToDto();
      this.rimborsoService.create(this.rimborso).subscribe(
        (res: RimborsoDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.rimborso = res;
          this.rimborsoId = this.rimborso.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  rimborsoUpdate() {
    if(this.form.valid && this.form.dirty) {
      this.formToDto();
      this.rimborsoService.update(this.rimborso).subscribe(
        (res) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.rimborsoRead();
          this.form.markAsPristine();
        }, (err: any) => {
          this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      )
    }
  }

  rimborsoDelete() {
    if(this.form.valid) {
      this.confirm('Sei sicuro di voler cancellare il rimborso?').subscribe(result => {
        if(result) {
          this.formToDto();
          this.rimborsoService.delete(this.rimborso.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/rimborso-pie-di-lista"]);
            }
          )
        }
      });
    }
  }

  ngOnInit() {
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        console.log("Utente loggato attualmente: " + res);
        this.form.get('collaboratore').setValue(res);
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res:ResponseQueryByCriteria<CollaboratoreDTO>) => {
        console.log(res.content);
        this.collaboratori = res.content;
      }
    );
    this.rimborsoId = Number(this.activeRoute.snapshot.paramMap.get('id'));
    if(this.rimborsoId != null && this.rimborsoId != 0) {
      this.rimborsoRead();
    }
    else {
      this.rimborso = new RimborsoDTO;
    }
  }
}
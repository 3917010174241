import { ResponseFail } from './../shared/dto/responseFail';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ClienteService } from '../services/cliente/cliente.service';
import { ClienteDTO } from '../shared/dto/cliente/cliente';

@Component({
  selector: 'app-cliente-detail',
  templateUrl: './cliente-detail.component.html',
  styleUrls: ['./cliente-detail.component.scss']
})
export class ClienteDetailComponent extends GenericDetailComponent implements OnInit {

  clienteId: number;
  cliente: ClienteDTO;

  constructor(
    private clienteService: ClienteService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.clienteId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.clienteId != null && this.clienteId != 0) {
      this.clienteRead();
    } else {
      this.cliente = new ClienteDTO();
    }
  }

  clienteSave() {
    if (this.cliente.id == null) {
      this.clienteCreate();
    } else {
      this.clienteUpdate();
    }
  }

  clienteCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.clienteService.create(this.cliente).subscribe(
        (res: ClienteDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.cliente = res;
          this.clienteId = this.cliente.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidClienteException_CLIENTE_ALREADY_EXISTS") {
            this.snackBar.open("Attenzione! Attivita già eistente.", null, { duration: 4000 });
          }
        }
      );
    }
  }

  clienteUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il cliente?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.clienteService.update(this.cliente).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.clienteRead();
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidClienteException_CLIENTE_ALREADY_EXISTS") {
                this.snackBar.open("Attenzione! Attivita già eistente.", null, { duration: 4000 });
              }
            }
          );
        }
      });
    }
  }

  clienteDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il cliente?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.clienteService.delete(this.cliente.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/cliente"]);
            }
          );
        }
      });
    }
  }

  clienteRead() {
    this.clienteService.read(this.clienteId).subscribe(
      (res: ClienteDTO) => {
        console.log("response : " + res);
        this.cliente = res;
        this.clienteId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.cliente.descrizione = this.form.get("descrizione").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.cliente.id);
    this.form.get("descrizione").setValue(this.cliente.descrizione);
    this.form.get("deleteDate").setValue(this.cliente.deleteDate);
    this.form.get("deleteUser").setValue(this.cliente.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.cliente.insertDate));
    this.form.get("insertUser").setValue(this.cliente.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.cliente.updateDate));
    this.form.get("updateUser").setValue(this.cliente.updateUser);
    this.form.get("optLock").setValue(this.cliente.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }

}

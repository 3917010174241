<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli turno Reperibilità</h2>

        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form" >

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Turno Id" />
                </mat-form-field>

                <!-- Collaborattore field -->
                <mat-form-field>
                    <mat-select 
                        name="collaboratore"
                        [compareWith]="compareDTO" 
                        formControlName="collaboratore"
                        placeholder="Scegli il collaboratore"
                        [disabled]="!isJappTenantAdmin()">
                        <mat-option [value]="null" readonly="true"></mat-option>
                        <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                            {{collaboratore.nome}} {{collaboratore.cognome}} 
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Tipo Servizio field -->
                <mat-form-field>
                    <mat-select name="servizio" [compareWith]="compareDTO" formControlName="tipoServizio"
                        placeholder="Scegli il tipo di servizio" 
                        (selectionChange)="onServizioChange()"
                        [disabled]="!isJappTenantAdmin()">
                        <mat-option [value]="null" readonly="true"></mat-option>
                        <mat-option *ngFor="let servizio of servizi" readonly="true" [value]="servizio">
                            {{servizio.descrizione}} - {{servizio.numeroReperibilita.mainNumber}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('tipoServizio').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- DataDa field -->
                <mat-form-field *ngIf="form.get('tipoServizio').value"
                    fxFlex="25"
                    fxFlex.md="100"
                    fxFlex.sm="100"
                    fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" 
                    [ngStyle.md]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" 
                    [ngStyle.xs]="{'padding-right.px': 0}"
                >
                    <input matInput (click)="picker.open()" [matDatepicker]="picker"
                        formControlName="dataDa" placeholder="Scegli data da"
                        [disabled]="!isJappTenantAdmin()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="form.get('dataDa').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="form.get('dataDa').setValue(null)"
                        [disabled]="form.get('dataDa').disabled">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('dataDa').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- ora da field -->
                <mat-form-field *ngIf="form.get('tipoServizio').value"
                    fxFlex="25"
                    fxFlex.md="100"
                    fxFlex.sm="100"
                    fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" 
                    [ngStyle.md]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" 
                    [ngStyle.xs]="{'padding-right.px': 0}"
                >
                    <input matInput [min]="timePickerMin" [max]="timePickerMax"
                        [ngxTimepicker]="toggleTimepickerA" [format]="24" formControlName="orarioInizioTurno"
                        placeholder="Inizio Turno"
                        [disabled]="!isJappTenantAdmin()">
                    <ngx-material-timepicker #toggleTimepickerA [preventOverlayClick]="false" [minutesGap]="timePickerGap"
                        hoursOnly="false">
                    </ngx-material-timepicker>
                </mat-form-field>

                <!-- DataA field -->
                <mat-form-field *ngIf="form.get('tipoServizio').value"
                    fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" 
                    [ngStyle.md]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" 
                    [ngStyle.xs]="{'padding-right.px': 0}"
                   
                >
                    <input matInput (click)="picker1.open()" 
                        [matDatepicker]="picker1"
                        formControlName="dataA" 
                        placeholder="Scegli data a" 
                        [min]="form.get('dataDa').value"
                        [max]="(isTurnoCorrente) ? dataAorig : MAXDATE"
                        (dateInput)="onChangeDateAndTime('')"
                        [disabled]="!isJappTenantAdmin()">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <button mat-button *ngIf="form.get('dataA').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="form.get('dataA').setValue(null)"
                        [disabled]="form.get('dataA').disabled">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('dataA').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Ora Fine  field -->
                <mat-form-field *ngIf="form.get('tipoServizio').value && form.get('dataA').value"
                    fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right.px': 0}"
                    
                >
                    <input matInput [min]="minOrarioFineTurnoValidator()" [ngxTimepicker]="toggleTimepicker" [format]="24"
                        formControlName="orarioFineTurno" placeholder="Fine Turno"
                        [max]="timePickerMax"
                        [disabled]="!isJappTenantAdmin()">
                    <ngx-material-timepicker #toggleTimepicker (timeChanged)="onChangeDateAndTime($event)" [preventOverlayClick]="false" [minutesGap]="timePickerGap"
                        hoursOnly="false">
                    </ngx-material-timepicker>
                </mat-form-field>

                <!-- Collaboratore sostituto -->
                <mat-form-field *ngIf="isTurnoCorrente && isJappTenantAdmin()">
                    <mat-select name="collaboratoreSostituto" [compareWith]="compareDTO" 
                        formControlName="collaboratoreSostituto"
                        placeholder="Scegli il collaboratore sostituto"
                        [disabled]="!isJappTenantAdmin()">
                        <mat-option [value]="null" readonly="true"></mat-option>
                        <mat-option *ngFor="let collaboratore of collaboratoriSostituti" readonly="true" [value]="collaboratore">
                            {{collaboratore.nome}} {{collaboratore.cognome}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratoreSostituto').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>


                <!-- DataDa Collaboratore  sostituto -->

                <mat-form-field *ngIf="isTurnoCorrente && isJappTenantAdmin()"
                fxFlex="25"
                fxFlex.md="100"
                fxFlex.sm="100"
                fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" 
                [ngStyle.md]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" 
                [ngStyle.xs]="{'padding-right.px': 0}"
                >
                    <input matInput (click)="picker2.open()" [matDatepicker]="picker2"
                        formControlName="dataDaSostituto" 
                        placeholder="Scegli data da sostituto"
                        [disabled]="!isJappTenantAdmin()">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <button mat-button *ngIf="form.get('dataDaSostituto').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="form.get('dataDaSostituto').setValue(null)"
                        [disabled]="form.get('dataDaSostituto').disabled">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('dataDaSostituto').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>



                <!--  ora da Collaboratore sostituto field -->
                <mat-form-field *ngIf="isTurnoCorrente && isJappTenantAdmin()"
                    fxFlex="25"
                    fxFlex.md="100"
                    fxFlex.sm="100"
                    fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" 
                    [ngStyle.md]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" 
                    [ngStyle.xs]="{'padding-right.px': 0}"
                >
                    <input matInput [min]="timePickerMin" [max]="timePickerMax"
                        [ngxTimepicker]="toggleTimepickerB" [format]="24" formControlName="orarioInizioTurnoSostituto"
                        placeholder="Inizio Turno sostituto"
                        [disabled]="!isJappTenantAdmin()">
                    <ngx-material-timepicker #toggleTimepickerB [preventOverlayClick]="false" [minutesGap]="timePickerGap"
                        hoursOnly="false">
                    </ngx-material-timepicker>
                </mat-form-field>

                 <!-- DataA Collaboratore sostituto field -->
                <mat-form-field *ngIf="isTurnoCorrente && isJappTenantAdmin()"
                 fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                 [ngStyle]="{'padding-right': 2 + '%'}" 
                 [ngStyle.md]="{'padding-right.px': 0}"
                 [ngStyle.sm]="{'padding-right.px': 0}" 
                 [ngStyle.xs]="{'padding-right.px': 0}"
             >
                 <input matInput (click)="picker2.open()" 
                     [matDatepicker]="picker2"
                     formControlName="dataASostituto" 
                     placeholder="Scegli data a sostituto" 
                     [min]="form.get('dataDa').value"
                     [max]="(isTurnoCorrente) ? dataAorig : MAXDATE"
                     [disabled]="!isJappTenantAdmin()"
                    >
                 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                 <mat-datepicker #picker2></mat-datepicker>
                 <button mat-button *ngIf="form.get('dataASostituto').value" matSuffix mat-icon-button aria-label="Clear"
                     (click)="form.get('dataASostituto').setValue(null)"
                     [disabled]="form.get('dataASostituto').disabled">
                     <mat-icon>close</mat-icon>
                 </button>
                 <mat-error *ngIf="form.get('dataASostituto').errors?.required">
                     Campo obbligatorio
                 </mat-error>
                </mat-form-field>



                <!-- Ora Fine Reperibilità sostituto field -->
                <mat-form-field *ngIf="isTurnoCorrente && isJappTenantAdmin()"
                    fxFlex="25" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right.px': 0}"
                >
                    <input matInput [min]="timePickerMin" [ngxTimepicker]="toggleTimepickerD" [format]="24"
                        formControlName="orarioFineTurnoSostituto" placeholder="Fine Turno sostituto"
                        [max]="timePickerMax"
                        [disabled]="!isJappTenantAdmin()">
                    <ngx-material-timepicker #toggleTimepickerD [preventOverlayClick]="false" [minutesGap]="timePickerGap"
                        hoursOnly="false">
                    </ngx-material-timepicker>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

            </form>


            <mat-card-actions>
                <button mat-raised-button [routerLink]="['/gestione-turni-servizi-rep/']" color="primary">Back</button>
                <button mat-raised-button (click)="turnoSave()" [disabled]=" (!form.dirty || form.invalid)  "
                    *ngIf="turno?.deleteDate==null"> {{ (turnoId == 0 )? 'Save' : 'Update' }}</button>
                <button mat-raised-button (click)="delete()" [disabled]=" this.turno?.id==null"
                 *ngIf="isLast  && !isTurnoCorrente">Cancella</button>
            </mat-card-actions>
        </div>

    </mat-card-content>
</mat-card>
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { ComandaDTO } from './comanda';

export class OrdineDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    comanda: ComandaDTO;
    descrizioneOrdine: string;
    importoDovuto: number;     
    importoPagato: number;
    showDetail: boolean;
}
import { SedeDTO } from './../../shared/dto/sede/sede';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SalettaDTO } from './../../shared/dto/saletta/saletta';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalettaService extends GenericService<SalettaDTO, number>  {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/saletta';
   }


  public salettaList (
    pageNumber: number,
    pageSize: number,
    direction: string,
    sortField: string, 
    filter: string,
    sede?: SedeDTO): Observable<ResponseQueryByCriteria<SalettaDTO>> {
    let params = {}
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    if (sede != null) params["sedeId"] = sede.id;
    return this.http.get<ResponseQueryByCriteria<SalettaDTO>>(`${this.url}/salettaList`, {
      params: params
    });

  }
}

import { UfficioDTO } from './../../shared/dto/ufficio/ufficio';
import { HttpClient } from '@angular/common/http';
import { PostazioneDTO } from './../../shared/dto/postazione/postazione';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PostazioneService extends GenericService<PostazioneDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/postazione';
  }

  public postazioniListByAutocomplete(descrizione: string): Observable<PostazioneDTO[]> {
    let params = {};
    if (descrizione != null) params["descrizione"] = descrizione;
    return this.http.get<PostazioneDTO[]>(`${this.url}/postazioneListByAutocomplete`, { params: params });

  }

  public postazioneList(pageNumber: number, pageSize: number, direction: string, sortField: string, descrizione?: string, filterUfficio?: UfficioDTO): Observable<ResponseQueryByCriteria<PostazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (descrizione != null) params["descrizione"] = descrizione;
    if (filterUfficio != null) params["filterUfficio"] = filterUfficio.id;

    return this.http.get<ResponseQueryByCriteria<PostazioneDTO>>(`${this.url}/postazioneListByList`, {
      params: params
    });
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { PresenzaDTO } from './../../shared/dto/presenze/presenza';

@Injectable({
  providedIn: 'root'
})
export class PresenzaService extends GenericService<PresenzaDTO, number>{
  httpSimple: any;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/presenza';
  }

  public presenzaList(pageNumber: number, pageSize: number, direction: string, sortField: string): Observable<ResponseQueryByCriteria<PresenzaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    return this.http.get<ResponseQueryByCriteria<PresenzaDTO>>(`${this.url}/presenzaListByCollaboratore`, {
      params: params
    });
  }
  public download(collaboratore: CollaboratoreDTO, location: number[], dateFrom: Date, dateTo: Date): Observable<Blob>{
    var url = `${this.url}/presenzaListToCsv`;
    return this.getMultipart(url, collaboratore, location, dateFrom, dateTo);
  }
  getMultipart(url: string, collaboratore: CollaboratoreDTO, location: number[], dateFrom: Date, dateTo: Date)  {
    let params = {};
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (location != null) params["locationId"] = location;
    if (dateFrom != null) params["dateFrom"] = dateFrom.toISOString();
    if (dateTo != null) params["dateTo"] = dateTo.toISOString();
    let requestOptions: Object = {
      observe: 'response',
      responseType: 'blob', 
      params: params
    };
    return this.http.get(url, requestOptions).pipe(map((res: HttpResponse<Blob>) => {
      (res.body as any).filename = res.headers.get('content-disposition')?.split('"')[1];
      return res.body;

    }, err => {
      console.log(err);
    }))
  }

  public presenzaListByCollaboratoreAndDates(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO, location: number[], dateFrom: Date, dateTo: Date, secondSortField?: string): Observable<ResponseQueryByCriteria<PresenzaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (secondSortField != null) params["secondSortField"] = secondSortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (location != null) params["locationId"] = location;
    if (dateFrom != null) params["dateFrom"] = dateFrom.toISOString();
    if (dateTo != null) params["dateTo"] = dateTo.toISOString();
    return this.http.get<ResponseQueryByCriteria<PresenzaDTO>>(`${this.url}/presenzaListByCollaboratoreAndDates`, {
      params: params
    });
  }

  public searchByGiorno(pageNumber: number, pageSize: number, direction: string, sortField: string, giorno: Date): Observable<ResponseQueryByCriteria<PresenzaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (giorno != null) params["giorno"] = giorno.toISOString();
    return this.http.get<ResponseQueryByCriteria<PresenzaDTO>>(`${this.url}/presenzaListByGiorno`, {
      params: params
    });
  }

  public presenzaByCollaboratoreAndGiorno(idCollaboratore: number, giorno: Date): Observable<PresenzaDTO[]> {
    let params = {
      giorno: giorno.toISOString(),
      idCollaboratore: idCollaboratore.toString()
    };
    return this.http.get<PresenzaDTO[]>(`${this.url}/presenzaByCollaboratoreAndGiorno/`, {
      params: params
    });
  }

  public presenzaByMese(collaboratore: CollaboratoreDTO, dateFrom:Date, dateTo: Date): Observable<PresenzaDTO[]> {
    let params = {}
      if (collaboratore != null) params ["idCollaboratore"] = collaboratore.id;
      if (dateFrom != null) params ["dateFrom"] = new Date(dateFrom.getTime() - (dateFrom.getTimezoneOffset() * 60000)).toISOString();
      if (dateTo != null) params ["dateTo"] = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString();
    return this.http.get<PresenzaDTO[]>(`${this.url}/presenzaSearchByMese`, {
      params: params
    });
  }

  public totNumeroDiOre(collaboratore: CollaboratoreDTO, location: number[], dateFrom: Date, dateTo: Date): Observable<number> {
    let params = {};
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (location != null) params["locationId"] = location;
    if (dateFrom != null) params["dateFrom"] = dateFrom.toISOString();
    if (dateTo != null) params["dateTo"] = dateTo.toISOString();
    return this.http.get<number>(`${this.url}/totaleNumeroDiOre`, {
      params: params
    });
  }
}
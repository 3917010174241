<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione caffè</h2>
    <p style="font-size: 18px;">Credito residuo: {{ creditodisp }} credito/i</p>
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" >
      <mat-card-actions fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <button mat-raised-button fxFlex id="caricaCaffe-button" [routerLink]="['/gestione-credito/ricarica/', credito.id]">Ricarica</button>
        <button mat-raised-button fxFlex id="spesaCaffe-button" [routerLink]="['/gestione-credito/acquisto/', credito.id]">Acquista</button>
        <button mat-raised-button fxFlex id="transazioni-button" [routerLink]="['/gestione-credito/movimenti/', credito.id]">Transazioni</button>
      </mat-card-actions>
    </div>
    
    <div *ngIf="parameters.showList" fxLayout="column" class="responsive-mat-form">
      <p style="font-size: 18px;">Catalogo</p>
      <table mat-table fxFlex matSort (matSortChange)="setSort($event)" matSortDisableClear
        [dataSource]="parameters.dataSource">
    
        <ng-container matColumnDef="descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Prodotto </th>
          <td mat-cell *matCellDef="let catalogo"> {{catalogo.descrizione}} </td>
        </ng-container>
    
        <ng-container matColumnDef="totaleCrediti">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo </th>
          <td mat-cell *matCellDef="let catalogo"> 
            {{catalogo.totaleCrediti}} 
            &nbsp;&nbsp;€{{(catalogo.totaleCrediti*0.3)|number:'1.2-2'}}
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
        [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { ChiusuraDTO } from './../shared/dto/assenza/chiusura';
import { NavigatorService } from '../services/navigator.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ChiusuraService } from '../services/assenza/chiusura.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-chiusura',
  templateUrl: './chiusura.component.html',
  styleUrls: ['./chiusura.component.scss']
})

export class ChiusuraComponent extends GenericListComponent implements OnInit, OnDestroy {

  chiusura: ChiusuraDTO;

  constructor(
    private chiusuraService: ChiusuraService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe
    );
    this.displayedColumns = ['id', 'dataDa', 'dataA', 'descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      descrizione: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.chiusuraService.chiusuraListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.descrizione).subscribe(
        (res: ResponseQueryByCriteria<ChiusuraDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnInit() {
    super.ngOnInit()

    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }
}
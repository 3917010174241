import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { TenantDTO } from '../shared/dto/domain/tenant';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { AuthConfig, OAuthService, JwksValidationHandler, OAuthEvent, OAuthInfoEvent, LoginOptions } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ServerDTO } from '../shared/dto/config/server';
import { AppDTO } from '../shared/dto/config/app';
import { ChangePasswordDTO } from '../shared/dto/config/changePassword';
import { MediaObserver } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  private readonly serverInfoURL = environment.urlPrefix+'/services/rest/json/configuration/server';

  private readonly tenantInfoURL = environment.urlPrefix+'/services/rest/json/configuration/tenant';

  private readonly changePasswordURL = environment.urlPrefix+'/services/rest/json/configuration/changePassword';

  private readonly collaboratoreInfoURL = environment.urlPrefix+'/services/rest/json/collaboratore';

  private readonly versionInfoURL = environment.sitePrefix+'/META-INF/maven/it.jenia.japp/japp.client.webapp/pom.properties';

  private readonly homeURL = environment.sitePrefix+'/';

  loggedIn: boolean = false;

  accessToken: any;

  private appSource = new ReplaySubject<AppDTO>();

  private serverSource = new ReplaySubject<ServerDTO>();

  private tenantSource = new ReplaySubject<TenantDTO>();

  private collaboratoreSource = new ReplaySubject<CollaboratoreDTO>();

  private screenWidthSource = new ReplaySubject<number>();

  private httpErrorSource = new ReplaySubject<HttpErrorResponse>();

  app : Observable<AppDTO> = this.appSource.asObservable();

  server : Observable<ServerDTO> = this.serverSource.asObservable();

  tenant : Observable<TenantDTO> = this.tenantSource.asObservable();

  collaboratore : Observable<CollaboratoreDTO> = this.collaboratoreSource.asObservable();

  screenWidth : Observable<number> = this.screenWidthSource.asObservable();

  httpError : Observable<HttpErrorResponse> = this.httpErrorSource.asObservable();

  constructor(private oauthService: OAuthService, private http: HttpClient, public mediaObserver: MediaObserver) {
  }

  setScreenWidth(screenWidth:number) {
    this.screenWidthSource.next(screenWidth);
  }

  public tenantInfo() : void{
    this.http.get(`${this.versionInfoURL}`, { responseType: 'text' }).subscribe(
      data => {
        console.log(data);
        const lines = data.split("\n");
        let properties : AppDTO = new AppDTO();
        for (const l of lines) {
          const line = l.trim();
          if (!line || line[0] === '#') {
            continue;
          }
          const keyValue = line.split("=");
          const key = keyValue[0].trim();
          const value = keyValue[1].trim();
          properties[key] = value
        }
        this.appSource.next(properties);
        console.log(properties);
      }, error => {
        console.log(error);
      }
    );
    this.http.get<ServerDTO>(`${this.serverInfoURL}`).subscribe(
      (res: ServerDTO) => {
        console.log("serverInfo response : " + res);
        this.serverSource.next(res);
      }
    );
    this.http.get<TenantDTO>(`${this.tenantInfoURL}`).subscribe(
      (res: TenantDTO) => {
        console.log("tenantInfo response : " + res);
        this.tenantSource.next(res);
        this.login(res);
      }
    );
  }

  public changePassword(oldPassword : string, newPassword : string) : Observable<void> {
    let dto : ChangePasswordDTO = new ChangePasswordDTO();
    dto.oldPassword = oldPassword;
    dto.newPassword = newPassword;
    return this.http.put<void>(`${this.changePasswordURL}`, dto);
  }

  public login(tenant: TenantDTO) : void {
    console.log("login");
    if (environment.production) {
      console.log("production = true => login using openid" );
      let authConfig: AuthConfig = {
        issuer: tenant.issuer,
        redirectUri: window.location.href,
        clientId: tenant.clientId,
        scope: 'openid profile email',
        requireHttps: (tenant.issuer.startsWith("https")?true:false),
        postLogoutRedirectUri: this.homeURL,
        strictDiscoveryDocumentValidation: false
      }
      this.oauthService.configure(authConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.events.pipe(filter((e: any) => e.type === 'discovery_document_loaded'))
        .subscribe((event : OAuthEvent) => {
          let infoEvent : OAuthInfoEvent = <OAuthInfoEvent> event;
          if (infoEvent.info!=null && infoEvent.info.jwks!=null) {
            let jwtHelper: JwtHelperService = new JwtHelperService();
            this.accessToken = jwtHelper.decodeToken(this.oauthService.getAccessToken());
            if (this.accessToken!=null) {
              console.log("token_received " + JSON.stringify(this.accessToken));
              this.postLogin();
            }
          }
        });
      this.oauthService.events.pipe(filter((e: any) => e.type === 'logout'))
        .subscribe((event : OAuthEvent) => {
          console.log("logout" );
          this.postLogout();
        });
      this.oauthService.loadDiscoveryDocumentAndLogin({
        onTokenReceived: (tokenParams)=>{
          let jwtHelper: JwtHelperService = new JwtHelperService();
          this.accessToken = jwtHelper.decodeToken(tokenParams.accessToken);
          if (this.accessToken!=null) {
            console.log("token_received " + JSON.stringify(this.accessToken));
            this.postLogin();
          }
        }
      } as LoginOptions);
    } else {
      console.log("production = false => login using fake authentication" );
      this.postLogin();
      this.accessToken = {
        roles : environment.fakeRoles
        // roles : ['ROLE_USER', 'ROLE_ANONYMOUS']
      };
    }
  }

  public postLogin() : void {
    this.http.get<CollaboratoreDTO>(`${this.collaboratoreInfoURL}`).subscribe(
      (res: CollaboratoreDTO) => {
        console.log("postLogin response : " + res);
        this.collaboratoreSource.next(res);
        this.loggedIn = true;
      }
    );
  }

  public logout() : void {
    console.log("logout");
    if (environment.production) {
      console.log("production = true => logout using openid" );
      this.oauthService.logOut();
    } else {
      console.log("production = false => logout using fake authentication" );
      this.postLogout();
    }
  }

  public postLogout() : void {
    this.collaboratoreSource.next(null);
    this.loggedIn = false;
    this.accessToken = null;
  }

  public isLoggedIn() {
    return this.loggedIn;
  }

  public hasRole(role: string) {
    if (this.accessToken==null) return false;
    if (this.accessToken.roles==null) return false;
    return this.accessToken.roles.includes(role) || 
            this.accessToken.roles.includes('ROLE_ADMINISTRATORS_TENANT_JAPP') ||
            this.accessToken.roles.includes('ROLE_ADMINISTRATORS_JAPP');
  }

  public isJappAdmin() {
    if (this.accessToken==null) return false;
    if (this.accessToken.roles==null) return false;
    return this.accessToken.roles.includes('ROLE_ADMINISTRATORS_JAPP');
  }

  public isJappTenantAdmin() {
    if (this.accessToken==null) return false;
    if (this.accessToken.roles==null) return false;
    return this.accessToken.roles.includes('ROLE_ADMINISTRATORS_TENANT_JAPP') ||
            this.accessToken.roles.includes('ROLE_ADMINISTRATORS_JAPP');
  }

  public setHttpError(httpError: HttpErrorResponse) : void {
    this.httpErrorSource.next(httpError);
  }

  public isActive(value: string) : boolean {
    return this.mediaObserver.isActive(value);
  }

}

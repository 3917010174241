import { TimestampFormatPipe } from './../../commons/timestampFormatPipe';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from '../../services/navigator.service';
import { ComponentCacheService } from '../../services/component-cache.service';
import { MovimentoService } from '../../services/credito/movimento.service';
import { MovimentoDTO } from '../../shared/dto/credito/movimento';
import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';


@Component({
  selector: 'app-movimento',
  templateUrl: './movimento.component.html',
  styleUrls: ['./movimento.component.scss']
})
export class MovimentoComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  collaboratore: CollaboratoreDTO;
  movimenti: MovimentoDTO[];
  creditoId: number;
  tipologie = ["Addebito", "Accredito"];

  constructor(
    private movimentoService: MovimentoService,
    private route: ActivatedRoute,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'data', 'totaleCrediti', 'catalogo', 'qtaProdotti'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      dataDa: null,
      dataA: null,
      tipo: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.creditoId = Number(this.route.snapshot.paramMap.get("id"));
  }


  list() {
    let data;
    if (this.parameters.dataA != null) {
      data = new Date(this.parameters.dataA.getFullYear(), this.parameters.dataA.getMonth(), this.parameters.dataA.getDate(), 24, 59, 59);
    } else {
      data = this.parameters.dataA;
    }
    this.movimentoService.movimentoListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      "DESC",
      "data",
      this.creditoId,
      this.parameters.tipo,
      this.parameters.dataDa,
      data
      ).subscribe(
        (res: ResponseQueryByCriteria<MovimentoDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  trascodificaOggetto(oggetto: String): String {
    var msg: String;
    if (oggetto == null) {
      msg = 'Ricarica';
    } else {
      msg = oggetto;
    }
    return msg;
  }

  trascodificaQTA(qta: number): number {
    console.log(qta);
    var msg: number;
    if (qta == null) {
      msg = 1;
    } else {
      msg = qta;
    }
    return msg;
  }

  ngOnInit() {
    super.ngOnInit();
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServizioDTO } from 'src/app/shared/dto/preferiti/servizio';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ServizioService extends GenericService<ServizioDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/servizio';
  }

  public serviziList() {
    return this.http.get<ServizioDTO[]>(`${this.url}/list`);
  }
}
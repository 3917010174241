import { OrdineService } from './../services/gestione-ordini/ordine.service';
import { OrdineDTO } from 'src/app/shared/dto/gestioneOrdini/ordine';
import { ComandaDTO } from './../shared/dto/gestioneOrdini/comanda';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { ResponseFail } from '../shared/dto/responseFail';
import { ComandaService } from '../services/gestione-ordini/comanda.service';

@Component({
    selector: 'app-gestione-pagamento',
    templateUrl: './gestione-pagamento.component.html',
    styleUrls: ['./gestione-pagamento.component.scss']
})
export class GestionePagamentoComponent extends GenericDetailComponent implements OnInit, OnDestroy {

    ordineId: number;
    comande: ComandaDTO[];
    collaboratori: CollaboratoreDTO[];
    ordine: OrdineDTO;
    today: Date;
    minDate: Date;
    previousUrlService: Observable<string> = this.urlService.previousUrl$;
    previousUrl: string;
   
    constructor( 
        private urlService: UrlService,
        navigatorService: NavigatorService,
        activatedRoute: ActivatedRoute,
        private ordineService: OrdineService,
        snackBar: MatSnackBar,
        dialog: MatDialog,
        private collaboratoreService: CollaboratoreService,
        router: Router,
        timestampFormatPipe: TimestampFormatPipe,
        dateAdapter: DateAdapter<Date>) {
        super(
            navigatorService,
            dialog,
            router,
            dateAdapter,
            activatedRoute,
            snackBar,
            timestampFormatPipe
        );
        this.form = new FormGroup({
            id: new FormControl({ value: '', disabled: true }),
            importoDovuto: new FormControl({ value: '', disabled: true }),
            importoPagato: new FormControl(null, [Validators.required, Validators.min(0)]),
            deleteDate: new FormControl({ value: '', disabled: true }),
            deleteUser: new FormControl({ value: '', disabled: true }),
            insertDate: new FormControl({ value: '', disabled: true }),
            insertUser: new FormControl({ value: '', disabled: true }),
            updateDate: new FormControl({ value: '', disabled: true }),
            updateUser: new FormControl({ value: '', disabled: true }),
            optLock: new FormControl({ value: '', disabled: true }),
        });
        this.minDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            new Date().getDate() - 1,
        );
        this.today = new Date();
    }

    ngOnInit() {
        this.ordineId = Number(this.activeRoute.snapshot.paramMap.get("id"));
        if (this.ordineId != null && this.ordineId != 0) {
            this.ordineRead();
        } else {
            this.ordineInit();
        }
        this.collaboratoreService.collaboratoreList(
            0,
            9999,
            'ASC',
            'nome',
            '').subscribe(
                (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
                    console.log("response : " + res);
                    this.collaboratori = res.content;
                }
            );

        this.urlService.previousUrl$.subscribe((previousUrl: string) => {
            this.previousUrl = previousUrl;
        });

        this.urlService.previousUrl$.subscribe((previousUrl: string) => {
            this.previousUrl = previousUrl;
        });
        this.miaFunzione(
            () => {
                console.log("hello world");
            }
        );
    }

    ordineSave() {
        if (this.ordine.id == null) {
            this.ordineCreate();
        } else {
            this.ordineUpdate();
        }
    }

    ordineCreate() {
        console.log(this.form.valid);
        if (this.form.valid) {
            this.formToDto();
            this.ordineService.create(this.ordine).subscribe(
                (res: OrdineDTO) => {
                    console.log("response : " + res);
                    this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
                    this.ordine = res;
                    this.ordineId = this.ordine.id;
                    this.dtoToForm();
                    this.form.markAsPristine();
                });
        }
    }

    ordineUpdate() {
        if (this.form.valid && this.form.dirty) {
            this.confirm("Sei sicuro di voler modificare l'ordine?").subscribe(result => {
                if (result) {
                    this.formToDto();
                    this.ordineService.update(this.ordine).subscribe(
                        (res) => {
                            console.log("response : " + res);
                            this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
                            this.ordineRead();
                            this.form.markAsPristine();
                        }, (err: HttpErrorResponse) => {
                            if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidOrdineException_IMPORTO_TROPPO_ALTO") {
                              this.snackBar.open("Attenzione! Non puoi inserire un importo pagato più alto di quello dovuto.", null, { duration: 4000 });
                            }
                        });
                }
            });
        }
    }

    ordineDelete() {
        if (this.form.valid) {
            this.confirm("Sei sicuro di voler cancellare l'ordine?").subscribe(result => {
                if (result) {
                    this.formToDto();
                    this.ordineService.delete(this.ordine.id).subscribe(
                        (res) => {
                            console.log("response : " + res);
                            this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
                            this.router.navigate(["/gestione-ordine"]);
                        }
                    );
                }
            });
        }
    }

    ordineRead() {
        this.ordineService.read(this.ordineId).subscribe(
            (res: OrdineDTO) => {
                console.log("response : " + res);
                this.ordine = res;
                this.ordineId = res.id;
                this.dtoToForm();
            }
        );
    }

    ordineInit() {
        this.ordine = new OrdineDTO();
        this.navigatorService.collaboratore.subscribe(
            (res: CollaboratoreDTO) => {
                this.form.get("collaboratore").setValue(res);
            }
        );
    }

    private formToDto(): void {
        this.ordine.importoPagato = this.form.get("importoPagato").value;
    }

    private dtoToForm(): void {
        this.form.get("id").setValue(this.ordine.id);
        this.form.get("deleteDate").setValue(this.ordine.deleteDate);
        this.form.get("importoDovuto").setValue(this.ordine.importoDovuto);
        this.form.get("importoPagato").setValue(this.ordine.importoPagato);
        this.form.get("deleteUser").setValue(this.ordine.deleteUser);
        this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.ordine.insertDate));
        this.form.get("insertUser").setValue(this.ordine.insertUser);
        this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.ordine.updateDate));
        this.form.get("updateUser").setValue(this.ordine.updateUser);
        this.form.get("optLock").setValue(this.ordine.optLock);
        this.form.markAsPristine();
    }

    ngOnDestroy(): void {
    }

    miaFunzione(callback: any) {
        console.log("robe");
        callback();
    }
}
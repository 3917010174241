<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione tenant</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field>
        <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/tenant/detail/0']">New</button>
    </mat-card-actions>
    <br />
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
        matSort (matSortChange)="setSort($event)" matSortDisableClear
        [dataSource]="parameters.dataSource">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let tenant"> {{tenant.id}} </td>
        </ng-container>

        <!-- Tenant Code Column -->
        <ng-container matColumnDef="tenantCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tenant Code </th>
          <td mat-cell *matCellDef="let tenant"> {{tenant.tenantCode}} </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let tenant"> {{tenant.description}} </td>
        </ng-container>

        <!-- hostName Column -->
        <ng-container matColumnDef="hostName">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Hostname </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let tenant"> {{tenant.hostName}} </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let tenant">
            <button mat-mini-fab color="primary" [routerLink]="['/tenant/detail/', tenant.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-actions>
            <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'tenants'})">Excel</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'tenants'})">Csv</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'tenants'})">Json</button>
          </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocationService } from '../services/presenze/location.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { GenericComponent } from '../shared/GenericComponent';

@Component({
  selector: 'app-cambioPassword',
  templateUrl: './cambioPassword.component.html',
  styleUrls: ['./cambioPassword.component.scss']
})

export class CambioPasswordComponent extends GenericComponent implements OnInit, OnDestroy {

  form: FormGroup;

  constructor(
    private locationService: LocationService,
    activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      router,
      dateAdapter,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      newPasswordVerify: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
  }

  passwordUpdate() {
    if (this.form.valid && this.form.dirty) {
      if (this.form.get("newPassword").value==this.form.get("newPasswordVerify").value) {
        this.confirm("Sei sicuro di voler cambiare la password?").subscribe(result => {
          if (result) {
            this.navigatorService.changePassword(this.form.get("oldPassword").value, this.form.get("newPassword").value).subscribe(
              (res) => {
                console.log("response : " + res);
                this.snackBar.open("Cambio password avvenuto con successo!", null, { duration: 3000 });
                this.form.markAsPristine();
              }
            );
          }
        });
      } else {
        this.snackBar.open("La nuova password non corrisponde alla password di verifica", null, { duration: 5000 });
      }
    }
  }

  confirm(message: String) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: message
    });
    return dialogRef.afterClosed();
  }


  ngOnDestroy(): void {
  }
}

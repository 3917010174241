import { Component, OnDestroy, OnInit } from "@angular/core";
import { GenericDetailComponent } from "../shared/GenericDetailComponent";
import { ServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { ServiziRepService } from "../services/gestione-servizi-reperibilita/serviziRep.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigatorService } from "../services/navigator.service";
import { DateAdapter } from "@angular/material/core";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import {  FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { NumeroService } from "../services/reperibilità/numero.service";
import { NumeroReperibilitaDTO } from "../shared/dto/reperibilità/NumeroReperibilita";
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";

@Component({
  selector: 'app-gestione-servizi-rep-detail',
  templateUrl: './gestione-servizi-detail.component.html',
  styleUrls: ['./gestione-servizi-detail.component.scss']
})
export class GestioneServiziDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  servizio: ServiziReperibilitaDTO;
  idService: number;
  numeriReperibilita: NumeroReperibilitaDTO[]
  readonly EMAIL_SEPARATOR=";"
  constructor(
    private serviziRep: ServiziRepService,
    private numeroService: NumeroService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      emailArray: new FormArray([]),
      numeroReperibilita: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.idService = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.idService != null && this.idService != 0) {
      this.serviziRep.read(this.idService).subscribe(res => {
        this.servizio = res
        this.dtoToForm();
      },
      err=>{
        this.snackBar.open(err.error.reason, null, { duration: 3000 })
        this.router.navigate(["gestione-servizi-rep"]);
       }

      )
        
    } else {
      this.servizio = new ServiziReperibilitaDTO();
      this.dtoToForm()
    }


    this.numeroService.numeriList(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<NumeroReperibilitaDTO>) => {
          this.numeriReperibilita = res.content;
        },
      );
  }
  dtoToForm() {
    this.form.get("id").setValue(this.servizio.id);
    this.form.get("descrizione").setValue(this.servizio.descrizione);
    this.form.get("numeroReperibilita").setValue(this.servizio.numeroReperibilita);
    this.form.get("deleteDate").setValue(this.servizio.deleteDate);
    this.form.get("deleteUser").setValue(this.servizio.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.servizio.insertDate));
    this.form.get("insertUser").setValue(this.servizio.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.servizio.updateDate));
    this.form.get("updateUser").setValue(this.servizio.updateUser);
    this.form.get("optLock").setValue(this.servizio.optLock);
    this.setEmailsForm()
    this.form.markAsPristine();
  }
  setEmailsForm() {
    if(this.idService!=0){
      let emailList= this.servizio.email.split(this.EMAIL_SEPARATOR)
      emailList.forEach(email=>this.addDtoEmail(email))
    }
    else this.addDtoEmail("");
  }


  private formToDto(): void {
    this.servizio.descrizione = this.form.get("descrizione").value;
    this.servizio.numeroReperibilita = this.form.get("numeroReperibilita").value;
    this.formEmailToDto(); 
  }

  private formEmailToDto() {
    let emails: String[] = [];
    this.emails.controls.forEach(control => {
      emails.push(control.get("email").value);

    });
    this.servizio.email=emails.join(this.EMAIL_SEPARATOR)
    console.log(this.servizio.email);
  }

  servizioDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il servizio della reperibilità?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.serviziRep.delete(this.idService).subscribe(
            (res) => {
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["gestione-servizi-rep"]);
            }
          );
        }
      });
    }



  }

  servizioSave() {
    if (this.servizio.id == null) {
      this.servizioCreate();
    } else {
      this.servizioUpdate();
    }
  }
  servizioCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.serviziRep.create(this.servizio).subscribe(
        (res: ServiziReperibilitaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.servizio = res;
          this.idService = this.servizio.id;
          this.dtoToForm();
          this.form.markAsPristine();
          this.router.navigate(['gestione-servizi-rep', "detail", this.idService])
        }, (err: any) => {
          this.snackBar.open("Attenzione: campi non compilati per creare il servizio reperibilità.", null, { duration: 3000 });
        }
      );
    }
  }

  servizioUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il servizio?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.serviziRep.update(this.servizio).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.serviziRep.read(this.idService).subscribe(res => {
                this.router.navigate(['gestione-servizi-rep', "detail", this.idService])

              });
              this.form.markAsPristine();
            }, (err: any) => {
              this.snackBar.open("Attenzione: campi non validi per aggiornare il servizio della reperibilità.", null, { duration: 3000 });
            }
          );
        }
      });
    }
  }

  get emails() {
    return this.form.get("emailArray") as FormArray;
  }

  addDtoEmail(emailAndress:String) {
    let email = new FormGroup({
      email: new FormControl(emailAndress, [Validators.pattern('[a-zA-Z0-9_.±]+@[a-zA-Z0-9.-]+(.[a-zA-Z]{2,})+'), Validators.required])
    });
    this.emails.push(email);
  }

  removeEmail(index: number) {
    if(this.emails.length > 1) {
      this.emails.removeAt(index);
    }
  }

  ngOnDestroy(): void {
  }
}
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { TenantDTO } from '../domain/tenant';

export class FeriePermessiDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    dataA: Date;
    residuoFerie: number;
    daFruireFerie: number;
    maturabiliFerie: number;
    residuoPermessi: number;
    daFruirePermessi: number;
    maturabiliPermessi: number;
    residuoROL: number;
    daFruireROL: number;
    maturabiliROL: number;
}
import { SedeDTO } from './../shared/dto/sede/sede';
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { SedeService } from './../services/sede/sede.service';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { SalettaService } from './../services/saletta/saletta.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { SalettaDTO } from './../shared/dto/saletta/saletta';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-gestione-saletta-detail',
  templateUrl: './gestione-saletta-detail.component.html',
  styleUrls: ['./gestione-saletta-detail.component.scss']
})
export class GestioneSalettaDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  salettaId: number;
  saletta: SalettaDTO;
  sedi: SedeDTO[];

  constructor(
    private salettaService: SalettaService,
    private sedeService: SedeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe); {
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        sede: new FormControl(null, Validators.required),
        descrizione: new FormControl('', Validators.required),
        deleteDate: new FormControl({ value: '', disabled: true }),
        deleteUser: new FormControl({ value: '', disabled: true }),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
        optLock: new FormControl({ value: '', disabled: true }),
      });
    };

  }
  ngOnDestroy(): void {
  }


  ngOnInit() {
    this.salettaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.salettaId != null && this.salettaId != 0) {
      this.salettaRead();
    } else {
      this.saletta = new SalettaDTO();
    }
    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'id',
      '').subscribe(
        (res: ResponseQueryByCriteria<SedeDTO>) => {
          console.log("response : " + res);
          this.sedi = res.content;
        })
  }


  salettaSave() {
    if (this.saletta.id == null) {
      this.salettaCreate();
    } else {
      this.salettaUpdate();
    }
  }

  salettaCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.salettaService.create(this.saletta).subscribe(
        (res: SalettaDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.saletta = res;
          this.salettaId = this.saletta.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }
      );
    }
  }


  salettaRead() {
    this.salettaService.read(this.salettaId).subscribe(
      (res: SalettaDTO) => {
        console.log('Response : ' + res.descrizione +" "+ res.insertUser);
        this.saletta = res;
        this.salettaId = res.id;
        this.dtoToForm();
      }
    )
  }

  salettaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la saletta?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.salettaService.update(this.saletta).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.salettaRead();
              this.form.markAsPristine();
            }
          );
        }
      });
    }
  }

  salettaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la saletta?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.salettaService.delete(this.saletta.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/saletta"]);
            }
          );
        }
      });
    }
  }


  private formToDto(): void {
    this.saletta.sede = this.form.get("sede").value;
    this.saletta.descrizione = this.form.get("descrizione").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.saletta.id);
    this.form.get("sede").setValue(this.saletta.sede);
    this.form.get("descrizione").setValue(this.saletta.descrizione);
    this.form.get("deleteDate").setValue(this.saletta.deleteDate);
    this.form.get("deleteUser").setValue(this.saletta.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.saletta.insertDate));
    this.form.get("insertUser").setValue(this.saletta.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.saletta.updateDate));
    this.form.get("updateUser").setValue(this.saletta.updateUser);
    this.form.get("optLock").setValue(this.saletta.optLock);
    this.form.markAsPristine();
  }


}


import { Component, OnInit, OnDestroy } from '@angular/core';
import { CryptoPriceService } from '../services/crypto-price.service';
// import { NodeJS } from '';

@Component({
  selector: 'app-crypto-widget',
  templateUrl: './crypto-widget.component.html',
  styleUrls: ['./crypto-widget.component.scss']
})

export class CryptoWidgetComponent implements OnInit, OnDestroy {

  public bitcoinValueEur: number;
  public bitcoinDayDiff: number;
  public ethereumValueEur: number;
  public ethereumDayDiff: number;
  public euroRate: number;
  public updateTimestamp: Date;
  private timer:any;

  constructor(private cryptoPriceService: CryptoPriceService) { }

  public cryptoCurrencyRateRead() {
    let ethereumValueUsd: number;
    let bitcoinValueUsd: number;
    this.cryptoPriceService.euroPriceRead().subscribe(
      (res: {}) => {
        this.euroRate = res["data"]["rateUsd"];
        this.cryptoPriceService.cryptoCurrencyRateRead("bitcoin").subscribe(
          (res: {}) => {
            bitcoinValueUsd = res["data"]["priceUsd"];
            this.bitcoinDayDiff = res["data"]["changePercent24Hr"];
            this.updateTimestamp = new Date(res["timestamp"]); 
            this.bitcoinValueEur = this.usdToEur(bitcoinValueUsd);
          }
        );
        this.cryptoPriceService.cryptoCurrencyRateRead("ethereum").subscribe(
          (res: {}) => {
            ethereumValueUsd = res["data"]["priceUsd"];
            this.ethereumDayDiff = res["data"]["changePercent24Hr"];
            this.ethereumValueEur = this.usdToEur(ethereumValueUsd);
          }
        );
      }
    )
  }

  usdToEur(usd: number): number {
    return usd / this.euroRate;
  }

  automaticDataUpdate() {
    let t = this;
    this.timer = setTimeout(function() {
      t.cryptoCurrencyRateRead();
      t.automaticDataUpdate();
    }, 30000);
  }

  ngOnInit() {
    this.cryptoCurrencyRateRead();
    this.automaticDataUpdate();
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }
}

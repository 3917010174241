import { ChiaviService } from '../services/gestione-chiavi/chiavi.service';
import { ChiaviDTO } from '../shared/dto/gestioneChiavi/chiavi';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-chiavi',
  templateUrl: './chiaviDetail.component.html',
  styleUrls: ['./chiaviDetail.component.scss']
})

export class ChiaviDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  chiaviId: number;
  chiavi: ChiaviDTO;

  constructor(
    private chiaviService: ChiaviService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.chiaviId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.chiaviId != null && this.chiaviId != 0) {
      this.chiaviRead();
    } else {
      this.chiavi = new ChiaviDTO();
    }
  }

  chiaviSave() {
    if (this.chiavi.id == null) {
      this.chiaviCreate();
    } else {
      this.chiaviUpdate();
    }
  }

  chiaviCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.chiaviService.create(this.chiavi)
      .subscribe(
        (res: ChiaviDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.chiavi = res;
          this.chiaviId = this.chiavi.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  chiaviUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere le chiavi?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.chiaviService.update(this.chiavi).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.chiaviRead();
              this.form.markAsPristine();
            }, (err: any) => {
              this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
            }
          );
        }
      });
    }
  }

  chiaviDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il mazzo di chiavi?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.chiaviService.delete(this.chiavi.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/chiavi"]);
            }
          );
        }
      });
    }
  }

  chiaviRead() {
    this.chiaviService.read(this.chiaviId).subscribe(
      (res: ChiaviDTO) => {
        console.log("response : " + res);
        this.chiavi = res;
        this.chiaviId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.chiavi.descrizione = this.form.get("descrizione").value;
  }
 
  private dtoToForm(): void {
    this.form.get("id").setValue(this.chiavi.id);
    this.form.get("descrizione").setValue(this.chiavi.descrizione);
    this.form.get("deleteDate").setValue(this.chiavi.deleteDate);
    this.form.get("deleteUser").setValue(this.chiavi.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.chiavi.insertDate));
    this.form.get("insertUser").setValue(this.chiavi.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.chiavi.updateDate));
    this.form.get("updateUser").setValue(this.chiavi.updateUser);
    this.form.get("optLock").setValue(this.chiavi.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { TenantDTO } from '../domain/tenant';
import { NumeroReperibilitaDTO } from './NumeroReperibilita';

export class TurnoReperibilitaDTO extends LogicalDeleteDTO {
    
    id: number;
    tenant: TenantDTO;
    numeroReperibilita: NumeroReperibilitaDTO;
    collaboratore: CollaboratoreDTO
    giorno: string;
    oraDa: String;
    oraA: String;
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigatorService } from '../services/navigator.service';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { TorneoDTO } from '../shared/dto/torneo/torneo';
import { TorneoService } from '../services/torneo/torneo.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { TurnoService } from '../services/torneo/turno.service';
import { TurnoDTO } from '../shared/dto/torneo/turno';
import { ResponseFail } from '../shared/dto/responseFail';


@Component({
  selector: 'torneo-detail',
  templateUrl: './torneo-detail.component.html',
  styleUrls: ['./torneo-detail.component.scss']
})

export class TorneoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  torneoId: number;
  torneo: TorneoDTO;
  index: number[];
  maxProf: number;
  currentProf: number;
  statiTorneo: selectedValue[];
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;
  livelloAttuale: string;

  displayedColumns: string[];
  parameters: any;

  constructor(
    private urlService: UrlService,
    private turnoService: TurnoService,
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private torneoService: TorneoService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>,
    private componentCacheService: ComponentCacheService,) {
      super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.displayedColumns = ['collaboratoreGiocatoreUno',
                             'collaboratoreGiocatoreDue', 
                             'collaboratoreVincitore', 
                             'risultato', 
                             'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      initDataDa: true,
    }
    this.statiTorneo = [
      { value: 'IN_CORSO', viewValue: 'In Corso' },
      { value: 'ISCRIZIONI_APERTE', viewValue: 'Iscrizioni Aperte' },
      { value: 'CONCLUSO', viewValue: 'Concluso' }
    ];
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      stato: new FormControl({ value: 'ISCRIZIONI_APERTE', disabled: true }),
      numeroGiocatori: new FormControl({
        value: null,
        disabled: Number(this.activeRoute.snapshot.paramMap.get("id")) != 0
      },
        [
          NumeroGiocatoriValidators.isExponentialOfTwo,
          Validators.required
        ]),
      descrizione: new FormControl(null, Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.maxProf = 0;
    this.currentProf = 0;
    this.livelloAttuale = "";
  }


  ngOnInit() {
    this.torneoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.torneoId != null && this.torneoId != 0) {
      this.torneoRead();
    } else {
      this.torneoInit();
    }
  }


  torneoSave() {
    if (this.torneo.id == null) {
      this.torneoCreate();
    } else {
      this.torneoUpdate();
    }
  }

  torneoCreate() {
    if (this.form.valid) {
      this.formToDto();
      console.log(this.torneo);
      this.torneoService.create(this.torneo).subscribe(
        (res: TorneoDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.torneo = res;
          this.torneoId = this.torneo.id;
          this.maxProf = (Math.log(this.torneo.numeroGiocatori)/Math.log(2)) - 1;
          this.currentProf = this.maxProf;
          this.form.get('numeroGiocatori').disable();
          this.dtoToForm();
          this.form.markAsPristine();
          this.list();
        }, (err: HttpErrorResponse) => {
          this.snackBar.open(err.error, null, { duration: 4000 });
        });
    }
  }

  torneoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il torneo").subscribe(result => {
        if (result) {
          this.formToDto();
          this.torneoService.update(this.torneo).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.torneoRead();
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              this.snackBar.open(err.error, null, { duration: 4000 });
            });
        }
      });
    }
  }

  torneoDelete() {
    //il form viene completamente disabilitato quando il torneo è con almeno un iscritto quindi non è valid, ma disabled
    if (this.form.valid || this.form.disabled) {
      this.confirm("Sei sicuro di voler cancellare il torneo").subscribe(result => {
        if (result) {
          this.formToDto();
          this.torneoService.delete(this.torneo.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/torneo"]);
            }
          );
        }
      });
    }
  }

  torneoRead() {
    this.torneoService.read(this.torneoId).subscribe(
      (res: TorneoDTO) => {
        console.log("response : " + res);
        this.torneo = res;
        this.torneoId = res.id;
        this.maxProf = (Math.log(this.torneo.numeroGiocatori)/Math.log(2)) - 1;
        this.currentProf = this.maxProf;
        this.dtoToForm();
        this.list();
      }
    );
  }

  torneoInit() {
    this.torneo = new TorneoDTO();
  }


  private formToDto(): void {
    this.torneo.numeroGiocatori = this.form.get("numeroGiocatori").value;
    this.torneo.descrizione = this.form.get("descrizione").value;
    this.torneo.status = this.form.get('stato').value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.torneo.id);
    this.form.get("stato").setValue(this.torneo.status);
    this.form.get("descrizione").setValue(this.torneo.descrizione);
    this.form.get("numeroGiocatori").setValue(this.torneo.numeroGiocatori);
    this.form.get("deleteDate").setValue(this.torneo.deleteDate);
    this.form.get("deleteUser").setValue(this.torneo.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.torneo.insertDate));
    this.form.get("insertUser").setValue(this.torneo.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.torneo.updateDate));
    this.form.get("updateUser").setValue(this.torneo.updateUser);
    this.form.get("optLock").setValue(this.torneo.optLock);
    this.form.markAsPristine();
  }
  hiddenColumns(): number[] {
    let ret: number[] = [];
    this.displayedColumns.forEach((element, index) => {
      if (element == "detail" || element == "print") {
        ret.push(index);
      }
    });
    return ret;
  }

  calculateCacheName(): string {
    console.log("cache name : " + this.router.routerState.snapshot.url);
    return this.router.routerState.snapshot.url;
  }

  list() {
    this.turnoService.turnoListByProfAndRoot(
      this.torneo.turnoRoot.id,
      this.currentProf
      ).subscribe((res: TurnoDTO[]) => {
          console.log("response : " + res);
          this.parameters.dataSource = res;
          this.parameters.length = res.length;
          this.parameters.showList = true;
          this.scriviLivelloAttuale();
          if(this.currentProf == this.maxProf){
            this.checkHasIscritto();
          }
        })
  }

  ngOnDestroy(): void {
  }

  goNextLevel(){
    this.currentProf--;
    this.list(); 
  }

  goPreviusLevel(){
    this.currentProf++;
    this.list();
  }

  scriviLivelloAttuale(){
  let livello: number = this.maxProf - this.parameters.dataSource[0].livello;
   if(livello == this.maxProf){
      this.livelloAttuale = "Finale";
   } else if(livello == (this.maxProf - 1)){
     this.livelloAttuale = "Semifinale";
   } else {
     this.livelloAttuale = "Turno " + (livello + 1);
   }
  }

  checkHasIscritto(){
    this.parameters.dataSource.forEach( foglia => {
       if(foglia.collaboratoreGiocatoreUno != null || foglia.collaboratoreGiocatoreDue != null){
          this.form.get('descrizione').disable();
       }
     });
  }

  iscrizione(){
    this.torneoService.iscriviCollaboratore(this.torneo)
    .subscribe(() => {
      this.list();
      this.torneoRead();
    }, (err: HttpErrorResponse) => {
      if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidTorneoException_DUPLICATED_ISCRITTO") {
        this.snackBar.open("Attenzione! Non puoi iscriverti due volte allo stesso torneo", null, { duration: 4000 });
      }
    });
  }

}

export interface selectedValue {
  value: string;
  viewValue: string;
}

export class NumeroGiocatoriValidators {
  static isExponentialOfTwo(control: AbstractControl): ValidationErrors {
    if ((Math.log(control.value as number) / Math.log(2) % 1 != 0)) {
      return { notExponentialOfTwo: true }
    }
    return null;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigatorService } from '../services/navigator.service';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { TurnoService } from '../services/torneo/turno.service';
import { TurnoDTO } from '../shared/dto/torneo/turno';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-turno-detail',
  templateUrl: './turno-detail.component.html',
  styleUrls: ['./turno-detail.component.scss']
})
export class TurnoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {


  turnoId: number;
  turno: TurnoDTO;
  collaboratoriGiocatori: CollaboratoreDTO[];
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;

  constructor(
    private urlService: UrlService,
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private turnoService: TurnoService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      livello: new FormControl({ value: '', disabled: true }),
      collaboratoreGiocatoreUno: new FormControl({ value: '', disabled: true }),
      collaboratoreGiocatoreDue: new FormControl({ value: '', disabled: true }),
      collaboratoreVincitore: new FormControl('',Validators.required),
      risultato: new FormControl('', Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });
    this.collaboratoriGiocatori = [];
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.turnoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.turnoId != 0 && this.turnoId != null) {
      this.turnoRead();
    } else {
      // Error
    }
    this.turnoService.read( this.turnoId ).subscribe(
      (res: TurnoDTO) => {
        console.log("response : " + res);
        this.turno = res;
      }
    );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
      console.log(this.previousUrl);
    });
  }

  turnoSave() {
    this.turnoUpdate();
  }

  turnoUpdate() {
    if (this.form.valid && this.form.dirty) {
      if (this.turno.collaboratoreGiocatoreUno != null && this.turno.collaboratoreGiocatoreDue != null) {
        this.confirm("Sei sicuro di voler sovrascrivere il turno").subscribe(result => {
          if (result) {
            this.formToDto();
            this.turnoService.update(this.turno).subscribe(
              (res) => {
                console.log("response : " + res);
                this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
                this.turnoRead();
                this.form.markAsPristine();
              }, (err: HttpErrorResponse) => {
                if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_INVALID_PARAMETER") {
                  this.snackBar.open("Servono due concorrenti per avere un vincitore!", null, { duration: 4000 });
                }
              });
          }
        });
      } else {
        this.snackBar.open("Servono due concorrenti per avere un vincitore!", null, { duration: 4000 });
      }
    }
  }

  turnoRead() {
    this.turnoService.read(this.turnoId).subscribe(
      (res: TurnoDTO) => {
        console.log("response : " + res);
        this.turno = res;
        this.turnoId = res.id;
        this.dtoToForm();
        this.collaboratoriGiocatori = [];
        if (this.turno.collaboratoreGiocatoreUno) {
          this.collaboratoriGiocatori.push(this.turno.collaboratoreGiocatoreUno);
        }
        if (this.turno.collaboratoreGiocatoreDue) {
          this.collaboratoriGiocatori.push(this.turno.collaboratoreGiocatoreDue);
        }
        if(this.turno.collaboratoreVincitore != null){
          this.form.get('collaboratoreVincitore').disable();
          this.form.get('risultato').disable();
        }
      });
  }

  private formToDto(): void {
    this.turno.livello = this.form.get("livello").value;
    this.turno.collaboratoreVincitore = this.form.get("collaboratoreVincitore").value;
    this.turno.risultato = this.form.get("risultato").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.turno.id);
    this.form.get("livello").setValue(this.turno.livello);
    this.form.get("collaboratoreGiocatoreUno").setValue(this.turno.collaboratoreGiocatoreUno ? this.turno.collaboratoreGiocatoreUno.nome + ' ' + this.turno.collaboratoreGiocatoreUno.cognome : 'Giocatore Uno');
    this.form.get("collaboratoreGiocatoreDue").setValue(this.turno.collaboratoreGiocatoreDue ? this.turno.collaboratoreGiocatoreDue.nome + ' ' + this.turno.collaboratoreGiocatoreDue.cognome : 'Giocatore Due');
    this.form.get("collaboratoreVincitore").setValue(this.turno.collaboratoreVincitore);
    this.form.get("risultato").setValue(this.turno.risultato);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.turno.insertDate));
    this.form.get("insertUser").setValue(this.turno.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.turno.updateDate));
    this.form.get("updateUser").setValue(this.turno.updateUser);
    this.form.markAsPristine();
  }

}

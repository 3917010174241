import { DAYS_OF_WEEK } from "angular-calendar";
import { LogicalDeleteDTO } from "../basic/logicalDeleteDTO";
import { CollaboratoreDTO } from "../domain/collaboratore";
import { TenantDTO } from "../domain/tenant";

export class OrarioLavorativoDTO extends LogicalDeleteDTO {
  id: number;
  tenant: TenantDTO;
  collaboratore: CollaboratoreDTO;
  giorno: Giorni;
  dalleOre: number;
  alleOre: number;
}


export class OrarioLavorativoDuplicaDTO {
  collaboratoriTargetList: CollaboratoreDTO[]
}


export enum Giorni {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli prenotazione sala</h2>
    <div fxLayout="column">
      <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Prenotazione Saletta Id" readonly="true" />
        </mat-form-field>

        <!-- Collaboratore field -->
        <mat-form-field>
          <mat-select name="collaboratore" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
            formControlName="collaboratore" placeholder="Scegli il collaboratore">
            <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('collaboratore').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Sede field -->
        <mat-form-field>
          <mat-select name="sede" [compareWith]="compareDTO" formControlName="sede"
            (selectionChange)="onSedeChange($event)" placeholder="Scegli la sede">
            <mat-option *ngFor="let sede of sedi" readonly="true" [value]="sede">
              {{sede.descrizione}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('sede').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Saletta field -->
        <mat-form-field>
          <mat-select name="saletta" [compareWith]="compareDTO" formControlName="saletta"
            placeholder="Scegli la saletta">
            <mat-option *ngFor="let saletta of salette" readonly="true" [value]="saletta">
              {{saletta.descrizione}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('saletta').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Descrizione field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="descrizione" placeholder="Descrizione Prenotazione" />
          <mat-error *ngIf="form.get('descrizione').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>



        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- DataDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput readonly="true" (click)="picker.open()" [max]="dataMax" (dateChange)="setMinDate()"
              [matDatepicker]="picker" formControlName="dataDa" placeholder="Scegli data da">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="form.get('dataDa').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()" [min]="dataMin" (dateChange)="setMaxDate()"
              [matDatepicker]="picker2" formControlName="dataA" placeholder="Scegli data a">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="form.get('dataA').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- TimeDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput readonly [min]="'08:00'" [max]="'18:00'" [ngxTimepicker]="toggleTimepickerDa" [format]="24"
              formControlName="timeDa" placeholder="Scegli ora da">
            <ngx-material-timepicker #toggleTimepickerDa [appendToInput]="!isMobile" [preventOverlayClick]="false" [minutesGap]="10"></ngx-material-timepicker>
            <mat-error *ngIf="form.get('timeDa').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
          <!-- TimeA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput readonly [min]="'08:00'" [max]="'18:00'" [ngxTimepicker]="toggleTimepickerA" [format]="24"
              formControlName="timeA" placeholder="Scegli ora a">
            <ngx-material-timepicker #toggleTimepickerA [appendToInput]="!isMobile" [preventOverlayClick]="false"
              [minutesGap]="10"></ngx-material-timepicker>
            <mat-error *ngIf="form.get('timeA').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>



        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/gestione-prenotazione-saletta')" color="primary">Back</button>
      <button mat-raised-button (click)="prenotazioneSalettaSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="prenotazioneSaletta?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="prenotazioneSalettaDelete()" [disabled]="prenotazioneSaletta?.id==null || this.disableDelete"
        *ngIf="prenotazioneSaletta?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione risorse aziendali</h2>
        <p>Imposta i filtri di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">

        <!-- Gruppo field -->
          <mat-form-field>
            <input matInput [(ngModel)]="parameters.gruppo" placeholder="Gruppo"/>
          </mat-form-field>

        <!-- descrizione field -->
        <mat-form-field>
            <input matInput [(ngModel)]="parameters.descrizione" placeholder="Descrizione"/>
          </mat-form-field>

        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/risorsa-aziendale/detail/0']">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let risorsaAziendale"> {{risorsaAziendale.id}} </td>
            </ng-container>
            
            <!-- Gruppo Column -->
            <ng-container matColumnDef="gruppo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Gruppo </th>
              <td mat-cell *matCellDef="let risorsaAziendale"> {{risorsaAziendale.gruppo}} </td>
            </ng-container>
            
            <!-- URL Column -->
            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > URL </th>
                <td mat-cell *matCellDef="let risorsaAziendale"> <a [href]="risorsaAziendale.url" target="_blank" rel="noopener noreferrer" >{{risorsaAziendale.url}}</a> </td>
            </ng-container>

            <!-- Icona Column -->
            <ng-container matColumnDef="icona">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Icona </th>
                <td mat-cell *matCellDef="let risorsaAziendale"> 
                    <mat-icon *ngIf="!risorsaAziendale.fontset" class="material-icons">{{risorsaAziendale.icona}}</mat-icon>
                    <mat-icon *ngIf="risorsaAziendale.fontset" class="material-icons" [fontSet]="risorsaAziendale.fontset" [fontIcon]="risorsaAziendale.icona"></mat-icon>
                </td>
              </ng-container>
              
              <!-- Descrizione Column -->
            <ng-container matColumnDef="descrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Descrizione </th>
                <td mat-cell *matCellDef="let risorsaAziendale"> {{risorsaAziendale.descrizione}} </td>
              </ng-container>

            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let risorsaAziendale">
                  <button mat-mini-fab color="primary" [routerLink]="['/risorsa-aziendale/detail/', risorsaAziendale.id]"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> 
      </div>
    </mat-card-content>
  </mat-card>


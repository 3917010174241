import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { OrarioLavorativoService } from '../services/orario-lavorativo/orario-lavorativo.service';
import { OrarioLavorativoDTO } from '../shared/dto/orario-lavorativo/orario-lavorativo';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';
import { switchMap } from 'rxjs/operators';

class CollaboratoreGiorni {
  collaboratore: CollaboratoreDTO;
  giorni = new Map<string, OrarioLavorativoDTO>();
};

@Component({
  selector: 'app-orario-lavorativo',
  templateUrl: './orario-lavorativo.component.html',
  styleUrls: ['./orario-lavorativo.component.scss']
})

export class OrarioLavorativoComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  giorni = this.orarioLavorativoService.giorni;
  tabellaRaggruppata: any[] = [];
  orarilavorativi: OrarioLavorativoDTO[];

  orarilavorativiDisplayColumns: string[];
  defaultSortField = 'collaboratore';
  defaultSortDirection = 'asc';

  constructor(
    private orarioLavorativoService: OrarioLavorativoService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.orarilavorativiDisplayColumns = ['collaboratore', this.giorni[0], this.giorni[1], this.giorni[2], this.giorni[3], this.giorni[4], this.giorni[5], this.giorni[6], 'duplicate'];
    this.displayedColumns = ['id', 'collaboratore', 'giorno', 'daOre', 'aOre', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: true,
      collaboratore: null,
      giorno: null,
      collaboratoreChanged: false,
      sortField: 'collaboratore',
      sortDirection: 'ASC',
      pageNumber: 0,
      pageSize: 50,
      length: 0,
    }
  }


  list() {
    this.orarioLavorativoService.orarioLavorativoList(
      this.parameters.collaboratore,
      this.parameters.giorno,
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
    ).subscribe(
      (res: ResponseQueryByCriteria<OrarioLavorativoDTO>) => {
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
        this.tabellaRaggruppata = this.ragruppaTabella(this.parameters.dataSource);
        console.log(this.tabellaRaggruppata);
      },
      (err: HttpErrorResponse) => {
        if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidOrarioLavorativoException_ORARIO_LAVORATIVO_NOT_FOUND") {
          this.snackBar.open("Attenzione! L'orario lavorativo di questo collaboratore e per questo giorno non esiste ", null, { duration: 4500 });
        }
      }
    )
  }


  ngOnInit() {

    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.pipe(
        switchMap((res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
          return this.collaboratoreService.collaboratoreNoAmList(
            0,
            9999,
            'ASC',
            'nome',
            '');
        })
      ).subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;

          if(this.collaboratori.find((col) => col.id == this.parameters.collaboratore.id) == null)
            this.parameters.collaboratore = null;
        }
      );
    }
  }

  ragruppaTabella(orariLavorativi: OrarioLavorativoDTO[]): any {
    const map = new Map<number, CollaboratoreGiorni>();

    orariLavorativi.forEach(orario => {
      if(!map.has(orario.collaboratore.id))
        map.set(orario.collaboratore.id, new CollaboratoreGiorni());

      map.get(orario.collaboratore.id).collaboratore = orario.collaboratore;
      map.get(orario.collaboratore.id).giorni.set(orario.giorno, orario);
    });

    return Array.from(map.values());
  };

  navigateToFiglio(orario: CollaboratoreGiorni) {
    this.router.navigate([`/orario-lavorativo/duplicate`, orario.collaboratore.id]);
  }

  translateDayToIta(giornotradotto: string) {
    return this.orarioLavorativoService.translateDayToIta(giornotradotto);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}




<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli acquisto</h2>
      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
      
          <!-- Id field -->
            <mat-form-field>
              <input matInput formControlName="id" placeholder="Acquisto Id" readonly="true" />
            </mat-form-field>
      
          <!-- Richiedente field -->
            <mat-form-field>
              <mat-select name="richiedente" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
                formControlName="richiedente" placeholder="Scegli il richiedente">
                <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                  {{collaboratore.nome}} {{collaboratore.cognome}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('richiedente').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>
      
            <!-- Responsive -->
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
              <!-- StatoRichiesta field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <mat-select name="statoRichiesta" [disabled]="!isJappTenantAdmin()" formControlName="statoRichiesta"
                  placeholder="Scegli lo stato della richiesta">
                  <mat-option *ngFor="let option of statoRichiestaValues" [value]="option.value">
                    {{option.viewValue}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('statoRichiesta').errors?.required">
                  Campo obbligatorio
                </mat-error>
              </mat-form-field>
      
              <!-- Approvatore field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <mat-select name="approvatore" disabled formControlName="approvatore" [compareWith]="compareDTO"
                  placeholder="Approvatore">
                  <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                    {{collaboratore.nome}} {{collaboratore.cognome}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('approvatore').errors?.required">
                  Campo obbligatorio
                </mat-error>
              </mat-form-field>
            </div>
      
            <!-- Descrizione field -->
            <mat-form-field>
              <textarea cdkTextareaAutosize matInput formControlName="descrizione" placeholder="Nota"></textarea>
              <mat-error *ngIf="form.get('descrizione').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>

            <!-- Url field -->
            <mat-form-field>
              <div fxLayout="row" fxLayoutAlign="space-evenly center" >
                <input matInput formControlName="url" placeholder="URL" />
              </div>
            </mat-form-field>
      
            <!-- Campi di informazione operazione -->
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Dettagli operazione
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row">
                <!-- InsertDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                </mat-form-field>
      
                <!-- InsertUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                </mat-form-field>
              </div>
              <div fxLayout="row">
                <!-- UpdateDate field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                  [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                  [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                  <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                </mat-form-field>
      
                <!-- UpdateUser field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                  <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                </mat-form-field>
              </div>
            </mat-expansion-panel>
          </form>
        </div>
        <mat-card-actions>
          <button mat-raised-button (click)="backConfirm('/richiesta-acquisto')" color="primary">Back</button>
          <button mat-raised-button (click)="acquistoSave()" [disabled]="form.invalid || !form.dirty"
            *ngIf="acquisto?.deleteDate==null">Save</button>
          <button mat-raised-button (click)="acquistoDelete()" [disabled]="acquisto?.id==null"
            *ngIf="acquisto?.deleteDate==null">Delete</button>
        </mat-card-actions>
  </mat-card-content>
</mat-card>
import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';
import { TurnoDTO } from './turno';

export class TorneoDTO extends LogicalDeleteDTO {
    
    id: number;
    
    tenant: TenantDTO;
    
    descrizione: string;

    turnoRoot: TurnoDTO;

    status: String;

    numeroGiocatori: number;
}
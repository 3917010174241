<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione presenze</h2>
    <p>Imposta i filtri di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">
      <!-- Collaboratore field -->
      <mat-form-field>
        <mat-label>Collaboratori</mat-label>
        <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO"
          [disabled]="!isJappTenantAdmin()" (selectionChange)= "this.parameters.collaboratoreChanged = true">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{collaboratore.nome}} {{collaboratore.cognome}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

      <!-- Responsive -->
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
        <!-- DataDa field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
            [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataDa=null" (click)="parameters.initDataDa=false">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- DataA field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
          <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
            [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataA=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div fxLayout="column" class="responsive-mat-form">
        <!-- Location field -->
        <mat-form-field>
          <mat-label>Locations</mat-label>
          <mat-select [compareWith]="compareDTO" multiple [(ngModel)]="this.parameters.selectedLocations">
            <mat-option *ngFor="let location of locations" [value]="location">
              {{location.descrizione}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search"
        color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/gestione-presenza/detail/0']">New</button>
    </mat-card-actions>

    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
        matSort (matSortChange)="setSort($event)" [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection" matSortDisableClear
        [dataSource]="parameters.dataSource">

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let presenza"> {{presenza.id }} </td>
          <td fxHide fxShow.gt-md mat-footer-cell *matFooterCellDef ></td>
        </ng-container>

        <!-- DataPresenza Column -->
        <ng-container matColumnDef="dataPresenza">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
          <td mat-cell *matCellDef="let presenza"> {{presenza.dataPresenza | date :'dd/MM/yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef > <div *ngIf="hideFooterColumns()"><strong>Giorni: {{ parameters?.giorni | number:'1.0-2' }}</strong></div> </td>
        </ng-container>

        <!-- Collaboratore Column -->
        <ng-container matColumnDef="collaboratore" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Collaboratore </th>
          <td mat-cell *matCellDef="let presenza" [hidden]="!isJappTenantAdmin()"> {{presenza.collaboratore?.nome}} {{presenza.collaboratore?.cognome}}</td>
          <td mat-footer-cell *matFooterCellDef [hidden]="!isJappTenantAdmin()"></td>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
          <td mat-cell *matCellDef="let presenza"> {{presenza.location?.descrizione}} </td>
          <td mat-footer-cell *matFooterCellDef ></td>
        </ng-container>

        <!-- NumeroDiOre Column -->
        <ng-container matColumnDef="numeroDiOre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ore </th>
          <td mat-cell *matCellDef="let presenza"> {{presenza.numeroDiOre}} </td>
          <td mat-footer-cell *matFooterCellDef > <div *ngIf="hideFooterColumns()"><strong>Totali: {{ parameters?.totaleOre | number:'1.0-2' }}</strong></div> </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let presenza">
            <button mat-mini-fab color="primary" [routerLink]="['/gestione-presenza/detail/', presenza.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef ></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button fxHide fxShow.gt-md mat-raised-button (click)="totPresenzeInCsv()">Csv</button>
        </mat-card-actions>
        <mat-paginator #paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
import { Injectable } from '@angular/core';
import { FrequentaCorsoDTO } from 'src/app/shared/dto/corsi-udemy/frequentaCorso';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { CorsiUdemyDTO } from 'src/app/shared/dto/corsi-udemy/corsiUdemy';

@Injectable({
  providedIn: 'root'
})
export class FrequentaCorsoService extends GenericService<FrequentaCorsoDTO, number>{

  constructor(http: HttpClient) { 
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/frequentacorso';
  }

  public frequentaCorsoList(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO): Observable<ResponseQueryByCriteria<FrequentaCorsoDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<FrequentaCorsoDTO>>(`${this.url}/collaboratoreFrequentaCorsoListByCollaboratore`, {
      params: params
    });
  }

  public frequentaCorsoListByCollaboratoreAndDates(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO, corsoUdemy: CorsiUdemyDTO, dataInizio: Date, dataFine: Date): Observable<ResponseQueryByCriteria<FrequentaCorsoDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (corsoUdemy != null) params["corsoUdemyId"] = corsoUdemy.id;
    if (dataInizio != null) params["dataInizio"] = dataInizio.toISOString();
    if (dataFine != null) params["dataFine"] = dataFine.toISOString();
    return this.http.get<ResponseQueryByCriteria<FrequentaCorsoDTO>>(`${this.url}/collaboratoreFrequentaCorsoListByCollaboratoreAndDates`, {
      params: params
    });
  }

} 
 
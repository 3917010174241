import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';

export class TenantDTO extends LogicalDeleteDTO {
    id: number;
    tenantCode: string;
    description: string;
    mobileDesc: string;
    hostName: string;
    jwkProviderUrl: string;
    clientId: string;
    issuer: string;
    rolesClaim: string;
    amministrazioneMailList: string;
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione rapportino mensile </h2>
        <p>Imposta i filtri di ricerca</p>
        <!-- Collaboratore field -->
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <mat-label>Collaboratori</mat-label>
                <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO"
                    [disabled]="!isJappTenantAdmin()">
                    <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                        {{collaboratore.nome}} {{collaboratore.cognome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Mese field -->
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <mat-label>Mese</mat-label>
                <mat-select [(value)]="parameters.mese" [compareWith]="compareDTO">
                    <mat-option *ngFor="let mese of mesi" [value]="mese">
                        {{mese.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--Anno field-->
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <input type="number" matInput [formControl]="annoFormControl" [(ngModel)]="parameters.anno" placeholder="anno" />
                <mat-error *ngIf="annoFormControl.hasError('required')">
                    Anno is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <!--Stato field-->
        <div fxHide fxShow.gt-xs fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <mat-label>Stato</mat-label>
                <mat-select [(value)]="parameters.stato" [compareWith]="compareDTO">
                    <mat-option *ngFor="let stato of statoRapportinoFinale" [value]="stato.value">
                        {{stato.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxShow fxHide.gt-xs fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <mat-label>Stato</mat-label>
                <mat-select [(value)]="parameters.stato" [compareWith]="compareDTO">
                    <mat-option *ngFor="let stato of statoRapportinoFinale" [value]="stato.value">
                        {{stato.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-card-actions>
            <button mat-raised-button [disabled]="annoNonValido()" id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button id="new-button" [routerLink]="['/rapportino-finale/detail/0']">New</button>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
                matSort (matSortChange)="setSort($event)" [matSortActive]="defaultSortField"
                [matSortDirection]="defaultSortDirection" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let rapportinoFinale"> {{ rapportinoFinale.id }} </td>
                </ng-container>

                <!-- Collaboratore Column -->
                <ng-container matColumnDef="collaboratore">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore
                    </th>
                    <td mat-cell *matCellDef="let rapportinoFinale">
                        {{rapportinoFinale.collaboratore?.nome}} {{rapportinoFinale.collaboratore?.cognome}}
                    </td>
                </ng-container>

                <!-- Data Column -->
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data
                    </th>
                    <td mat-cell *matCellDef="let rapportinoFinale">
                        {{ rapportinoFinale.data | date :'MMMM yyyy':'':locale | titlecase}}
                    </td>
                </ng-container>

                <!-- Descrizione Column -->
                <ng-container matColumnDef="statoRapportinoFinale">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
                    <td mat-cell *matCellDef="let rapportinoFinale"> {{ estraiStato(rapportinoFinale) }} </td>
                </ng-container>
                <!-- Da iniziare a modificare  -->
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let rapportinoFinale">
                        <button mat-mini-fab color="primary" [routerLink]="['/rapportino-finale/detail/', rapportinoFinale.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-actions>
                    <button mat-raised-button
                        (click)="exporter.exportTable('xlsx', {fileName:'rapportinoFinale'})">Excel</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('csv', {fileName:'rapportinoFinale'})">Csv</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('json', {fileName:'rapportinoFinale'})">Json</button>
                </mat-card-actions>
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
import { ComandaDTO } from 'src/app/shared/dto/gestioneOrdini/comanda';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { OrdineDTO } from '../shared/dto/gestioneOrdini/ordine';
import { OrdineService } from '../services/gestione-ordini/ordine.service';
import * as moment from 'moment';
@Component({
  selector: 'app-gestione-ordine',
  templateUrl: './gestione-ordine.component.html',
  styleUrls: ['./gestione-ordine.component.scss']
})
export class GestioneOrdineComponent extends GenericListComponent implements OnInit, OnDestroy {
  collaboratori: CollaboratoreDTO[];
  comanda: ComandaDTO[];
  ordine: OrdineDTO;
  today=moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});

  defaultSortField = 'comanda.dataComanda';
  defaultSortDirection = 'asc';

  constructor(
    private ordineService: OrdineService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'comanda.dataComanda', 'importoDovuto', 'collaboratore', 'comanda.email', 'comanda.descrizione', 'descrizioneOrdine', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      collaboratoreChanged: false,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      totaleImp: null,
      giorni: null,
      sortField: 'comanda.dataComanda',
      sortDirection: 'DESC',
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  list() {    
    this.ordineService.ordineListByCollaboratoreAndDates(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.dataDa,
      this.parameters.dataA).subscribe(
        (res: ResponseQueryByCriteria<OrdineDTO>) => {
          console.log("response : " + res);
          res.content.forEach(o => {
            o.showDetail=this.hideDetail(o.comanda);
          });
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
    this.totImportoPersonale();
  }

  hideFooterColumns(): boolean {
    let hide: boolean = false;
    this.parameters.totaleImp > 0 ? hide = true : hide = false;
    return hide;
  }

  private hideDetail(comanda: ComandaDTO): boolean {
    let dataC=comanda.dataComanda
    let momentDataC=moment(dataC).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    let hide: boolean = false;
    let us= comanda.deleteUser
    if(momentDataC >= this.today){
      hide=true;
    }
    return hide;
  }

  totImportoPersonale() {
    this.ordineService.totImportoPersonale(
      this.parameters.collaboratore,
      this.parameters.dataDa,
      this.parameters.dataA).subscribe(
        (res: number) => {
          console.log("response : " + res);
          this.parameters.totaleImp = res;
        }
      )
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}


import { MovimentoService } from '../../services/credito/movimento.service';
import { MovimentoDTO } from '../../shared/dto/credito/movimento';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreditoService } from '../../services/credito/credito.service';
import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { CreditoDTO } from '../../shared/dto/credito/credito';
import { NavigatorService } from '../../services/navigator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carica-credito',
  templateUrl: './carica-credito.component.html',
  styleUrls: ['./carica-credito.component.scss']
})
export class CaricaCreditoComponent implements OnInit {
  displayedColumns: string[] = ['messaggio'];
  
  creditoId:number;
  collaboratore: CollaboratoreDTO;
  credito : CreditoDTO;
  movimento: MovimentoDTO;
  //reg = new RegExp('^[0-9]*$');
  reg = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/);

  constructor(
    private navigatorService : NavigatorService, 
    private route: ActivatedRoute, 
    private movimentoService: MovimentoService,
    private creditoService : CreditoService,
    private snackBar: MatSnackBar, 
    private router : Router
  ) {
    this.creditoId = Number(this.route.snapshot.paramMap.get("id"));
    this.collaboratore = new CollaboratoreDTO();
    this.credito = new CreditoDTO(); 
    this.movimento = new MovimentoDTO(); 
    this.movimento.data = new Date();
   }

  ngOnInit() {
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        this.creditoService.creditoSearchByCollaboratore(this.collaboratore.id).subscribe(
          (result: CreditoDTO) => {
          console.log("response : " + result);
          this.movimento.credito = result;
          }
        );
      }
    );
  }

  conversioneEuro(euro: number){
    console.log("euro "+euro);
    var test = ""+euro;
    if(this.reg.test(test)){
      if(euro <= 300){
        var test2 = +(euro % 0.30).toFixed(2);
        console.log("modulo "+test2);
        if(test2 == 0.0 ){
          var saldo = euro/0.30;
          saldo = +saldo.toFixed(0);
          console.log("Saldo conversione "+saldo);
          this.creditoSave(saldo);
        }else{
          this.snackBar.open("L'importo inserito non è multiplo di 0.30€", null, {
            duration: 3000
          });
        }
      }else{
        this.snackBar.open('Puoi caricare un massimo di 300 euro ', null, {
          duration: 3000
        });
      }
  }else{
    this.snackBar.open('Valore non valido ', null, {
      duration: 3000
    });
  }
  }

  creditoSave(saldo : number) {
    console.log("saldo save "+ saldo);
      this.movimento.totaleCrediti = saldo;
      this.movimentoService.create(this.movimento).subscribe(
        (res: MovimentoDTO) => {
          console.log("response : " + res);
          this.snackBar.open('Caricati : ' + this.movimento.totaleCrediti, null, {
            duration: 3000
          });
          this.router.navigate(["/gestione-credito"]);
        }
      );
  }
}
export interface Ricariche {
  messaggio: string;
  valore: number;
}
 
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Prenotazione Stampante 3D</h2>
    <p>Imposta i filtri di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">

      <!-- Collaboratore field -->
      <mat-form-field>
        <mat-select name="collaboratore" [(ngModel)]="parameters.collaboratore" [compareWith]='compareDTO'
          (selectionChange)="onFieldChange($event)" placeholder="Scegli il collaboratore">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{collaboratore.nome}} {{collaboratore.cognome}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Sede field -->
      <mat-form-field>
        <mat-select name="sede" [(ngModel)]="parameters.sede" [compareWith]='compareDTO'
          (selectionChange)="onFieldChange($event)" placeholder="Scegli la sede">
          <mat-option [value]="null">
            Tutte
          </mat-option>
          <mat-option *ngFor="let sede of sedi" [value]="sede">
            {{sede.descrizione}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Stampante 3D field -->
      <mat-form-field>
        <mat-select name="stampante3D" [(ngModel)]="parameters.stampante3D" [compareWith]='compareDTO'
          (selectionChange)="onFieldChange($event)" placeholder="Scegli la stampante 3D">
          <mat-option [value]="null">
            Tutte
          </mat-option>
          <mat-option *ngFor="let stampante3D of stampanti3D" [value]="stampante3D">
            {{stampante3D.codice}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Responsive -->
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form" *ngIf="vistaData">
        <!-- DataDa field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput readonly="true" (click)="picker.open()" [max]="parameters.dataDa"
            [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataDa=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- DataA field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
          <input matInput readonly="true" (click)="picker2.open()" [min]="parameters.dataDa"
            [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataA=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>

    <mat-card-actions>
      <button mat-raised-button id="search-button" *ngIf="vistaData" (click)="list()" name="Search"
        color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/prenotazionestampante3d/detail/0']">New</button>
    </mat-card-actions>
    <br />

    <mat-tab-group (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Calendario">
        <!-- CALENDARIO -->
        <mwl-utils-calendar-header [(view)]="this.parameters.view" [(viewDate)]="this.parameters.viewDate"
          [weekStartsOn]="weekStartsOn">
        </mwl-utils-calendar-header>

        <div *ngIf="!vistaData" [ngSwitch]="this.parameters.view">
          <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="this.parameters.viewDate" [locale]="locale"
            [weekStartsOn]="weekStartsOn" [weekendDays]="weekendDays" [events]="events"
            [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
            (eventClicked)="eventClicked($event.event)" (beforeViewRender)="estrazionePeriodoVista($event)">
          </mwl-calendar-month-view>
          <mwl-calendar-week-view *ngSwitchCase="'week'" class="cal-week-view-hide-hours"
            [viewDate]="this.parameters.viewDate" [locale]="locale" [weekStartsOn]="weekStartsOn"
            [weekendDays]="weekendDays" [events]="events" (eventClicked)="eventClicked($event.event)"
            (beforeViewRender)="estrazionePeriodoVista($event)">
          </mwl-calendar-week-view>
          <mwl-calendar-day-view *ngSwitchCase="'day'" class="cal-day-view-hide-hours"
            [viewDate]="this.parameters.viewDate" [locale]="locale" [events]="events"
            (eventClicked)="eventClicked($event.event)" (beforeViewRender)="estrazionePeriodoVista($event)">
          </mwl-calendar-day-view>
        </div>
      </mat-tab>
      <mat-tab label="Lista tabella">

        <div fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazionestampa"> {{prenotazionestampa.id}}
              </td>
            </ng-container>

            <!-- Data -->
            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
              <td mat-cell *matCellDef="let prenotazionestampa"> {{prenotazionestampa.data | date
                :'dd/MM/yyyy'}}
              </td>
            </ng-container>

            <!-- Orario Inizio Stampa -->
            <ng-container matColumnDef="orarioInizioStampa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Inizio Stampa </th>
              <td mat-cell *matCellDef="let prenotazionestampa">
                {{prenotazionestampa.orarioInizioStampa < 10 ? '0' + prenotazionestampa.orarioInizioStampa :
                  prenotazionestampa.orarioInizioStampa}}:00 </td>
            </ng-container>

            <!-- Ore -->
            <ng-container matColumnDef="ore">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ore </th>
              <td mat-cell *matCellDef="let prenotazionestampa"> {{prenotazionestampa.ore}}
              </td>
            </ng-container>

            <!-- Stampante 3D Codice -->
            <ng-container matColumnDef="stampante3D.codice">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Stampante 3D </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazionestampa">
                {{prenotazionestampa.stampante3D.codice}}
              </td>
            </ng-container>

            <!-- Stampante 3D Sede -->
            <ng-container matColumnDef="stampante3D.sede.descrizione">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Sede </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazionestampa">
                {{prenotazionestampa.stampante3D.sede.descrizione}}
              </td>
            </ng-container>

            <!-- Stampante 3D Colore -->
            <ng-container matColumnDef="stampante3D.colore">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Colore </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazionestampa">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div class="color-preview" [style.background-color]="prenotazionestampa.stampante3D.colore"></div>
                  <span class="color-code">{{prenotazionestampa.stampante3D.colore}}</span>
                </div>
              </td>
            </ng-container>

            <style>
              .color-preview {
                width: 20px;
                /* Larghezza del quadratino del colore */
                height: 20px;
                /* Altezza del quadratino del colore */
                margin-right: 10px;
                /* Margine a destra per separare il quadratino dal testo */
                border: 1px solid #ccc;
                /* Bordo del quadratino */
              }

              /* Stile del testo per il codice esadecimale */
              .color-code {
                font-size: 14px;
                color: #333;
                margin-top: 5px;
              }
            </style>

            <!-- Collaboratore -->
            <ng-container matColumnDef="collaboratore">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore </th>
              <td mat-cell *matCellDef="let prenotazionestampa"> {{prenotazionestampa.collaboratore.nome}}
                {{prenotazionestampa.collaboratore.cognome}}</td>
            </ng-container>


            <!-- detail  -->
            <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let prenotazionestampa">
                <button mat-mini-fab color="primary"
                  [routerLink]="['/prenotazionestampante3d/detail/', prenotazionestampa.idMaster]">
                  <mat-icon>search</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
              [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
            </mat-paginator>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
  </mat-card-content>
</mat-card>
import { Component, OnDestroy, OnInit } from "@angular/core";
import { GenericDetailComponent } from "../shared/GenericDetailComponent";
import { TurnoServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/TurnoServizioReperibilitaDTO";
import { CollaboratoreDTO } from "../shared/dto/domain/collaboratore";
import { ServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { Observable } from "rxjs";
import { CollaboratoreService } from "../services/domain/collaboratore.service";
import { ServiziRepService } from "../services/gestione-servizi-reperibilita/serviziRep.service";
import { TurniServiziReperibilitaService } from "../services/gestione-servizi-reperibilita/turniservizireperibilita.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigatorService } from "../services/navigator.service";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from "@angular/material/dialog";
import { UrlService } from "../commons/calendar/prevUrl.service";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { SostituzioneTurnoServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/SostituzioneTurnoServizioReperibilitaDTO";





@Component({
  selector: 'app-gestione-turni-detail-servizi-rep',
  templateUrl: './gestione-turni-servizi-detail-rep.component.html',
  styleUrls: ['./gestione-turni-servizi-detail-rep.component.scss'],

})
export class GestioneTurniServiziDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  readonly MAXDATE = new Date(2099, 11, 31)
  readonly timePickerMin = "00:00";
  readonly timePickerMax = "23:59";
  readonly timePickerGap = "5";

  turno: TurnoServiziReperibilitaDTO;
  turnoSostituto: SostituzioneTurnoServiziReperibilitaDTO;
  turnoId: number;
  isTurnoCorrente: boolean;
  isTurnoFuturo: boolean;
  isLast: boolean
  dataAorig: Date;
  orarioFineTurnoOrig;

  servizi: ServiziReperibilitaDTO[];
  collaboratori: CollaboratoreDTO[];
  collaboratoriSostituti: CollaboratoreDTO[];

  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string

 



  constructor(
    private urlService: UrlService,
    private turnoReperibilitaService: TurniServiziReperibilitaService,
    private collaboratoreService: CollaboratoreService,
    private serviziReperivilitaService: ServiziRepService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );

    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl('', Validators.required),
      tipoServizio: new FormControl('', Validators.required),
      dataDa: new FormControl('', Validators.required),
      orarioInizioTurno: new FormControl('', Validators.required),
      dataA: new FormControl('', Validators.required),
      orarioFineTurno: new FormControl('', Validators.required),
      collaboratoreSostituto: new FormControl({ value: '', disabled: true }, Validators.required),
      dataDaSostituto: new FormControl({ value: '', disabled: true }, Validators.required),
      orarioInizioTurnoSostituto: new FormControl({ value: '', disabled: true }, Validators.required),
      dataASostituto: new FormControl({ value: '', disabled: true }, Validators.required),
      orarioFineTurnoSostituto: new FormControl({ value: '', disabled: true }, Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });

  }

  ngOnInit(): void {
    this.turnoSostituto = new SostituzioneTurnoServiziReperibilitaDTO()

    this.turnoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.isNew()) {
      this.turno = new TurnoServiziReperibilitaDTO();
      this.dtoToForm();
    } else {
      this.turnoRead();
    }

    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
        if (this.turno.collaboratore && this.turno.collaboratore.id) {
          this.collaboratoriSostituti = this.collaboratori.filter(c => c.id != this.turno.collaboratore.id);
        }
      }
    );

    this.serviziReperivilitaService.serviziList(
      0,
      9999,
      'ASC',
      'descrizione',
      '',
      '',
      null).subscribe(
        (res: ResponseQueryByCriteria<ServiziReperibilitaDTO>) => {
          this.servizi = res.content;
        },
        error => this.errorHandler(error)
      );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  private isNew(): boolean {
    return this.turnoId == 0 || this.turnoId == null;
  }

  private initContext() {
    const today = new Date();
    this.isTurnoCorrente = (this.turno.dataDa <= today) && (this.turno.dataA >= today);
    this.isTurnoFuturo = today <= this.turno.dataDa;

    if(!this.isNew()){
      let ultimaDataPerServizio: Date
      this.turnoReperibilitaService.getLastDateforService(this.turno.servizio).subscribe(res=>
        {ultimaDataPerServizio=res
          this.isLast=(this.turno.dataA==ultimaDataPerServizio)
          console.log(this.isLast)
          this.dtoToForm()
          
        }
      )
    }

  }

  private turnoRead() {
    this.turnoReperibilitaService.read(this.turnoId).subscribe(
      (res: TurnoServiziReperibilitaDTO) => {
        this.turno = res;
        this.turnoId = res.id;
        this.dataAorig = this.getNormalizedDate(res.dataA);
        this.orarioFineTurnoOrig = this.getNormalizedHour(res.dataA);
        this.initContext();
        this.dtoToForm();
      },
      error => {
        this.errorHandler(error)
        this.router.navigate(["gestione-turni-servizi-rep"]);

      }
    );
  }

  turnoSave() {
    if(this.isJappTenantAdmin()){
      this.formToDto();
      if (this.isNew()) {
        this.turnoCreate();
      } else if (this.isTurnoCorrente) {
        this.turnoPresentUpdate();
      } else if (this.isTurnoFuturo) {
        this.turnoFuturoUpdate();
      }

    }
  }

  turnoCreate() {
    this.turnoReperibilitaService.create(this.turno).subscribe(
      res => {
        this.onSuccess()
      },
      error => {
        this.errorHandler(error)
      }
    )
  }
  turnoPresentUpdate() {

    this.turnoReperibilitaService.updateTurnoPresent(this.turnoSostituto).subscribe(
      res => {
        this.onSuccess();
      },
      error => {
        this.errorHandler(error)
      }

    )
  }

  turnoFuturoUpdate() {
    this.turnoReperibilitaService.update(this.turno).subscribe(
      res => {
        this.onSuccess();
      },
      error => {
        this.errorHandler(error)
      }
    )
  }



  private dtoToForm(): void {
   

    this.form.get("id").setValue(this.turno.id);
    this.form.get("collaboratore").setValue(this.turno.collaboratore);
    this.form.get("tipoServizio").setValue(this.turno.servizio);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.turno.insertDate));
    this.form.get("insertUser").setValue(this.turno.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.turno.updateDate));
    this.form.get("updateUser").setValue(this.turno.updateUser);
    //ritardo l' inizializzazione per nuove insert per evitare null pointer sul timepicker
    this.form.get("dataDa").disable();
    this.form.get("orarioInizioTurno").disable();

    if (this.turno.collaboratore && this.turno.collaboratore.id && this.collaboratori) {
      this.collaboratoriSostituti = this.collaboratori.filter(c => c.id != this.turno.collaboratore.id);
    }
    if(this.turno.dataDa){
        this.setDateTimeControl(new Date(this.turno.dataDa), this.form.get("orarioInizioTurno"), this.form.get("dataDa"))
        this.form.get("dataDa").disable()
        this.form.get("orarioInizioTurno").disable()
    }

    if (!this.isNew()) {
      this.setDateTimeControl(new Date(this.turno.dataDa), this.form.get("orarioInizioTurno"), this.form.get("dataDa"))
      this.setDateTimeControl(new Date(this.turno.dataA), this.form.get("orarioFineTurno"), this.form.get("dataA"))

      this.form.get("tipoServizio").disable();

      if (this.isTurnoCorrente) {
        this.form.get("collaboratore").disable();
        this.form.get("collaboratoreSostituto").enable()

      } else if (this.isTurnoFuturo) {
        this.form.get("dataA").disable();
        this.form.get("orarioFineTurno").disable();
      } 
      else { //turno passato
        this.form.get("collaboratore").disable();
        this.form.get("dataA").disable();
        this.form.get("orarioFineTurno").disable();
      }
      if(this.isLast){
        this.form.get("dataA").enable();
        this.form.get("orarioFineTurno").enable();
      }
      
     
    }

    this.form.markAsPristine();
  }

  private formToDto(): void {

    this.turno.collaboratore = this.form.get("collaboratore").value;
    this.turno.servizio = this.form.get("tipoServizio").value;
    this.turno.dataDa = this.formToDateDto(this.form.get("orarioInizioTurno"), this.form.get("dataDa"))
    this.turno.dataA = this.formToDateDto(this.form.get("orarioFineTurno"), this.form.get("dataA"));
    console.log(this.turno)

    if (this.isTurnoCorrente) {
      this.turnoSostituto.idTurno = this.turnoId
      this.turnoSostituto.collaboratoreSostituto = this.form.get("collaboratoreSostituto").value
      this.turnoSostituto.dataSostituzione = this.formToDateDto(this.form.get("orarioInizioTurnoSostituto"), this.form.get("dataDaSostituto"))
    }
  }

  formToDateDto(controlTime: AbstractControl, controlDate: AbstractControl) {
    let tempDate = new Date(controlDate.value)
    let timedate = controlTime.value
    let s = (timedate as string).split(":")
    tempDate.setHours(+s[0])
    tempDate.setMinutes(+s[1])
    return tempDate

  }

  onServizioChange() {
    this.turno.servizio = this.form.get("tipoServizio").value;
    if (this.turno.servizio) {
      this.turnoReperibilitaService.getLastDateforService(this.turno.servizio).subscribe(res => {
        this.turno.dataDa = new Date(res)
        this.dtoToForm()

      },
        error => {
          this.errorHandler(error)
          this.form.reset()//serve per gestire l'errore nel caso in cui manca l'insert d'impianto 
        }
      )

    }


  }


  minOrarioFineTurnoValidator() {
    if (this.isNew()) {
      return (this.isSameDay()) ? this.form.get("orarioInizioTurno").value : this.timePickerMin
    } else {
      return this.timePickerMin
    }
  }
  //da sistemare!
  maxOrarioFineTurnoValidator() {
    if (this.isNew()) {
      return this.timePickerMax
    } else {
      return (this.isSameDay()) ? this.form.get("orarioInizioTurno").value : this.timePickerMax
    }
  }

  isSameDay(): boolean {
    let dateDa = new Date(this.form.get("dataDa").value);
    let dateA = new Date(this.form.get("dataA").value);
    return (dateDa.getFullYear() == dateA.getFullYear()) &&
      (dateDa.getMonth() == dateA.getMonth()) &&
      (dateDa.getDay() == dateA.getDay());
  }
  onChangeDateAndTime($newTime?: string) {
    if (this.isTurnoCorrente) {
      let nuovaOra = ($newTime) ? $newTime : this.form.get("orarioFineTurno").value
      this.form.get("dataDaSostituto").setValue(this.form.get("dataA").value);
      this.form.get("orarioInizioTurnoSostituto").setValue(nuovaOra)
      this.form.get("dataASostituto").setValue(this.dataAorig);
      this.form.get("orarioFineTurnoSostituto").setValue(this.orarioFineTurnoOrig)
    }

  }


  errorHandler(error: any): void {
    console.log(error)
    this.snackBar.open(error.error.reason, 'Chiudi', { duration: 5000 });
  }
  private onSuccess() {
    this.snackBar.open("Operazione avvenuta con successo!", null, { duration: 3000 });
    this.router.navigate(["gestione-turni-servizi-rep"]);
  }

  private getNormalizedDate(date: Date): Date {
    let tempDate = new Date(date);
    tempDate.setHours(0);
    tempDate.setMinutes(0);
    return tempDate
  }

  private getNormalizedHour(date: Date): string {
    let tempdate = new Date(date);
    let hour = tempdate.getHours();
    let minutes = tempdate.getMinutes();
    return hour.toString() + ":" + minutes.toString();
  }


  setDateTimeControl(date: Date, controlTime: AbstractControl, controlDate: AbstractControl) {
    controlDate.setValue(this.getNormalizedDate(date));
    controlTime.setValue(this.getNormalizedHour(date));
  }
  delete(){
    if (this.form.valid && this.isJappTenantAdmin()) {
      this.confirm("Sei sicuro di voler cancellare il turno?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.turnoReperibilitaService.delete(this.turno.id).subscribe(
            (res) => {
             this.onSuccess()
            },
            error => {
              this.errorHandler(error)
            }
          );
        }
      });
    }
  }

  ngOnDestroy(): void {

  }
}
import { ComandaDTO } from 'src/app/shared/dto/gestioneOrdini/comanda';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrdineDTO } from 'src/app/shared/dto/gestioneOrdini/ordine';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';

@Injectable({
  providedIn: 'root'
})
export class OrdineService extends GenericService<OrdineDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/ordine';
  }

  public ordineList(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO): Observable<ResponseQueryByCriteria<OrdineDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<OrdineDTO>>(`${this.url}/ordineListByCollaboratore`, {
      params: params
    });
  }

  pagamentoCompletato(importoDovuto: number, importoPagato: number,) {
    let params = {};
    if (importoDovuto != null) params["direction"] = importoPagato;
    return this.http.put<OrdineDTO>(`${this.url}/ordineUpdate`, {
      params: params
    });;
  }

  public ordineListByCollaboratoreAndDates(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO, dateFrom: Date, dateTo: Date): Observable<ResponseQueryByCriteria<OrdineDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (dateFrom != null) params["dateFrom"] = dateFrom.toISOString();
    if (dateTo != null) params["dateTo"] = dateTo.toISOString();
    return this.http.get<ResponseQueryByCriteria<OrdineDTO>>(`${this.url}/ordineListByCollaboratoreAndDates`, {
      params: params
    });
  }
  public ordineListByComanda(pageNumber: number, pageSize: number, direction: string, sortField: string, comanda: ComandaDTO): Observable<ResponseQueryByCriteria<OrdineDTO>> {
    let params = {};
    if (comanda != null) params["comandaId"] = comanda.id;
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    return this.http.get<ResponseQueryByCriteria<OrdineDTO>>(`${this.url}/ordineListByComanda`, {
      params: params
    });
  }
  public totImporto(comanda: ComandaDTO): Observable<number> {
    let params = {};
    if (comanda != null) params["comandaId"] = comanda.id;
    return this.http.get<number>(`${this.url}/totaleImporto`, {
      params: params
    });
  }
  public totImportoPersonale(collaboratore: CollaboratoreDTO, dateFrom: Date, dateTo: Date): Observable<number> {
    let params = {};
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (dateFrom != null) params["dateFrom"] = dateFrom.toISOString();
    if (dateTo != null) params["dateTo"] = dateTo.toISOString();
    return this.http.get<number>(`${this.url}/totaleImportoPersonale`, {
      params: params
    });
  }
  public totPersone(comanda: ComandaDTO): Observable<number> {
    let params = {};
    if (comanda != null) params["comandaId"] = comanda.id;
    return this.http.get<number>(`${this.url}/totalePersone`, {
      params: params
    });
  }
}
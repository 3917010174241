import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { TurnoReperibilitaDTO } from 'src/app/shared/dto/reperibilità/TurnoReperobilita';
import { NumeroReperibilitaDTO } from 'src/app/shared/dto/reperibilità/NumeroReperibilita';
import { SostituisciTurnoReperibilitaDTO } from 'src/app/shared/dto/reperibilità/SostituisciTurnoReperibilitaDTO';

@Injectable({
  providedIn: 'root'
})
export class TurnoService extends GenericService<TurnoReperibilitaDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/turniReperibilita';

  }

  public turniList(pageNumber: number, pageSize: number, direction: string, sortField1: string, sortField2: string, numeroReperibilita: NumeroReperibilitaDTO): Observable<ResponseQueryByCriteria<TurnoReperibilitaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField1 != null) params["sortField1"] = sortField1;
    if (sortField2 != null) params["sortField2"] = sortField2;
    if (numeroReperibilita != null) params["idNumeroReperibilita"] = numeroReperibilita.id;
    return this.http.get<ResponseQueryByCriteria<TurnoReperibilitaDTO>>(`${this.url}/turniList`, {
      params: params
    });
  }
  public turniListOrderByDayAndHour(pageNumber: number, pageSize: number, direction: string, sortField1: string, sortField2: string, numeroReperibilita: NumeroReperibilitaDTO): Observable<ResponseQueryByCriteria<TurnoReperibilitaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField1 != null) params["sortField1"] = sortField1;
    if (sortField2 != null) params["sortField2"] = sortField2;
    if (numeroReperibilita != null) params["idNumeroReperibilita"] = numeroReperibilita.id;
    return this.http.get<ResponseQueryByCriteria<TurnoReperibilitaDTO>>(`${this.url}/turniListByDaysAndHour`, {
      params: params
    });
  }
 
  public sostituzioneTurno(turniList:SostituisciTurnoReperibilitaDTO){
    return this.http.put(`${this.url}/sostituzione/`,turniList)
  }
    
  public getTurniListSostituzione(pageNumber: number, pageSize: number, direction: string, sortField1: string, sortField2: string,idTurno: number): Observable<ResponseQueryByCriteria<TurnoReperibilitaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField1 != null) params["sortField1"] = sortField1;
    if (sortField2 != null) params["sortField2"] = sortField2;
    if (idTurno != null) params["idTurno"] = idTurno;
    return this.http.get<ResponseQueryByCriteria<TurnoReperibilitaDTO>>(`${this.url}/getTurniListSostituzione`, {
      params: params
    });
  }
  

}
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { EventoService } from '../services/evento/evento.service';
import { PartecipanteEventoService } from '../services/evento/partecipante-evento.service';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { EventoDTO } from '../shared/dto/evento/evento';
import { PartecipanteEventoDTO } from '../shared/dto/evento/partecipante-evento';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-partecipante-evento-detail',
  templateUrl: './partecipante-evento-detail.component.html',
  styleUrls: ['./partecipante-evento-detail.component.scss']
})
export class PartecipanteEventoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  partecipanteEventoId: number;
  partecipanteEvento: PartecipanteEventoDTO;
  collaboratori: CollaboratoreDTO[];
  eventi: EventoDTO[];
  shouldDisable = false;

  eventId: any;
  eventSubscription: Subscription;

  constructor(
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private eventoService: EventoService,
    private partecipanteEventoService: PartecipanteEventoService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );

    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl({ value: '', disabled: !this.isJappTenantAdmin() }, Validators.required),
      evento: new FormControl('', Validators.required),
      spettatore: new FormControl(false, Validators.required),
      numOspiti: new FormControl('0', [Validators.required, Validators.min(0)]),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {

    this.form.enable();
    this.shouldDisable = false;

    if (!this.isJappTenantAdmin())
      this.form.get('collaboratore').disable();

    this.eventId = this.activeRoute.snapshot.queryParamMap.get('id');

    if (this.eventId != null && !isNaN(this.eventId)) {
      this.eventoService.read(Number(this.eventId)).subscribe({
        next: ev => {
          this.form.get('evento').setValue(ev);
        }
      });
    }

    this.eventSubscription = this.form.get('evento').valueChanges.subscribe({
      next: value => {
        this.eventId = value.id;
        this.form.get('numOspiti').setValidators([Validators.required, Validators.max(value.maxEsterniPerPartecipante), Validators.min(0)]);
      }
    });

    this.partecipanteEventoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.partecipanteEventoId != null && this.partecipanteEventoId != 0 && !isNaN(this.partecipanteEventoId)) {
      this.partecipanteEventoRead();
    } else {
      this.partecipanteEventoInit();
    }

    this.collaboratoreService.collaboratoreList(0, 9999, 'ASC', 'nome', '').subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      }
    );

    this.eventoService.eventoListByList(0, 9999, 'ASC', 'id').subscribe(
      (res: ResponseQueryByCriteria<EventoDTO>) => {
        this.eventi = res.content;
      }
    );
  }

  public ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  public partecipanteEventoRead() {
    if (!this.isJappTenantAdmin())
      this.form.get('evento').disable();

    this.partecipanteEventoService.read(this.partecipanteEventoId).
      pipe(
        switchMap((partecipante: PartecipanteEventoDTO) => {
          this.partecipanteEvento = partecipante;
          this.dtoToForm();

          return this.navigatorService.collaboratore;
        })
      ).subscribe({
        next: collaboratore => {
          if (!this.isJappTenantAdmin() && collaboratore.userName != this.partecipanteEvento.collaboratore.userName) {
            this.form.disable();
            this.shouldDisable = true;
          }
        }
      });
  }

  public partecipanteEventoInit() {
    this.partecipanteEvento = new PartecipanteEventoDTO();
    this.partecipanteEvento.spettatore = false;
    this.form.get('spettatore').markAsDirty();

    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get('collaboratore').setValue(res);
      }
    );
  }

  public partecipanteEventoSave() {
    if (this.partecipanteEventoId == 0)
      this.partecipanteEventoCreate()
    else
      this.partecipanteEventoUpdate();
  }

  public partecipanteEventoCreate() {
    this.partecipanteEvento = this.formToDto();

    this.partecipanteEventoService.create(this.partecipanteEvento).subscribe({
      next: response => {
        this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
        this.partecipanteEvento = response;
        this.partecipanteEventoId = this.partecipanteEvento.id;
        this.dtoToForm();
        this.form.markAsPristine();
      },
      error: (error: HttpErrorResponse) => {
        if (error.error.reasonBundleKey == "InvalidPartecipanteEventoException_PARTECIPANTE_ALREADY_EXIST")
          this.form.setErrors({
            alreadySubscribed: true
          });
        this.snackBar.dismiss();
      }
    });
  }

  public partecipanteEventoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere l'evento?")
        .pipe(
          switchMap(result => {
            this.partecipanteEvento = this.formToDto();
            return this.partecipanteEventoService.update(this.partecipanteEvento);
          })
        ).subscribe({
          next: (res) => {
            this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
            this.partecipanteEventoRead();
            this.form.markAsPristine();
          }
        });
    }
  }

  public partecipanteEventoDelete() {
    this.confirm("Vuoi davvero cancellare questo evento?")
      .pipe(
        switchMap(confirm => {
          return this.partecipanteEventoService.delete(this.partecipanteEventoId);
        })
      ).subscribe({
        next: response => {
          this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
          this.backConfirmUrl('/partecipante-evento');
        }
      });
  }

  public formToDto(): PartecipanteEventoDTO {
    let dto = new PartecipanteEventoDTO();
    dto.id = this.form.get('id').value;
    dto.collaboratore = this.form.get('collaboratore').value;
    dto.evento = this.form.get('evento').value;
    dto.spettatore = this.form.get('spettatore').value;
    dto.numOspiti = this.form.get('numOspiti').value;

    return dto
  }

  public dtoToForm() {
    this.form.get("id").setValue(this.partecipanteEventoId);
    this.form.get("collaboratore").setValue(this.partecipanteEvento.collaboratore);
    this.form.get("evento").setValue(this.partecipanteEvento.evento);
    this.form.get("spettatore").setValue(this.partecipanteEvento.spettatore);
    this.form.get("numOspiti").setValue(this.partecipanteEvento.numOspiti);
    this.form.get("deleteDate").setValue(this.timestampFormatPipe.transform(this.partecipanteEvento.deleteDate));
    this.form.get("deleteUser").setValue(this.partecipanteEvento.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.partecipanteEvento.insertDate));
    this.form.get("insertUser").setValue(this.partecipanteEvento.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.partecipanteEvento.updateDate));
    this.form.get("updateUser").setValue(this.partecipanteEvento.updateUser);
    this.form.get("optLock").setValue(this.partecipanteEvento.optLock);
    this.form.markAsPristine();
  }

  backConfirmUrl(url: string) {
    const M: string = "Proseguendo, tutte le modifiche andranno perse. Sei sicuro di voler tornare alla pagina precedente?";
    if (this.form.dirty) {
      this.confirm(M).subscribe(result => {
        if (result) {
          this.router.navigateByUrl(url);
        }
      });
    }
    else {
      this.router.navigateByUrl(url);
    }
  }

}
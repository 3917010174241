import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { DispositivoDTO } from 'src/app/shared/dto/dispositivo/dispositivo';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';

@Injectable({
  providedIn: 'root'
})
export class DispositivoService extends GenericService<DispositivoDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/dispositivo';
  }

  public dispositivoList(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO): Observable<ResponseQueryByCriteria<DispositivoDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<DispositivoDTO>>(`${this.url}/dispositivoListByCollaboratore`, {
      params: params
    });
  }

  public dispositivoListAll(pageNumber: number, pageSize: number, direction: string, sortField: string): Observable<ResponseQueryByCriteria<DispositivoDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    return this.http.get<ResponseQueryByCriteria<DispositivoDTO>>(`${this.url}/dispositivoListAll`, {
      params: params
    });
  }
}

import { TipoTurnoAMDTO } from './../../shared/dto/turno-am/tipo-turno-am';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TurnoAMDTO } from './../../shared/dto/turno-am/turno-am';
import { Injectable } from '@angular/core';
import { GenericService } from 'src/app/shared/GenericService';
import { CollaboratoreAMDTO } from 'src/app/shared/dto/turno-am/collaboratore-am';

@Injectable({
  providedIn: 'root'
})
export class TurnoAmService extends GenericService<TurnoAMDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/turnoAM'
  }

  public turnoAMListByList(
    collaboratoreAM?: CollaboratoreAMDTO,
    tipoTurnoAM?: TipoTurnoAMDTO,
    dataDa?: Date,
    dataA?: Date,
    pageNumber?: number,
    pageSize?: number,
    direction?: string,
    sortField?: string
  ): Observable<ResponseQueryByCriteria<TurnoAMDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratoreAM != null) params["collaboratore"] = collaboratoreAM.collaboratore.id;
    if (tipoTurnoAM != null) params["tipoTurnoAM"] = tipoTurnoAM.id;
    if (dataA != null) params["dataA"] = new Date(dataA.getTime() - (dataA.getTimezoneOffset() * 60000)).toISOString();
    if (dataDa != null) params["dataDa"] = new Date(dataDa.getTime() - (dataDa.getTimezoneOffset() * 60000)).toISOString();

    return this.http.get<ResponseQueryByCriteria<TurnoAMDTO>>(`${this.url}/turnoListByList`, {
      params: params
    });
  }

  public turnoAMSwap(
    idTurno1: number,
    idTurno2: number,
  ): Observable<void> {
    let params = {};
    params["turno1"] = idTurno1;
    params["turno2"] = idTurno2;
    return this.http.put<void>(`${this.url}/swapTurniAM`, {}, {
      params: params
    });
  }

  public turnoAMCopyWeek(
    dataDa: Date,
    dataA: Date,
    overwritable: boolean
  ): Observable<void> {
    let params = {};
    params["dateFrom"] = new Date(dataDa.getTime() - (dataDa.getTimezoneOffset() * 60000)).toISOString();
    params["dateTo"] = new Date(dataA.getTime() - (dataA.getTimezoneOffset() * 60000)).toISOString();
    params["overwrite"] = overwritable;
    console.log(params);
    return this.http.post<void>(`${this.url}/copyWeek`, {}, {
      params: params
    });
  }

}

import { SedeDTO } from './../sede/sede';
import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';

export class SalettaDTO extends LogicalDeleteDTO {
    
    id:number;
    
    tenant:TenantDTO;
    
    sede: SedeDTO;

    descrizione:String;
}
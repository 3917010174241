<mat-card id="card" *ngIf="isJappTenantAdmin()" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione cliente</h2>
        <div fxLayout="column" class="responsive-mat-form">
          <mat-form-field>
            <input matInput [(ngModel)]="parameters.filter" placeholder="Filter"/>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/cliente/detail/0']">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let cliente"> {{cliente.id}} </td>
            </ng-container>
            
            <!-- Description Column -->
            <ng-container matColumnDef="descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Description </th>
              <td mat-cell *matCellDef="let cliente"> {{cliente.descrizione}} </td>
            </ng-container>
        
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let cliente">
                  <button mat-mini-fab color="primary" [routerLink]="['/cliente/detail/', cliente.id]"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-actions>
                <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'clienti'})">Excel</button>
                <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'clienti'})">Csv</button>
                <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'clienti'})">Json</button>
            </mat-card-actions>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
          </div>
      </div>
    </mat-card-content>
  </mat-card>
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { LocationDTO } from './location';

export class PresenzaDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    location: LocationDTO;
    dataPresenza: Date;
    numeroDiOre: number;
    oreCensite: number;
    greenPassCheck: Boolean;
    greenPassContent: string;
}
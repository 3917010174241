
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli orari lavorativi</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <form [formGroup]="form" fxLayout="column">
                <!-- Id orario field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Orario Lavorativo ID" readonly="true" />
                </mat-form-field>

                <!-- Collaboratore field -->
                <mat-form-field fxLayout="column">
                    <mat-label>Collaboratore</mat-label>
                    <mat-select formControlName="collaboratore" [compareWith]="compareDTO"
                    [disabled]="!isJappTenantAdmin()">
                        <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                            {{collaboratore.nome}} {{collaboratore.cognome}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!--Giorno field-->
                <mat-form-field fxLayout="column">
                    <mat-label>Giorno</mat-label>
                    <mat-select formControlName="giorno" [value]="orarioLavorativo.giorno" [disabled]="!isJappTenantAdmin()">
                        <mat-option *ngFor="let giorno of giorni" [value]="giorno">{{translateDayToIta(giorno)}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('giorno').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                
                <!--Da Ore field-->
                <mat-form-field fxFlex="25%" fxFlex.md="25%" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly [min]="'00:00'" [max]="'23:00'" [ngxTimepicker]="toggleTimepickerA" [format]="24"
                      formControlName="daOre" placeholder="Inizio">
                    <ngx-material-timepicker #toggleTimepickerA [preventOverlayClick]="false" hoursOnly="true" >
                    </ngx-material-timepicker>
                    <mat-error *ngIf="form.get('daOre').errors?.required">
                      Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                
                <!--A Ore field-->
                <mat-form-field fxFlex="25%" fxFlex.md="25%" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly [min]="'00:00'" [max]="'23:00'" [ngxTimepicker]="toggleTimepickerB" [format]="24"
                      formControlName="aOre" placeholder="Fine">
                    <ngx-material-timepicker #toggleTimepickerB [preventOverlayClick]="false" hoursOnly="true" >
                    </ngx-material-timepicker>
                    <mat-error *ngIf="form.get('aOre').errors?.required">
                      Campo obbligatorio
                    </mat-error>
                </mat-form-field>

            </div>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                    <mat-panel-title>
                        Dettagli operazione
                    </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                    <!-- InsertDate field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                    </mat-form-field>
        
                    <!-- InsertUser field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                    </mat-form-field>   
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>
                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        
        <mat-card-actions style="display: flex;">
            <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
            <button mat-raised-button (click)=" orarioLavorativoSave()" [disabled]="!form.dirty || form.invalid"
              *ngIf="orarioLavorativo?.deleteDate==null">Save</button>
          <button mat-raised-button (click)="orarioLavorativoDelete()" 
          [disabled]="orarioLavorativo?.id==null" 
          *ngIf="orarioLavorativo?.deleteDate==null && isJappTenantAdmin()">Delete</button>
          </mat-card-actions>
    </mat-card-content>
  </mat-card>


import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';

export class AssenzaDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    dataDa: Date;
    dataA: Date;
    statoRichiesta: string;
    richiedente: CollaboratoreDTO;
    approvatore: CollaboratoreDTO;
    tipoRichiesta: string;
    ore : number;
    descrizione: string;
    orarioInizioPermesso: number;
}
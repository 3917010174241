import { StoriaChiaviDTO } from '../../shared/dto/gestioneChiavi/storiaChiavi';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';

@Injectable({
  providedIn: 'root'
})
export class StoriaChiaviService extends GenericService<StoriaChiaviDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/storiaChiavi';
  }

  public storiaChiaviList(pageNumber: number, pageSize: number, direction: string, sortField: string, descrizioneChiave: String, userCollaboratore: String, dataDa: Date, dataA: Date): Observable<ResponseQueryByCriteria<StoriaChiaviDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (descrizioneChiave != null) params["descrizioneChiave"] = descrizioneChiave;
    if (userCollaboratore != null) params["userCollaboratore"] = userCollaboratore;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();

    return this.http.get<ResponseQueryByCriteria<StoriaChiaviDTO>>(`${this.url}/StoriaChiaviList`, {
      params: params
    });

  }

  public hasUserKeys(idCollaboratore: number): Observable<Date> {
    let params = {};
    if (idCollaboratore != null) params["idCollaboratore"] = idCollaboratore;
    
    return this.http.get<Date>(`${this.url}/hasUserKeys`, {
      params: params
    });
  }

  public selectByChiavi( idCollaboratore: number, idChiavi: number): Observable<StoriaChiaviDTO> {
    let params = {};
    if (idChiavi != null) params["idChiavi"] = idChiavi;
    if (idCollaboratore != null) params["idCollaboratore"] = idCollaboratore;
    
    return this.http.get<StoriaChiaviDTO>(`${this.url}/selectByChiavi`, {
      params: params
    });
  }
}
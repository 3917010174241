import { Component, OnInit } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { AssenzaService } from "../services/assenza/assenza.service";
import { ComponentCacheService } from "../services/component-cache.service";
import { CollaboratoreService } from "../services/domain/collaboratore.service";
import { NavigatorService } from "../services/navigator.service";
import { PresenzaService } from "../services/presenze/presenza.service";
import { AssenzaDTO } from "../shared/dto/assenza/assenza";
import { CollaboratoreDTO } from "../shared/dto/domain/collaboratore";
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { GenericListComponent } from "../shared/GenericListCoumponent";
import { MalattiaService } from '../services/malattia/malattia.service';
import { MalattiaDTO } from '../shared/dto/malattia/malattia';
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { PresenzaDTO } from "../shared/dto/presenze/presenza";
import { FormControl } from "@angular/forms";


@Component({
  selector: "visualizza-presenze",
  templateUrl: "./visualizza-presenze.component.html",
  styleUrls: ["./visualizza-presenze.component.scss"],
})

export class VisualizzaPresenzeComponent<T> extends GenericListComponent implements OnInit {
  displayedColumns: any;
  observablePresenze$: Observable<ResponseQueryByCriteria<PresenzaDTO>>;
  observableCollaboratori$: Observable<ResponseQueryByCriteria<CollaboratoreDTO>>;
  observableAssenze$: Observable<AssenzaDTO[]>;
  observableMalattia$: Observable<MalattiaDTO[]>;
  collaboratori: CollaboratoreDTO[];
  assenze: AssenzaDTO[] = null;
  collaboratoreSelect: CollaboratoreDTO;
  presenze: PresenzaDTO[];
  hiddenResult: boolean = true;
  isPresente: boolean = false;
  private collaboratoreTimer: any;
  filter: FormControl;

  private datiPerLista = new ReplaySubject<CollaboratoreFormattato[]>();
  observableListaCompleta$: Observable<CollaboratoreFormattato[]> = this.datiPerLista.asObservable();
  private mappa = new Map<number, CollaboratoreFormattato>([]);
 

  ngOnInit(): void {
      super.ngOnInit();
      this.list();
  }

  constructor(
    private presenzaService: PresenzaService,
    private collaboratoreService: CollaboratoreService,
    private assenzaService: AssenzaService,
    private malattiaService: MalattiaService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe,
    );
    this.displayedColumns = ['nomeCognome','stato'];
    this.parameters = {
      showList: false,
      sortField: "id",
      sortDirection: 'desc',
      pageNumber: 0,
      pageSize: 50,
      length: 0,
    };
  }
  
  list() {
    //serve per popolare l'array con la lista delle presenze di oggi
    let oggi = new Date();
    oggi.setHours(0, 0, 0, 0);
    this.observablePresenze$ = this.presenzaService.searchByGiorno(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.direction,
      this.parameters.sortField,
      oggi
    );
    //serve per popolare l'array con la lista di tutti i collaboratori
    this.observableCollaboratori$ = this.collaboratoreService.collaboratoreList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.direction,
      this.parameters.sortField,
      '');
    //serve per popolare l'array con la lista di tutti i collaboratori in malattia
    this.observableMalattia$ = this.malattiaService.searchAllByday(oggi);
    //serve per popolare l'array con la lista di collaboratori con assenza giustificata
    this.observableAssenze$ = this.assenzaService.searchAllByday(oggi);

    combineLatest(
      [this.observableCollaboratori$, this.observablePresenze$, this.observableMalattia$,
      this.observableAssenze$]
    ).subscribe(res => {
      this.filtraCollaboratori(res[0].content, res[1].content, res[2], res[3]);
      this.datiPerLista.next(Array.from(this.mappa.values()));
    })
  }
  /*
    Serve per popolare la mappa con i vari collaboratori e impostarne uno stato 
    popolando i vari array che detengono.(arrPresenze/Malattie/Assenze).
  */
  filtraCollaboratori(
    arrCollaboratori: CollaboratoreDTO[],
    arrPresenze: PresenzaDTO[],
    arrMalattie: MalattiaDTO[],
    arrAssenze: AssenzaDTO[]): void 
    {
    arrPresenze.forEach((presenza: PresenzaDTO) => {
      if (this.mappa.get(presenza.collaboratore.id) == null) {
        this.mappa.set(presenza.collaboratore.id, {
          collaboratore: presenza.collaboratore,
          listaAsssenze: [],
          listaMalattie: [],
          listaPresenze: [presenza],
          isNotFound: false
        });
      } else {
        this.mappa.get(presenza.collaboratore.id).listaPresenze.push(presenza);
      }
    });

    arrMalattie.forEach((malattia: MalattiaDTO) => {
      if (this.mappa.get(malattia.collaboratore.id) == null) {
        this.mappa.set(malattia.collaboratore.id, {
          collaboratore: malattia.collaboratore,
          listaAsssenze: [],
          listaMalattie: [malattia],
          listaPresenze: [],
          isNotFound: false
        });
      } else {
        this.mappa.get(malattia.collaboratore.id).listaMalattie.push(malattia);
      }
    });

    arrAssenze.forEach((assenza: AssenzaDTO) => {
      if (this.mappa.get(assenza.richiedente.id) == null) {
        this.mappa.set(assenza.richiedente.id, {
          collaboratore: assenza.richiedente,
          listaAsssenze: [assenza],
          listaMalattie: [],
          listaPresenze: [],
          isNotFound: false
        });
      } else {
        this.mappa.get(assenza.richiedente.id).listaAsssenze.push(assenza);
      }
    });

    if (this.mappa.size < arrCollaboratori.length) {
      arrCollaboratori = arrCollaboratori.filter((collaboratore: CollaboratoreDTO) => {
        return (this.mappa.get(collaboratore.id) == null)
      });

    arrCollaboratori.forEach((collaboratoreNotFound: CollaboratoreDTO) => {
        this.mappa.set(collaboratoreNotFound.id, {
          collaboratore: collaboratoreNotFound,
          listaAsssenze: [],
          listaMalattie: [],
          listaPresenze: [],
          isNotFound: true
        });
      });
    }
   }

  hasAssenze( collaboratore: CollaboratoreFormattato): boolean{
    return (collaboratore.listaAsssenze.length>0);
  }

  hasMalattie( collaboratore: CollaboratoreFormattato): boolean{
    return (collaboratore.listaMalattie.length>0);
  }

  hasPresenze( collaboratore: CollaboratoreFormattato): boolean{
    return (collaboratore.listaPresenze.length>0);
  }

  isNotFound(collaboratore: CollaboratoreFormattato):boolean{
    return collaboratore.isNotFound;
  }

  /*  
    creo una copia dell'interesa lista formattata di collaboratori dalla mappa alla variabile
    locale listaCompleta dopo filtro questa copia mantenendo solo gli elementi presenti in 
    listaCompleta e filteredCollaboratore per poi passare tramite .next() la lista filtrata
    non posso passare direttamente l'array filteredCollaboratore poiché è non di tipo 
    collaboratoreFormattato.
  */
  filtraCompletaByFiltered(filteredCollaboratore: CollaboratoreDTO[]) {
    let listaCompleta = Array.from(this.mappa.values());
    let flag: boolean;
    listaCompleta = listaCompleta.filter( elemOfCompleta =>{
      flag=false;
      filteredCollaboratore.forEach( elemOfFiltered =>{
        if(elemOfCompleta.collaboratore.id === elemOfFiltered.id)
          flag = true;
      })
      return flag;
    })
    this.datiPerLista.next(listaCompleta);
  }

  /*
    serve per ottenre una lista dei collaboratori che hanno la stringa inviata tramite input
    dentro al Nome o Cognome o Email. Quindi restituisce una lista filtrata di collaboratori.
  */
  collaboratoreChanged(newValue:string, fromUser:boolean) {
    if (fromUser) {
      clearTimeout(this.collaboratoreTimer);
      this.collaboratoreTimer = setTimeout(() => {
        this.collaboratoreChanged(newValue, false);
      }, 500);
    } else {
      this.collaboratoreService.collaboratoreList(
        0,
      9999,
      'ASC',
      'nome',
      newValue)
      .subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>)=> {
            this.filtraCompletaByFiltered(res.content);            
        });
      }
  }
  
}
/*
  CollaboratoreFormattato serve per formattare tutti i collaboratori in modo da poter capire
  facilmente il loro stato e poter mostrare tutti gli eventuali stati in caso ipotetico di 
  presenza,permesso/malattia nello stesso giorno.
*/
interface CollaboratoreFormattato {
  collaboratore: CollaboratoreDTO;
  listaMalattie: MalattiaDTO[],
  listaAsssenze: AssenzaDTO[],
  listaPresenze: PresenzaDTO[],
  isNotFound: boolean
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione assenze</h2>
    <p>Imposta i filtri di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">
      <!-- Richiedente field -->

      <mat-form-field>
        <mat-select name="richiedente" [(ngModel)]="parameters.richiedente" [compareWith]='compareDTO'
          [disabled]="!isJappTenantAdmin()" placeholder="Scegli il richiedente"
          (selectionChange)="this.parameters.collaboratoreChanged = true">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{collaboratore.nome}} {{collaboratore.cognome}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- TipoRichiesta field -->
      <mat-form-field>
        <mat-select name="tipoRichiesta" [(ngModel)]="parameters.tipoRichiesta"
          placeholder="Scegli il tipo di richiesta">
          <mat-option *ngFor="let selectedValue of tipoRichiestaValues" [value]="selectedValue.value">
            {{selectedValue.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Responsive -->
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
        <!-- DataDa field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
            [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- DataA field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
          <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
            [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataA=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Stato field -->
      <mat-form-field>
        <mat-select name="stato" [(ngModel)]="parameters.stato" placeholder="Scegli lo stato della richiesta">
          <mat-option *ngFor="let option of statoRichiestaValues" [value]="option.value">
            {{option.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/registra-assenza/detail/0']">New</button>
    </mat-card-actions>
    <br />
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()" matSort
        (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Assenza </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let assenza"> {{assenza.id}} </td>
        </ng-container>

        <!-- dataDa Column -->
        <ng-container matColumnDef="dataDa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Da </th>
          <td mat-cell *matCellDef="let assenza"> {{assenza.dataDa | date :'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- dataA Column -->
        <ng-container matColumnDef="dataA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data A </th>
          <td mat-cell *matCellDef="let assenza"> {{assenza.dataA | date :'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- statoRichiesta Column -->
        <ng-container matColumnDef="statoRichiesta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
          <td mat-cell *matCellDef="let assenza">
            <div fxHide fxShow.gt-md>{{ trascodificaStatoRichiesta(assenza.statoRichiesta)}} </div>
            <div fxShow fxHide.gt-md fxLayoutAlign="center center">
              <mat-icon *ngIf="assenza.statoRichiesta=='APPROVATO'">check_box</mat-icon>
              <mat-icon *ngIf="assenza.statoRichiesta=='DA_APPROVARE'">check_box_outline_blank</mat-icon>
              <mat-icon *ngIf="assenza.statoRichiesta=='RIFIUTATO'">not_interested</mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- Richiedente Column -->
        <ng-container matColumnDef="richiedente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Richiedente </th>
          <td mat-cell *matCellDef="let assenza" [hidden]="!isJappTenantAdmin()"> {{assenza.richiedente?.nome}}
            {{assenza.richiedente?.cognome}} </td>
        </ng-container>

        <!-- Approvatore Column -->
        <ng-container matColumnDef="approvatore">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Approvatore </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let assenza"> {{assenza.approvatore?.nome}}
            {{assenza.approvatore?.cognome}} </td>
        </ng-container>

        <!-- tipoRichiesta Column -->
        <ng-container matColumnDef="tipoRichiesta">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Richiesta </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let assenza"> {{assenza.tipoRichiesta}} </td>
        </ng-container>

        <!-- Ore Column -->
        <ng-container matColumnDef="ore">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Numero Di Ore </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let assenza"> {{assenza.ore}}</td>
        </ng-container>

        <!-- Orario Inizio Permesso Column -->
        <ng-container matColumnDef="orarioInizioPermesso">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Orario Inizio Permesso </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let assenza">
            {{ assenza.tipoRichiesta == 'FERIE' ? '-' : (assenza.orarioInizioPermesso / 60 | number: '2.0-0') + (':') + (assenza.orarioInizioPermesso % 60 | number: '2.0-0')}}
          </td>
        </ng-container>

        <!-- descrizione Column -->
        <ng-container matColumnDef="descrizione">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let assenza"> {{assenza.descrizione}}</td>
        </ng-container>

        <!-- Info Column -->
        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let assenza">
            <button mat-mini-fab color="primary" [routerLink]="['/registra-assenza/detail/' , assenza.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'assenze'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('csv', {fileName:'assenze'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('json', {fileName:'assenze'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettaglio risorsa aziendale</h2>
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Risorsa Aziendale Id" readonly="true" />
          </mat-form-field>
  
          <!-- Gruppo field -->
          <mat-form-field fxlayout="column">
            <input type="text" matInput formControlName="gruppo" (input)="gruppoChanged($event.target.value, true)" [matAutocomplete]="gruppoAutocomplete" placeholder="Gruppo Risorsa" />
            
            <mat-autocomplete autoActiveFirstOption #gruppoAutocomplete="matAutocomplete">
              <mat-option *ngFor="let risorsaGruppo of risorseGruppi" [value]="risorsaGruppo" >
                {{risorsaGruppo}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('gruppo').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

           <!-- URL field -->
           <mat-form-field fxlayout="column">
            <input matInput formControlName="url" placeholder="URL Risorsa" />
            <mat-error *ngIf="form.get('url').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- Icona ( in material-icons è il nome dell'icona in owesome font è il fontIcon) field -->
          <mat-form-field fxlayout="column">
            <input matInput formControlName="icona" placeholder="Icona Risorsa" />
            <mat-error *ngIf="form.get('url').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- FontSet (fontSet da specificare per altri packfont) field -->
          <mat-form-field fxlayout="column">
            <input matInput formControlName="fontset" placeholder="Fontset Icona (opzionale)" />
          </mat-form-field>

          <!-- Descrizione field -->
          <mat-form-field fxlayout="column">
            <input matInput formControlName="descrizione" placeholder="Descrizione Risorsa" />
          </mat-form-field>

          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>
  
              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>
  
              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/risorsa-aziendale')" color="primary">Back</button>
        <button mat-raised-button (click)="risorsaAziendaleSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="risorsa?.deleteDate==null">Save</button>
        <button mat-raised-button (click)="risorsaAziendaleDelete()" [disabled]="risorsa?.id==null"
          *ngIf="risorsa?.deleteDate==null">Delete</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>


import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { AssenzaDTO } from '../../shared/dto/assenza/assenza';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';


@Injectable({
  providedIn: 'root'
})
export class AssenzaService extends GenericService<AssenzaDTO, number> {


  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/assenza';
  }

  public assenzaListByList(pageNumber: number, pageSize: number, direction: string, sortField: string, richiedente?: CollaboratoreDTO, stato?: string, dataDa?: Date, dataA?: Date, tipoRichiesta?: string): Observable<ResponseQueryByCriteria<AssenzaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (richiedente != null) params["richiedente"] = richiedente.id;
    if (stato != null) params["stato"] = stato;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    if (tipoRichiesta != null) params["tipoRichiesta"] = tipoRichiesta;
    return this.http.get<ResponseQueryByCriteria<AssenzaDTO>>(`${this.url}/assenzaListByList`, {
      params: params
    });
  }

  public assenzaListByMese(collaboratore: CollaboratoreDTO, dataDa: Date, dataA: Date): Observable<AssenzaDTO[]> {
    let params = {};
    if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
    if (dataDa != null) params["dataDa"] = new Date(dataDa.getTime() - (dataDa.getTimezoneOffset() * 60000)).toISOString();
    if (dataA != null) params["dataA"] = new Date(dataA.getTime() - (dataA.getTimezoneOffset() * 60000)).toISOString();
    return this.http.get<AssenzaDTO[]>(`${this.url}/assenzaByMese`, {
      params: params
    });
  }

    //FUNZIONALITÀ PER IL CALENDARIO DELL'AMMINISTRAZIONE
    public searchAllByday(data : Date): Observable<AssenzaDTO[]> {
      let params = {};
      if (data != null) params["data"] = data.toISOString();
      return this.http.get<AssenzaDTO[]>(`${this.url}/searchAllByday`, {
        params: params
      });
    }
}

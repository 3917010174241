<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettaglio partecipante</h2>

    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">

        <mat-form-field>
          <input matInput name="partecipanteId" formControlName="id" placeholder="Partecipante ID" readonly="true" />
        </mat-form-field>

        <mat-form-field>
          <mat-select name="collaboratore" [compareWith]="compareDTO" placeholder="Seleziona collaboratore"
            formControlName="collaboratore">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{ collaboratore.nome }} {{ collaboratore.cognome }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('collaboratore').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-select name="evento" [compareWith]="compareDTO" placeholder="Seleziona evento" formControlName="evento">
            <mat-option *ngFor="let evento of eventi" [value]="evento">
              {{ evento.descrizione }} {{ evento.dataOraInizio | date: 'yyyy/MM/dd' }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('evento').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="number" name="numOspiti" formControlName="numOspiti" placeholder="Numero Ospiti" />
          <mat-error *ngIf="form.get('numOspiti').errors?.required">
            Campo obbligatorio
          </mat-error>
          <mat-error *ngIf="form.get('numOspiti').errors?.max">
            Troppi ospiti, max {{ form.get('numOspiti').errors?.max.max }}
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="spettatore">Spettatore</mat-checkbox>

      </form>
    </div>

    <mat-card-actions>
      <button mat-raised-button (click)="partecipanteEventoSave()" id="save-button" name="Search" color="primary"
        [disabled]="form.invalid || !form.dirty">Save</button>
      <button mat-raised-button (click)="partecipanteEventoDelete()" id="delete-button" name="Delete"
        [disabled]="partecipanteEventoId == 0 || shouldDisable">Delete</button>
      <button mat-raised-button [queryParams]="{'id': eventId}" [routerLink]="['/partecipante-evento']" id="back-button"
        name="Back">Back</button>
    </mat-card-actions>

    <mat-error *ngIf="form.errors?.alreadySubscribed">
      Iscrizione esiste gia'
    </mat-error>

  </mat-card-content>
</mat-card>
import { Component, OnInit, ViewChild } from '@angular/core';
import { PresenzaDTO } from '../shared/dto/presenze/presenza';
import { NavigatorService } from '../services/navigator.service';
import { TenantDTO } from '../shared/dto/domain/tenant';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { LocationDTO } from '../shared/dto/presenze/location';
import { LocationService } from '../services/presenze/location.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PresenzaService } from '../services/presenze/presenza.service';
import { QrcodeService } from '../services/qrcode.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-registra-presenza',
  templateUrl: './registra-presenza.component.html',
  styleUrls: ['./registra-presenza.component.scss']
})
export class RegistraPresenzaComponent implements OnInit {

  presenza : PresenzaDTO;

  locations : LocationDTO[];

  qrcode: boolean = true;

  manualSwitchEnabled: boolean = false;

  audio = new Audio();

  audioError = new Audio();
  
  barcodeReading: boolean;

  availableDevices: MediaDeviceInfo[];

  selectedDevice: MediaDeviceInfo;

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  constructor(private navigatorService : NavigatorService, 
    private locationService : LocationService, 
    private presenzaService : PresenzaService,
    private qrcodeService : QrcodeService,
    private breakpointObserver: BreakpointObserver,
    private snackBar: MatSnackBar, 
    private router : Router ) {
    this.presenza = new PresenzaDTO(); 
    this.presenza.dataPresenza = new Date();
    this.presenza.numeroDiOre = 8;
    this.audio.src = "../../assets/beep.wav";
    this.audio.load();
    this.audioError.src = "../../assets/Error.wav";
    this.audioError.load();
    this.barcodeReading = false;
    this.selectedDevice = null;
  }

  ngOnInit() {
    this.navigatorService.tenant.subscribe(
      (res: TenantDTO) => {
        this.presenza.tenant = res;
      }
    );
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.presenza.collaboratore = res;
      }
    );
    this.locationService.locationList(
      0, 
      9999, 
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<LocationDTO>) => {
          console.log("response : " + res);
          this.locations = res.content;
        }
      );
    this.barcodeReading = false;
  }

  qrcodeReaded(qrcode : string) {
    console.log("qrcode readed : " + qrcode);

    if (this.barcodeReading) return;
    this.barcodeReading=true;

    if (!qrcode.startsWith("location")) {
      this.audioError.play();
      this.snackBar.open("Attenzione! QR Code non valido.", null, { duration: 3000 });
      this.barcodeReading=false;
      return;
    }
    this.qrcodeService.qrcodeRead(qrcode).subscribe(
      (res: LocationDTO) => {
        console.log("response : " + res);
        this.audio.play();
        this.presenza.location = res;
        this.presenzaSave();
      }, (err: HttpErrorResponse) => {
        this.audioError.play();
        this.barcodeReading=false;
        this.snackBar.open("Attenzione! QR Code non valido.", null, { duration: 3000 });
      }
    );
  }

  scannerStarted(event?: any) {
    console.log("scannerStarted()");
    this.manualSwitchEnabled = true;
  }

  scannerCamerasFound(devices : MediaDeviceInfo[]) {
    navigator.mediaDevices.enumerateDevices()
    .then( (mediaDevices) => {
      this.availableDevices = [];
      console.log("Lista dei device trovati: ");
      mediaDevices.forEach( (mediaDevice : MediaDeviceInfo) => {
        if(mediaDevice.kind == 'videoinput'){
          console.log(mediaDevice)
          this.availableDevices.push(mediaDevice);
        }
      })
    });
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  onDeviceChange(value : MediaDeviceInfo){
    console.log("Device cambiato in: " + value.label);
    this.selectedDevice = value;
    this.scanner.restart(); 
  }

  presenzaSave() {
    this.presenzaService.create(this.presenza).subscribe(
      (res: PresenzaDTO) => {
        console.log("response : " + res);
        this.snackBar.open('Presenza registrata : ' + this.presenza.location.descrizione, null, {
          duration: 3000
        });
        this.router.navigate(["/"]);
      }, (err: HttpErrorResponse) => {
        this.barcodeReading=false;
        if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPresenzaException_PRESENZA_TOO_MANY_HOURS") {
          this.snackBar.open("Attenzione! Non puoi inserire più di 8 ore di presenza al giorno.", null, { duration: 4000 });
        }
      }
    );
  }

  scanQrcode() {
    this.qrcode = true;
    this.scanner.restart();
  }

  enterManually() {
    this.qrcode = false;
    this.manualSwitchEnabled = false;
    this.scanner.reset();
  }

}

import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';

export class ComunicazioneDTO extends LogicalDeleteDTO{
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    url: string;
    titolo: string;
    nascosta: boolean;
    descrizione: string;
    inizioValid: Date;
    fineValid: Date;
}
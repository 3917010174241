import { StoriaChiaviDTO } from './../shared/dto/gestioneChiavi/storiaChiavi';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { StoriaChiaviService } from '../services/gestione-chiavi/storia-chiavi.service';
import { ChiaviDTO } from '../shared/dto/gestioneChiavi/chiavi';
import { ChiaviService } from '../services/gestione-chiavi/chiavi.service';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-gestione-chiavi',
  templateUrl: './gestione-chiavi.component.html',
  styleUrls: ['./gestione-chiavi.component.scss']
})

export class GestioneChiaviComponent extends GenericListComponent implements OnInit, OnDestroy {

  storiaChiavi: StoriaChiaviDTO;

  constructor(
    private storiaChiaviService: StoriaChiaviService,
    private chiaviService: ChiaviService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      hasKeys: false,
      userCollaboratore: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() { }

  hasUserKeys() {
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.storiaChiaviService.hasUserKeys(res.id).
          subscribe((res2: Date) => {
            if (res2 == null) {
              this.parameters.hasKeys = true;
            }
            else {
              this.parameters.hasKeys = false;
            }
            this.gestioneChiavi();
          }
          );

      }
    );
  }

  gestioneChiavi() {
    if (this.parameters.hasKeys == false) {
      this.chiaviService.chiaviDisponibili(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        this.parameters.sortDirection,
        this.parameters.sortField).subscribe(
          (res: ResponseQueryByCriteria<ChiaviDTO>) => {
            
            this.parameters.dataSource = res.content;
            this.parameters.pageNumber = res.pageNumber;
            this.parameters.length = res.totalElements;
          }
        )
    }
    else {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          
          this.chiaviService.chiaviOccupateByUser(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField,
            res.id)
            .subscribe(
              (resChiavi: ResponseQueryByCriteria<ChiaviDTO>) => {
                this.parameters.dataSource = resChiavi.content;
                this.parameters.pageNumber = resChiavi.pageNumber;
                this.parameters.length = resChiavi.totalElements;
              }
            )
        }
      );
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.hasUserKeys();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Calendario amministrazione</h2>
        <div *ngIf="isJappTenantAdmin()">
        </div>
        <div>
            <mwl-utils-calendar-header [calendarioPresenze]="false" [weekStartsOn]="weekStartsOn"
                [(view)]="this.parameters.view" [(viewDate)]="this.parameters.viewDate">
            </mwl-utils-calendar-header>
        </div>
        <ng-template #customHeaderTemplate let-day="day" let-locale="locale">
            <div role="row" class="cal-cell-row cal-header">
                <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> LUN </div>
                <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> MAR </div>
                <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> MER </div>
                <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> GIO </div>
                <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> VEN </div>
                <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> SAB </div>
                <div tabindex="0" role="columnheader" class="cal-cell custom-cell-class"> DOM </div>
            </div>
        </ng-template>
        <ng-template #customTemplate let-day="day" let-openDay="openDay" let-locale="locale"
            let-eventClicked="eventClicked" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay">
            <div class="cal-cell-top custom-cell-top" fxFlex="row" fxLayoutAlign="center" fxLayoutAlign.gt-md="end">
                <span ngClass="cal-day-number custom-day-top-number-mobile"
                    [ngClass.gt-md]="{'cal-day-number':true, 'custom-day-top-number-mobile':false }">{{ day.date |
                    calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            <!--Gestione eventi-->
            <div class="cal-events custom-cal-events" *ngIf="day.events.length > 0" fxLayout="column"
                fxLayout.lt-md="row" fxLayoutAlign="center start" fxLayoutAlign.lt-md="start">
                <div *ngFor="let event of day.events" fxLayout="row">
                    <span fxFlex="1 0 auto" class="cal-event" [style.backgroundColor]="event.color?.primary"
                        [ngClass]="event?.cssClass" (mouseenter)="highlightDay.emit({event: event})"
                        (mouseleave)="unhighlightDay.emit({event: event})"
                        (mwlClick)="eventClicked.emit({ event: event })"></span>
                    <div [ngClass]="event?.cssClass" (mouseenter)="highlightDay.emit({event: event})"
                        (mouseleave)="unhighlightDay.emit({event: event})"
                        (mwlClick)="eventClicked.emit({ event: event })" *ngIf="!isMobile">{{ event.title }}</div>
                </div>
            </div>

        </ng-template>
        <div>
            <mwl-calendar-month-view [events]="events"
                [cellTemplate]="customTemplate" [headerTemplate]="customHeaderTemplate"
                [(viewDate)]="this.parameters.viewDate" [locale]="locale" [weekStartsOn]="weekStartsOn"
                [activeDayIsOpen]="activeDayIsOpen" (eventClicked)="eventClicked($event.event)"
                (dayClicked)="dayClicked($event.day)">
            </mwl-calendar-month-view>
        </div>
    </mat-card-content>
</mat-card>
import {GenericService} from "../../shared/GenericService";
import {MenuDTO} from "../../shared/dto/menu/menu";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {PostazioneDTO} from "../../shared/dto/postazione/postazione";
import {shareReplay} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class MenuService extends GenericService<MenuDTO, number>{
    url: string

    constructor(http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/services/rest/json/menu';
    }

    getListMenu(): Observable<MenuDTO[]>{
        return this.http.get<MenuDTO[]>(this.url)
        .pipe(
            shareReplay()
        );
    }
}
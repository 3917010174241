import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { RapportinoDTO } from 'src/app/shared/dto/rapportino/rapportino';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';

@Injectable({
  providedIn: 'root'
})
export class RapportinoService extends GenericService<RapportinoDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/rapportino';
  }

  public rapportinoList(pageNumber: number, pageSize: number, direction: string, sortField: string, idRapportinoFinale: number): Observable<ResponseQueryByCriteria<RapportinoDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    return this.http.get<ResponseQueryByCriteria<RapportinoDTO>>(`${this.url}/rapportiniRapportinoFinaleId/${idRapportinoFinale}`, {
      params: params
    });
  }
  public ultimoRapportino(collaboratore: CollaboratoreDTO): Observable<RapportinoDTO> {
    return this.http.get<RapportinoDTO>(`${this.url}/collaboratoreId/${collaboratore.id}`);
  }
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettaglio Servizio reperibilità</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Servizio Id" readonly="true" />
        </mat-form-field>

        <!-- Descrizione field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="descrizione" placeholder="Servizio Description" />
          <mat-error *ngIf="form.get('descrizione').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>






          <!-- NumeroReperibilità field -->


        <mat-form-field>
          <mat-select 
              name="numeroReperibilita"
              [compareWith]="compareDTO" 
              formControlName="numeroReperibilita"
              placeholder="Scegli il numero di Reperibilità"
              [disabled]="!isJappTenantAdmin()">
              <mat-option [value]="null" readonly="true"></mat-option>
              <mat-option *ngFor="let numero of numeriReperibilita" readonly="true" [value]="numero">
                  {{numero.mainNumber}}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('numeroReperibilita').errors?.required">
              Campo obbligatorio
          </mat-error>
        </mat-form-field>
         <!-- Email field -->

        <ng-container formArrayName="emailArray">
          Aggiungi email
          <mat-card-actions>
           
            <button  (click)="addDtoEmail('')">
            <mat-icon>email </mat-icon> 
            </button>
          </mat-card-actions>
 
          <ng-container *ngFor="let emailForm of emails.controls; let i = index">
                  <mat-form-field  [formGroupName]="i" fxlayout="column">
                      <input matInput placeholder="inserisci email" formControlName="email" >
                      <mat-error *ngIf="emails.at(i).errors?.valid || emails.at(i).errors?.required">
                        Email non valida
                      </mat-error>
                    </mat-form-field>
                    <mat-card-actions>
                       <button mat-raised-button (click)="removeEmail(i)">
                          <mat-icon class="delete-btn" (click)="removeEmail(i)" color="primary">
                          delete 
                          </mat-icon>
                      </button>
                    </mat-card-actions>

            
          </ng-container>
      </ng-container>




        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/gestione-servizi-rep')" color="primary">Back</button>
      <button mat-raised-button (click)="servizioSave()" [disabled]="(form.invalid || !form.dirty)   "
        *ngIf="servizio?.deleteDate==null && isJappTenantAdmin()">Save</button>
      <button mat-raised-button (click)="servizioDelete()" [disabled]="servizio?.id==null"
        *ngIf="servizio?.deleteDate==null && isJappTenantAdmin()">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
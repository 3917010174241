<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli tipo turno</h2>

        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Tipo Turno Id" readonly="true" />
                </mat-form-field>

                <!-- Codice field -->
                <mat-form-field>
                    <input matInput formControlName="nome" placeholder="Nome" />
                    <mat-error *ngIf="form.get('nome').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Orario field -->
                <mat-form-field>
                    <input matInput formControlName="orario" placeholder="Orario" />
                    <mat-error *ngIf="form.get('orario').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <!-- Responsive -->
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">

                    <!-- Colore field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                        <input matInput [ngxMatColorPicker]="picker" formControlName="colore" placeholder="Colore">
                        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
                        <ngx-mat-color-picker #picker [touchUi]="isMobile"></ngx-mat-color-picker>
                        <mat-error *ngIf="form.get('colore').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <!-- Ordine field -->
                    <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                        <input matInput type="number" formControlName="ordine" placeholder="Ordine" />
                        <mat-error *ngIf="form.get('ordine').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                </div>

                <!-- Descrizione field -->
                <mat-form-field>
                    <input matInput formControlName="descrizione" placeholder="Descrizione" />
                </mat-form-field>

                <mat-form-field fxlayout="column">
                    <mat-slide-toggle formControlName="sovrapponibile" color="primary">Turno sovrapponibile?</mat-slide-toggle>
                    <textarea matInput hidden></textarea>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>

        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
            <button mat-raised-button (click)="tipoTurnoSave()" [disabled]="form.invalid || !form.dirty"
                *ngIf="tipoTurno?.deleteDate==null">Save</button>
            <button mat-raised-button (click)="tipoTurnoDelete()" [disabled]="tipoTurno?.id==null"
                *ngIf="tipoTurno?.deleteDate==null">Delete</button>
        </mat-card-actions>

    </mat-card-content>
</mat-card>
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { DateAdapter } from "@angular/material/core";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { CollaboratoreDTO } from "../shared/dto/domain/collaboratore";
import { CollaboratoreService } from "../services/domain/collaboratore.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { NavigatorService } from "../services/navigator.service";
import { MatDialog } from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { ResponseFail } from "../shared/dto/responseFail";
import { GenericDetailComponent } from "../shared/GenericDetailComponent";
import { Observable } from "rxjs";
import { UrlService } from "../commons/calendar/prevUrl.service";
import { Stampante3DService } from "../services/stampante3D/stampante3D.service";
import { Stampante3DDTO } from "../shared/dto/stampante3D/stampante3D";
import { PrenotazioneStampante3DDTO } from "../shared/dto/prenotazione-stampante3D/prenotazione-stampante3D";
import { PrenotazioneStampante3DService } from "../services/prenotazione-stampante3D/prenotazione-stampante3D.service";

@Component({
  selector: "app-prenotazione-stampante3D-detail",
  templateUrl: "./prenotazione-stampante3D-detail.component.html",
  styleUrls: ["./prenotazione-stampante3D-detail.component.scss"],
})
export class PrenotazioneStampante3DDetailComponent
  extends GenericDetailComponent
  implements OnInit, OnDestroy {
  prenotazionestampa: PrenotazioneStampante3DDTO;
  collaboratori: CollaboratoreDTO[];
  collaboratoreAttuale: CollaboratoreDTO;
  filteredStampanti3D: Stampante3DDTO[];
  data: Date;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;
  URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  
  constructor(
    private urlService: UrlService,
    private prenotazioneStampante3DService: PrenotazioneStampante3DService,
    private collaboratoreService: CollaboratoreService,
    private stampante3DService: Stampante3DService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: "", disabled: true }),
      collaboratore: new FormControl({ value: "", disabled: !this.isJappTenantAdmin() }, Validators.required),
      stampante3D: new FormControl("", Validators.required),
      data: new FormControl("", Validators.required),
      orarioInizioStampa: new FormControl("", Validators.required),
      ore: new FormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.max(50),
      ]),
      url: new FormControl("", Validators.pattern(this.URL_REGEXP)),
      info: new FormControl(""),
      insertDate: new FormControl({ value: "", disabled: true }),
      insertUser: new FormControl({ value: "", disabled: true }),
      updateDate: new FormControl({ value: "", disabled: true }),
      updateUser: new FormControl({ value: "", disabled: true }),
    });
  }



  ngOnInit() {

    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratoreAttuale = res;
        let idPrenotazioneStampante3D = Number(this.activeRoute.snapshot.paramMap.get("id"));
        this.prenotazionestampante3DRead(idPrenotazioneStampante3D);
      }
    );

    this.collaboratoreService
      .collaboratoreList(0, 9999, "ASC", "nome", "")
      .subscribe((res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      });

    this.stampante3DService
      .stampante3DSearchByCodice(0, 9999, "ASC", "codice", "")
      .subscribe((res: ResponseQueryByCriteria<Stampante3DDTO>) => {
        this.filteredStampanti3D = res.content;
      });

    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  isValidFormStampante3D(): boolean {
    if (
      typeof this.form.get("stampante3D").value === "string" ||
      this.form.get("stampante3D").value == null
    ) {
      return false;
    }
    return true;
  }

  prenotazionestampante3DSave() {
    if (this.prenotazionestampa.id == null) {
      this.prenotazionestampante3DCreate();
    } else {
      this.prenotazionestampante3DUpdate();
    }
  }

  prenotazionestampante3DCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.prenotazioneStampante3DService.create(this.prenotazionestampa).subscribe(
        (res: PrenotazioneStampante3DDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, {
            duration: 3000,
          });
          this.prenotazionestampa = res;
          this.dtoToForm();
          this.router.navigate(["/prenotazionestampante3d"]);
        },
        (err: HttpErrorResponse) => {
          if (
            err.status == 400 &&
            (<ResponseFail>err.error).reasonBundleKey ==
            "InvalidPrenotazioneStampante3DException_PRENOTAZIONE_STAMPANTE_3D_ALREADY_EXIST"
          ) {
            this.snackBar.open(
              "Attenzione! Esiste già una stampa nel periodo indicato",
              null,
              { duration: 3000 }
            );
          } else {
            this.snackBar.open(
              "Impossibile prenotare una stampa nel passato",
              null,
              { duration: 3000 }
            );
          }
        }
      );
    }
  }

  prenotazionestampante3DUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la stampa?").subscribe(
        (result) => {
          if (result) {
            this.formToDto();
            this.prenotazioneStampante3DService.update(this.prenotazionestampa).subscribe((res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, {
                duration: 3000,
              });
              this.router.navigate(["/prenotazionestampante3d"]);
            },
              (err: HttpErrorResponse) => {
                if (
                  err.status == 400 &&
                  (<ResponseFail>err.error).reasonBundleKey ==
                  "InvalidPrenotazioneStampante3DException_PRENOTAZIONE_STAMPANTE_3D_ALREADY_EXIST"
                ) {
                  this.snackBar.open(
                    "Attenzione! Esiste già una stampa nel periodo indicato",
                    null,
                    { duration: 3000 }
                  );
                } else {
                  this.snackBar.open(
                    "Impossibile prenotare una stampa nel passato",
                    null,
                    { duration: 3000 }
                  );
                }
              }
            );
          }
        }
      );
    }
  }

  prenotazionestampante3DDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la stampa?").subscribe(
        (result) => {
          if (result) {
            this.formToDto();
            this.prenotazioneStampante3DService
              .delete(this.prenotazionestampa.id)
              .subscribe((res) => {
                this.snackBar.open(
                  "Cancellazione avvenuta con successo!",
                  null,
                  { duration: 3000 }
                );
                this.router.navigate(["/prenotazionestampante3d"]);
              });
          }
        }
      );
    }
  }

  prenotazionestampante3DRead(idPrenotazioneStampante3D?: number) {
    if (idPrenotazioneStampante3D != 0 && idPrenotazioneStampante3D != null) {
      this.prenotazioneStampante3DService
        .read(idPrenotazioneStampante3D)
        .subscribe((res: PrenotazioneStampante3DDTO) => {
          this.prenotazionestampa = res;
          this.dtoToForm();
        });
    } else {
      this.prenotazionestampa = new PrenotazioneStampante3DDTO();
      this.prenotazionestampa.collaboratore = this.collaboratoreAttuale;
      this.dtoToForm();
    }
  }

  private formToDto(): void {
    this.prenotazionestampa.collaboratore = this.form.get("collaboratore").value;
    this.prenotazionestampa.stampante3D = this.form.get("stampante3D").value;
    this.prenotazionestampa.data = this.form.get("data").value;
    this.prenotazionestampa.ore = this.form.get("ore").value;
    this.prenotazionestampa.url = this.form.get("url").value;
    this.prenotazionestampa.info = this.form.get("info").value;
    if (this.form.get("orarioInizioStampa").value != null) {
      this.prenotazionestampa.orarioInizioStampa = this.formatToNumberStartTimePermit(
        this.form.get("orarioInizioStampa").value
      );
    } else {
      this.prenotazionestampa.orarioInizioStampa =
        this.formatToNumberStartTimePermit("0:00");
    }
  }

  private formatToNumberStartTimePermit(str): number {
    var splitted = str.split(":");
    return parseInt(splitted[0]);
  }

  private dtoToForm(): void {

    this.form.get("id").setValue(this.prenotazionestampa.id);
    this.form.get("data").setValue(this.prenotazionestampa.data);
    this.form.get("collaboratore").setValue(this.prenotazionestampa.collaboratore);
    this.form.get("stampante3D").setValue(this.prenotazionestampa.stampante3D);
    this.form.get("ore").setValue(this.prenotazionestampa.ore);
    this.form.get("url").setValue(this.prenotazionestampa.url);
    this.form.get("info").setValue(this.prenotazionestampa.info);
    this.form
      .get("insertDate")
      .setValue(
        this.timestampFormatPipe.transform(this.prenotazionestampa.insertDate)
      );
    this.form.get("insertUser").setValue(this.prenotazionestampa.insertUser);
    this.form
      .get("updateDate")
      .setValue(
        this.timestampFormatPipe.transform(this.prenotazionestampa.updateDate)
      );
    this.form.get("updateUser").setValue(this.prenotazionestampa.updateUser);

    if (this.prenotazionestampa.orarioInizioStampa != null) {
      this.form.get("orarioInizioStampa").setValue(
        `${this.prenotazionestampa.orarioInizioStampa}:00`
      );
    } else {
      this.form.get("orarioInizioStampa").setValue(null);
    }
    this.setFieldsDisabledformToDto();
  }
  private setFieldsDisabledformToDto(): void {
    let stampante3D = this.form.get("stampante3D");
    let data = this.form.get("data");
    let ore = this.form.get("ore");
    let url = this.form.get("url");
    let info = this.form.get("info");
    let orarioInizioStampa = this.form.get("orarioInizioStampa");
    if (this.isFieldsDisabled()) {
      stampante3D.disable();
      data.disable();
      ore.disable();
      url.disable();
      info.disable();
      orarioInizioStampa.disable();
    } else {
      stampante3D.enable();
      data.enable();
      ore.enable();
      url.enable();
      info.enable();
      orarioInizioStampa.enable();
    }
  }
  isFieldsDisabled():boolean {
    return !this.isJappTenantAdmin() && this.collaboratoreAttuale?.id != this.prenotazionestampa.collaboratore?.id;
   }
  ngOnDestroy(): void { }
}

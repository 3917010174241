<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli Formazione</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">

        <mat-form-field>
          <input matInput formControlName="id" placeholder="Frequenta Corso ID" readonly="true" />
        </mat-form-field>

        <mat-form-field fxLayout="column">
          <mat-label>Collaboratori</mat-label>
          <mat-select formControlName="collaboratore" [compareWith]="compareDTO" [disabled]="!isJappTenantAdmin()">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('collaboratore').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field> 

        <mat-form-field fxLayout="column">
          <mat-label>Corsi Udemy</mat-label>
          <mat-select name="corso" formControlName="corsoUdemy" [compareWith]="compareDTO">
            <mat-option *ngFor="let corsoUdemy of corsiUdemy" [value]="corsoUdemy">
              {{ corsoUdemy.titolo}} - durata {{corsoUdemy.ore}}h {{corsoUdemy.minuti}}min
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('corsoUdemy').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- Campo Data Inizio-->
          <mat-form-field fxFlex="40" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput  [max]="maxDate" [max]="today" (dateChange)="setMinDate()"  [matDatepicker]="picker" formControlName="dataInizio"
              placeholder="Data Inizio" (click)="picker.open()" readonly="true">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="form.get('dataInizio').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="form.get('dataInizio').setValue(null)">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataInizio').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- Campo Data Fine -->
          <mat-form-field fxFlex="40" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [min]="minDate" [max]="today" (dateChange)="setMaxDate()" [matDatepicker]="picker2" formControlName="dataFine"
              placeholder="Data Fine" (click)="picker2.open()" readonly="true">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button *ngIf="form.get('dataFine').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="form.get('dataFine').setValue(null)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field fxFlex="20" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <mat-label>N° lezioni</mat-label>
            <input matInput name="nlezioni" formControlName="nlezioni" placeholder="N° lezioni" type="number" min="0" onKeyPress="if(this.value.length==3) return false;"/>       
          </mat-form-field>
        </div>

        <mat-form-field fxlayout="column" style="width: 100%;" class="example-full-width">
          <mat-label>Descizione</mat-label>
          <textarea matInput rows="8" cols="40" name="descrizione" formControlName="descrizione" placeholder="Descrizione del corso"></textarea>
          <mat-error *ngIf="form.get('descrizione').errors?.required">
              Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/frequentacorso')" color="primary">Back</button>
      <button mat-raised-button (click)="frequentaCorsoSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="frequentaCorso?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="frequentaCorsoDelete()" [disabled]="frequentaCorso?.id==null"
        *ngIf="frequentaCorso?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CorsiUdemyService } from '../services/corsi-udemy/corsi-udemy.service';
import { CorsiUdemyDTO } from '../shared/dto/corsi-udemy/corsiUdemy';

@Component({
  selector: 'app-corsi-udemy-detail',
  templateUrl: './corsi-udemy-detail.component.html',
  styleUrls: ['./corsi-udemy-detail.component.scss']
})
export class CorsiUdemyDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  corsoUdemyId: number;
  corsoUdemyDTO: CorsiUdemyDTO;
 

  constructor(
    private corsiUdemyService: CorsiUdemyService, 
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      titolo: new FormControl('', Validators.required),
      link: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      ore: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
      minuti: new FormControl('', [Validators.required, Validators.min(0), Validators.max(60)]),
      descrizione: new FormControl('',[Validators.required, Validators.maxLength(1000)]),
      nlezioni: new FormControl('', [Validators.required, Validators.min(0)]),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.corsoUdemyId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.corsoUdemyId != null && this.corsoUdemyId != 0) {
      this.corsoUdemyRead();
    } else {
      this.corsoUdemyDTO = new CorsiUdemyDTO();
    } 
  }



  corsoUdemySave() {
    if (this.corsoUdemyDTO.id == null) {
      this.corsoUdemyCreate();
    } else {
      this.corsoUdemyUpdate();
    }
  }

  corsoUdemyCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.corsiUdemyService.create(this.corsoUdemyDTO).subscribe(
        (res: CorsiUdemyDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.corsoUdemyDTO = res;
          this.corsoUdemyId = this.corsoUdemyDTO.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  corsoUdemyUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il corso?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.corsiUdemyService.update(this.corsoUdemyDTO).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.corsoUdemyRead();
              this.form.markAsPristine();
            }, (err: any) => {
              this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
            }
          );
        }
      });
    }
  }

  corsoUdemyDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il corso?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.corsiUdemyService.delete(this.corsoUdemyDTO.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/corsoudemy"]);
            }
          );
        }
      });
    }
  }

  corsoUdemyRead() {
    this.corsiUdemyService.read(this.corsoUdemyId).subscribe(
      (res: CorsiUdemyDTO) => {
        console.log("response : " + res);
        this.corsoUdemyDTO = res;
        this.corsoUdemyId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.corsoUdemyDTO.titolo = this.form.get("titolo").value;
    this.corsoUdemyDTO.link = this.form.get("link").value;
    this.corsoUdemyDTO.ore = this.form.get("ore").value;
    this.corsoUdemyDTO.minuti = this.form.get("minuti").value;
    this.corsoUdemyDTO.descrizione = this.form.get("descrizione").value;
    this.corsoUdemyDTO.nlezioni = this.form.get("nlezioni").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.corsoUdemyDTO.id);
    this.form.get("titolo").setValue(this.corsoUdemyDTO.titolo);
    this.form.get("link").setValue(this.corsoUdemyDTO.link);
    this.form.get("ore").setValue(this.corsoUdemyDTO.ore);
    this.form.get("minuti").setValue(this.corsoUdemyDTO.minuti);
    this.form.get("descrizione").setValue(this.corsoUdemyDTO.descrizione);
    this.form.get("nlezioni").setValue(this.corsoUdemyDTO.nlezioni);
    this.form.get("deleteDate").setValue(this.corsoUdemyDTO.deleteDate);
    this.form.get("deleteUser").setValue(this.corsoUdemyDTO.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.corsoUdemyDTO.insertDate));
    this.form.get("insertUser").setValue(this.corsoUdemyDTO.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.corsoUdemyDTO.updateDate));
    this.form.get("updateUser").setValue(this.corsoUdemyDTO.updateUser);
    this.form.get("optLock").setValue(this.corsoUdemyDTO.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }


}

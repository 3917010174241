import { PrenotazioneSalettaDTO } from './../shared/dto/prenotazioneSaletta/prenotazioneSaletta';

import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';

import { CalendarEvent, CalendarView, CalendarDateFormatter, DAYS_OF_WEEK, CalendarEventAction, CalendarEventTitleFormatter, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { CustomDateFormatter } from '../commons/calendar/custom-calendar-date-formater';


import { SedeService } from './../services/sede/sede.service';
import { SedeDTO } from './../shared/dto/sede/sede';
import { SalettaService } from './../services/saletta/saletta.service';
import { PrenotazioneSalettaService } from './../services/prenotazioneSaletta/prenotazione-saletta.service';
import { ComponentCacheService } from './../services/component-cache.service';
import { NavigatorService } from './../services/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';

import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { SalettaDTO } from '../shared/dto/saletta/saletta';
import {
  isSameMonth,
  isSameDay,
} from 'date-fns';

import { CustomEventTitleFormatter } from '../commons/calendar/custom-event-title-formatter.provider';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-gestione-prenotazione-saletta',
  templateUrl: './gestione-prenotazione-saletta.component.html',
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ],
  styleUrls: ['./gestione-prenotazione-saletta.component.scss']
})
export class GestionePrenotazioneSalettaComponent extends GenericListComponent implements OnInit, OnDestroy, AfterContentChecked {

  salette: SalettaDTO[];

  sedi: SedeDTO[];

  collaboratore: CollaboratoreDTO;

  events: CalendarEvent<{ prenotazione: PrenotazioneSalettaDTO }>[];

  locale: string = 'it';

  activeDayIsOpen: boolean = false;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.router.navigate(["/gestione-prenotazione-saletta/detail/" + event.meta.prenotazioneSaletta.id]);
      },
    }
  ]

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  CalendarView = CalendarView;

  vistaData: boolean = false;

  matIndex: number;

  private destroy$ = new Subject();

  eventClicked(event: CalendarEvent<{ prenotazioneSaletta: PrenotazioneSalettaDTO }>): void {
    this.router.navigate(["/gestione-prenotazione-saletta/detail/" + event.meta.prenotazioneSaletta.id]);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private prenotazioneSalettaService: PrenotazioneSalettaService,
    private salettaService: SalettaService,
    private sedeService: SedeService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService, componentCacheService, dateAdapter, router, snackBar, timestampFormatPipe);
    this.displayedColumns = ['id', 'collaboratore', 'sede', 'saletta', 'dataDa', 'dataA', 'descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sede: null,
      saletta: null,
      collaboratore: null,
      initDataDa: true,
      selectedTab: null,
      view: CalendarView.Month,
      viewDate: new Date(),
      vistaData: null,
      dataDa: null,
      dataA: null,
      dataDaCalendario: null,
      dataACalendario: null,
      descrizione: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }
  list() {
    this.prenotazioneSalettaService.prenotazioneListBySalaAndDates(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.sede,
      this.parameters.saletta,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.descrizione
    ).subscribe(
      (res: ResponseQueryByCriteria<PrenotazioneSalettaDTO>) => {
        console.log("Response : " + res.content);
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      });
  }

  onTabChange(event: MatTabChangeEvent) {

    event.index == 1 ? this.parameters.vistaData = true : this.parameters.vistaData = false;
    this.parameters.selectedTab = event.index;
  }

  estrazionePeriodoVistaMensile(event: CalendarMonthViewBeforeRenderEvent) {
    if ((this.parameters.dataDaCalendario == null || event.period.start.getTime() != this.parameters.dataDaCalendario.getTime()) || (this.parameters.dataACalendario == null || event.period.end.getTime() != this.parameters.dataACalendario.getTime())) {
      this.caricaEventi(event.period.start, event.period.end);
    }

  }

  caricaEventi(dataDa: Date, dataA: Date) {
    this.prenotazioneSalettaService.prenotazioneListBySalaAndMonth(
      this.parameters.sede,
      this.parameters.saletta,
      dataDa,
      dataA,
      this.parameters.descrizione
    ).subscribe(
      (res: PrenotazioneSalettaDTO[]) => {
        this.parameters.dataDaCalendario = dataDa;
        this.parameters.dataACalendario = dataA;
        this.events = res.map((prenotazioneSaletta: PrenotazioneSalettaDTO) => {
          return {
            title: prenotazioneSaletta.descrizione,
            start: new Date(prenotazioneSaletta.dataDa),
            cssClass: 'custom-template-cal',
            color: {
              primary: '#e3bc08',
              secondary: '#cb412b'
            },
            meta: {
              prenotazioneSaletta
            },
            end: new Date(prenotazioneSaletta.dataA),
            actions: this.actions
          } as CalendarEvent
        })
      }
    )
  }
  caricaEventiOnSalettaChange() {
    if (this.matIndex != 1) {
      this.prenotazioneSalettaService.prenotazioneListBySalaAndMonth(
        this.parameters.sede,
        this.parameters.saletta,
        this.parameters.dataDaCalendario,
        this.parameters.dataACalendario,
        this.parameters.descrizione
      ).subscribe(
        (res: PrenotazioneSalettaDTO[]) => {
          this.events = res.map((prenotazioneSaletta: PrenotazioneSalettaDTO) => {
            return {
              title: prenotazioneSaletta.descrizione,
              start: new Date(prenotazioneSaletta.dataDa),
              cssClass: 'custom-template-cal',
              color: {
                primary: '#e3bc08',
                secondary: '#cb412b',
              },
              meta: {
                prenotazioneSaletta
              },
              end: new Date(prenotazioneSaletta.dataA),
              actions: this.actions
            } as CalendarEvent
          })
        }
      )
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.parameters.viewDate = date;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        this.parameters.collaboratore = this.collaboratore;
      }
    );
    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<SedeDTO>) => {
          this.sedi = res.content;
          if (this.parameters.sede !== null) {
            this.salettaService.salettaList(
              0,
              9999,
              'ASC',
              'descrizione',
              '', this.parameters.sede).subscribe(
                (res: ResponseQueryByCriteria<SalettaDTO>) => {
                  console.log("Response: " + res)
                  this.salette = res.content;
                });
          }
        }
      );

    if (this.parameters.dataDaCalendario != null && this.parameters.dataACalendario != null) {
      this.caricaEventi(this.parameters.dataDaCalendario, this.parameters.dataACalendario);
    }
    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }
  afterSceltaSede() {
    this.salettaService.salettaList(
      0,
      9999,
      'ASC',
      'descrizione',
      '', this.parameters.sede).subscribe(
        (res: ResponseQueryByCriteria<SalettaDTO>) => {
          console.log("Response: " + res)
          this.salette = res.content;
        });
    this.parameters.saletta = null;
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
  }
}


import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { AcquistoService } from '../services/acquisti/acquisto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from '../services/navigator.service';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AcquistoDTO } from '../shared/dto/acquisto/acquisto';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';

@Component({
  selector: 'app-richiesta-acquisto-detail',
  templateUrl: './richiesta-acquisto-detail.component.html',
  styleUrls: ['./richiesta-acquisto-detail.component.scss']
})
export class RichiestaAcquistoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  statoRichiestaValues: selectedValue[];
  acquistoId: number;
  acquisto: AcquistoDTO;
  collaboratori: CollaboratoreDTO[];
  bloccaModifica: boolean;
  URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;


  constructor(
    private acquistoService: AcquistoService,
    private collaboratoreService: CollaboratoreService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
      super(
        navigatorService,
        dialog,
        router,
        dateAdapter,
        activeRoute,
        snackBar,
        timestampFormatPipe
      );
      this.form = new FormGroup({
        id: new FormControl(''),
        statoRichiesta: new FormControl('', Validators.required),
        richiedente: new FormControl('', Validators.required),
        approvatore: new FormControl(),
        descrizione: new FormControl('', Validators.required),
        url: new FormControl('', Validators.pattern(this.URL_REGEXP)),
        insertDate: new FormControl({ value: '', disabled: true }),
        insertUser: new FormControl({ value: '', disabled: true }),
        updateDate: new FormControl({ value: '', disabled: true }),
        updateUser: new FormControl({ value: '', disabled: true }),
      });
      this.statoRichiestaValues = [
        { value: 'APPROVATO', viewValue: 'APPROVATO' },
        { value: 'DA_APPROVARE', viewValue: 'DA APPROVARE' },
        { value: 'RIFIUTATO', viewValue: 'RIFIUTATO' }
      ]; }

  ngOnInit() {
    this.acquistoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.acquistoId != 0 && this.acquistoId != null) {
      this.acquistoRead();
    } else {
      this.acquisto = new AcquistoDTO();
    }
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("statoRichiesta").setValue("DA_APPROVARE");
        this.form.get("richiedente").setValue(res);
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }

  acquistoSave() {
    if (this.acquisto.id == null) {
      this.acquistoCreate();
    } else {
      this.acquistoUpdate();
    }
  }

  acquistoCreate() {
    if (this.form.valid) {
      if (this.isJappTenantAdmin() == false) {
        this.acquisto.statoRichiesta = 'DA APPROVARE';
      }
      if (this.form.get("statoRichiesta").value == "APPROVATO" || this.form.get("statoRichiesta").value == "RIFIUTATO") {
        this.navigatorService.collaboratore.subscribe(
          (res: CollaboratoreDTO) => {
            this.form.get("approvatore").setValue(res);
          }
        );
      }
      this.formToDto();
      this.acquistoService.create(this.acquisto).subscribe(
        (res: AcquistoDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.acquisto = res;
          this.acquistoId = this.acquisto.id;
          this.dtoToForm();
        }, (err: any) => {
          this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  acquistoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere l'acquisto?").subscribe(result => {
        if (result) {
          if (this.isJappTenantAdmin() == true) {
            if (this.form.get("statoRichiesta").value == "APPROVATO" || this.form.get("statoRichiesta").value == "RIFIUTATO") {
              this.navigatorService.collaboratore.subscribe(
                (res: CollaboratoreDTO) => {
                  this.form.get("approvatore").setValue(res);
                }
              );
            }
          }
          this.formToDto();
          console.log(this.acquisto.approvatore);
          this.acquistoService.update(this.acquisto).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.acquistoRead();
            }, (err: any) => {
              this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
            }
          );
        }
      });
    }
  }
  
  acquistoDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare l'acquisto?").subscribe(result => {
        if (result) {
          this.formToDto();
          if(this.acquisto.statoRichiesta == "APPROVATO" || this.acquisto.statoRichiesta == "RIFIUTATO"){
            this.snackBar.open("Attenzione! Non puoi cancellare un acquisto approvato o rifiutato", null, { duration: 3000 });
            return;
          }
          this.acquistoService.delete(this.acquisto.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/richiesta-acquisto"]);
            }
          );
        }
      });
    }
  }

  acquistoRead() {
    this.acquistoService.read(this.acquistoId).subscribe(
      (res: AcquistoDTO) => {
        console.log("response : " + res);
        this.acquisto = res;
        this.acquistoId = res.id;
        this.dtoToForm();
        if (this.form.get("statoRichiesta").value == "APPROVATO" || this.form.get("statoRichiesta").value == "RIFIUTATO") {
          if (this.isJappTenantAdmin() == false) {
            this.bloccaModifica = true;
            this.form.get("descrizione").disable();
          }
        }
      }
    );
  }

  private formToDto(): void {
    this.acquisto.approvatore = this.form.get("approvatore").value;
    this.acquisto.richiedente = this.form.get("richiedente").value;
    this.acquisto.statoRichiesta = this.form.get("statoRichiesta").value;
    this.acquisto.descrizione = this.form.get("descrizione").value;
    this.acquisto.url = this.form.get("url").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.acquisto.id);
    this.form.get("approvatore").setValue(this.acquisto.approvatore);
    this.form.get("richiedente").setValue(this.acquisto.richiedente);
    this.form.get("statoRichiesta").setValue(this.acquisto.statoRichiesta);
    this.form.get("descrizione").setValue(this.acquisto.descrizione);
    this.form.get("url").setValue(this.acquisto.url);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.acquisto.insertDate));
    this.form.get("insertUser").setValue(this.acquisto.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.acquisto.updateDate));
    this.form.get("updateUser").setValue(this.acquisto.updateUser);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {

  }
}

export interface selectedValue {
  value: string;
  viewValue: string;
}
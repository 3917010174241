import { SedeService } from './../services/sede/sede.service';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { SedeDTO } from '../shared/dto/sede/sede';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { PrenotazionePostazioneDTO, PrenotazioneDeleteMassivaDTO } from '../shared/dto/prenotazionepostazione/prenotazionepostazione';
import { PrenotazionePostazioneService } from '../services/prenotazionepostazione/prenotazionepostazione.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-prenotazionepostazione-delete',
    templateUrl: './prenotazionepostazione-delete.component.html',
})

export class PrenotazionePostazioneDeleteComponent extends GenericListComponent implements OnInit, OnDestroy {
    collaboratori: CollaboratoreDTO[];
    collaboratore: CollaboratoreDTO;
    sedi: SedeDTO[];
    sede: SedeDTO;
    prenotazionepostazione: PrenotazioneDeleteMassivaDTO;
    collaboratoreAttuale: CollaboratoreDTO;
    selectAllChecked = false;

    constructor(
        private prenotazionePostazioneService: PrenotazionePostazioneService,
        private sedeService: SedeService,
        private collaboratoreService: CollaboratoreService,
        componentCacheService: ComponentCacheService,
        navigatorService: NavigatorService,
        dateAdapter: DateAdapter<Date>,
        router: Router,
        snackBar: MatSnackBar,
        timestampFormatPipe: TimestampFormatPipe
    ) {
        super(
            navigatorService,
            componentCacheService,
            dateAdapter,
            router,
            snackBar,
            timestampFormatPipe);
        this.displayedColumns = ['id', 'dataDa', 'dataA', 'richiedente', 'postazione.descrizione', 'postazione.ufficio.descrizione', 'checkbox'];
        this.parameters = {
            dataSource: [],
            initDataDa: true,
            collaboratoreChanged: false,
            showList: false,
            filter: null,
            sortField: null,
            sortDirection: null,
            pageNumber: 0,
            pageSize: 50,
            length: 0
        }
    }

    list() {
        this.prenotazionePostazioneService.prenotazionePostazioneList(
            this.parameters.pageNumber,
            this.parameters.pageSize,
            this.parameters.sortDirection,
            this.parameters.sortField,
            this.parameters.richiedente,
            this.parameters.sede,
            this.parameters.dataDa,
            this.parameters.dataA
        ).subscribe((response: ResponseQueryByCriteria<PrenotazionePostazioneDTO>) => {
            this.parameters.dataSource = response.content.map(item => {
                const prenotazione: PrenotazioneDeleteMassivaDTO = ({
                    ...item,
                    isSelected: false
                });
                return prenotazione;
            });
            this.parameters.pageNumber = response.pageNumber;
            this.parameters.length = response.totalElements;
            this.parameters.showList = true;
            this.selectAllChecked = false;
        }
        )
    }

    ngOnInit() {
        super.ngOnInit();

        if (!this.parameters.collaboratoreChanged) {
            this.navigatorService.collaboratore.subscribe(
                (res: CollaboratoreDTO) => {
                    this.collaboratore = res;
                    this.parameters.richiedente = this.collaboratore;
                }
            );
        }
        this.collaboratoreService.collaboratoreList(
            0,
            9999,
            'ASC',
            'nome',
            '').subscribe(
                (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
                    this.collaboratori = res.content;
                }
            );

        this.sedeService.sedeListByDescrizione(
            0,
            9999,
            'ASC',
            'descrizione',
            '').subscribe(
                (res: ResponseQueryByCriteria<SedeDTO>) => {
                    this.sedi = res.content;
                }
            );

        this.navigatorService.collaboratore.subscribe(
            (res: CollaboratoreDTO) => {
                this.collaboratoreAttuale = res;
            }
        );


        if (this.parameters.dataDa == null && this.parameters.initDataDa) {
            this.parameters.dataDa = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
            );
        }
        this.list();
    }

    prenotazionepostazioneDeleteMassiva() {
        let selectedIds = this.parameters.dataSource
            .filter((item: PrenotazioneDeleteMassivaDTO) => item.isSelected)
            .map((item: PrenotazioneDeleteMassivaDTO) => item.id);

        this.prenotazionePostazioneService.deleteMassiva(selectedIds).subscribe(
            () => {
                this.snackBar.open('Prenotazioni eliminate con successo', 'Chiudi', { duration: 3000 });
                this.list();
            },
            error => {
                this.snackBar.open("Errore durante l'eliminazione delle prenotazioni", 'Chiudi', { duration: 3000 });
            }
        );
    }

    toggleAll() {
        this.parameters.dataSource.forEach(item => item.isSelected = this.selectAllChecked);
    }

    thereIsSelection(): boolean {
        return this.parameters.dataSource.some(item => item.isSelected);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Cancella Postazioni Prenotate</h2>
        <p>Imposta i filtri di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">

            <!-- Richiedente field -->
            <mat-form-field>
                <mat-select name="richiedente" [(ngModel)]="parameters.richiedente" [compareWith]='compareDTO'
                    [disabled]="!isJappTenantAdmin()" placeholder="Scegli il richiedente">
                    <mat-option [value]="null">
                        Tutti
                    </mat-option>
                    <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                        {{collaboratore.nome}} {{collaboratore.cognome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Sede field -->
            <mat-form-field>
                <mat-select name="sede" [(ngModel)]="parameters.sede" [compareWith]='compareDTO'
                    placeholder="Scegli la sede">
                    <mat-option [value]="null">
                        Tutte
                    </mat-option>
                    <mat-option *ngFor="let sede of sedi" [value]="sede">
                        {{sede.descrizione}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Responsive -->
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                <!-- DataDa field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
                        [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataDa=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <!-- DataA field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
                        [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataA=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

        </div>
        <mat-card-actions>
            <button mat-raised-button [routerLink]="['/prenotazionepostazione']" color="primary">Back</button>
            <button mat-raised-button id="search-button" (click)="list()" name="Search">Search</button>
            <button mat-raised-button id="new-button" [disabled]="!thereIsSelection()"
                (click)="prenotazionepostazioneDeleteMassiva()">Delete</button>
        </mat-card-actions>
        <br />



        <div fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazionepostazione">
                        {{prenotazionepostazione.id}}
                    </td>
                </ng-container>

                <!-- Data Da -->
                <ng-container matColumnDef="dataDa">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> data Da </th>
                    <td mat-cell *matCellDef="let prenotazionepostazione"> {{prenotazionepostazione.dataDa | date
                        :'dd/MM/yyyy'}}
                    </td>
                </ng-container>

                <!-- Data A -->
                <ng-container matColumnDef="dataA">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> data A </th>
                    <td mat-cell *matCellDef="let prenotazionepostazione"> {{prenotazionepostazione.dataA | date
                        :'dd/MM/yyyy'}}
                    </td>
                </ng-container>

                <!-- Richiedente -->
                <ng-container matColumnDef="richiedente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Richiedente </th>
                    <td mat-cell *matCellDef="let prenotazionepostazione"> {{prenotazionepostazione.richiedente.nome}}
                        {{prenotazionepostazione.richiedente.cognome}}</td>
                </ng-container>

                <!-- postazioneDescrizione -->
                <ng-container matColumnDef="postazione.descrizione">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione Postazione
                    </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazionepostazione">
                        {{prenotazionepostazione.postazione.descrizione}}
                    </td>
                </ng-container>

                <!-- UfficioDescrizione -->
                <ng-container matColumnDef="postazione.ufficio.descrizione">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> UfficioDescrizione </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazionepostazione">
                        {{prenotazionepostazione.postazione.ufficio.descrizione}} </td>
                </ng-container>

                <!-- checkbox  -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox [(ngModel)]="selectAllChecked" (change)="toggleAll()"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let prenotazionepostazione">
                        <mat-checkbox [(ngModel)]="prenotazionepostazione.isSelected"></mat-checkbox>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>
        </div>

    </mat-card-content>
</mat-card>
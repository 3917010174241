
import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { NumeroReperibilitaDTO } from '../reperibilità/NumeroReperibilita';

export class ServiziReperibilitaDTO extends LogicalDeleteDTO{
	
	id: number;
    tenant: TenantDTO;
    numeroReperibilita: NumeroReperibilitaDTO;
    descrizione: String;
    email: String;
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Rimborso piè di lista</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <!-- Collaboratore -->
            <mat-form-field>
                <mat-select name="collaboratore" [(ngModel)]="parameters.collaboratore" [compareWith]="compareDTO"
                    [disabled]="!isJappTenantAdmin()" placeholder="Scegli il collaboratore">
                    <mat-option *ngIf="isJappTenantAdmin()">Tutti</mat-option>
                    <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                        {{ (collaboratore.nome + ' ' + collaboratore.cognome) | titlecase }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Responsive -->
            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                <!-- Data Da -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput [matDatepicker]="pickerDataDa" placeholder="Inserisci data da"
                        [(max)]="today" [(ngModel)]="parameters.dataDa" (click)="pickerDataDa.open()"
                        readonly="true">
                    <mat-datepicker-toggle matSuffix [for]="pickerDataDa"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDataDa></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataDa=null" (click)="parameters.initDataDa=false">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <!-- Data A -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                    <input matInput [matDatepicker]="pickerDataA" placeholder="Inserisci data a"
                        [(min)]="parameters.dataDa" [max]="today" [(ngModel)]="parameters.dataA" (click)="pickerDataA.open()"
                        readonly="true">
                    <mat-datepicker-toggle matSuffix [for]="pickerDataA"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDataA></mat-datepicker>
                    <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
                        (click)="parameters.dataA=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <!-- Descrizione -->
            <mat-form-field>
                <input matInput type="text" placeholder="Inserisci la descrizione" [(ngModel)]="parameters.descrizione">
            </mat-form-field>
        </div>
        <div>
            <mat-card-actions>
                <button mat-raised-button id="search-button" (click)="list()" name="Search"
                    color="primary">Search</button>
                <button mat-raised-button id="new-button"
                    [routerLink]="['/rimborso-pie-di-lista/detail/0']">New</button>
            </mat-card-actions>
        </div>
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear
                [dataSource]="parameters.dataSource">
                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let rimborso"> {{rimborso.id}} </td>
                </ng-container>

                <!-- Collaboratore Column -->
                <ng-container matColumnDef="collaboratore">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore </th>
                    <td mat-cell *matCellDef="let rimborso">
                        {{ (rimborso.collaboratore?.nome + ' ' + rimborso.collaboratore?.cognome) | titlecase }} </td>
                </ng-container>

                <!-- DataEmissione Column -->
                <ng-container matColumnDef="DataEmissione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Emissione </th>
                    <td mat-cell *matCellDef="let rimborso"> {{ rimborso.dataEmissione | date:'dd/MM/yyyy' }} </td>
                </ng-container>

                <!-- Importo Column -->
                <ng-container matColumnDef="importo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importo </th>
                    <td mat-cell *matCellDef="let rimborso"> {{ rimborso.importo | currency:'EUR' }} </td>
                </ng-container>

                <!-- Descrizione Column -->
                <ng-container matColumnDef="descrizione">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let rimborso"> {{ rimborso.descrizione }} </td>
                </ng-container>

                <!-- Fatto Column -->
                <ng-container matColumnDef="fatto">
                    <th mat-header-cell *matHeaderCellDef> <div *ngIf="!isJappTenantAdmin()">Stato</div> </th>
                    <td mat-cell *matCellDef="let rimborso">
                        <div fxHide fxShow.gt-md *ngIf="!isJappTenantAdmin()">{{ trascodificaStatoRimborso(rimborso?.fatto) }}</div>
                        <div fxShow fxHide.gt-md fxLayoutAlign="center center" *ngIf="!isJappTenantAdmin()">
                            <mat-icon *ngIf="rimborso?.fatto">check_box</mat-icon>
                            <mat-icon *ngIf="!rimborso?.fatto">check_box_outline_blank</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <!-- Verifica Column -->
                <ng-container matColumnDef="verifica">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let rimborso">
                        <button mat-mini-fab color="primary" (click)="verificaRimborso(rimborso)" *ngIf="isJappTenantAdmin()" [disabled]="rimborso.fatto">
                            <mat-icon>done</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Info Column -->
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let rimborso">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/rimborso-pie-di-lista/detail/' , rimborso.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-actions>
                    <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'rimborsi'})">Excel</button>
                    <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'rimborsi'})">Csv</button>
                    <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'rimborsi'})">Json</button>
                </mat-card-actions>
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
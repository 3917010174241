import { List } from '@amcharts/amcharts4/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComandaDTO } from 'src/app/shared/dto/gestioneOrdini/comanda';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';

@Injectable({
  providedIn: 'root'
})
export class ComandaService extends GenericService<ComandaDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/comanda';
  }

  public comandaList(): Observable<ComandaDTO[]> {
    return this.http.get<ComandaDTO[]>(`${this.url}/comandaListByGiorno`, {
    });
  }

  public comandaListByCollaboratoreAndDates(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO, dateFrom: Date, dateTo: Date): Observable<ResponseQueryByCriteria<ComandaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    if (dateFrom != null) params["dateFrom"] = dateFrom.toISOString();
    if (dateTo != null) params["dateTo"] = dateTo.toISOString();
    return this.http.get<ResponseQueryByCriteria<ComandaDTO>>(`${this.url}/comandaListByCollaboratoreAndDates`, {
      params: params
    });
}
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettaglio ufficio</h2>
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Ufficio Id" readonly="true" />
          </mat-form-field>
  
          <!-- Descrizione field -->
          <mat-form-field fxlayout="column">
            <input matInput formControlName="descrizione" placeholder="Ufficio Description" />
            <mat-error *ngIf="form.get('descrizione').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- Sede field -->
          <mat-form-field>
            <input type="text" matInput formControlName="sede" (input)="sedeChanged($event.target.value, true)" [matAutocomplete]="sedeAutocomplete" placeholder="Sede">
            
            <mat-autocomplete autoActiveFirstOption #sedeAutocomplete="matAutocomplete" [displayWith]="inizializzaSede()">
              <mat-option *ngFor="let sede of filteredSedi" [value]="sede" >
                {{sede.descrizione}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

         <!-- PostazioniMassime field -->
         <mat-form-field fxlayout="column">
          <input matInput type="number" formControlName="postazioniMassime" placeholder="Postazioni Massime" min="0">
          <mat-error *ngIf="form.get('postazioniMassime').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>
  
              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>
  
              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/ufficio')" color="primary">Back</button>
        <button mat-raised-button (click)="ufficioSave()" [disabled]="form.invalid || !form.dirty || !isValidFormSede()"
          *ngIf="ufficio?.deleteDate==null">Save</button>
        <button mat-raised-button (click)="ufficioDelete()" [disabled]="ufficio?.id==null"
          *ngIf="ufficio?.deleteDate==null">Delete</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>


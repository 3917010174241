import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { EventoDTO } from './evento';

export class PartecipanteEventoDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    evento: EventoDTO;
    spettatore: boolean;
    numOspiti: number;
}
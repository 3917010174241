import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TenantDTO } from '../shared/dto/domain/tenant';
import { TenantService } from '../services/domain/tenant.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenantDetail.component.html',
  styleUrls: ['./tenantDetail.component.scss']
})

export class TenantDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  tenantId: number;
  tenant: TenantDTO;

  constructor(
    private tenantService: TenantService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    timestampFormatPipe: TimestampFormatPipe,
    snackBar: MatSnackBar,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      tenantCode: new FormControl('', [Validators.required, Validators.maxLength(16)]),
      description: new FormControl('', Validators.required),
      mobileDesc: new FormControl('', Validators.required),
      hostName: new FormControl('', Validators.required),
      jwkProviderUrl: new FormControl('', Validators.required),
      clientId: new FormControl('', Validators.required),
      issuer: new FormControl('', Validators.required),
      rolesClaim: new FormControl('', Validators.required),
      amministrazioneEmailList: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });

  }

  ngOnInit() {
    this.tenantId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.tenantId != null && this.tenantId != 0) {
      this.tenantRead();
    } else {
      this.tenant = new TenantDTO();
    }
  }

  tenantSave() {
    if (this.tenant.id == null) {
      this.tenantCreate();
    } else {
      this.tenantUpdate();
    }
  }

  tenantCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.tenantService.create(this.tenant).subscribe(
        (res: TenantDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.tenant = res;
          this.tenantId = this.tenant.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  tenantUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il tenant?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.tenantService.update(this.tenant).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.tenantRead();
              this.form.markAsPristine();
            }, (err: any) => {
              this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
            }
          );
        }
      });
    }
  }

  tenantDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il tenant?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.tenantService.delete(this.tenant.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/tenant"]);
            }
          );
        }
      });
    }
  }

  tenantRead() {
    this.tenantService.read(this.tenantId).subscribe(
      (res: TenantDTO) => {
        console.log("response : " + res);
        this.tenant = res;
        this.tenantId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.tenant.tenantCode = this.form.get("tenantCode").value;
    this.tenant.description = this.form.get("description").value;
    this.tenant.mobileDesc = this.form.get("mobileDesc").value;
    this.tenant.hostName = this.form.get("hostName").value;
    this.tenant.jwkProviderUrl = this.form.get("jwkProviderUrl").value;
    this.tenant.clientId = this.form.get("clientId").value;
    this.tenant.issuer = this.form.get("issuer").value;
    this.tenant.rolesClaim = this.form.get("rolesClaim").value;
    this.tenant.amministrazioneMailList = this.form.get("amministrazioneEmailList").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.tenant.id);
    this.form.get("tenantCode").setValue(this.tenant.tenantCode);
    this.form.get("description").setValue(this.tenant.description);
    this.form.get("mobileDesc").setValue(this.tenant.mobileDesc);
    this.form.get("hostName").setValue(this.tenant.hostName);
    this.form.get("jwkProviderUrl").setValue(this.tenant.jwkProviderUrl);
    this.form.get("clientId").setValue(this.tenant.clientId);
    this.form.get("issuer").setValue(this.tenant.issuer);
    this.form.get("rolesClaim").setValue(this.tenant.rolesClaim);
    this.form.get("deleteDate").setValue(this.tenant.deleteDate);
    this.form.get("deleteUser").setValue(this.tenant.deleteUser);
    this.form.get("amministrazioneEmailList").setValue(this.tenant.amministrazioneMailList);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.tenant.insertDate));
    this.form.get("insertUser").setValue(this.tenant.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.tenant.updateDate));
    this.form.get("updateUser").setValue(this.tenant.updateUser);
    this.form.get("optLock").setValue(this.tenant.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}
import { ComandaDTO } from './../shared/dto/gestioneOrdini/comanda';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { ComandaService } from '../services/gestione-ordini/comanda.service';
import { OrdineService } from '../services/gestione-ordini/ordine.service';
import { OrdineDTO } from '../shared/dto/gestioneOrdini/ordine';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-gestione-comanda-detail',
  templateUrl: './gestione-comanda-detail.component.html',
  styleUrls: ['./gestione-comanda-detail.component.scss']
})
export class GestioneComandaDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  comandaId: number;
  comanda: ComandaDTO;
  collaboratori: CollaboratoreDTO[];
  today: Date;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;


  parameters: any;

  defaultSortField = 'dataComanda';
  defaultSortDirection = 'asc';
  displayedColumns: String[];

  constructor(
    private urlService: UrlService,
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private comandaService: ComandaService,
    snackBar: MatSnackBar,

    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    private ordineService: OrdineService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      dataComanda: new FormControl('', Validators.required),
      descrizione: new FormControl('', Validators.required),
      email: new FormControl(''),
      link: new FormControl(''),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.today = new Date();
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      collaboratoreChanged: false,
      initDataDa: true,
      sortField: 'id',
      sortDirection: 'asc',
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  ngOnInit() {
    this.displayedColumns = ['checkbox', 'descrizioneOrdine', 'importoDovuto', 'importoPagato', 'differenza', 'collaboratore', 'detail', 'detail1'];
    this.comandaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.comandaId != null && this.comandaId != 0) {
      this.comandaRead();
    } else {
      this.comandaInit();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );

    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }
  list() {
    this.ordineService.ordineListByComanda(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
      this.comanda
    ).subscribe(
      (res: ResponseQueryByCriteria<OrdineDTO>) => {
        console.log("response : " + res);
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      }
    )
  }

  pagamentoCompletato(ordine: OrdineDTO) {
    ordine.importoPagato = ordine.importoDovuto;
    this.ordineService.update(ordine).subscribe(
      (res) => {
        console.log("response : " + res);
        this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
      });
  }
  totImporto(comanda: ComandaDTO) {
    this.ordineService.totImporto(
      comanda).subscribe(
        (res: number) => {
          console.log("importo totale : " + res);
          comanda.totale = ((comanda.totale==null)?0:comanda.totale) + res;
        }
      )
  }
  comandaSave() {
    if (this.comanda.id == null) {
      this.comandaCreate();
    } else {
      this.comandaUpdate();
    }
  }

  comandaCreate() {
    console.log(this.form.valid);
    if (this.form.valid) {
      this.formToDto();
      this.comandaService.create(this.comanda).subscribe(
        (res: ComandaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.comanda = res;
          this.comandaId = this.comanda.id;
          this.dtoToForm();
          this.form.markAsPristine();
        });
    }
  }

  comandaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la comanda?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.comandaService.update(this.comanda).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.comandaRead();
              this.form.markAsPristine();
            });
        }
      });
    }
  }

  comandaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la comanda?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.comandaService.delete(this.comanda.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/gestione-comanda"]);
            }
          );
        }
      });
    }
  }

  comandaRead() {
    this.comandaService.read(this.comandaId).subscribe(
      (res: ComandaDTO) => {
        console.log("response : " + res);
        this.comanda = res;
        this.comandaId = res.id;

        this.totImporto(this.comanda);
        this.dtoToForm();
        this.list();
      }
    );
  }

  comandaInit() {
    this.comanda = new ComandaDTO();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
  }

  private formToDto(): void {
    this.comanda.collaboratore = this.form.get("collaboratore").value;
    this.comanda.dataComanda = this.form.get("dataComanda").value;
    this.comanda.descrizione = this.form.get("descrizione").value;
    this.comanda.email = this.form.get("email").value;
    this.comanda.link = this.form.get("link").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.comanda.id);
    this.form.get("collaboratore").setValue(this.comanda.collaboratore);
    this.form.get("dataComanda").setValue(this.comanda.dataComanda);
    this.form.get("descrizione").setValue(this.comanda.descrizione);
    this.form.get("email").setValue(this.comanda.email);
    this.form.get("link").setValue(this.comanda.link);
    this.form.get("deleteDate").setValue(this.comanda.deleteDate);
    this.form.get("deleteUser").setValue(this.comanda.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.comanda.insertDate));
    this.form.get("insertUser").setValue(this.comanda.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.comanda.updateDate));
    this.form.get("updateUser").setValue(this.comanda.updateUser);
    this.form.get("optLock").setValue(this.comanda.optLock);
    this.form.markAsPristine();
  }


  hideFooterColumns(): boolean {
    let hide: boolean = false;
    this.parameters.dataSource.length > 0 ? hide = true : hide = false;
    return hide;
  }

  ngOnDestroy(): void {
  }


  hiddenColumns(): number[] {
    let ret: number[] = [];
    this.displayedColumns.forEach((element, index) => {
      if (element == "detail") {
        ret.push(index);
      }
    });
    return ret;
  }

  setSort(sort: Sort) {
    this.parameters.sortDirection = sort.direction;
    this.parameters.sortField = sort.active;
    this.list();
  }

  setPaginator(pagination: PageEvent) {
    this.parameters.pageSize = pagination.pageSize;
    this.parameters.pageNumber = pagination.pageIndex;
    this.list();
  }
}
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';

export class DatiAnagraficiSDIDTO extends LogicalDeleteDTO {
    
    id: number;
    tenant: TenantDTO;
	cf: string;
	piva: string;
    denom: string;
    prov: string;
	cap: string;
	com: string;
	ind: string;
	naz: string;
	pec: string;
	cod: string;
}
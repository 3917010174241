import { NavigatorService } from './../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { ComponentCacheService } from '../services/component-cache.service';
import { OnDestroy, OnInit } from '@angular/core';
import { GenericComponent } from './GenericComponent';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';

export abstract class GenericListComponent extends GenericComponent implements OnInit, OnDestroy {

    displayedColumns: string[];

    routerSnapshotUrlFrom: string;

    parameters: any;

    constructor(
        navigatorService: NavigatorService,
        private componentCacheService: ComponentCacheService,
        dateAdapter: DateAdapter<Date>,
        router: Router,
        snackBar: MatSnackBar,
        timestampFormatPipe: TimestampFormatPipe
    ) {
        super(
            navigatorService,
            router,
            dateAdapter,
            snackBar,
            timestampFormatPipe);
    }

    setSort(sort: Sort) {
        this.parameters.sortDirection = sort.direction;
        this.parameters.sortField = sort.active;
        this.list();
    }

    setPaginator(pagination: PageEvent) {
        this.parameters.pageSize = pagination.pageSize;
        this.parameters.pageNumber = pagination.pageIndex;
        this.list();
    }

    ngOnDestroy(): void {
        this.componentCacheService.set(this.routerSnapshotUrlFrom, this.parameters);
    }

    ngOnInit(): void {
        this.routerSnapshotUrlFrom = this.calculateCacheName();    
        if (this.componentCacheService.has(this.routerSnapshotUrlFrom)) {
            this.parameters = this.componentCacheService.get(this.routerSnapshotUrlFrom);
            if (this.parameters.showList) {
                this.list();
            }
        }
    }

    hiddenColumns(): number[] {
        let ret:number[] = [];
        this.displayedColumns.forEach((element, index) => {
            if (element=="detail" || element=="print") {
                ret.push(index);
            }
        });
        return ret;
    }

    calculateCacheName(): string {
        console.log("cache name : " + this.router.routerState.snapshot.url);
        return this.router.routerState.snapshot.url;
    }

    abstract list();
}
import { SedeDTO } from 'src/app/shared/dto/sede/sede';
import { Injectable } from '@angular/core';
import { GenericService } from 'src/app/shared/GenericService';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { ListaAttesaPrenotazionePostazioneDTO } from 'src/app/shared/dto/lista-attesa-prenotazionepostazione/listaattesaprenotazionepostazione';

@Injectable({
  providedIn: 'root'
})
export class ListaAttesaPrenotazionePostazioneService extends GenericService<ListaAttesaPrenotazionePostazioneDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/listaattesaprenotazionepostazione';
   }
   
  public listaattesaprenotazionePostazioneList(pageNumber: number, pageSize: number, direction: string, sortField: string, richiedente?: CollaboratoreDTO, dataDa?: Date , dataA?: Date , sede?: SedeDTO): Observable<ResponseQueryByCriteria<ListaAttesaPrenotazionePostazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (richiedente != null) params["idRichiedente"] = richiedente.id;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    if (sede != null) params["idSede"] = sede.id;
    return this.http.get<ResponseQueryByCriteria<ListaAttesaPrenotazionePostazioneDTO>>(`${this.url}/listaattesaprenotazionepostazioneListByList`, {
      params: params
    });
  }
}

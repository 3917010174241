import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GreenPassService {

  protected url: String;

  constructor(protected http: HttpClient) { 
    this.url = environment.urlPrefix + '/services/rest/json/greenPass';
  }

  public check(data: Date, idCollaboratore: number, qrcode: string): Observable<void> {
    return this.http.put<void>(`${this.url}` + "/" + data.getTime() + "/" + idCollaboratore, qrcode)
  }
}
import { VotoDTO } from 'src/app/shared/dto/proposte/voto';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { GenericService } from 'src/app/shared/GenericService';

@Injectable({
  providedIn: 'root'
})
export class VotoService extends GenericService<VotoDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + "/services/rest/json/voto";
   }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Formazione</h2>
    <p>Imposta i filtri di ricerca</p>

    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field>
        <mat-label>Collaboratori</mat-label>
        <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO" [disabled]="!isJappTenantAdmin()"
          (selectionChange)="this.parameters.collaboratoreChanged = true">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{collaboratore.nome}} {{collaboratore.cognome}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
      <!-- Campo Data Inizio-->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
        [ngStyle.md]="{'padding-right.px': 0}">
        <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataFine"
          [(ngModel)]="parameters.dataInizio" [matDatepicker]="picker" placeholder="Scegli data da">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <button mat-button *ngIf="parameters.dataInizio" matSuffix mat-icon-button aria-label="Clear"
          (click)="parameters.dataInizio=null" (click)="parameters.initDataDa=false">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Campo Data Fine -->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataInizio"
          [(ngModel)]="parameters.dataFine" [matDatepicker]="picker2" placeholder="Scegli data a">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <button mat-button *ngIf="parameters.dataFine" matSuffix mat-icon-button aria-label="Clear"
          (click)="parameters.dataFine=null">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field>
        <mat-label>Corsi Udemy</mat-label>
        <mat-select  [compareWith]="compareDTO" [(ngModel)]="this.parameters.corsoUdemy">
          <mat-option [value]="null">
            ------Visualizza tutti i corsi------
          </mat-option>
          <mat-option *ngFor="let corso of corsiUdemy" [value]="corso">
            {{corso.titolo}} - durata {{corso.ore}}h {{corso.minuti}}min
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/frequentacorso/detail/0']">New</button>
    </mat-card-actions>
    
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
        matSort (matSortChange)="setSort($event)" matSortDisableClear [matSortDirection]="defaultSortDirection"
        [dataSource]="parameters.dataSource">

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let frequentaCorso"> {{frequentaCorso.id }} </td>
          <td fxHide fxShow.gt-md mat-footer-cell *matFooterCellDef ></td>
        </ng-container>

        <!-- Collaboratore Column -->
        <ng-container matColumnDef="collaboratore" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Collaboratore </th>
          <td mat-cell *matCellDef="let frequentaCorso" [hidden]="!isJappTenantAdmin()"> {{frequentaCorso.collaboratore?.nome}} {{frequentaCorso.collaboratore?.cognome}}</td>
          <td mat-footer-cell *matFooterCellDef [hidden]="!isJappTenantAdmin()"></td>
        </ng-container>

        <!-- Coro Udemy Column -->
        <ng-container matColumnDef="corsoUdemy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Corso Udemy </th>
          <td mat-cell *matCellDef="let frequentaCorso">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="icon-font-size corsoLink" (click)="riderect(frequentaCorso.corsoUdemy?.link)">language</mat-icon>
              <div class="corsoLink" [ngStyle]="{'padding': 2 + '%'}" (click)="riderect(frequentaCorso.corsoUdemy?.link)">{{frequentaCorso.corsoUdemy?.titolo}}</div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef ></td>
        </ng-container>
        
        <!-- dataInizio dataFine Column -->
       <ng-container matColumnDef="dataInizio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Inizio - Data Fine </th>
        <td mat-cell *matCellDef="let frequentaCorso">{{frequentaCorso?.dataInizio | date :'dd/MM/yyyy'}} - {{frequentaCorso?.dataFine | date :'dd/MM/yyyy'}}</td>
        <td mat-footer-cell *matFooterCellDef ></td>
      </ng-container>

        <!-- Stato Column -->
       <ng-container matColumnDef="dataFine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
          <td mat-cell *matCellDef="let frequentaCorso" 
          [ngStyle] = "{
            'backgroundColor': frequentaCorso?.dataFine ? 'lawngreen': 'yellow'}"
            >{{visualizzaStato(frequentaCorso?.dataFine)}} </td>
          <td mat-footer-cell *matFooterCellDef ></td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let frequentaCorso">
            <button mat-mini-fab color="primary" [routerLink]="['/frequentacorso/detail/', frequentaCorso.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef ></td>
        </ng-container> 
 
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'green' : visualizzaStato(frequentaCorso?.dataFine)}"></tr>
      </table>
      <br>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'frequentaCorso'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'frequentaCorso'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'frequentaCorso'})">Json</button>
        </mat-card-actions>
      </div>
    </div>
  </mat-card-content>
</mat-card>
import { SedeService } from './../services/sede/sede.service';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { SedeDTO } from '../shared/dto/sede/sede';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-sede-detail',
  templateUrl: './sede-detail.component.html',
  styleUrls: ['./sede-detail.component.scss']
})

export class SedeDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  sedeId: number;
  sede: SedeDTO;

  constructor(
    private sedeService: SedeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      postazioniMassime: new FormControl('', Validators.required),
      pathImmagine: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.sedeId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.sedeId != null && this.sedeId != 0) {
      this.sedeRead();
    } else {
      this.sede = new SedeDTO();
    }
  }

  sedeSave() {
    if (this.sede.id == null) {
      this.sedeCreate();
    } else {
      this.sedeUpdate();
    }
  }

  sedeCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.sedeService.create(this.sede).subscribe(
        (res: SedeDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.sede = res;
          this.sedeId = this.sede.id;
          this.dtoToForm();
          this.form.markAsPristine();
        } , (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidSedeException_MIN_AVIABLE_POSTAZIONI") {
            this.snackBar.open("Attenzione! Le postazioni massime disponibili non possono essere un numero negativo.", null, { duration: 3000 });
          }
        }
      );
    }
  }

  sedeUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la sede?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.sedeService.update(this.sede).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.sedeRead();
              this.form.markAsPristine();
            } , (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidSedeException_MIN_AVIABLE_POSTAZIONI") {
                this.snackBar.open("Attenzione! Le postazioni massime disponibili non possono essere un numero negativo.", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  sedeDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la sede?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.sedeService.delete(this.sede.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/sede"]);
            }
          );
        }
      });
    }
  }

  sedeRead() {
    this.sedeService.read(this.sedeId).subscribe(
      (res: SedeDTO) => {
        console.log("response : " + res);
        this.sede = res;
        this.sedeId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.sede.descrizione = this.form.get("descrizione").value;
    this.sede.postazioniMassime = this.form.get("postazioniMassime").value;
    this.sede.pathImmagine = this.form.get("pathImmagine").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.sede.id);
    this.form.get("descrizione").setValue(this.sede.descrizione);
    this.form.get("pathImmagine").setValue(this.sede.pathImmagine);
    this.form.get("postazioniMassime").setValue(this.sede.postazioniMassime);
    this.form.get("deleteDate").setValue(this.sede.deleteDate);
    this.form.get("deleteUser").setValue(this.sede.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.sede.insertDate));
    this.form.get("insertUser").setValue(this.sede.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.sede.updateDate));
    this.form.get("updateUser").setValue(this.sede.updateUser);
    this.form.get("optLock").setValue(this.sede.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GenericService } from "src/app/shared/GenericService";
import { CollaboratoreDTO } from "src/app/shared/dto/domain/collaboratore";
import { SostituzioneTurnoServiziReperibilitaDTO } from "src/app/shared/dto/gestione-servizi-reperibilita/SostituzioneTurnoServizioReperibilitaDTO";
import { TurnoServiziReperibilitaDTO } from "src/app/shared/dto/gestione-servizi-reperibilita/TurnoServizioReperibilitaDTO";
import { ServiziReperibilitaDTO } from "src/app/shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { ResponseQueryByCriteria } from "src/app/shared/dto/responseQueryByCriteria";
import { environment } from "src/environments/environment";





@Injectable({ providedIn: 'root' })

export class TurniServiziReperibilitaService extends GenericService<TurnoServiziReperibilitaDTO, number> {
  updateTurnoPresent(turnoSost: SostituzioneTurnoServiziReperibilitaDTO) {
    return this.http.post<void>(this.url+"/updateSostutizioneTurno",turnoSost); 

  }
  


  getLastDateforService(servizio: ServiziReperibilitaDTO): Observable<any> {
  if(servizio.id){
    return this.http.get<any>(this.url + "/lastDateperServizio/" + servizio.id)
  }

  }




  turniList(pageNumber: number, pageSize: number, sortDirection: string, sortField: any, collaboratore: CollaboratoreDTO, servizio: ServiziReperibilitaDTO, dataDa: any, dataA: any)
    : Observable<ResponseQueryByCriteria<TurnoServiziReperibilitaDTO>> {
    let params = {};
    if (pageNumber != null) {
      params["pageNumber"] = String(pageNumber);
    }
    if (pageSize != null) {
      params["pageSize"] = String(pageSize);
    }
    if (sortDirection != null) {
      params["direction"] = sortDirection;
    }
    if (sortField != null) {
      params["sortField"] = sortField;
    }
    if (collaboratore != null) {
      params["collaboratore"] = collaboratore.id;
    }
    if (servizio != null) {
      params["servizio"] = servizio.id;
    }
    if (dataDa != null) {
      params["dataDa"] = dataDa.toISOString();
    }
    if (dataA != null) {
      params["dataA"] = dataA.toISOString();
    }
    return this.http.get<ResponseQueryByCriteria<TurnoServiziReperibilitaDTO>>(`${this.url}/turniservizioreperibilitaserviceList`, {
      params: params
    });










  }
  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/gestioneturni-servizio-reperibilita';

  }






}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione collaboratori AM</h2>

        <div fxLayout="column" class="responsive-mat-form">
            <!-- Collaboratore field -->
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
            </mat-form-field>
        </div>

        <!--Bottoni-->
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button id="new-button" [routerLink]="['/collaboratore-am/detail/0']">New</button>
        </mat-card-actions>

        <!--search-->
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Collaboratore AM </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let coll"> {{coll.id}} </td>
                </ng-container>

                <!-- Email Column  -->
                <ng-container matColumnDef="email">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let coll"> {{coll.collaboratore.email}}</td>
                </ng-container>

                <!-- Username Column  -->
                <ng-container matColumnDef="username">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let coll"> {{coll.collaboratore.userName}}</td>
                </ng-container>

                <!-- Nome Column  -->
                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
                    <td mat-cell *matCellDef="let coll"> {{coll.collaboratore.nome}}</td>
                </ng-container>

                <!-- Cognome Column  -->
                <ng-container matColumnDef="cognome">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
                    <td mat-cell *matCellDef="let coll"> {{coll.collaboratore.cognome}}</td>
                </ng-container>

                <!-- Info Column -->
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let coll">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/collaboratore-am/detail/' , coll.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>



            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-actions>
                    <button mat-raised-button
                        (click)="exporter.exportTable('xlsx', {fileName:'collaboratori_am'})">Excel</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('csv', {fileName:'collaboratori_am'})">Csv</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('json', {fileName:'collaboratori_am'})">Json</button>
                </mat-card-actions>
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>


        </div>
        
    </mat-card-content>
</mat-card>
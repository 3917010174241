<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione prenotazioni sala</h2>
    <p>Imposta i filtri di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">
      <!-- Sede field -->
      <mat-form-field>
        <mat-select name="sedi" [(ngModel)]="parameters.sede" (selectionChange)="afterSceltaSede()" (selectionChange)="caricaEventiOnSalettaChange()"
          [compareWith]='compareDTO' placeholder="Scegli la sede">
          <mat-option *ngFor="let sede of sedi" [value]="sede">
            {{sede.descrizione}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Saletta field -->
      <mat-form-field>
        <mat-select name="salette" [(ngModel)]="parameters.saletta" (selectionChange)="caricaEventiOnSalettaChange()" [compareWith]='compareDTO'
          placeholder="Scegli la saletta">
          <mat-option *ngIf="salette != null && salette.length > 0">
            Tutte           
          </mat-option>
          <mat-option *ngFor="let saletta of salette" [value]="saletta">
            {{saletta.descrizione}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Responsive -->
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form" *ngIf="this.parameters.vistaData">
        <!-- DataDa field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
            [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataDa=null" (click)="parameters.initDataDa=false">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- DataA field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-left': 2 + '%'}" [ngStyle.xs]="{'padding-left.px': 0}"
          [ngStyle.sm]="{'padding-left.px': 0}" [ngStyle.md]="{'padding-left.px': 0}">
          <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
            [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataA=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <mat-card-actions>
      <button mat-raised-button *ngIf="this.parameters.vistaData" id="search-button" 
        (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/gestione-prenotazione-saletta/detail/0']">New</button>
    </mat-card-actions>
    <br />

    <!-- Calendario con eventi -->

    <mat-tab-group (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="this.parameters.selectedTab">
      <mat-tab label="Calendario">
        <mwl-utils-calendar-header [(view)]="this.parameters.view" [(viewDate)]="this.parameters.viewDate" [weekStartsOn]="weekStartsOn">
        </mwl-utils-calendar-header>
        <div [ngSwitch]="this.parameters.view">
          <mwl-calendar-month-view (beforeViewRender)="estrazionePeriodoVistaMensile($event)" *ngSwitchCase="'month'" [events]="events" [viewDate]="this.parameters.viewDate" [locale]="locale"
            (eventClicked)="eventClicked($event.event)" [weekStartsOn]="weekStartsOn" (dayClicked)="dayClicked($event.day)"  [activeDayIsOpen]="activeDayIsOpen">
          </mwl-calendar-month-view>

          <mwl-calendar-week-view (beforeViewRender)="estrazionePeriodoVistaMensile($event)" [weekStartsOn]="weekStartsOn" *ngSwitchCase="'week'" [events]="events" [viewDate]="this.parameters.viewDate" [locale]="locale"
           [dayStartHour]="8" [dayEndHour]="18" (eventClicked)="eventClicked($event.event)">
          </mwl-calendar-week-view>

          <mwl-calendar-day-view (beforeViewRender)="estrazionePeriodoVistaMensile($event)" *ngSwitchCase="'day'" [dayStartHour]="8" [dayEndHour]="18" [events]="events" [viewDate]="this.parameters.viewDate" [locale]="locale" (eventClicked)="eventClicked($event.event)">
          </mwl-calendar-day-view>
        </div>

      </mat-tab>
      <mat-tab label="Lista">
        <div fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Prenotazione </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione"> {{prenotazione.id}} </td>
            </ng-container>

            <!-- Collaboratore Column -->
            <ng-container matColumnDef="collaboratore">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione"> {{prenotazione.collaboratore?.nome}}
                {{prenotazione.collaboratore?.cognome}} </td>
            </ng-container>

             <!-- Sede Column -->
             <ng-container matColumnDef="sede">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Sede </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione"> {{prenotazione.saletta?.sede.descrizione}} </td>
            </ng-container>

            <!-- Saletta Column -->
            <ng-container matColumnDef="saletta">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Saletta </th>
              <td mat-cell *matCellDef="let prenotazione"> {{prenotazione.saletta?.descrizione}} </td>
            </ng-container>

            <!-- dataDa Column -->
            <ng-container matColumnDef="dataDa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Da </th>
              <td mat-cell *matCellDef="let prenotazione"> {{prenotazione.dataDa | date:'dd/MM/yyyy HH:mm'}} </td>
            </ng-container>

            <!-- dataA Column -->
            <ng-container matColumnDef="dataA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data A </th>
              <td mat-cell *matCellDef="let prenotazione">
                {{prenotazione.dataA | date:'dd/MM/yyyy HH:mm'}} </td>
            </ng-container>

            <!-- Descrizione Column -->
            <ng-container matColumnDef="descrizione">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione"> {{prenotazione.descrizione}} </td>
            </ng-container>

            <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let prenotazione">
                <button mat-mini-fab color="primary"
                  [routerLink]="['/gestione-prenotazione-saletta/detail/', prenotazione.id]">
                  <mat-icon>search</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-actions>
              <button mat-raised-button
                (click)="exporter.exportTable('xlsx', {fileName:'Prenotazioni Salette'})">Excel</button>
              <button fxHide fxShow.gt-md mat-raised-button
                (click)="exporter.exportTable('csv', {fileName:'Prenotazioni Salette'})">Csv</button>
              <button fxHide fxShow.gt-md mat-raised-button
                (click)="exporter.exportTable('json', {fileName:'Prenotazioni Salette'})">Json</button>
            </mat-card-actions>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
              [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
            </mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
import { UfficioService } from './../services/ufficio/ufficio.service';
import { UfficioDTO } from './../shared/dto/ufficio/ufficio';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SedeService } from '../services/sede/sede.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { NavigatorService } from '../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SedeDTO } from '../shared/dto/sede/sede';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-ufficio-detail',
  templateUrl: './ufficio-detail.component.html',
  styleUrls: ['./ufficio-detail.component.scss']
})
export class UfficioDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  ufficioId: number;
  ufficio: UfficioDTO;
  //auto-compl.
  filteredSedi: SedeDTO[];
  private sedeTimer: any;

  inizializzaSede = () => ( value : SedeDTO | string) : string => {

    if (typeof value == 'string' ){
      return value;
    }
    return value.descrizione;
  }

  constructor(
    private sedeService: SedeService,
    private ufficioService: UfficioService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      sede: new FormControl('',Validators.required),
      postazioniMassime: new FormControl ('',Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.ufficioId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.ufficioId != null && this.ufficioId != 0) {
      this.ufficioRead();
    } else {
      this.ufficio = new UfficioDTO();
    }

    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<SedeDTO>)=> {
        console.log("response : " + res);
          this.filteredSedi = res.content;
          console.log( this.filteredSedi);
      });
  }


  sedeChanged(newValue:string, fromUser:boolean) {
    if (fromUser) {
      clearTimeout(this.sedeTimer);
      this.sedeTimer = setTimeout(() => {
        this.sedeChanged(newValue, false);
      }, 500);
    } else {
      this.sedeService.sedeListByDescrizione(
        0,
        9999,
        'ASC',
        'descrizione',
        newValue
      ).subscribe(
        (res: ResponseQueryByCriteria<SedeDTO>)=> {
          console.log("response : " + res);
            this.filteredSedi = res.content;
            this.filteredSedi.forEach(s => {
              if(s.descrizione == newValue){
                this.form.get("sede").setValue(s); 
              }
            });
        });
      }
  }

  sedeDescrizione(sede: SedeDTO): string {
    return (sede && sede.descrizione) ? sede.descrizione : '';
  }

  ufficioSave() {
    if (this.ufficio.id == null) {
      this.ufficioCreate();
    } else {
      this.ufficioUpdate();
    }
  }

  ufficioCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.ufficioService.create(this.ufficio).subscribe(
        (res: UfficioDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.ufficio = res;
          this.ufficioId = this.ufficio.id;
          this.dtoToForm();
          this.form.markAsPristine();
        } , (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidSedeException_MIN_AVIABLE_POSTAZIONI") {
            this.snackBar.open("Attenzione! Le postazioni massime disponibili non possono essere un numero negativo.", null, { duration: 3000 });
          }
        }
      );
    }
  }

  ufficioUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere l' ufficio?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.ufficioService.update(this.ufficio).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.ufficioRead();
              this.form.markAsPristine();
            } , (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidSedeException_MIN_AVIABLE_POSTAZIONI") {
                this.snackBar.open("Attenzione! Le postazioni massime disponibili non possono essere un numero negativo.", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  ufficioDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare l' ufficio?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.ufficioService.delete(this.ufficio.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/ufficio"]);
            }
          );
        }
      });
    }
  }

  ufficioRead() {
    this.ufficioService.read(this.ufficioId).subscribe(
      (res: UfficioDTO) => {
        console.log("response : " + res);
        this.ufficio = res;
        this.ufficioId = res.id;
        this.dtoToForm();
      }
    );
  }

  isValidFormSede() :boolean{
    console.log(typeof this.form.get("sede").value === "string" || this.form.get("sede").value == null);
    if(typeof this.form.get("sede").value === "string" || this.form.get("sede").value == null ){
      return false;
    }
    return true;
  }

  private formToDto(): void {
    this.ufficio.descrizione = this.form.get("descrizione").value;
    this.ufficio.sede = this.form.get("sede").value;
    this.ufficio.postazioniMassime = this.form.get("postazioniMassime").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.ufficio.id);
    this.form.get("descrizione").setValue(this.ufficio.descrizione);
    this.form.get("sede").setValue(this.ufficio.sede);
    this.form.get("postazioniMassime").setValue(this.ufficio.postazioniMassime);
    this.form.get("deleteDate").setValue(this.ufficio.deleteDate);
    this.form.get("deleteUser").setValue(this.ufficio.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.ufficio.insertDate));
    this.form.get("insertUser").setValue(this.ufficio.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.ufficio.updateDate));
    this.form.get("updateUser").setValue(this.ufficio.updateUser);
    this.form.get("optLock").setValue(this.ufficio.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }

}

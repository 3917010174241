import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';

export class NumeroReperibilitaDTO extends LogicalDeleteDTO {
    
    id: number;
    tenant: TenantDTO;
    descrizione: string;
    mainNumber: String;
    mainNumberId: String;
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Mio Profilo</h2>
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column">
          <!-- Id Field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Collaboratore Id" readonly="true" />
          </mat-form-field>
  
          <!-- E-mailCollaboratore Field -->
          <mat-form-field fxlayout="column">
            <input matInput formControlName="email" placeholder="Email" />
            <mat-error *ngIf="form.get('email').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
  
          <!-- Username Field -->
          <mat-form-field fxlayout="column">
            <input matInput formControlName="userName" placeholder="Username"/>
            <mat-error *ngIf="form.get('userName').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
  
          <!-- Responsive -->
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            <!-- NomeCollaboratore Field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="nome" placeholder="Nome" />
              <mat-error *ngIf="form.get('nome').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>
  
            <!-- Cognome Field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="cognome" placeholder="Cognome" />
              <mat-error *ngIf="form.get('cognome').errors?.required">
                Campo obbligatorio
              </mat-error>
            </mat-form-field>
          </div>
          
          <!-- Telefono Field -->
          <mat-form-field>
            <input matInput type="number" formControlName="telefono" placeholder="Telefono"/>
          </mat-form-field>
  
          <!-- Sede Field -->
          <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
              <mat-label>Sede</mat-label>
              <mat-select name="sede" formControlName="sede">
                <mat-option *ngFor="let sede of sedi" [value]="sede.descrizione">
                  {{sede.descrizione}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> 
          
          <!-- Ruolo Field -->
          <mat-form-field>
            <input matInput formControlName="ruolo" placeholder="Ruolo" />
          </mat-form-field>

          <!-- Compleanno Field -->
          <div fxLayout="row" class="responsive-mat-form" fxLayout.xs="column" fxLayout.sm="column">
            <mat-form-field style="width: 100%;">
              <input matInput readonly="true" (click)="picker.open()"
              [matDatepicker]="picker" formControlName="compleannoPersonale" placeholder="Compleanno personale">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
              <mat-checkbox fxFlex class = "checkboxCompleanno" formControlName="checkboxCompleanno" > Nascondi compleanno</mat-checkbox>
          </div> 

        <!-- JeniaCompleanno Field -->
        <mat-form-field>
         <input matInput readonly="true" (click)="picker2.open()" 
              [matDatepicker]="picker2" formControlName="jeniaCompleanno" placeholder="JeniaCompleanno">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">

              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>
  
              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">

              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>
  
              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="collaboratoreSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="collaboratore?.deleteDate==null">Save</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';

export class ColloquioDTO extends LogicalDeleteDTO{
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    dataColloquio: Date;
    titolo: string;
    descrizione: string;
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione sala</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <!-- Sede field -->
            <mat-form-field>
                <mat-label>Sede</mat-label>
                <mat-select [(value)]="parameters.sede" [compareWith]="compareDTO"
                    [disabled]="!isJappTenantAdmin()">
                    <mat-option *ngFor="let sede of sedi" [value]="sede">
                        {{sede.descrizione}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
            </mat-form-field>
        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button id="new-button" [routerLink]="['/saletta/detail/0']">New</button>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">




                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th fxHide fxShow.gt-sm mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td fxHide fxShow.gt-sm mat-cell *matCellDef="let saletta"> {{saletta.id}} </td>
                </ng-container>

                <!-- Id Column -->
                <ng-container matColumnDef="sede">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sede </th>
                    <td mat-cell *matCellDef="let saletta"> {{saletta.sede.descrizione}} </td>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="descrizione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let saletta"> {{saletta.descrizione}} </td>
                </ng-container>

                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let saletta">
                        <button mat-mini-fab color="primary" [routerLink]="['/saletta/detail/', saletta.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-actions>
                    <button mat-raised-button
                        (click)="exporter.exportTable('xlsx', {fileName:'salette'})">Excel</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('csv', {fileName:'salette'})">Csv</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('json', {fileName:'salette'})">Json</button>
                </mat-card-actions>
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
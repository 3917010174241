<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Registra presenza</h2>
    <div *ngIf="qrcode" fxLayout="column">
      <div layout="row" fxFlex class="mainDiv">
        <zxing-scanner #scanner [formats]="['QR_CODE']" (scanSuccess)="qrcodeReaded($event)"
          (autostarted)="scannerStarted($event)" (camerasFound)="scannerCamerasFound($event.value)"
          class="qrcodeScanner">
        </zxing-scanner>

        <mat-select *ngIf="!isMobile" (selectionChange)="onDeviceChange($event.value)"
          placeholder="Scegli la fotocamera" class="matSelect">
          <mat-option *ngFor="let device of availableDevices" [value]="device">
            {{device.label}}
          </mat-option>
        </mat-select>

        <button fxHide="true" fxShow.gt-xs="true" mat-fab color="primary" *ngIf="manualSwitchEnabled"
          (click)="enterManually()" style="position: relative; margin-left : -100px; margin-top : 30px">
          <mat-icon>pan_tool</mat-icon>
        </button>

      </div>

      <!-- Visualizzazione solo mobile -->
      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <button fxHide="false" fxShow.gt-xs="false" mat-fab color="primary" *ngIf="manualSwitchEnabled"
          (click)="enterManually()">
          <mat-icon>pan_tool</mat-icon>
        </button>
      </div>

    </div>
    <div *ngIf="!qrcode">
      <div fxLayout="column" class="responsive-mat-form">
        <!-- Location field -->
        <mat-form-field>
          <mat-label>Luogo di lavoro</mat-label>
          <mat-select [(value)]="presenza.location">
            <mat-option *ngFor="let location of locations" [value]="location">
              {{location.descrizione}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Ore field -->
        <mat-form-field>
          <mat-label>Ore</mat-label>
          <input matInput type="number" [(ngModel)]="presenza.numeroDiOre">
        </mat-form-field>
      </div>
      <mat-card-actions>
        <button mat-stroked-button (click)="presenzaSave()"
          [disabled]="presenza.location==null || presenza.id!=null">Save</button>
        <button mat-stroked-button (click)="scanQrcode()">
          <mat-icon>photo_camera</mat-icon>
        </button>
      </mat-card-actions>
    </div>
  </mat-card-content>
</mat-card>
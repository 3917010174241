import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { TimestampFormatPipe } from "src/app/commons/timestampFormatPipe";
import { CategoriaEventoService } from "src/app/services/evento/categoria-evento.service";
import { NavigatorService } from "src/app/services/navigator.service";
import { CategoriaEventoDTO } from "src/app/shared/dto/evento/categoria-evento";
import { GenericDetailComponent } from "src/app/shared/GenericDetailComponent";

@Component({
  selector: 'app-categoria-detail',
  templateUrl: './categoria-detail.component.html',
  styleUrls: ['./categoria-detail.component.scss']
})
export class CategoriaDetailComponent extends GenericDetailComponent implements OnInit {

  categoriaId: number;
  categoria: CategoriaEventoDTO;

  shouldDisable = false;

  constructor(
    protected navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private categoriaService: CategoriaEventoService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>,) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );

    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      nome: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.form.enable();
    this.shouldDisable = false;

    this.categoriaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.categoriaId != null && this.categoriaId != 0 && !isNaN(this.categoriaId)) {
      this.categoriaRead();
    } else {
      this.categoriaInit();
    }
  }

  public categoriaRead() {
    this.categoriaService.read(this.categoriaId).subscribe((categoria: CategoriaEventoDTO) => {
      this.categoria = categoria;
      this.dtoToForm();
    });
  }

  public categoriaInit() {
    this.categoria = new CategoriaEventoDTO();
  }

  public categoriaSave() {
    if (this.categoriaId == 0)
      this.categoriaCreate()
    else
      this.categoriaUpdate();
  }

  private categoriaCreate() {
    this.categoria = this.formToDto();

    this.categoriaService.create(this.categoria).subscribe({
      next: response => {
        this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
        this.categoria = response;
        this.categoriaId = this.categoria.id;
        this.dtoToForm();
        this.form.markAsPristine();
      }
    });
  }

  private categoriaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la categoria?")
        .pipe(
          switchMap(confirm => {
            this.categoria = this.formToDto();
            return this.categoriaService.update(this.categoria);
          })
        ).subscribe({
          next: (res) => {
            this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
            this.categoriaRead();
            this.form.markAsPristine();
          }
        });
    }
  }

  public categoriaDelete() {
    this.confirm("Vuoi davvero cancellare questa categoria?")
      .pipe(
        switchMap(confirm => {
          return this.categoriaService.delete(this.categoriaId);
        })
      ).subscribe({
        next: response => {
          this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
          this.router.navigate(["/evento"]);
        }
      });
  }

  public formToDto(): CategoriaEventoDTO {
    let categoria = new CategoriaEventoDTO();
    categoria.id = this.form.get('id').value;
    categoria.nome = this.form.get('nome').value;

    return categoria;
  }

  public dtoToForm() {
    this.form.get("id").setValue(this.categoriaId);
    this.form.get("nome").setValue(this.categoria.nome);
    this.form.markAsPristine();
  }

}
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { CustomDateFormatter } from '../commons/calendar/custom-calendar-date-formater';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { AssenzaService } from '../services/assenza/assenza.service';
import { MalattiaService } from '../services/malattia/malattia.service';
import { ChiusuraService } from '../services/assenza/chiusura.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { NavigatorService } from '../services/navigator.service';
import { AssenzaDTO } from '../shared/dto/assenza/assenza';
import { MalattiaDTO } from '../shared/dto/malattia/malattia';
import { ChiusuraDTO } from '../shared/dto/assenza/chiusura';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
@Component({
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    },
  ],
  selector: 'app-calendario-amministrazione',
  templateUrl: './calendario-amministrazione.component.html',
  styleUrls: ['./calendario-amministrazione.component.scss']
})
export class CalendarioAmministrazioneComponent extends GenericListComponent implements OnInit {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private collaboratoreService: CollaboratoreService,
    private assenzaService: AssenzaService,
    private malattiaService: MalattiaService,
    private chiusuraService: ChiusuraService,
    navigatorService: NavigatorService,
    router: Router,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService, componentCacheService, dateAdapter, router, snackBar, timestampFormatPipe);
    this.parameters = {
      collaboratore: null,
      collaboratoreChanged: false,
      dataDaCalendario: null,
      dataACalendario: null,
      view: CalendarView.Month,
      viewDate: new Date(),
    }
  }

  collaboratoreDTO: CollaboratoreDTO;

  collaboratori: CollaboratoreDTO[];

  eventsAssenza: CalendarEvent<AssenzaDTO>[];

  eventsMalattia: CalendarEvent<MalattiaDTO>[];

  eventsChiusureAziendali: CalendarEvent<ChiusuraDTO>[];

  locale: string = 'it';

  activeDayIsOpen: boolean = false;

  dataCalendario: Date;

  events: CalendarEvent[];

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;


  fetchEvents(collaboratore: CollaboratoreDTO, data: Date): void {

    //EVENTO CHIUSURE AZIENDALI
    this.chiusuraService.searchAllByday(
      data
    ).subscribe((res: ChiusuraDTO[]) => {
      this.eventsChiusureAziendali = res.map((chiusura: ChiusuraDTO) => {
        return {
          id: "chiusura",
          title: chiusura.descrizione,
          start: new Date(chiusura.dataDa),
          cssClass: 'custom-template-cal',
          color: {
            primary: '#ff5e1e',
            secondary: '#ffc8b3',
          },
          allDay: true,
          end: new Date(chiusura.dataA),
          meta: {
            chiusura
          },
        } as CalendarEvent
      })

      //EVENTO ASSENZE
      this.assenzaService.searchAllByday(
        data
      ).subscribe((res: AssenzaDTO[]) => {
        this.eventsAssenza = res.map((assenza: AssenzaDTO) => {
          return {
            id: "assenza",
            title: assenza.richiedente.nome + " " + assenza.richiedente.cognome + " " + assenza.tipoRichiesta.toLocaleLowerCase() + " " + (assenza.ore > 0 ? (" da " + assenza.ore + (assenza.ore == 1 ? " ora" : " ore")) : ""),
            start: new Date(assenza.dataDa),
            allDay: assenza.tipoRichiesta == "FERIE" ? true : false,
            cssClass: 'custom-template-cal',
            color: {
              primary: '#1eff61',
              secondary: '#c9ffd9',
            },
            end: new Date(assenza.dataA),
            meta: {
              assenza
            },
          } as CalendarEvent
        })

        //EVENTO MALATTIA
        this.malattiaService.searchAllByday(
          data
        ).subscribe((res: MalattiaDTO[]) => {
          this.eventsMalattia = res.map((malattia: MalattiaDTO) => {
            return {
              id: "malattia",
              title: malattia.collaboratore.nome + " " + malattia.collaboratore.cognome + " in malattia",
              start: new Date(malattia.dataDa),
              cssClass: 'custom-template-cal',
              color: {
                primary: '#ff0000',
                secondary: '#ff4d4d',
              },
              end: new Date(malattia.dataA),
              meta: {
                malattia
              },
            } as CalendarEvent
          })
          this.events = [...this.eventsAssenza, ...this.eventsChiusureAziendali, ...this.eventsMalattia];
        })
      })
    })
  }

  list() {
  }


  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  eventClicked(event: CalendarEvent): void {
    if (event.id == "presenza") {
      this.router.navigate(["/gestione-presenza/detail/" + event.meta.presenza.id]);
    }
    if (event.id == "assenza") {
      this.router.navigate(["/registra-assenza/detail/" + event.meta.assenza.id]);
    }
    if (event.id == "chiusura") {
      this.router.navigate(["/chiusura/detail/" + event.meta.chiusura.id]);
    }
    if (event.id == "malattia") {
      this.router.navigate(["/registra-malattia/detail/" + event.meta.malattia.id]);
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else if (this.isMobile) {
        this.activeDayIsOpen = true;
      }
      this.parameters.viewDate = date;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe((res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      });
      this.fetchEvents(this.parameters.collaboratore, this.parameters.dataCalendario);
  }


}

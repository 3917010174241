import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { GenericListComponent } from "../shared/GenericListCoumponent";
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarMonthViewDay, CalendarView, DAYS_OF_WEEK } from "angular-calendar";
import { CustomDateFormatter } from "../commons/calendar/custom-calendar-date-formater";
import { CollaboratoreDTO } from "../shared/dto/domain/collaboratore";
import { TurnoServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/TurnoServizioReperibilitaDTO";
import { TurniServiziReperibilitaService } from "../services/gestione-servizi-reperibilita/turniservizireperibilita.service";
import { CollaboratoreService } from "../services/domain/collaboratore.service";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { Router } from "@angular/router";
import { ComponentCacheService } from "../services/component-cache.service";
import { NavigatorService } from "../services/navigator.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DateAdapter } from '@angular/material/core';
import { ServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { ServiziRepService } from "../services/gestione-servizi-reperibilita/serviziRep.service";
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { isSameDay, isSameMonth } from "date-fns";
import { DatePipe } from "@angular/common";


@Component({
    selector: 'app-gestione-turni-servizi-rep',
    templateUrl: './gestione-turni-servizi-rep.component.html',
    styleUrls: ['./gestione-turni-servizi-rep.component.scss'],
    providers: [
        {
          provide: CalendarDateFormatter,
          useClass: CustomDateFormatter
        }
      ],
  })
  export class GestioneTurniServiziComponent extends GenericListComponent implements OnInit, OnDestroy{
    collaboratori: CollaboratoreDTO[];
    collaboratore: CollaboratoreDTO;
    collaboratoreAttuale: CollaboratoreDTO;
    servizi: ServiziReperibilitaDTO[];
    servizio: ServiziReperibilitaDTO;
    today: Date;



    vistaData: boolean = false;




     //CALENDARIO 
  view: CalendarView = CalendarView.Month;

  weekView: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  events: CalendarEvent<{ prenotazionepostazione: TurnoServiziReperibilitaDTO }>[];

  activeDayIsOpen: boolean = false;

  selectedMonthViewDay: CalendarMonthViewDay;

  selectedDayViewDate: Date;

  selectedDays: any = [];
  @Input() locale: string = 'it';

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;
  constructor(
    private turnoReperibilitaService: TurniServiziReperibilitaService,
    private collaboratoreService: CollaboratoreService,
    private servizioReperibilitaService: ServiziRepService,

    private cd: ChangeDetectorRef,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ){
      super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe
      );
    this.displayedColumns = ['id', 'dataDa', 'dataA', 'collaboratore', 'servizio.descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      initDataDa: true,
      collaboratoreChanged: false,
      view: CalendarView.Month,
      viewDate: new Date(),
      dataDaCal: null,
      dataACal: null,
      showList: false,
      filter: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.today = new Date();










  }
  estrazionePeriodoVista(event: CalendarMonthViewBeforeRenderEvent) {
    if ((this.parameters.dataDaCal == null || event.period.start.getTime() != this.parameters.dataDaCal.getTime())
      || (this.parameters.dataACal == null || event.period.end.getTime() != this.parameters.dataACal.getTime())) {
      this.popolaCalendario(event.period.start, event.period.end);
    }
  }
  popolaCalendario(dataDa: Date, dataA: Date) {
    this.turnoReperibilitaService.turniList(
      this.parameters.pageNumber,
      9999,
      this.parameters.sortDirection,
      "id",
      null,
      this.parameters.servizio,
      dataDa,
      dataA
    ).subscribe((response: ResponseQueryByCriteria<TurnoServiziReperibilitaDTO>) => {
      this.parameters.dataDaCal = dataDa;
      this.parameters.dataACal = dataA;
      console.log(response.content)

      this.mapEvent(response);
    })
  }
  popolaCalendarioOnFilterChange() {
    this.turnoReperibilitaService.turniList(
      this.parameters.pageNumber,
      9999,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.servizio,
      null,
      null
    ).subscribe((response: ResponseQueryByCriteria<TurnoServiziReperibilitaDTO>) => this.mapEvent(response))
  }
  




  private mapEvent(response: ResponseQueryByCriteria<TurnoServiziReperibilitaDTO>) {
    console.log(response.content)
    this.events = response.content.map((turno: TurnoServiziReperibilitaDTO) => {
      
      

      return {
        title: ` ${turno.collaboratore.nome} ${turno.collaboratore.cognome} - ${turno.servizio.descrizione}   -   ${(turno.servizio.numeroReperibilita.mainNumber)}`,
        start: new Date(
          turno.dataDa
        ),
        end: new Date(
          turno.dataA
        ),
        allDay: true,
        color: {
          primary: '#e3bc08',
          secondary: '#FDF1BA',
        },
        meta: {
          turno,
        },
        cssClass: ""
      } as CalendarEvent;
    });
  }





  list() {
    this.turnoReperibilitaService.turniList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.direction,
      this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.servizio,
      this.parameters.dataDa,
      this.parameters.dataA
    ).subscribe((response: ResponseQueryByCriteria<TurnoServiziReperibilitaDTO>) => {
        console.log(response.content)
      this.parameters.dataSource = response.content;
      this.parameters.pageNumber = response.pageNumber;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    }
    )
  }
  onTabChange(event: MatTabChangeEvent) {
    event.index != 0 ? this.vistaData = true : this.vistaData = false;
    console.log(this.parameters.sortField)
  }

  
 



  ngOnInit(): void {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.collaboratore = res;
          this.parameters.collaboratore = this.collaboratore;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;
        }
      );
      if (this.parameters.dataDaCal != null && this.parameters.dataACal != null )
        this.popolaCalendario(this.parameters.dataDaCal, this.parameters.dataACal);
  
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.collaboratoreAttuale = res;
        }
      );
      

      this.servizioReperibilitaService.serviziList(
        0,
        9999,
        'ASC',
        'descrizione',
        '',
        '',
        null).subscribe(
          (res: ResponseQueryByCriteria<ServiziReperibilitaDTO>) => {
            this.servizi = res.content;
          }
        );






  
      if (this.parameters.dataDa == null && this.parameters.initDataDa) {
        this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      }
      

      
  }
  setViewDate() {
    this.parameters.viewDate = this.parameters.dataDa;
  }




  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {

    body.forEach((day) => {
      if (
        day.events.some(
          (_event) => _event.meta.turno.collaboratore.id == this.collaboratoreAttuale.id
        )
      ) {}
    });
  }



  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ turno: TurnoServiziReperibilitaDTO }>[];
  }): void {

    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
            this.activeDayIsOpen = true;
            this.parameters.viewDate = date;
      }
    }
  }




  eventClicked(event: CalendarEvent<{ turno: TurnoServiziReperibilitaDTO }>): void {
    if (this.collaboratoreAttuale.id == event.meta.turno.collaboratore.id || this.navigatorService.isJappTenantAdmin()) {
      this.router.navigate(["/gestione-turni-servizi-rep/detail/" + event.meta.turno.id]);
    }
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }



 




  











    
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { AcquistoService } from '../services/acquisti/acquisto.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { AcquistoDTO } from '../shared/dto/acquisto/acquisto';

@Component({
  selector: 'app-richiesta-acquisto',
  templateUrl: './richiesta-acquisto.component.html',
  styleUrls: ['./richiesta-acquisto.component.scss']
})
export class RichiestaAcquistoComponent extends GenericListComponent implements OnInit, OnDestroy {


  collaboratore: CollaboratoreDTO;
  collaboratori: CollaboratoreDTO[];
  statoRichiestaValues: SelectedValue[];

  constructor(
    private collaboratoreService: CollaboratoreService,
    private acquistoService: AcquistoService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe) {
      super(
        navigatorService,
        componentCacheService,
        dateAdapter,
        router,
        snackBar,
        timestampFormatPipe);
      this.displayedColumns = ['id', 'statoRichiesta', 'richiedente', 'approvatore', 'descrizione', 'url', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        stato: null,
        richiedente: null,
        sortField: null,
        sortDirection: null,
        pageNumber: 0,
        pageSize: 50,
        length: 0
      }
      this.statoRichiestaValues = [
        { value: null, viewValue: '---Seleziona---' },
        { value: 'APPROVATO', viewValue: 'APPROVATO' },
        { value: 'DA_APPROVARE', viewValue: 'DA APPROVARE' },
        { value: 'RIFIUTATO', viewValue: 'RIFIUTATO' }
      ];
    }

  ngOnInit() {
    super.ngOnInit();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        this.parameters.richiedente = this.collaboratore;
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }

  list() {
    this.acquistoService.acquistoListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.richiedente,
      this.parameters.stato).subscribe(
        (res: ResponseQueryByCriteria<AcquistoDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }
  
  trascodificaStatoRichiesta(stato: String): String {
    var msg: String;
    if (stato == 'DA_APPROVARE') {
      msg = 'DA APPROVARE';
    } else if (stato == 'APPROVATO') {
      msg = 'APPROVATO';
    } else if (stato == 'RIFIUTATO') {
      msg = 'RIFIUTATO';
    }
    return msg;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

export interface SelectedValue {
  value: string;
  viewValue: string;
}

import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { CalendarEvent, CalendarEventTitleFormatter } from "angular-calendar";


@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
    }

    month(event: CalendarEvent): string {
        return `
            ${event.title}, ${event.meta.prenotazioneSaletta.saletta.descrizione}, ${event.meta.prenotazioneSaletta.collaboratore.cognome} ${event.meta.prenotazioneSaletta.collaboratore.nome}`;
        ;
    }
    week(event: CalendarEvent): string {
        return `${event.title},<br /> ${event.meta.prenotazioneSaletta.saletta.descrizione}<br /> ${event.meta.prenotazioneSaletta.collaboratore.cognome} ${event.meta.prenotazioneSaletta.collaboratore.nome}`;
        ;
    }
    day(event: CalendarEvent): string {
        return `${event.title}, ${event.meta.prenotazioneSaletta.saletta.descrizione}, ${event.meta.prenotazioneSaletta.collaboratore.cognome} ${event.meta.prenotazioneSaletta.collaboratore.nome}`;
        ;
    }
}
import { AssenzaDTO } from './../shared/dto/assenza/assenza';
import { PresenzaService } from './../services/presenze/presenza.service';
import { AssenzaService } from './../services/assenza/assenza.service';
import { PresenzaDTO } from './../shared/dto/presenze/presenza';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { Component, OnInit } from '@angular/core';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-ricerca-collaboratore',
  templateUrl: './ricerca-collaboratore.component.html',
  styleUrls: ['./ricerca-collaboratore.component.scss']
})

export class RicercaCollaboratoreComponent implements OnInit {

  public collaboratori: CollaboratoreDTO[];
  public assenze: AssenzaDTO[] = null;
  public collaboratoreSelect: CollaboratoreDTO;
  public presenze: PresenzaDTO[];
  public hiddenResult: boolean = true;
  public isPresente: boolean = false;
  form: FormGroup;
  filteredCollaboratore: CollaboratoreDTO[];
  private collaboratoreTimer: any;
  filter: FormControl;

  inizializzaCollaboratore = () => ( value : CollaboratoreDTO | string) : string => {
    if (typeof value == 'string' ){
      return value;
    }
    const str = value.nome + ' ' + value.cognome;
    return str;
  }

  constructor(
    private collaboratoreService: CollaboratoreService,
    private presenzaService: PresenzaService,
    private assenzaService: AssenzaService) { 
      this.filter = new FormControl();
      this.form = new FormGroup({
        id: new FormControl({ value: '', disabled: true }),
        email: new FormControl({ value: '', disabled: true}),
        userName: new FormControl({ value: '', disabled: true }),
        nome: new FormControl({ value: '', disabled: true }),
        cognome: new FormControl({ value: '', disabled: true }),
        telefono: new FormControl({ value: '', disabled: true }),
        sede: new FormControl({ value: '', disabled: true }),
        ruolo: new FormControl({ value: '', disabled: true }),
      });
    }

  ricercaPresenza(collaboratore: CollaboratoreDTO) {
    let data = new Date();
    data.setHours(0);
    data.setMinutes(0);
    data.setSeconds(0);
    data.setMilliseconds(0);
    this.presenzaService.presenzaByCollaboratoreAndGiorno(
      collaboratore.id,
      new Date()).subscribe(
        (res: PresenzaDTO[]) => {
          console.log(res);
          this.presenze = res;
          this.collaboratoreSelect = collaboratore;
          this.hiddenResult = false;
          this.isPresente = false;
          this.assenzaService.assenzaListByList(
            0,
            10,
            'ASC',
            'id',
            this.collaboratoreSelect,
            null,
            data,
            data,
            null).subscribe(
              (res: ResponseQueryByCriteria<AssenzaDTO>) => {
                console.log(res.content);
                this.assenze = res.content;
              }
            )
        }
      );
  }

  collaboratoreChanged(newValue:string, fromUser:boolean) {
    if (fromUser) {
      clearTimeout(this.collaboratoreTimer);
      this.collaboratoreTimer = setTimeout(() => {
        this.collaboratoreChanged(newValue, false);
      }, 500);
    } else {
      this.collaboratoreService.collaboratoreList(
        0,
      9999,
      'ASC',
      'nome',
      newValue)
      .subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>)=> {
          console.log("response : " + res);
            this.filteredCollaboratore = res.content;
            console.log( this.filteredCollaboratore);
        });
      }
  }

  determinaStato() {
    this.dtoToForm();
    if (this.presenze[0] == null) {
      if (this.assenze[0] == null) {
        return <String>(this.collaboratoreSelect.nome + ' ' + this.collaboratoreSelect.cognome + ' <b>non risulta ancora presente</b>');
      }
      else {
        return <String>(this.collaboratoreSelect.nome + ' ' + this.collaboratoreSelect.cognome + ' è <b>assente giustificato</b>.');
      }
    } else {
      this.isPresente = true;
    }
    
  }

  ngOnInit() {
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log(res.content);
          this.collaboratori = res.content;
        }
      );
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.collaboratoreSelect.id);
    this.form.get("email").setValue(this.collaboratoreSelect.email);
    this.form.get("userName").setValue(this.collaboratoreSelect.userName);
    this.form.get("nome").setValue(this.collaboratoreSelect.nome);
    this.form.get("cognome").setValue(this.collaboratoreSelect.cognome);
    this.form.get("telefono").setValue(this.collaboratoreSelect.telefono);
    this.form.get("sede").setValue(this.collaboratoreSelect.sede);
    this.form.get("ruolo").setValue(this.collaboratoreSelect.ruolo);
    this.form.markAsPristine();
  }
  

}
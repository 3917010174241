import { StoriaChiaviDTO } from './../shared/dto/gestioneChiavi/storiaChiavi';
import { StoriaChiaviService } from './../services/gestione-chiavi/storia-chiavi.service';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-storia-chiavi',
  templateUrl: './storia-chiavi.component.html',
  styleUrls: ['./storia-chiavi.component.scss']
})

export class StoriaChiaviComponent extends GenericListComponent implements OnInit, OnDestroy {

  constructor(
    private storiaChiaviService: StoriaChiaviService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'chiavi', 'collaboratore', 'dataDa', 'dataA'];
    this.parameters = {
      dataSource: [],
      userCollaboratore: null,
      descrizioneChiave: null,
      showList: false,
      collaboratore: null,
      chiavi: null,
      dataDa: null,
      dataA: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.storiaChiaviService.storiaChiaviList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.descrizioneChiave,
      this.parameters.userCollaboratore,
      this.parameters.dataDa,
      this.parameters.dataA)
      .subscribe(
        (res: ResponseQueryByCriteria<StoriaChiaviDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;

        }
      )
  }

  ngOnInit() {
    super.ngOnInit();

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from "@angular/router";
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewDay,
  CalendarView,
  DAYS_OF_WEEK,
} from "angular-calendar";
import { isSameDay, isSameMonth } from "date-fns";
import { CustomDateFormatter } from "../commons/calendar/custom-calendar-date-formater";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { ComponentCacheService } from "../services/component-cache.service";
import { NavigatorService } from "../services/navigator.service";
import { CollaboratoreDTO } from "../shared/dto/domain/collaboratore";
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { GenericListComponent } from "../shared/GenericListCoumponent";
import { CollaboratoreService } from "../services/domain/collaboratore.service";
import { Stampante3DDTO } from "../shared/dto/stampante3D/stampante3D";
import { Stampante3DService } from "../services/stampante3D/stampante3D.service";
import { SedeService } from "../services/sede/sede.service";
import { SedeDTO } from "../shared/dto/sede/sede";
import { PrenotazioneStampante3DDTO } from "../shared/dto/prenotazione-stampante3D/prenotazione-stampante3D";
import { PrenotazioneStampante3DService } from "../services/prenotazione-stampante3D/prenotazione-stampante3D.service";

@Component({
  selector: "app-prenotazione-stampante3D",
  templateUrl: "./prenotazione-stampante3D.component.html",
  styleUrls: ["./prenotazione-stampante3D.component.scss"],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class PrenotazioneStampante3DComponent
  extends GenericListComponent
  implements OnInit, OnDestroy {
  stampanti3D: Stampante3DDTO[];
  stampante3D: Stampante3DDTO;
  collaboratori: CollaboratoreDTO[];
  sedi: SedeDTO[];
  collaboratore: CollaboratoreDTO;
  collaboratoreAttuale: CollaboratoreDTO;
  vistaData: boolean = false;

  //CALENDARIO
  view: CalendarView = CalendarView.Month;

  weekView: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  events: CalendarEvent<{ prenotazionestampa: PrenotazioneStampante3DDTO }>[];

  activeDayIsOpen: boolean = false;

  selectedMonthViewDay: CalendarMonthViewDay;

  selectedDayViewDate: Date;

  selectedDays: any = [];

  @Input() locale: string = "it";

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;

  constructor(
    private prenotazioneStampante3DService: PrenotazioneStampante3DService,
    private stampante3DService: Stampante3DService,
    private sedeService: SedeService,
    private collaboratoreService: CollaboratoreService,
    private cd: ChangeDetectorRef,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe
    );
    this.displayedColumns = [
      "id",
      "data",
      "orarioInizioStampa",
      "ore",
      "stampante3D.codice",
      "stampante3D.sede.descrizione",
      "stampante3D.colore",
      "collaboratore",
      "detail",
    ];
    this.parameters = {
      dataSource: [],
      initDataDa: true,
      collaboratoreChanged: false,
      view: CalendarView.Month,
      viewDate: new Date(),
      dataDaCal: null,
      dataACal: null,
      showList: false,
      filter: null,
      sortFields: ["data", "orarioInizioStampa"],
      sortDirection: "ASC",
      pageNumber: 0,
      pageSize: 50,
      length: 0,
    };
  }

  estrazionePeriodoVista(event: CalendarMonthViewBeforeRenderEvent) {
    if (
      this.parameters.dataDaCal == null ||
      event.period.start.getTime() != this.parameters.dataDaCal.getTime() ||
      this.parameters.dataACal == null ||
      event.period.end.getTime() != this.parameters.dataACal.getTime()
    ) {
      this.popolaCalendario(event.period.start, event.period.end);
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.vistaData = event.index != 0;
    this.list();
  }

  onFieldChange(event: any) {
    if (!this.vistaData) {
      this.list();
    }
  }

  list() {
    this.popolaCalendario(this.parameters.dataDa, this.parameters.dataA);
  }

  popolaCalendario(dataDa: Date, dataA: Date) {
    let pageSize = this.vistaData ? this.parameters.pageSize : 9999;
    let start = this.vistaData ? dataDa : null;
    let end = this.vistaData ? dataA : null;

    this.prenotazioneStampante3DService
      .prenotazionestampante3DSearchByList(
        this.parameters.pageNumber,
        pageSize,
        this.parameters.sortDirection,
        this.parameters.sortFields,
        this.parameters.collaboratore,
        this.parameters.stampante3D,
        this.parameters.sede,
        start,
        end
      )
      .subscribe((response: ResponseQueryByCriteria<PrenotazioneStampante3DDTO>) => {
        this.parameters.dataDaCal = dataDa;
        this.parameters.dataACal = dataA;
        this.parameters.dataSource = response.content;
        this.parameters.pageNumber = response.pageNumber;
        this.parameters.length = response.totalElements;
        this.parameters.showList = true;

        this.events = response.content
          .map((prenotazionestampa: PrenotazioneStampante3DDTO) => {
            return this.getCalendarEvent(prenotazionestampa);
          })
          .sort((a, b) =>
            this.comparePrenotazioneStampante3D(a.meta.prenotazionestampa, b.meta.prenotazionestampa)
          );
      });
  }

  private comparePrenotazioneStampante3D(
    dto1: PrenotazioneStampante3DDTO,
    dto2: PrenotazioneStampante3DDTO,
  ): number {
    let compareSede = this.compareString(
      dto1.stampante3D.sede.descrizione,
      dto2.stampante3D.sede.descrizione
    );
    if (compareSede != 0) {
      return compareSede;
    } else {
      let compareStampante = this.compareString(
        dto1.stampante3D.codice,
        dto2.stampante3D.codice
      );
      if (compareStampante != 0) {
        return compareStampante;
      } else {
        return dto1.orarioInizioStampa - dto2.orarioInizioStampa;
      }
    }
  }

  private compareString(s1: string, s2: string): number {
    if (s1 > s2) {
      return 1;
    } else if (s1 < s2) {
      return -1;
    } else {
      return 0;
    }
  }

  private formatTime(time: number): string {
    if(time == null) {
      return null;
    }
    if (time === 0 || time === 24) {
      return "00";
    }
    return time.toString().padStart(2, "0");
  }

  getCalendarEvent(prenotazionestampa: PrenotazioneStampante3DDTO): CalendarEvent {
    let orarioInizioStampaFormatted = this.formatTime(
      prenotazionestampa.orarioInizioStampa
    );
    let orarioFineStampaFormatted = this.formatTime(
      prenotazionestampa.orarioFineStampa
    );

    return {
      title: `${prenotazionestampa.collaboratore.nome} ${prenotazionestampa.collaboratore.cognome} - ${orarioInizioStampaFormatted}:00 | ${orarioFineStampaFormatted}:00 - ${prenotazionestampa.stampante3D.codice}`,
      start: new Date(prenotazionestampa.data),
      end: new Date(prenotazionestampa.data),
      allDay: true,
      color: {
        primary: prenotazionestampa.stampante3D.colore,
        secondary: "#FDF1BA",
      },
      meta: {
        prenotazionestampa,
      },
    } as CalendarEvent;
  }

  ngOnInit() {
    super.ngOnInit();

    this.navigatorService.collaboratore.subscribe((res: CollaboratoreDTO) => {
      this.collaboratoreAttuale = res;
    });

    this.collaboratoreService
      .collaboratoreList(0, 9999, "ASC", "nome", "")
      .subscribe((res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      });

    this.stampante3DService
      .stampante3DSearchByCodice(0, 9999, "ASC", "codice", "")
      .subscribe((res: ResponseQueryByCriteria<Stampante3DDTO>) => {
        this.stampanti3D = res.content;
      });

    this.sedeService
      .sedeListByDescrizione(0, 9999, "ASC", "descrizione", "")
      .subscribe((res: ResponseQueryByCriteria<SedeDTO>) => {
        this.sedi = res.content;
      });

    if (this.parameters.dataDaCal != null && this.parameters.dataACal != null)
      this.popolaCalendario(
        this.parameters.dataDaCal,
        this.parameters.dataACal
      );

    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    }
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ prenotazionestampa: PrenotazioneStampante3DDTO }>[];
  }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) &&
          this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.parameters.viewDate = date;
      }
    }
  }

  eventClicked(event: CalendarEvent<{ prenotazionestampa: PrenotazioneStampante3DDTO }>): void {
      this.router.navigate([
        "/prenotazionestampante3d/detail/" + event.meta.prenotazionestampa.idMaster,
      ]);
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

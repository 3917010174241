import { ChiaviDTO } from '../../shared/dto/gestioneChiavi/chiavi';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';

@Injectable({
  providedIn: 'root'
})
export class ChiaviService extends GenericService<ChiaviDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/chiavi';
  }

  public chiaviList(pageNumber: number, pageSize: number, direction: string, sortField: string, descrizione: string): Observable<ResponseQueryByCriteria<ChiaviDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (descrizione != null) params["descrizione"] = descrizione;
    return this.http.get<ResponseQueryByCriteria<ChiaviDTO>>(`${this.url}/chiaviList`, {
      params: params
    });
  }

  public chiaviDisponibili(pageNumber: number, pageSize: number, direction: string, sortField: string): Observable<ResponseQueryByCriteria<ChiaviDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    return this.http.get<ResponseQueryByCriteria<ChiaviDTO>>(`${this.url}/chiaviDisponibili`, {
      params: params
    });
  }

  public chiaviOccupateByUser(pageNumber: number, pageSize: number, direction: string, sortField: string, idCollaboratore: number): Observable<ResponseQueryByCriteria<ChiaviDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (idCollaboratore != null) params["idCollaboratore"] = idCollaboratore;
    return this.http.get<ResponseQueryByCriteria<ChiaviDTO>>(`${this.url}/chiaviOccupateByUser`, {
      params: params
    });
  }

  public chiaviById(id: number): Observable<ChiaviDTO> {
    let params = {};
    if (id != null) params["chiaviId"] = id;
    return this.http.get<ChiaviDTO>(`${this.url}/` + id, {
      params: params
    });
  }
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Gestione acquisti</h2>
      <p>Imposta i filtri di ricerca</p>
      <div fxLayout="column" class="responsive-mat-form">
        <!-- Richiedente field -->
        <mat-form-field>
          <mat-select name="richiedente" [(ngModel)]="parameters.richiedente" [compareWith]='compareDTO'
            [disabled]="!isJappTenantAdmin()" placeholder="Scegli il richiedente">
            <mat-option [value]="null">
              Tutti
            </mat-option>
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Stato field -->
        <mat-form-field>
          <mat-select name="stato" [(ngModel)]="parameters.stato" placeholder="Scegli lo stato della richiesta">
            <mat-option *ngFor="let option of statoRichiestaValues" [value]="option.value">
              {{option.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/richiesta-acquisto/detail/0']">New</button>
        </mat-card-actions>
        
        <div *ngIf="parameters.showList" fxLayout="column">
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" matSortDisableClear
          [dataSource]="parameters.dataSource">
  
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Acquisto </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let acquisto"> {{acquisto.id}} </td>
          </ng-container>
  
          <!-- statoRichiesta Column -->
          <ng-container matColumnDef="statoRichiesta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
            <td mat-cell *matCellDef="let acquisto">
              <div fxHide fxShow.gt-md >{{ trascodificaStatoRichiesta(acquisto.statoRichiesta)}} </div>
              <div fxShow fxHide.gt-md fxLayoutAlign="center center">
                  <mat-icon *ngIf="(acquisto.statoRichiesta=='DA_APPROVARE')">check_box</mat-icon>
                  <mat-icon *ngIf="(acquisto.statoRichiesta=='APPROVATO')">check_box_outline_blank</mat-icon>
                  <mat-icon *ngIf="(acquisto.statoRichiesta=='RIFIUTATO')">indeterminate_check_box</mat-icon>
              </div>
           </td>
          </ng-container>
  
          <!-- Richiedente Column -->
          <ng-container matColumnDef="richiedente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Richiedente </th>
            <td mat-cell *matCellDef="let acquisto" [hidden]="!isJappTenantAdmin()"> {{acquisto.richiedente?.nome}} {{acquisto.richiedente?.cognome}} </td>
          </ng-container>
  
          <!-- Approvatore Column -->
          <ng-container matColumnDef="approvatore">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Approvatore </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let acquisto"> {{acquisto.approvatore?.nome}} {{acquisto.approvatore?.cognome}} </td>
          </ng-container>
  
          <!-- descrizione Column -->
          <ng-container matColumnDef="descrizione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
            <td mat-cell *matCellDef="let acquisto"> {{acquisto.descrizione}}</td>
          </ng-container>

          <!-- Url Column -->
          <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef> Link </th>
            <td mat-cell *matCellDef="let acquisto">
              <a target="_blank" *ngIf="acquisto.url?.length > 0" [href]="acquisto.url">
                <button mat-raised-button color="primary">Vai</button>
              </a>
            </td>
          </ng-container>
  
          <!-- Info Column -->
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let acquisto">
              <button mat-mini-fab color="primary" [routerLink]="['/richiesta-acquisto/detail/' , acquisto.id]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-actions>
            <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'richieste'})">Excel</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'richieste'})">Csv</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'richieste'})">Json</button>
          </mat-card-actions>
          <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
            [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
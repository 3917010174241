import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { FeriePermessiDTO } from '../shared/dto/gestioneferie/ferie';
import { FerieService } from '../services/gestione-ferie/ferie.service';

@Component({
  selector: 'app-gestione-ferie',
  templateUrl: './ferieDetail.component.html',
  styleUrls: ['./ferieDetail.component.scss']
})
export class FerieDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  ferieId: number;
  ferie: FeriePermessiDTO;
  collaboratori: CollaboratoreDTO[];
  index: number[];
  dataA: Date;
  today: Date;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;
 
  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  audioOk = new Audio();

  audioError = new Audio();
  
  barcodeReading: boolean;

  constructor(
    private urlService: UrlService,
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private ferieService: FerieService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      dataA: new FormControl('', Validators.required),
      residuoFerie: new FormControl('',Validators.required),
      maturabiliFerie: new FormControl('',Validators.required),
      daFruireFerie: new FormControl('',Validators.required),
      residuoPermessi: new FormControl('',Validators.required),
      maturabiliPermessi: new FormControl('',Validators.required),
      daFruirePermessi: new FormControl('',Validators.required),
      residuoROL: new FormControl('',Validators.required),
      maturabiliROL: new FormControl('',Validators.required),
      daFruireROL: new FormControl('',Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.today = new Date();
    this.audioOk.src = "../../assets/beep.wav";
    this.audioOk.load();
    this.audioError.src = "../../assets/Error.wav";
    this.audioError.load();
  }

  ngOnInit() {
    this.ferieId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.ferieId != null && this.ferieId != 0) {
      this.ferieRead();
    } else {
      this.ferieInit();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  ferieSave() {
    if (this.ferie.id == null) {
      this.ferieCreate();
    } else {
      this.ferieUpdate();
    }
  }

  ferieCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.ferieService.create(this.ferie).subscribe(
        (res: FeriePermessiDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.ferie = res;
          this.ferieId = this.ferie.id;
          this.dtoToForm();
          this.form.markAsPristine();
          this.router.navigate(["/ferie"]);
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_FERIE_ALREADY_EXIST") {
            this.snackBar.open("Attenzione: ferie per questo utente nel periodo selezionato esistono già. Aggiornare quelle già esistenti invece di crearne di nuove.", null, { duration: 4000 });
          } else if (err.status == 500 && (<ResponseFail>err.error).reasonBundleKey == "generic_error") {
            this.snackBar.open("Attenzione: valori dei dati errati (probabilmente troppo grandi). Digitare dei numeri corretti.", null, { duration: 4000 });
          }
        });
    }
  }

  ferieUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere le ferie?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.ferieService.update(this.ferie).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.ferieRead();
              this.router.navigate(["/ferie"]);
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_FERIE_NOT_FOUND") {
                this.snackBar.open("Attenzione! Ferie non trovate.", null, { duration: 4000 });
              } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_COLLABORATORE_NOT_FOUND") {
                this.snackBar.open("Attenzione! Questo collaboratore non ha ferie.", null, { duration: 4000 });
              } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidFerieException_REQUIRED_FIELDS_MISSING") {
                this.snackBar.open("Attenzione! Mancano dei campi per l'aggiornamento", null, { duration: 4000 });
              }
            });
        }
      });
    }
  }

  ferieDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare le ferie?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.ferieService.delete(this.ferie.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/ferie"]);
            }
          );
        }
      });
    }
  }

  ferieRead() {
    this.ferieService.read(this.ferieId).subscribe(
      (res: FeriePermessiDTO) => {
        console.log("response : " + res);
        this.ferie = res;
        console.log(new Date(res.dataA));
        this.ferieId = res.id;
        this.dtoToForm();
      }
    );
  }

  ferieInit() {
    this.ferie = new FeriePermessiDTO();
    this.form.get("dataA").setValue(new Date());
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
  }

  private formToDto(): void {
    this.ferie.collaboratore = this.form.get("collaboratore").value;
    this.ferie.dataA = this.form.get("dataA").value;
    this.ferie.residuoFerie = this.form.get("residuoFerie").value;
    this.ferie.maturabiliFerie = this.form.get("maturabiliFerie").value;
    this.ferie.daFruireFerie = this.form.get("daFruireFerie").value;
    this.ferie.residuoPermessi = this.form.get("residuoPermessi").value;
    this.ferie.maturabiliPermessi = this.form.get("maturabiliPermessi").value;
    this.ferie.daFruirePermessi = this.form.get("daFruirePermessi").value;
    this.ferie.residuoROL = this.form.get("residuoROL").value;
    this.ferie.maturabiliROL = this.form.get("maturabiliROL").value;
    this.ferie.daFruireROL = this.form.get("daFruireROL").value;

  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.ferie?.id);
    this.form.get("collaboratore").setValue(this.ferie?.collaboratore);
    this.form.get("dataA").setValue(new Date(this.ferie?.dataA));
    this.form.get("residuoFerie").setValue(this.ferie?.residuoFerie);
    this.form.get("maturabiliFerie").setValue(this.ferie?.maturabiliFerie);
    this.form.get("daFruireFerie").setValue(this.ferie?.daFruireFerie);
    this.form.get("residuoPermessi").setValue(this.ferie?.residuoPermessi);
    this.form.get("maturabiliPermessi").setValue(this.ferie?.maturabiliPermessi);
    this.form.get("daFruirePermessi").setValue(this.ferie?.daFruirePermessi);
    this.form.get("residuoROL").setValue(this.ferie?.residuoROL);
    this.form.get("maturabiliROL").setValue(this.ferie?.maturabiliROL);
    this.form.get("daFruireROL").setValue(this.ferie?.daFruireROL);
    this.form.get("deleteDate").setValue(this.ferie?.deleteDate);
    this.form.get("deleteUser").setValue(this.ferie?.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.ferie?.insertDate));
    this.form.get("insertUser").setValue(this.ferie?.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.ferie?.updateDate));
    this.form.get("updateUser").setValue(this.ferie?.updateUser);
    this.form.get("optLock").setValue(this.ferie?.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }

  isNotNewCreation():boolean {
    return this.ferieId==0;
  }

}
import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { RimborsoDTO } from 'src/app/shared/dto/rimborso';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { GenericService } from 'src/app/shared/GenericService';

@Injectable({
  providedIn: 'root'
})
export class RimborsoService extends GenericService<RimborsoDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + "/services/rest/json/rimborso";
  }

  public rimborsoListByList(
    pageNumber: number,
    pageSize: number,
    direction: string,
    sortField: string,
    collaboratore: CollaboratoreDTO,
    dataDa: Date,
    dataA: Date,
    descrizione: String): Observable<ResponseQueryByCriteria<RimborsoDTO>> {
    let params = {};
    if (collaboratore != null) params["idCollaboratore"] = String(collaboratore.id);
    if (dataDa != null) params["dataEmissioneDa"] = dataDa.toISOString();
    if (dataA != null) params["dataEmissioneA"] = dataA.toISOString();
    if (descrizione != null) params["descrizione"] = descrizione;
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    return this.http.get<ResponseQueryByCriteria<RimborsoDTO>>(`${this.url}/rimborsoByList/`, {
      params: params
    });
  }
}
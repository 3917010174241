import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarMonthViewDay, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import {
  getDate,
  isSameDay, isSameMonth
} from 'date-fns';
import { CustomDateFormatter } from '../commons/calendar/custom-calendar-date-formater';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { PrenotazionePostazioneDTO } from '../shared/dto/prenotazionepostazione/prenotazionepostazione';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { PrenotazionePostazioneService } from './../services/prenotazionepostazione/prenotazionepostazione.service';
import { SedeService } from './../services/sede/sede.service';
import { SedeDTO } from './../shared/dto/sede/sede';
import { StoriaChiaviService } from '../services/gestione-chiavi/storia-chiavi.service';
import { id } from 'date-fns/locale';


@Component({
  selector: 'app-prenotazionepostazione',
  templateUrl: './prenotazionepostazione.component.html',
  styleUrls: ['./prenotazionepostazione.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
})
export class PrenotazionepostazioneComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  collaboratore: CollaboratoreDTO;
  sedi: SedeDTO[];
  sede: SedeDTO;
  collaboratoreAttuale: CollaboratoreDTO;
  maxPostazione: SedeDTO;
  vistaData: boolean = false;
  today: Date;

  //CALENDARIO 
  view: CalendarView = CalendarView.Month;

  weekView: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  events: CalendarEvent<{ prenotazionepostazione: PrenotazionePostazioneDTO }>[];

  activeDayIsOpen: boolean = false;

  selectedMonthViewDay: CalendarMonthViewDay;

  selectedDayViewDate: Date;

  selectedDays: any = [];

  @Input() locale: string = 'it';

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;
  constructor(
    private prenotazionePostazioneService: PrenotazionePostazioneService,
    private collaboratoreService: CollaboratoreService,
    private sedeService: SedeService,
    private cd: ChangeDetectorRef,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'dataDa', 'dataA', 'richiedente', 'postazione.descrizione', 'postazione.ufficio.descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      initDataDa: true,
      collaboratoreChanged: false,
      view: CalendarView.Month,
      viewDate: new Date(),
      dataDaCal: null,
      dataACal: null,
      showList: false,
      filter: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.today = new Date();
  }

  estrazionePeriodoVista(event: CalendarMonthViewBeforeRenderEvent) {
    if ((this.parameters.dataDaCal == null || event.period.start.getTime() != this.parameters.dataDaCal.getTime())
      || (this.parameters.dataACal == null || event.period.end.getTime() != this.parameters.dataACal.getTime())) {
      this.popolaCalendario(event.period.start, event.period.end);
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    event.index != 0 ? this.vistaData = true : this.vistaData = false;
  }

  popolaCalendario(dataDa: Date, dataA: Date) {
    this.prenotazionePostazioneService.prenotazionePostazioneList(
      this.parameters.pageNumber,
      9999,
      this.parameters.sortDirection,
      "postazione.descrizione",
      null,
      this.parameters.sede,
      dataDa,
      dataA
    ).subscribe((response: ResponseQueryByCriteria<PrenotazionePostazioneDTO>) => {
      this.parameters.dataDaCal = dataDa;
      this.parameters.dataACal = dataA;
      this.mapEvent(response);
    })
  }

  popolaCalendarioOnSedeChange() {
    this.prenotazionePostazioneService.prenotazionePostazioneList(
      this.parameters.pageNumber,
      9999,
      this.parameters.sortDirection,
      this.parameters.sortField,
      null,
      this.parameters.sede,
      null,
      null
    ).subscribe((response: ResponseQueryByCriteria<PrenotazionePostazioneDTO>) => this.mapEvent(response))
  }

  private mapEvent(response: ResponseQueryByCriteria<PrenotazionePostazioneDTO>) {
    this.events = response.content.map((prenotazione: PrenotazionePostazioneDTO) => {
      if (prenotazione.prenotazionePerOspite == false) {
        console.log(prenotazione.richiedente.id);
      }

      const hasKeyClass = prenotazione.keyOwner ? 'hasKeyClass' : '';
      let ospite = "";
      if (prenotazione.prenotazionePerOspite) {
        ospite = "Ospite di";
      }

      return {
        title: `${ospite} ${prenotazione.richiedente.nome} ${prenotazione.richiedente.cognome} - ${prenotazione.postazione.descrizione} `,
        start: new Date(
          prenotazione.dataDa
        ),
        end: new Date(
          prenotazione.dataA
        ),
        allDay: true,
        color: {
          primary: '#e3bc08',
          secondary: '#FDF1BA',
        },
        meta: {
          prenotazione,
        },
        cssClass: hasKeyClass
      } as CalendarEvent;
    });
  }

  list() {
    this.prenotazionePostazioneService.prenotazionePostazioneList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.richiedente,
      this.parameters.sede,
      this.parameters.dataDa,
      this.parameters.dataA
    ).subscribe((response: ResponseQueryByCriteria<PrenotazionePostazioneDTO>) => {
      this.maxPostazione = this.parameters.sede;
      this.parameters.dataSource = response.content;
      this.parameters.pageNumber = response.pageNumber;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    }
    )
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.collaboratore = res;
          this.parameters.richiedente = this.collaboratore;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;
        }
      );

    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<SedeDTO>) => {
          this.sedi = res.content;
        }
      );

    if (this.parameters.dataDaCal != null && this.parameters.dataACal != null )
      this.popolaCalendario(this.parameters.dataDaCal, this.parameters.dataACal);

    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratoreAttuale = res;
      }
    );

    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }
  
  setViewDate() {
    this.parameters.viewDate = this.parameters.dataDa;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {

    body.forEach((day) => {
      if (this.parameters.sede != null) {
        this.maxPostazione = this.parameters.sede;
        if (day.events.length == this.maxPostazione.postazioniMassime) {
          day.cssClass = "prenotazionepostazione-non-prenotabile";
        }
      }
      if (
        day.events.some(
          (_event) => _event.meta.prenotazione.richiedente.id == this.collaboratoreAttuale.id
        )
      ) {
        day.cssClass = "prenotazionepostazione-prenotata";
      }
    });
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ prenotazione: PrenotazionePostazioneDTO }>[];
  }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.parameters.viewDate = date;
      }
    }
  }

  eventClicked(event: CalendarEvent<{ prenotazione: PrenotazionePostazioneDTO }>): void {
    if (this.collaboratoreAttuale.id == event.meta.prenotazione.richiedente.id || this.navigatorService.isJappTenantAdmin()) {
      this.router.navigate(["/prenotazionepostazione/detail/" + event.meta.prenotazione.id]);
    }
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
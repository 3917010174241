import { TimestampFormatPipe } from '../../commons/timestampFormatPipe';
import { NavigatorService } from '../../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogoService } from '../../services/credito/catalogo.service';
import { CatalogoDTO } from '../../shared/dto/credito/catalogo';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericComponent } from 'src/app/shared/GenericComponent';

@Component({
  selector: 'app-gestioneCatalogoPrint',
  templateUrl: './gestioneCatalogoPrint.component.html',
  styleUrls: ['./gestioneCatalogoPrint.component.scss']
})
export class GestioneCatalogoPrintComponent extends GenericComponent implements OnInit, OnDestroy {

  catalogoId: number;
  catalogo: CatalogoDTO;

  constructor(
    private catalogoService: CatalogoService,
    private activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      router,
      dateAdapter,
      snackBar,
      timestampFormatPipe
    );
  }



  ngOnInit() {
    this.catalogoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.catalogoId != 0 && this.catalogoId != null) {
      this.catalogoRead();
    } else {
      this.snackBar.open("Attenzione! catalogo non valido.", null, { duration: 5000 });
    }
  }

  catalogoRead() {
    this.catalogoService.read(this.catalogoId).subscribe(
      (res: CatalogoDTO) => {
        console.log("response : " + res);
        this.catalogo = res;
        this.catalogoId = res.id;
      }
    );
  }

  ngOnDestroy() { }

}

import { TenantDTO } from './domain/tenant';
import { LogicalDeleteDTO } from './basic/logicalDeleteDTO';
import { CollaboratoreDTO } from './domain/collaboratore';

export class RimborsoDTO extends LogicalDeleteDTO {
    id:number;
    tenant:TenantDTO;
    collaboratore:CollaboratoreDTO;
    dataEmissione:Date;
    importo:number;
    descrizione:String;
    fatto:boolean;
}
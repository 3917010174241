<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione Orari lavorativi</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <!-- Collaboratore field -->
            <mat-form-field>
                <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO"
                    [disabled]="!isJappTenantAdmin()" (selectionChange)="this.parameters.collaboratoreChanged = true"
                    placeholder="Scegli collaboratore">
                    <mat-option [value]="null">
                        Tutti
                    </mat-option>
                    <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                        {{collaboratore.nome}} {{collaboratore.cognome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--Giorno field-->
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <mat-label>Inserisci il giorno</mat-label>
                <mat-select [(ngModel)]="parameters.giorno" [compareWith]="compareDTO">
                    <mat-option [value]="null">
                        Tutti
                    </mat-option>
                    <mat-option *ngFor="let giorno of giorni" [value]="giorno">
                        {{ translateDayToIta(giorno) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--Bottoni-->
        <mat-card-actions>
            <!-- search -->
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <!-- new -->
            <button mat-raised-button id="new-button" [routerLink]="['/orario-lavorativo/detail/0']">New</button>
        </mat-card-actions>

        <mat-tab-group>

            <mat-tab label="Orari Lavorativi">
                <div fxLayout="column">
                    <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
                        [hiddenColumns]="hiddenColumns()" matSort (matSortChange)="setSort($event)" matSortDisableClear
                        [dataSource]="tabellaRaggruppata">

                        <!-- Collaboratore Column-->
                        <ng-container matColumnDef="collaboratore">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"
                                style="text-align:center"> Collaboratore </th>
                            <td mat-cell *matCellDef="let orario">{{orario.collaboratore.nome}} {{orario.collaboratore.cognome}}</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <!-- Da-Ore e A-Ore per Giorno della settimana columns-->
                        <ng-container *ngFor="let giorno of giorni" [matColumnDef]="giorno">

                            <th mat-header-cell class="text-center-sl" *matHeaderCellDef>{{translateDayToIta(giorno)}}
                            </th>
                            <td mat-cell *matCellDef="let orario" class="text-center-sl">
                                <span class="hoverspan" *ngIf="orario.giorni.has(giorno)"
                                    [routerLink]="['/orario-lavorativo/detail/' , orario.giorni.get(giorno).id]"
                                    [hidden]="!isJappTenantAdmin()">
                                    {{orario.giorni.get(giorno).dalleOre }}:00 - {{ orario.giorni.get(giorno).alleOre }}:00
                                </span>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>

                        </ng-container>

                        <!-- Duplica button Column -->
                        <ng-container matColumnDef="duplicate">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let orario">
                                <button mat-mini-fab color="primary" (click)="navigateToFiglio(orario)">
                                    <mat-icon>control_point_duplicate</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="orarilavorativiDisplayColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: orarilavorativiDisplayColumns;"></tr>
                    </table>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-paginator #paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                            [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                            (page)="setPaginator($event)"></mat-paginator>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Lista tabella">
                <div *ngIf="parameters.showList && parameters.dataSource" fxLayout="column">
                    <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
                        [hiddenColumns]="hiddenColumns()" matSort (matSortChange)="setSort($event)" matSortDisableClear
                        [dataSource]="parameters.dataSource">
                        <!-- Id Orario Lavorativo Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let orario"> {{orario.id }} </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <!-- Collaboratore Column-->
                        <ng-container matColumnDef="collaboratore">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()">
                                Collaboratore </th>
                            <td mat-cell *matCellDef="let orario"> {{orario.collaboratore?.nome}}
                                {{orario.collaboratore?.cognome}}</td>
                            <td mat-footer-cell *matFooterCellDef> </td>
                        </ng-container>

                        <!-- Giorno Column-->
                        <ng-container matColumnDef="giorno">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Giorno </th>
                            <td mat-cell *matCellDef="let orario"> {{translateDayToIta(orario.giorno)}} </td>
                            <td mat-footer-cell *matFooterCellDef> </td>
                        </ng-container>
                        <!-- DaOre Column-->
                        <ng-container matColumnDef="daOre">
                            <th mat-header-cell *matHeaderCellDef>Da Ore</th>
                            <td mat-cell *matCellDef="let orario">{{orario.dalleOre}}:00</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                        <!-- aOre Column-->
                        <ng-container matColumnDef="aOre">
                            <th mat-header-cell *matHeaderCellDef>A Ore</th>
                            <td mat-cell *matCellDef="let orario">{{orario.alleOre}}:00</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <!-- Info Column -->
                        <ng-container matColumnDef="detail">
                            <th mat-header-cell *matHeaderCellDef [hidden]="!isJappTenantAdmin()"></th>
                            <td mat-cell *matCellDef="let orario" [hidden]="!isJappTenantAdmin()">
                                <button mat-mini-fab color="primary"
                                    [routerLink]="['/orario-lavorativo/detail/' , orario.id]"
                                    [hidden]="!isJappTenantAdmin()">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-paginator #paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                            [length]="parameters.length" [pageSizeOptions]="[10,50,100]"
                            (page)="setPaginator($event)"></mat-paginator>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <div style="max-width: 800px;">
      <h1>Risorse aziendali :</h1>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let risorsaGruppo of risorseGruppi ">
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> {{risorsaGruppo}}</mat-expansion-panel-header>
          <div *ngFor="let risorsa of risorse">
            <div *ngIf="(risorsa.gruppo == risorsaGruppo)">
              <button mat-button (click)="riderect(risorsa.url)">
                <mat-icon *ngIf="!risorsa.fontset" class="material-icons">
                  {{risorsa.icona}} </mat-icon>
                <mat-icon *ngIf="risorsa.fontset" class="material-icons"
                  [fontSet]="risorsa.fontset" [fontIcon]="risorsa.icona"></mat-icon> &nbsp;
                <span>{{risorsa.descrizione}}</span>
              </button>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card-content>
</mat-card>
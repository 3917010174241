import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { TimestampFormatPipe } from 'src/app/commons/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { CollaboratoreService } from 'src/app/services/domain/collaboratore.service';
import { EventoService } from 'src/app/services/evento/evento.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { EventoDTO } from 'src/app/shared/dto/evento/evento';
import { CategoriaEventoDTO } from '../shared/dto/evento/categoria-evento';
import { CategoriaEventoService } from '../services/evento/categoria-evento.service';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.scss']
})
export class EventoComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  categorie: CategoriaEventoDTO[];

  defaultSortField = 'id';
  defaultSortDirection = 'asc';

  constructor(private eventoService: EventoService,
    private collaboratoreService: CollaboratoreService,
    private categoriaService: CategoriaEventoService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);

    this.displayedColumns = ['id', 'organizzatore', 'descrizione', 'categoria', 'dataOraInizio', 'dataOraFine', 'luogo', 'minPartecipanti', 'maxPartecipanti', 'dataFineIscrizioni', 'maxEsterniPerPartecipante', 'detail'];

    this.parameters = {
      dataSource: [],
      showList: false,
      organizzatore: null,
      categoria: null,
      dataDa: null,
      dataA: null,
      fineIscrizioneCompare: null,
      minPartecipanti: null,
      maxPartecipanti: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.eventoService.eventoListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
      this.parameters.organizzatore,
      this.parameters.categoria,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.minPartecipanti,
      this.parameters.maxPartecipanti).subscribe({
        next: (response: ResponseQueryByCriteria<EventoDTO>) => {
          this.parameters.dataSource = response.content;
          this.parameters.pageNumber = response.pageNumber;
          this.parameters.pageSize = response.pageSize;
          this.parameters.showList = true;
          this.parameters.length = response.totalElements;
        }
      });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.collaboratoreService.collaboratoreList(0, 9999, 'ASC', 'nome', '').subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      }
    );

    this.categoriaService.categoriaEventoGetAll().subscribe(
      (res: CategoriaEventoDTO[]) => {
        this.categorie = res;
      }
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { OrarioLavorativoDTO } from 'src/app/shared/dto/orario-lavorativo/orario-lavorativo';

@Injectable({
  providedIn: 'root'
})

export class CollaboratoreService extends GenericService<CollaboratoreDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/collaboratore';
  }

  public collaboratoreList(pageNumber: number, pageSize: number, direction: string, sortField: string, filter: string): Observable<ResponseQueryByCriteria<CollaboratoreDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    return this.http.get<ResponseQueryByCriteria<CollaboratoreDTO>>(`${this.url}/collaboratoreList`, {
      params: params
    });
  }

  public collaboratoreNoAmList(pageNumber: number, pageSize: number, direction: string, sortField: string, filter: string): Observable<ResponseQueryByCriteria<CollaboratoreDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    return this.http.get<ResponseQueryByCriteria<CollaboratoreDTO>>(`${this.url}/collaboratoreNoAmList`, {
      params: params
    });
  }

}
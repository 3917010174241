<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettagli della lista d'attesa per la postazione</h2>
      <div fxLayout="column">
        <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
  
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Lista Attesa Id" readonly="true" />
          </mat-form-field>
  
          <!-- Richiedente field -->
          <mat-form-field>
            <mat-select name="richiedente" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
              formControlName="richiedente" placeholder="Scegli il richiedente">
              <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                {{collaboratore.nome}} {{collaboratore.cognome}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('richiedente').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
  
          <!-- Responsive -->
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            <!-- DataDa field -->
            <mat-form-field fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 0}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker.open()"
                    [matDatepicker]="picker" formControlName="data" placeholder="Scegli data ">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('data').value" matSuffix
                    mat-icon-button aria-label="Clear" (click)="form.get('data').setValue(null)">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error *ngIf="form.get('data').errors?.required">
                    Campo obbligatorio
                </mat-error>
            </mat-form-field>
          </div>
  
          <!-- Sede field -->
          <mat-form-field>
            <mat-label>Scegli la sede</mat-label>
            <mat-select [compareWith]="compareDTO" formControlName="sede" >
              <mat-option *ngFor="let sede of sedi" [value]="sede">
                {{sede.descrizione}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('sede').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field> 
  
          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>
  
              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">
  
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>
  
              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/listaattesaprenotazionepostazione')" color="primary">Back</button>
        <button mat-raised-button (click)="listaattesaprenotazionepostazioneSave()"
          [disabled]="form.invalid || !form.dirty"
          *ngIf="listaattesaprenotazionepostazione?.deleteDate==null">Save</button>
        <button mat-raised-button (click)="listaattesaprenotazionepostazioneDelete()" [disabled]="listaattesaprenotazionepostazione?.id==null"
          *ngIf="listaattesaprenotazionepostazione?.deleteDate==null">Delete</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Gestione Tornei</h2>
      <p>Imposta i filtri di ricerca</p>
        <!-- Responsive -->
        <div fxLayout="column" class="responsive-mat-form">
          <!-- Stato field -->
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [compareWith]="compareDTO" multiple [(ngModel)]="this.parameters.selectedStato">
              <mat-option *ngFor="let stato of statiTorneo" [value]="stato">
                {{stato.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search"
          color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/torneo/detail/0']">New</button>
      </mat-card-actions>
  
      <div *ngIf="parameters.showList" fxLayout="column">
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection" matSortDisableClear
          [dataSource]="parameters.dataSource">
  
          <!-- Data Inizio Column -->
        <ng-container matColumnDef="insertDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
          <td mat-cell *matCellDef="let torneo"> {{torneo.insertDate | date :'dd/MM/yyyy'}} </td>
        </ng-container>
  
          <!-- Descrizione Column -->
          <ng-container matColumnDef="descrizione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
            <td mat-cell *matCellDef="let torneo"> {{torneo.descrizione}} </td>
          </ng-container>
  
          <!-- Status Column -->
          <ng-container matColumnDef="status" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Stato </th>
            <td mat-cell *matCellDef="let torneo" >{{torneo.status}}</td>
          </ng-container>
  
          <!-- Numero Giocatori Column -->
          <ng-container matColumnDef="numeroGiocatori">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero Giocatori </th>
            <td mat-cell *matCellDef="let torneo"> {{torneo.numeroGiocatori}} </td>
          </ng-container>
  
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let torneo">
              <button mat-mini-fab color="primary" [routerLink]="['/torneo/detail/', torneo.id]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-actions>
            <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'tornei'})">Excel</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'tornei'})">Csv</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'tornei'})">Json</button>
          </mat-card-actions>
          <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
            [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
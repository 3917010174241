import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { NavigatorService } from '../services/navigator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PropostaDTO } from '../shared/dto/proposte/proposta';
import { PropostaService } from '../services/proposte/proposta.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';

@Component({
  selector: 'app-proposta-detail',
  templateUrl: './proposta-detail.component.html',
  styleUrls: ['./proposta-detail.component.scss']
})
export class PropostaDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy  {

  propostaId: number;
  proposta: PropostaDTO;
  today = new Date();

  constructor(
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private propostaService: PropostaService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      domanda: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.propostaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.propostaId != null && this.propostaId != 0) {
      this.propostaRead();
    } else {
      this.proposta = new PropostaDTO();
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.proposta.creatore = res;
        }
      );
      this.proposta.giornoCorrente = this.today;
    }
  }

  propostaSave() {
    if (this.proposta.id == null) {
      this.propostaCreate();
    } else {
      this.propostaUpdate();
    }
  }

  propostaCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.propostaService.create(this.proposta).subscribe(
        (res: PropostaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.proposta = res;
          this.propostaId = this.proposta.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (error: any) => {
          this.snackBar.open("Non puoi creaare più proposte al giorno!", null, { duration: 3000 });
        }
      );
    }
  }

  propostaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la proposta?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.propostaService.update(this.proposta).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.propostaRead();
              this.form.markAsPristine();
            }
          );
        }
      });
    }
  }

  propostaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la proposta?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.propostaService.delete(this.proposta.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/proposta"]);
            }
          );
        }
      });
    }
  }

  propostaRead() {
    this.propostaService.read(this.propostaId).subscribe(
      (res: PropostaDTO) => {
        console.log("response : " + res);
        this.proposta = res;
        this.propostaId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.proposta.domanda = this.form.get("domanda").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.proposta.id);
    this.form.get("domanda").setValue(this.proposta.domanda);
    this.form.get("deleteDate").setValue(this.proposta.deleteDate);
    this.form.get("deleteUser").setValue(this.proposta.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.proposta.insertDate));
    this.form.get("insertUser").setValue(this.proposta.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.proposta.updateDate));
    this.form.get("updateUser").setValue(this.proposta.updateUser);
    this.form.get("optLock").setValue(this.proposta.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dove sei</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field >
        <mat-label>Seleziona il collaboratore</mat-label>
        <input (input)="collaboratoreChanged($event.target.value, true)" 
                type="text" placeholder="Seleziona il collaboratore" 
                matInput [formControl]="filter" [matAutocomplete]="collaboratoreAutocomplete">
        <mat-autocomplete autoActiveFirstOption #collaboratoreAutocomplete="matAutocomplete"
            [displayWith]="inizializzaCollaboratore()">
          <mat-option *ngFor="let collaboratore of filteredCollaboratore" [value]="collaboratore" (onSelectionChange)="ricercaPresenza(collaboratore)">
            {{ collaboratore?.nome }} {{collaboratore?.cognome}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div fxLayout="column" class="responsive-mat-form">
      <div *ngIf="hiddenResult == false && isPresente == false">
        <h2>Risultato della ricerca</h2>
        <p [innerHTML]="determinaStato()"></p>
      </div> 
      <div *ngIf="hiddenResult == false && isPresente == true">
        <h2>Risultato della ricerca</h2>
        <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: -15px;">
          <p style="font-size: 14px;">Ha registrato la presenza in:</p>
          <p *ngFor="let presenza of presenze; let ultimo = last" style="font-size: 14px; margin-left: 10px;">
            <strong>
              {{ presenza.location.descrizione }}  
              <span *ngIf="!ultimo">,</span>
            </strong>
          </p>
        </div>  
      </div>
      <form [formGroup]="form" fxLayout="column" *ngIf="hiddenResult==false" >
        
        <!-- E-mailCollaboratore Field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="email" placeholder="Email" />
        </mat-form-field>

        <!-- Username Field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="userName" placeholder="Username"/>
          <mat-error *ngIf="form.get('userName').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- NomeCollaboratore Field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput formControlName="nome" placeholder="Nome" />
          </mat-form-field>

          <!-- Cognome Field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput formControlName="cognome" placeholder="Cognome" />
          </mat-form-field>
        </div>
        <!-- Telefono Field -->
        <mat-form-field>
          <input matInput formControlName="telefono" placeholder="Telefono"/>
        </mat-form-field>

       <!-- Sede Field -->
        <mat-form-field>
          <input matInput formControlName="sede" placeholder="Sede" />
        </mat-form-field>
        <!-- Ruolo Field -->
        <mat-form-field>
          <input matInput formControlName="ruolo" placeholder="Ruolo" />
        </mat-form-field>
        </form>
    </div>
  </mat-card-content>
</mat-card>

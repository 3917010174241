<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <mat-card-title>Cerca per collaboratore</mat-card-title>
    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field>
          <mat-label>Collaboratori</mat-label>
          <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO"
              [disabled]="!isJappTenantAdmin()" (selectionChange)= "this.parameters.collaboratoreChanged = true">
              <mat-option [value]="null">
                Tutti
              </mat-option>
              <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                  {{collaboratore.nome}} {{collaboratore.cognome}}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search"
        color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/dispositivi/detail/0']">New</button>
    </mat-card-actions>

    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
        matSort (matSortChange)="setSort($event)" [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection" matSortDisableClear
        [dataSource]="parameters.dataSource">

        <!-- Codice Column -->
        <ng-container matColumnDef="codice">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Codice </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let dispositivo"> {{dispositivo.codice }} </td>
          <td fxHide fxShow.gt-md mat-footer-cell *matFooterCellDef ></td>
        </ng-container>

        <!-- Collaboratore Column -->
        <ng-container matColumnDef="collaboratore" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Collaboratore </th>
          <td mat-cell *matCellDef="let dispositivo" [hidden]="!isJappTenantAdmin()"> {{dispositivo.collaboratore?.nome}} {{dispositivo.collaboratore?.cognome}}</td>
          <td mat-footer-cell *matFooterCellDef [hidden]="!isJappTenantAdmin()"></td>
        </ng-container>

        <!-- Descrizione Column -->
        <ng-container matColumnDef="descrizione" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Descrizione </th>
          <td mat-cell *matCellDef="let dispositivo" [hidden]="!isJappTenantAdmin()"> {{dispositivo.descrizione}}</td>
          <td mat-footer-cell *matFooterCellDef [hidden]="!isJappTenantAdmin()"></td>
        </ng-container>
        

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let dispositivo">
            <button mat-mini-fab color="primary" [routerLink]="['/dispositivi/detail/', dispositivo.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef ></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'presenze'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'presenze'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'presenze'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>

  </mat-card-content>
</mat-card>
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { TipoTurnoAmService } from '../services/turno-am/tipo-turno-am.service';
import { Component, OnInit } from '@angular/core';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { TipoTurnoAMDTO } from '../shared/dto/turno-am/tipo-turno-am';

@Component({
  selector: 'app-tipo-turno-am',
  templateUrl: './tipo-turno-am.component.html',
  styleUrls: ['./tipo-turno-am.component.scss']
})
export class TipoTurnoAmComponent extends GenericListComponent implements OnInit {

  constructor(
    private tipoTurnoService: TipoTurnoAmService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['id', 'nome', 'orario', 'ordine', 'colore', 'descrizione', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        nome: null,
        orario: null,
        descrizione: null,
        sortField: 'ordine',
        sortDirection: null,
        pageNumber: 0,
        pageSize: 50,
        length: 0
      }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  list() {
    this.tipoTurnoService.tipoTurnoListByList(
      this.parameters.nome,
      this.parameters.orario,
      this.parameters.descrizione,
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField
    
    ).subscribe(
      (res: ResponseQueryByCriteria<TipoTurnoAMDTO>) => {
        console.log("response : " + res);
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      }
    )
  }

}

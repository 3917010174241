<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione comande</h2>
    <p>Imposta i filtri di ricerca</p>
    <div fxLayout="column" class="responsive-mat-form">
      <!-- Collaboratore field -->
      <mat-form-field>
        <mat-label>Collaboratori</mat-label>
        <mat-select [(value)]="parameters.collaboratore" [compareWith]="compareDTO" [disabled]="!isJappTenantAdmin()"
          (selectionChange)="this.parameters.collaboratoreChanged = true">
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{collaboratore.nome}} {{collaboratore.cognome}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Responsive -->
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
      <!-- DataDa field -->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" [ngStyle]="{'padding-right': 2 + '%'}"
        [ngStyle.xs]="{'padding-right.px': 0}" [ngStyle.sm]="{'padding-right.px': 0}"
        [ngStyle.md]="{'padding-right.px': 0}">
        <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
          [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
          (click)="parameters.dataDa=null" (click)="parameters.initDataDa=false">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- DataA field -->
      <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
          [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
          (click)="parameters.dataA=null">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/gestione-comanda/detail/0']">New</button>
    </mat-card-actions>

    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()" matSort
        (matSortChange)="setSort($event)" [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection"
        matSortDisableClear [dataSource]="parameters.dataSource">

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let comanda"> {{comanda.id }} </td>
        </ng-container>

        <!-- Descrizione Column -->
        <ng-container matColumnDef="descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
          <td mat-cell *matCellDef="let comanda"> {{comanda.descrizione}}</td>
        </ng-container>

        <!-- DataComanda Column -->
        <ng-container matColumnDef="dataComanda">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
          <td mat-cell *matCellDef="let comanda"> {{comanda.dataComanda | date :'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- ImportoTotale Column -->
        <ng-container matColumnDef="importoTotale">
          <th mat-header-cell *matHeaderCellDef> Importo totale </th>
          <td mat-cell *matCellDef="let comanda">{{comanda.importoTotale}} </td>
        </ng-container>

        <!-- Persone Column -->
        <ng-container matColumnDef="persone">
          <th mat-header-cell *matHeaderCellDef > Partecipanti </th>
          <td mat-cell *matCellDef="let comanda"> {{comanda.persone}} </td>
        </ng-container>

        <!-- Collaboratore Column -->
        <ng-container matColumnDef="collaboratore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore </th>
          <td mat-cell *matCellDef="let comanda"> {{comanda.collaboratore?.nome}} {{comanda.collaboratore?.cognome}}
          </td>
        </ng-container>

        <ng-container matColumnDef="link-menu">
          <th mat-header-cell *matHeaderCellDef> Link</th>
          <td mat-cell *matCellDef="let comanda"> {{comanda.link}}
          </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let comanda">
            <button mat-mini-fab color="primary" [routerLink]="['/gestione-comanda/detail/', comanda.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'comande'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('csv', {fileName:'comande'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('json', {fileName:'comande'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
          [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TurnoDTO } from 'src/app/shared/dto/torneo/turno';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TurnoService extends GenericService<TurnoDTO, number> {

  constructor(http: HttpClient) { 
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/turno';
  }

  public turnoListByProfAndRoot( idTurnoRoot: number, profondita: number ): Observable<TurnoDTO[]> {
    let params = {};
    if (idTurnoRoot != null) params["idTurnoRoot"] = idTurnoRoot;
    if (profondita != null) params["profondita"] = profondita;
    return this.http.get<TurnoDTO[]>(`${this.url}/turniListByProfAndRoot`, {
      params: params
    });
  }

}

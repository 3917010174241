import { CollaboratoreDTO } from './../domain/collaboratore';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { SedeDTO } from '../sede/sede';

export class ListaAttesaPrenotazionePostazioneDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    data: Date;
    richiedente: CollaboratoreDTO;
    sede: SedeDTO;
}
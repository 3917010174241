<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dati anagrafici</h2>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="qrCodeSDI!=null">
      <ngx-qrcode fxHide fxShow.gt-md [qrc-value]="qrCodeSDIContent" [qrc-width]="256" [qrc-errorCorrectionLevel]="'L'"></ngx-qrcode>
      <ngx-qrcode fxShow fxHide.gt-md [qrc-value]="qrCodeSDIContent" [qrc-width]="qrcodeWidth" [qrc-errorCorrectionLevel]="'L'"></ngx-qrcode>
    </div>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- cf -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="cf" placeholder="Codice fiscale" [readonly]="!isJappTenantAdmin()" />
          <mat-error *ngIf="form.get('cf').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- piva -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="piva" placeholder="Partita IVA" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('piva').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- denom -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="denom" placeholder="Denominazione" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('denom').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- prov -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="prov" placeholder="Provincia" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('prov').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- cap -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="cap" placeholder="CAP" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('cap').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- com -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="com" placeholder="Comune" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('com').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- ind -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="ind" placeholder="Indirizzo" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('ind').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- naz -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="naz" placeholder="Nazione (IT)" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('naz').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- pec -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="pec" placeholder="PEC" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('pec').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- cod -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="cod" placeholder="Codice SDI" [readonly]="!isJappTenantAdmin()"/>
          <mat-error *ngIf="form.get('cod').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="save()" [disabled]="(form.invalid || !form.dirty)" *ngIf="isJappTenantAdmin()">Save</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
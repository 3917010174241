import { Component, OnDestroy, OnInit } from '@angular/core';
import { FrequentaCorsoDTO } from '../shared/dto/corsi-udemy/frequentaCorso';
import { NavigatorService } from '../services/navigator.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { CorsiUdemyDTO } from '../shared/dto/corsi-udemy/corsiUdemy';
import { FrequentaCorsoService } from '../services/corsi-udemy/frequenta-corso.service';
import { CorsiUdemyService } from '../services/corsi-udemy/corsi-udemy.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';

@Component({
  selector: 'app-frequenta-corso',
  templateUrl: './frequenta-corso.component.html',
  styleUrls: ['./frequenta-corso.component.scss']
})
export class FrequentaCorsoComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  corsiUdemy: CorsiUdemyDTO[];
  frequentaCorso: FrequentaCorsoDTO;
  dataInizio: Date;
  dataFine: Date;
  defaultSortDirection = 'asc';
  url: string;

  constructor(
    private frequentaCorsoService: FrequentaCorsoService,
    private corsoUdemyService: CorsiUdemyService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'collaboratore', 'corsoUdemy', 'dataInizio', 'dataFine', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0,
      collaboratore: null,
      corsoUdemy: null,
      collaboratoreChanged: false,
      initDataDa: true,
      dataInizio: null,
      dataFine: null,
    }
  }

  visualizzaStato(dataFine: Date): String {
    var msg: String;
    if (dataFine != null) {
      msg = 'Terminato';
    } else{
      msg = 'In corso';
    }
    return msg;
  }
  


  list() {
    this.frequentaCorsoService.frequentaCorsoListByCollaboratoreAndDates(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.corsoUdemy,
      this.parameters.dataInizio,
      this.parameters.dataFine).subscribe(
        (res: ResponseQueryByCriteria<FrequentaCorsoDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        } 
      )
  }


  ngOnInit() {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.corsoUdemyService.corsiUdemyList(
      0,
      9999,
      'ASC',
      'titolo',
      '').subscribe(
        (res: ResponseQueryByCriteria<CorsiUdemyDTO>) => {
          console.log("response : " + res.content[0]);
          this.corsiUdemy = res.content;
        }
      );

    if (this.parameters.dataInizio == null && this.parameters.initDataDa) {
      this.parameters.dataInizio = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  riderect(url : string){
    window.open(url);
  }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione template FreeMarker</h2>
        <mat-card-actions>
            <button mat-raised-button id="new-button" [routerLink]="['/gestione-template/detail/0']">New</button>
        </mat-card-actions>
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear
                [dataSource]="parameters.dataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Template </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let template"> {{template.id}} </td>
                </ng-container>

                <!-- CodTemplate Column -->
                <ng-container matColumnDef="codTemplate">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Codice Template </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let template"> {{template.codTemplate}} </td>
                </ng-container>

                <!-- Descrizione Column -->
                <ng-container matColumnDef="Descrizione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
                    <td mat-cell *matCellDef="let template"> {{template.descrizione}} </td>
                </ng-container>

                <!-- Info Column -->
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let template">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/gestione-template/detail/' , template.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-card-actions>
                <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'templates'})">Excel</button>
                <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'templates'})">Csv</button>
                <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'templates'})">Json</button>
              </mat-card-actions>
              <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
              </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
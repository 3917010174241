import { SalettaDTO } from './../saletta/saletta';
import { CollaboratoreDTO } from './../domain/collaboratore';
import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';

export class PrenotazioneSalettaDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    saletta: SalettaDTO;
    descrizione: String;
    dataDa: Date;
    dataA: Date;
}
import { SedeDTO } from './../sede/sede';
import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';


export class UfficioDTO extends LogicalDeleteDTO {
    
    id: number;
    
    tenant: TenantDTO;
    
    descrizione: string;
    
    sede: SedeDTO;

    postazioniMassime: number;

}
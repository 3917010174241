import { CollaboratoreDTO } from './../domain/collaboratore';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';

export class StampaTabellaRapportinoDTO extends LogicalDeleteDTO {
    collaboratore: CollaboratoreDTO;
    data: Date;
    location: string;
    cliente: string;
    attivita: string;
    numeroOre: number;   
    clienteFinale: string;
}
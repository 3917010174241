import { CollaboratoreAMDTO } from './../shared/dto/turno-am/collaboratore-am';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from '../services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { DateAdapter } from '@angular/material/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreAmService } from '../services/turno-am/collaboratore-am.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-collaboratore-am-detail',
  templateUrl: './collaboratore-am-detail.component.html',
  styleUrls: ['./collaboratore-am-detail.component.scss']
})
export class CollaboratoreAmDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  collaboratoreAMId: number;
  collaboratoreAM: CollaboratoreAMDTO;
  collaboratori: CollaboratoreDTO[];
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string

  constructor(
    private urlService: UrlService,
    private collaboratoreAMService: CollaboratoreAmService,
    private collaboratoreService: CollaboratoreService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      collaboratore: new FormControl('', Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.collaboratoreAMId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.collaboratoreAMId != 0 && this.collaboratoreAMId != null) {
      this.collaboratoreAMRead();
    } else {
      this.collaboratoreAM = new CollaboratoreAMDTO();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  ngOnDestroy(): void {
  }

  private formToDto(): void {
    this.collaboratoreAM.collaboratore = this.form.get("collaboratore").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.collaboratoreAM.id);
    this.form.get("collaboratore").setValue(this.collaboratoreAM.collaboratore);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.collaboratoreAM.insertDate));
    this.form.get("insertUser").setValue(this.collaboratoreAM.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.collaboratoreAM.updateDate));
    this.form.get("updateUser").setValue(this.collaboratoreAM.updateUser);
    this.form.markAsPristine();
  }

  collaboratoreAMSave(){
    if (this.collaboratoreAM.id == null) {
      this.collaboratoreAMCreate();
    } else {
      this.collaboratoreAMUpdate();
    }
  }

  collaboratoreAMCreate(){
    if (this.form.valid) {
      this.formToDto();
      this.collaboratoreAMService.create(this.collaboratoreAM).subscribe(
        (res: CollaboratoreAMDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.collaboratoreAM = res;
          this.collaboratoreAMId = this.collaboratoreAM.id;
          this.dtoToForm();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreAMException_COLLABORATORE_AM_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Questo collaboratore è già in AM.", null, { duration: 3000 });
          } else {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
  }

  collaboratoreAMRead(){
    this.collaboratoreAMService.read(this.collaboratoreAMId).subscribe(
      (res: CollaboratoreAMDTO) => {
        console.log("response : " + res);
        this.collaboratoreAM = res;
        this.collaboratoreAMId = res.id;
        this.dtoToForm();
      }
    )
  }

  collaboratoreAMUpdate(){
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler cambiare il collaboratore ?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.collaboratoreAMService.update(this.collaboratoreAM).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.collaboratoreAMRead();
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreAMException_COLLABORATORE_AM_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Questo collaboratore è già in AM.", null, { duration: 3000 });
              } else {
                this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  collaboratoreAMDelete(){
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler eliminare il collaboratore da AM?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.collaboratoreAMService.delete(this.collaboratoreAM.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/collaboratore-am"]);
            }
          );
        }
      });
    }
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { RisorsaAziendaleService } from '../services/risorsa-aziendale/risorsa-aziendale.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { RisorsaAziendaleDTO } from '../shared/dto/risorsa-aziendale/risorsa-aziendale';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-risorse-aziendali',
  templateUrl: './risorse-aziendali.component.html',
  styleUrls: ['./risorse-aziendali.component.scss']
})
export class RisorseAziendaliComponent extends GenericListComponent implements OnInit, OnDestroy {

  risorse: RisorsaAziendaleDTO[];
  risorseGruppi: string[] = [];

  constructor(
    private risorsaAziendaleService:  RisorsaAziendaleService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['gruppo', 'url',  'icona', 'descrizione'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      filterSede: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.risorsaAziendaleService.risorsaAziendaleList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.descrizione,
      this.parameters.gruppo).subscribe(
        (res: ResponseQueryByCriteria<RisorsaAziendaleDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      );
  }

  ngOnInit(): void {
    this.risorsaAziendaleService.risorsaAziendaleList(
      0,
      9999,
      'ASC',
      'gruppo',
      '',
      '').subscribe(
        (res: ResponseQueryByCriteria<RisorsaAziendaleDTO>) => {
          this.risorse = res.content;
          this.setGruppi();
        }
      );
  }

  setGruppi(){
    for(var _i = 0; _i < this.risorse.length; _i++){
     if(this.risorseGruppi.indexOf(this.risorse[_i].gruppo) < 0){
      this.risorseGruppi.push(this.risorse[_i].gruppo);
     }
    }
  }


  riderect(url : string){
    window.open(url);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

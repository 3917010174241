<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettaglio corso udemy</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <form [formGroup]="form" fxLayout="column">

                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Corso Id" readonly="true" />
                </mat-form-field>

                <!-- Campo titolo -->
                <mat-form-field fxlayout="column">
                    <input matInput [readonly]="!isJappTenantAdmin()" name="titolo" formControlName="titolo" placeholder="Titolo corso" type="text" />
                    <mat-error *ngIf="form.get('titolo').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Campo link -->
                <mat-form-field fxlayout="column" class="responsive-mat-form">
                    <input matInput [readonly]="!isJappTenantAdmin()" name="link" formControlName="link" placeholder="Link" type="url" />
                    <mat-error *ngIf="form.get('link').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
                    <!-- Campo ora e minuti-->
                    <mat-form-field fxFlex="40" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}" floatLabel="auto">
                        <mat-label>Ore</mat-label>
                        <input matInput [readonly]="!isJappTenantAdmin()" formControlName="ore" placeholder="00" min="0" max="999"
                            onKeyPress="if(this.value.length==3) return false;" type="number" name="ore" />
                        <mat-error *ngIf="form.get('ore').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="40" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                        [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}" floatLabel="auto">
                        <mat-label>Minuti</mat-label>
                        <input matInput [readonly]="!isJappTenantAdmin()" formControlName="minuti" placeholder="00" min="0" max="60"
                            onKeyPress="if(this.value.length==2) return false;" type="number" name="minuti" />
                        <mat-error *ngIf="form.get('minuti').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>
                    <!-- Campo nlezioni -->
                    <mat-form-field  fxFlex="20" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"  style=" height: fit-content ">
                        <mat-label>N° lezioni</mat-label>
                        <input matInput [readonly]="!isJappTenantAdmin()" name="nlezioni" formControlName="nlezioni" placeholder="N° lezioni"
                            type="number" min="0" onKeyPress="if(this.value.length==3) return false;" />
                        <mat-error *ngIf="form.get('nlezioni').errors?.required">
                            Campo obbligatorio
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Campo descrizione -->
                <mat-form-field fxlayout="column" class="example-full-width">
                    <mat-label>Descizione</mat-label>
                    <textarea cdkTextareaAutosize [readonly]="!isJappTenantAdmin()" matInput rows="8" cols="10" name="descrizione" formControlName="descrizione"
                        placeholder="Descrizione del corso" class="textArea"></textarea>
                    <mat-error *ngIf="form.get('descrizione').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </form>
        </div>
        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm('/corsoudemy')" color="primary">Back</button>
            <button mat-raised-button (click)="corsoUdemySave()" [disabled]="form.invalid || !form.dirty"
                *ngIf="corsoUdemyDTO?.deleteDate==null && isJappTenantAdmin()">Save</button>
            <button mat-raised-button (click)="corsoUdemyDelete()" [disabled]="corsoUdemyDTO?.id==null"
                *ngIf="corsoUdemyDTO?.deleteDate==null && isJappTenantAdmin()">Delete</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>
import { Observable } from 'rxjs';
import { AssenzaDTO } from './../shared/dto/assenza/assenza';
import { AssenzaService } from './../services/assenza/assenza.service';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { TipoTurnoAmService } from './../services/turno-am/tipo-turno-am.service';
import { TipoTurnoAMDTO } from './../shared/dto/turno-am/tipo-turno-am';
import { TurnoAMDTO } from './../shared/dto/turno-am/turno-am';
import { CollaboratoreAmService } from './../services/turno-am/collaboratore-am.service';
import { TurnoAmService } from './../services/turno-am/turno-am.service';
import { Component, OnInit } from '@angular/core';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ComponentCacheService } from '../services/component-cache.service';
import { NavigatorService } from '../services/navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreAMDTO } from '../shared/dto/turno-am/collaboratore-am';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import {
  isSameDay, isSameMonth
} from 'date-fns';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-turno-am',
  templateUrl: './turno-am.component.html',
  styleUrls: ['./turno-am.component.scss']
})
export class TurnoAmComponent extends GenericListComponent implements OnInit {

  collaboratori: CollaboratoreAMDTO[];
  tipiTurno: TipoTurnoAMDTO[];

  vistaData: boolean = false;

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  turniMese: CalendarEvent<TurnoAMDTO>[];
  ferieMese: CalendarEvent<AssenzaDTO>[];
  activeDayIsOpen: boolean = false;
  locale: string = 'it';

  events: CalendarEvent[];
  currentCollaboratore: CollaboratoreDTO;

  ordine = "collaboratoreAM.collaboratore.nome";

  constructor(
    private breakpointObserver: BreakpointObserver,
    private turnoAmService: TurnoAmService,
    private collaboratoreAmService: CollaboratoreAmService,
    private tipoTurnoAmService: TipoTurnoAmService,
    private assenzaService: AssenzaService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'collaboratoreAM', 'tipoTurno', 'dataTurno', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      tipoTurno: null,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0,
      view: CalendarView.Month,
      viewDate: new Date(),
      dataDaCalendario: null,
      dataACalendario: null
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.currentCollaboratore = res;
      }
    );
    this.collaboratoreAmService.collaboratoreAMListByList(
      '',
      0,
      9999,
      'ASC',
      'collaboratore.nome'
    ).subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreAMDTO>) => {
        console.log("response : ", res);
        this.collaboratori = res.content;
      }
    );
    this.tipoTurnoAmService.tipoTurnoListByList(
      '',
      '',
      '',
      0,
      9999,
      'ASC',
      'ordine'
    ).subscribe(
      (res: ResponseQueryByCriteria<TipoTurnoAMDTO>) => {
        console.log("response : ", res);
        this.tipiTurno = res.content;
      }
    );
    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
    if (this.parameters.dataDaCalendario != null && this.parameters.dataACalendario != null) {
      this.fetchEvents(this.parameters.collaboratore, this.parameters.dataDaCalendario, this.parameters.dataACalendario);
    }
  }

  list() {
    this.turnoAmService.turnoAMListByList(
      this.parameters.collaboratore,
      this.parameters.tipoTurno,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField
    ).subscribe(
      (res: ResponseQueryByCriteria<TurnoAMDTO>) => {
        console.log("response : ", res);
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      }
    );
    this.fetchEvents(this.parameters.collaboratore, this.parameters.dataDaCalendario, this.parameters.dataACalendario);
  }

  onTabChange(event: MatTabChangeEvent) {
    event.index != 0 ? this.vistaData = true : this.vistaData = false;
    this.vistaData ? this.parameters.tipoTurno = null : null;
  }

  estrazionePeriodoVistaMensile(event: CalendarMonthViewBeforeRenderEvent) {
    if ((this.parameters.dataDaCalendario == null || event.period.start.getTime() != this.parameters.dataDaCalendario.getTime()) || (this.parameters.dataACalendario == null || event.period.end.getTime() != this.parameters.dataACalendario.getTime())) {
      this.fetchEvents(this.parameters.collaboratore, event.period.start, event.period.end);
    }
  }

  fetchEventsEmpty(){
    this.fetchEvents(this.parameters.collaboratore, this.parameters.dataDaCalendario, this.parameters.dataACalendario);
  }

  fetchEvents(collaboratore: CollaboratoreAMDTO, dataDa: Date, dataA: Date): void {
    this.parameters.dataDaCalendario = dataDa;
    this.parameters.dataACalendario = dataA;
    
    this.fetchFerieEvents(collaboratore, dataDa, dataA).then((ferie) => {
      this.fetchTurniEvents(collaboratore, dataDa, dataA, ferie).then((turni) => {
        if(this.parameters.tipoTurno == null)
          this.events = [...turni, ...ferie];
        else if(this.parameters.tipoTurno == "FERIE")
          this.events = [...ferie];
        else
          this.events = [...turni];
      });

    });

  }

  fetchTurniEvents(collaboratore: CollaboratoreAMDTO, dataDa: Date, dataA: Date, ferie?: CalendarEvent[]): Promise<CalendarEvent<TurnoAMDTO>[]>{
    return new Promise(resolve => {
      this.turnoAmService.turnoAMListByList(
        collaboratore,
        (this.parameters.tipoTurno == "FERIE")? null : this.parameters.tipoTurno,
        dataDa,
        dataA,
        0,
        9999,
        'ASC',
        this.ordine
      ).subscribe((response: ResponseQueryByCriteria<TurnoAMDTO>) => {
        let res = response.content;
        this.turniMese = res.map((turno: TurnoAMDTO) => {
          let colore = this.hexToRgb(turno.tipoTurno.colore);
          let assenzaMeta = null;
          for(let value of ferie){
            let assenza = value.meta.assenza as AssenzaDTO;
            if(new Date(assenza.dataDa).getTime() <= new Date(turno.dataTurno).getTime() 
            && new Date(assenza.dataA).getTime() >= new Date(turno.dataTurno).getTime() 
            && assenza.richiedente.id == turno.collaboratoreAM.collaboratore.id){              
              assenzaMeta = assenza;
            }
          }
          return {
            id: "turno",
            title: turno.collaboratoreAM.collaboratore.nome + " " + turno.collaboratoreAM.collaboratore.cognome + " - " + turno.tipoTurno.nome + " (" + turno.tipoTurno.orario + ")",
            start: new Date(turno.dataTurno),
            cssClass: 'custom-template-cal',
            color: {
              primary: turno.tipoTurno.colore,
              secondary: "rgb("+ colore.r + " " + colore.g + " " + colore.b + " / 10%)",
            },
            meta: {
              turno: turno,
              assenza: assenzaMeta
            },
          } as CalendarEvent
        });
        resolve(this.turniMese);
      });
    });
  }

  fetchFerieEvents(collaboratore: CollaboratoreAMDTO, dataDa: Date, dataA: Date): Promise<CalendarEvent<AssenzaDTO>[]>{ 
    return new Promise(resolve => {
      let obs: Observable<ResponseQueryByCriteria<AssenzaDTO>>;
      if(collaboratore == null){
        obs = this.collaboratoreAmService.collaboratoreAMAssenze(
          0,
          9999,
          'ASC',
          'id',
          'APPROVATO',
          dataDa,
          dataA,
          'FERIE'
        );
      } else {
        obs = this.assenzaService.assenzaListByList(
          0,
          9999,
          'ASC',
          'id',
          collaboratore.collaboratore,
          'APPROVATO',
          dataDa,
          dataA,
          'FERIE'
        );
      }
      obs.subscribe((response: ResponseQueryByCriteria<AssenzaDTO>) => {
        this.ferieMese = response.content.map((assenza: AssenzaDTO) => {
          return {
            id: "turno",
            title: assenza.richiedente.nome + " " + assenza.richiedente.cognome + " - " + assenza.tipoRichiesta,
            start: new Date(assenza.dataDa),
            end: new Date(assenza.dataA),
            cssClass: 'custom-template-cal',
            color: {
              primary: "#00ffff",
              secondary: "rgb(0 255 255 / 10%)",
            },
            meta: {
              assenza
            },
          } as CalendarEvent 
        });        
        resolve(this.ferieMese);
      });
    });
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  eventClicked(event: CalendarEvent): void {
    if (event.meta.turno != null){
      this.router.navigate(["/turno-am/detail/" + event.meta.turno.id]);
    } else if (event.meta.assenza != null && event.meta.assenza.richiedente.id == this.currentCollaboratore.id){
      this.router.navigate(["/registra-assenza/detail/" + event.meta.assenza.id]);
    }
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ turno: TurnoAMDTO }>[];
  }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.parameters.viewDate = date;
      }
    }
  }

  private hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }  

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { NavigatorService } from '../services/navigator.service';
import { OrarioLavorativoService } from '../services/orario-lavorativo/orario-lavorativo.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { OrarioLavorativoDTO, OrarioLavorativoDuplicaDTO } from '../shared/dto/orario-lavorativo/orario-lavorativo';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';


@Component({
  selector: 'app-orario-lavorativo-duplicate',
  templateUrl: './orario-lavorativo-duplicate.component.html',
  styleUrls: ['./orario-lavorativo-duplicate.component.scss']
})
export class OrarioLavorativoDuplicateComponent extends GenericDetailComponent implements OnInit, OnDestroy{

  idCollab: number;
  collaboratoriFiltrati: CollaboratoreDTO[]=[];
  orariDuplica: OrarioLavorativoDuplicaDTO = new OrarioLavorativoDuplicaDTO;
  collaboratori: CollaboratoreDTO[]=[];
  collaboratoriPassati: CollaboratoreDTO[]=[];
  collaboratore: CollaboratoreDTO;
  
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;

  constructor(
    private urlService: UrlService,
    private orarioLavorativoService: OrarioLavorativoService,
    private collaboratoreService: CollaboratoreService,
    dialog: MatDialog,
    router: Router,
    private activatedRoute: ActivatedRoute, 
    dateAdapter: DateAdapter<Date>,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    timestampFormatPipe: TimestampFormatPipe,

  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe,
    );

    this.form = new FormGroup({
      collaboratoreSource: new FormControl({value: '', disabled:true}),
      collaboratori: new FormControl([], Validators.required),
      });
  }


  ngOnInit(): void {

    this.idCollab= this.activatedRoute.snapshot.params.id;
    if(this.idCollab != null && this.idCollab != 0){
      this.collaboratoreRead();
    }
  

    this.orarioLavorativoService.collaboratoreNoAmNoOrariLavorativi(this.idCollab).subscribe(
        (res: CollaboratoreDTO[]) => {
          this.collaboratori= res;
          console.log("collaboratori filtrati " + this.collaboratori);
        }
    );


    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });

  }


  collaboratoreRead() {
    this.collaboratoreService.read(this.idCollab).subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratore = res;
        this.dtoToForm();
      }
    );
  }

  orariLavorativiDuplica() {
      this.formToDto();
      this.orariDuplica.collaboratoriTargetList = this.collaboratoriPassati;
      let msg:string =`Sei sicuro di voler copiare gli orari lavorativi di ${this.collaboratore.nome} ${this.collaboratore.cognome} su `;
      let msgX1Col:string=`${this.orariDuplica.collaboratoriTargetList[0].nome} ${this.orariDuplica.collaboratoriTargetList[0].cognome} ? `;
      let msgXNCol:string=`${this.orariDuplica.collaboratoriTargetList.length} collaboratori ? `;
      this.orariDuplica.collaboratoriTargetList.length == 1 ? msg += msgX1Col : msg += msgXNCol;
      
      if (this.form.dirty) {
          this.confirm(msg).subscribe(result => {
              if (result) {
                this.orarioLavorativoService.duplicaOrari(this.idCollab, this.orariDuplica).subscribe(
                  (res) => {
                    this.snackBar.open("Duplicazione orari lavorativi completata con successo!", null, { duration: 3000 });
                    this.back(this.previousUrl);
                  }, (err: HttpErrorResponse) => {
                    console.log(err);
                  } 
                );
              }
          });
      }
  }

  private dtoToForm(): void{
    this.form.get("collaboratoreSource").setValue(this.collaboratore.nome+" "+this.collaboratore.cognome);
    this.form.get("collaboratori").setValue(this.collaboratori);
  }

  private formToDto(): void{
    this.collaboratoriPassati=this.form.get("collaboratori").value;
  }

  backConfirm(url: String) {
    const msg: string = "Proseguendo, tutte le modifiche andranno perse. Sei sicuro di voler tornare alla pagina precedente?";
    if (this.form.dirty) {
        this.confirm(msg).subscribe(result => {
            if (result) {
                this.back(url);
            }
        });
    }
    else {
        this.back(url);
    }
  }

  isJappTenantAdmin() : boolean {
    return this.navigatorService.isJappTenantAdmin();
  }

  ngOnDestroy(): void {
  }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione ServiziReperibilità</h2>
        <div fxLayout="column" class="responsive-mat-form">
          <mat-form-field>
            <input matInput [(ngModel)]="parameters.descrizione" placeholder="descrizione"/>
          </mat-form-field>
          <mat-form-field>
            <input matInput [(ngModel)]="parameters.email" placeholder="email"/>
          </mat-form-field>
        </div>

        
        <mat-form-field>
          <mat-select 
              name="numeroReperibilita"
              [compareWith]="compareDTO" 
              [(ngModel)]=" parameters.numeroReperibilita"
              placeholder="Scegli il numero di Reperibilità"
              [disabled]="!isJappTenantAdmin()">
              <mat-option [value]="null" readonly="true"></mat-option>
              <mat-option *ngFor="let numero of numeriReperibilita" readonly="true" [value]="numero">
                  {{numero.mainNumber}}
              </mat-option>
          </mat-select>
       
        </mat-form-field>





        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" 
          [routerLink]="['/gestione-servizi-rep/detail/0']" *ngIf="isJappTenantAdmin()">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let servizio"> {{servizio.id}} </td>
            </ng-container>
            
            <!-- Description Column -->
            <ng-container matColumnDef="descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Description </th>
              <td mat-cell *matCellDef="let servizio"> {{servizio.descrizione}} </td>
            </ng-container>

             <!-- Id Column -->
             <ng-container matColumnDef="numeroReperibilita.mainNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero </th>
              <td mat-cell *matCellDef="let servizio"> {{servizio.numeroReperibilita.mainNumber}} </td>
            </ng-container>

            <!-- Id email -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let servizio"> 
                <ul>
                  <li *ngFor="let email of parseEmail(servizio.email)" >
                    {{email}} 
                  </li>  
                  
                </ul>

              </td>
            </ng-container>
          
        
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let servizio">
                  <button mat-mini-fab color="primary" [routerLink]="['/gestione-servizi-rep/detail/', servizio.id]"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-actions>
                <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'servizireperibilita'})">Excel</button>
                <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'servizireperibilita'})">Csv</button>
                <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'servizireperibilita'})">Json</button>
            </mat-card-actions>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
          </div>
      </div>
    </mat-card-content>
  </mat-card>
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';

export class EventoDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    organizzatore: CollaboratoreDTO;
    descrizione: string;
    categoria: string;
    dataOraInizio: Date;
    dataOraFine: Date;
    luogo: string;
    minPartecipanti: number;
    maxPartecipanti: number;
    maxEsterniPerPartecipante: number;
    dataFineIscrizioni: Date;
    avvertimentoGiorni: number;
}
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { NumeroReperibilitaDTO } from '../shared/dto/reperibilità/NumeroReperibilita';
import { NumeroService } from '../services/reperibilità/numero.service';

@Component({
  selector: 'app-numeri',
  templateUrl: './numeriDetail.component.html',
  styleUrls: ['./numeriDetail.component.scss']
})

export class NumeriDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  numeroId: number;
  numero: NumeroReperibilitaDTO;

  constructor(
    private numeroService: NumeroService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      mainNumber: new FormControl('', Validators.required),
      mainNumberId: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.numeroId= Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.numeroId != null && this.numeroId != 0) {
      this.numeroRead();
    } else {
      this.numero = new NumeroReperibilitaDTO();
    }
  }

  numeroSave() {
    if (this.numero.id == null) {
      this.numeroCreate();
    } else {
      this.numeroUpdate();
    }
  }

  numeroCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.numeroService.create(this.numero).subscribe(
        (res: NumeroReperibilitaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.numero = res;
          this.numeroId = this.numero.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          this.snackBar.open("Attenzione: campi non compilati per creare il numero della reperibilità.", null, { duration: 3000 });
        }
      );
    }
  }

  numeroUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il numero?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.numeroService.update(this.numero).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.numeroRead();
              this.form.markAsPristine();
            }, (err: any) => {
              this.snackBar.open("Attenzione: campi non validi per aggiornare il numero della reperibilità.", null, { duration: 3000 });
            }
          );
        }
      });
    }
  }

  numeroDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il numero della reperibilità?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.numeroService.delete(this.numero.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/numeri-reperibilita"]);
            }
          );
        }
      });
    }
  }

  numeroRead() {
    this.numeroService.read(this.numeroId).subscribe(
      (res: NumeroReperibilitaDTO) => {
        console.log("response : " + res);
        this.numero = res;
        this.numeroId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.numero.descrizione = this.form.get("descrizione").value;
    this.numero.mainNumber = this.form.get("mainNumber").value;
    this.numero.mainNumberId = this.form.get("mainNumberId").value;
  }
 
  private dtoToForm(): void {
    this.form.get("id").setValue(this.numero.id);
    this.form.get("descrizione").setValue(this.numero.descrizione);
    this.form.get("mainNumber").setValue(this.numero.mainNumber);
    this.form.get("mainNumberId").setValue(this.numero.mainNumberId);
    this.form.get("deleteDate").setValue(this.numero.deleteDate);
    this.form.get("deleteUser").setValue(this.numero.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.numero.insertDate));
    this.form.get("insertUser").setValue(this.numero.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.numero.updateDate));
    this.form.get("updateUser").setValue(this.numero.updateUser);
    this.form.get("optLock").setValue(this.numero.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}
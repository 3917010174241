<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli turno am</h2>

        <div fxLayout="column">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
                <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Turno AM Id" readonly="true" />
                </mat-form-field>
                <!-- Collaborattore field -->
                <mat-form-field>
                    <mat-select name="collaboratore" [compareWith]="compareDTO" formControlName="collaboratore"
                        placeholder="Scegli il collaboratore">
                        <mat-option [value]="null">
                        </mat-option>
                        <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                            {{collaboratore.collaboratore.nome}} {{collaboratore.collaboratore.cognome}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <!-- Tipo Turno field -->
                <mat-form-field>
                    <mat-select name="tipoTurno" [compareWith]="compareDTO" formControlName="tipoTurno"
                        placeholder="Scegli il tipo di turno">
                        <mat-option [value]="null">
                        </mat-option>
                        <mat-option *ngFor="let tipo of tipiTurno" readonly="true" [value]="tipo">
                            {{tipo.nome}} ({{tipo.orario}})
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('tipoTurno').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>
                <!-- Data Turno field -->
                <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                    <input matInput readonly="true" (click)="picker.open()" [matDatepicker]="picker"
                        formControlName="dataTurno" placeholder="Scegli data turno">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-button *ngIf="form.get('dataTurno').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="form.get('dataTurno').setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('dataTurno').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Campi di informazione operazione -->
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Dettagli operazione
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row">
                        <!-- InsertDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
                        </mat-form-field>

                        <!-- InsertUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <!-- UpdateDate field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                            <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
                        </mat-form-field>

                        <!-- UpdateUser field -->
                        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                            <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

            </form>
        </div>

    <mat-card-actions>
        <button mat-raised-button [routerLink]="['/turno-am/']" color="primary">Back</button>
        <button mat-raised-button (click)="turnoAMSave()" [disabled]="form.invalid || !form.dirty"
            *ngIf="turnoAM?.deleteDate==null"> {{ (turnoAMId == 0 )? 'Save' : 'Update' }}</button>
            <button mat-raised-button (click)="turnoAMSaveNew()" [disabled]="form.invalid || !form.dirty"
                *ngIf="turnoAM?.deleteDate==null && turnoAMId != 0">Save new</button>
                <button mat-raised-button [routerLink]="['/turno-am/swap/' , turnoAM?.id]" [disabled]="form.invalid"
                    *ngIf="turnoAM?.deleteDate==null && turnoAMId != 0 && !turnoAM?.tipoTurno.sovrapponibile">Swap</button>
        <button mat-raised-button (click)="turnoAMDelete()" [disabled]="turnoAM?.id==null"
            *ngIf="turnoAM?.deleteDate==null">Delete</button>
    </mat-card-actions>

    </mat-card-content>
</mat-card>
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { PropostaDTO } from './proposta';

export class VotoDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    votante: CollaboratoreDTO;
    proposta: PropostaDTO;
    dataVoto: Date;
}
import { Component, OnInit } from '@angular/core';
import { NavigatorService } from '../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatiAnagraficiSDIService } from '../services/fattura-elettronica/datiAnagraficiSDI.service';
import { DatiAnagraficiSDIDTO } from '../shared/dto/fattura-elettronica/datiAnagraficiSDI';
import { QrCodeSDIDTO } from '../shared/dto/fattura-elettronica/qrCodeSDI';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericComponent } from '../shared/GenericComponent';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dati-anagrafici',
  templateUrl: './dati-anagrafici.component.html',
  styleUrls: ['./dati-anagrafici.component.scss']
})
export class DatiAnagraficiComponent extends GenericComponent implements OnInit {

  form: FormGroup;

  datiAnagraficiSDI: DatiAnagraficiSDIDTO;

  qrCodeSDI: QrCodeSDIDTO;

  qrCodeSDIContent: string;

  qrcodeWidth: number;

  constructor(navigatorService : NavigatorService, 
    router: Router,
    dateAdapter: DateAdapter<Date>,
    private datiAnagraficiSDIService : DatiAnagraficiSDIService,
    timestampFormatPipe: TimestampFormatPipe,
    private dialog: MatDialog,
    snackBar: MatSnackBar) {
      super(navigatorService, router, dateAdapter, snackBar, timestampFormatPipe);
      navigatorService.screenWidth.subscribe((screenWidth:number)=>{
        this.qrcodeWidth = screenWidth - (screenWidth*0.1);
      });
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      cf: new FormControl('', Validators.required),
      piva: new FormControl('', Validators.required),
      denom: new FormControl('', Validators.required),
      prov: new FormControl('', Validators.required),
      cap: new FormControl('', Validators.required),
      com: new FormControl('', Validators.required),
      ind: new FormControl('', Validators.required),
      naz: new FormControl('', Validators.required),
      pec: new FormControl(''),
      cod: new FormControl(''),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.read();
  }

  confirm(message: String) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: message
    });
    return dialogRef.afterClosed();
  }

  save() {
    if (this.datiAnagraficiSDI.id == null) {
      this.create();
    } else {
      this.update();
    }
  }

  create() {
    if (this.form.valid) {
      this.formToDto();
      this.datiAnagraficiSDIService.create(this.datiAnagraficiSDI).subscribe(
        (res: DatiAnagraficiSDIDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.datiAnagraficiSDI = res;
          this.dtoToForm();
          this.read();
          this.form.markAsPristine();
        }
      );
    }
  }

  update() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la i dati anagrafici?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.datiAnagraficiSDIService.update(this.datiAnagraficiSDI).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.read();
              this.form.markAsPristine();
            }
          );
        }
      });
    }
  }

  read() {
    this.datiAnagraficiSDIService.qrCodeSDIRead().subscribe(
      (res:QrCodeSDIDTO) => {
        this.qrCodeSDI = res;
        this.qrCodeSDIContent = JSON.stringify(res);
      }
    );
    this.datiAnagraficiSDIService.read().subscribe(
      (res:DatiAnagraficiSDIDTO) => {
        if (res!=null) {
          this.datiAnagraficiSDI = res;
          this.dtoToForm();
        } else {
          this.datiAnagraficiSDI = new DatiAnagraficiSDIDTO();
        }
      }
    );
  }

  private formToDto(): void {
    this.datiAnagraficiSDI.cf = this.form.get("cf").value;
    this.datiAnagraficiSDI.piva = this.form.get("piva").value;
    this.datiAnagraficiSDI.denom = this.form.get("denom").value;
    this.datiAnagraficiSDI.prov = this.form.get("prov").value;
    this.datiAnagraficiSDI.cap = this.form.get("cap").value;
    this.datiAnagraficiSDI.com = this.form.get("com").value;
    this.datiAnagraficiSDI.ind = this.form.get("ind").value;
    this.datiAnagraficiSDI.naz = this.form.get("naz").value;
    this.datiAnagraficiSDI.pec = this.form.get("pec").value;
    this.datiAnagraficiSDI.cod = this.form.get("cod").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.datiAnagraficiSDI.id);
    this.form.get("cf").setValue(this.datiAnagraficiSDI.cf);
    this.form.get("piva").setValue(this.datiAnagraficiSDI.piva);
    this.form.get("denom").setValue(this.datiAnagraficiSDI.denom);
    this.form.get("prov").setValue(this.datiAnagraficiSDI.prov);
    this.form.get("cap").setValue(this.datiAnagraficiSDI.cap);
    this.form.get("com").setValue(this.datiAnagraficiSDI.com);
    this.form.get("ind").setValue(this.datiAnagraficiSDI.ind);
    this.form.get("naz").setValue(this.datiAnagraficiSDI.naz);
    this.form.get("pec").setValue(this.datiAnagraficiSDI.pec);
    this.form.get("cod").setValue(this.datiAnagraficiSDI.cod);
    this.form.get("deleteDate").setValue(this.datiAnagraficiSDI.deleteDate);
    this.form.get("deleteUser").setValue(this.datiAnagraficiSDI.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.datiAnagraficiSDI.insertDate));
    this.form.get("insertUser").setValue(this.datiAnagraficiSDI.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.datiAnagraficiSDI.updateDate));
    this.form.get("updateUser").setValue(this.datiAnagraficiSDI.updateUser);
    this.form.get("optLock").setValue(this.datiAnagraficiSDI.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

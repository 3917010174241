import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { environment } from 'src/environments/environment';
import { GenericService } from 'src/app/shared/GenericService';
import { AttivitaDTO } from './../../shared/dto/rapportino/attivita';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AttivitaService extends GenericService<AttivitaDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/attivita';
  }

  public attivitaList(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO, filter: string): Observable<ResponseQueryByCriteria<AttivitaDTO>> {
    let idCollaboratore: number;
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    if (collaboratore != null) idCollaboratore = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<AttivitaDTO>>(`${this.url}/attvitaListByDescrizione/${idCollaboratore}`, {
      params: params
    });
  }

  public attivitByDescrizione(collaboratore: CollaboratoreDTO, descrizione: string): Observable<AttivitaDTO> {
    let params = {};
    if (descrizione != null) params["descrizione"] = descrizione;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    return this.http.get<AttivitaDTO>(`${this.url}/attivitaByDescrizione`, {
      params: params
    });
  }
}

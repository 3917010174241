import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssenzaDTO } from 'src/app/shared/dto/assenza/assenza';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { EventoDTO } from 'src/app/shared/dto/evento/evento';
import { PartecipanteEventoDTO } from 'src/app/shared/dto/evento/partecipante-evento';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartecipanteEventoService extends GenericService<PartecipanteEventoDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/partecipanteEvento';
  }

  public partecipanteEventoListByList(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore?: CollaboratoreDTO,
      evento?: EventoDTO, spettatore?: boolean
      ): Observable<ResponseQueryByCriteria<PartecipanteEventoDTO>> {
    let params = {};
    params["pageNumber"] = String(pageNumber);
    params["pageSize"] = String(pageSize);
    params["direction"] = direction;
    params["sortField"] = sortField;

    if(collaboratore != null) params["collaboratore"] = String(collaboratore.id);
    if(evento != null) params["evento"] = String(evento.id);
    params["spettatore"] = spettatore;

    return this.http.get<ResponseQueryByCriteria<PartecipanteEventoDTO>>(`${this.url}/partecipanteEventoListByList`, {
      params: params
    });
  }

}

<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione partecipanti</h2>

    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field>
        <mat-select name="collaboratore" [compareWith]="compareDTO" [(ngModel)]="parameters.collaboratore"
          placeholder="Seleziona collaboratore">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{ collaboratore.nome }} {{ collaboratore.cognome }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select name="evento" [compareWith]="compareDTO" [(ngModel)]="parameters.evento"
          placeholder="Seleziona evento">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let evento of eventi" [value]="evento">
            {{ evento.descrizione }} - {{ evento.dataOraInizio | date: 'yyyy/MM/dd' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox name="spettatore" [(ngModel)]="parameters.spettatore">Spettatore</mat-checkbox>
    </div>

    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>

      <div *ngIf="parameters.evento;then new_with_id else new"></div>
      <ng-template #new_with_id>
        <button mat-raised-button id="new-button" [disabled]="isEventFull()"
          [queryParams]="{'id': parameters.evento.id}" [routerLink]="['/partecipante-evento/detail/0']">New</button>
      </ng-template>
      <ng-template #new>
        <button mat-raised-button id="new-button" [disabled]="isEventFull()"
          [routerLink]="['/partecipante-evento/detail/0']">New</button>
      </ng-template>

      <button mat-raised-button id="back-button" (click)="backToEvent()">Back</button>
    </mat-card-actions>

    <br>

    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table [dataSource]="parameters.dataSource" matSort (matSortChange)="setSort($event)"
        matSortDisableClear>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let partecipante"> {{partecipante.id}} </td>
        </ng-container>

        <ng-container matColumnDef="collaboratore.nome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore </th>
          <td mat-cell *matCellDef="let partecipante"> {{partecipante.collaboratore.nome}}
            {{partecipante.collaboratore.cognome}}
          </td>
        </ng-container>

        <ng-container matColumnDef="evento.descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Evento </th>
          <td mat-cell *matCellDef="let partecipante"> {{partecipante.evento.descrizione}}</td>
        </ng-container>

        <ng-container matColumnDef="spettatore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Spettatore </th>
          <td mat-cell *matCellDef="let partecipante"> {{partecipante.spettatore}}</td>
        </ng-container>

        <ng-container matColumnDef="numOspiti">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero Ospiti </th>
          <td mat-cell *matCellDef="let partecipante"> {{partecipante.numOspiti}}</td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let partecipante">
            <button [disabled]="!isJappTenantAdmin() && partecipante.collaboratore.id != currentCollaboratore.id"
              mat-mini-fab color="primary" [routerLink]="['/partecipante-evento/detail/', partecipante.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>

      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-paginator #paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
          [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
        </mat-paginator>
      </div>
    </div>

  </mat-card-content>
</mat-card>
import { Component, OnDestroy, OnInit } from "@angular/core";
import { GenericListComponent } from "../shared/GenericListCoumponent";
import { CollaboratoreDTO } from "../shared/dto/domain/collaboratore";
import { TurnoServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/TurnoServizioReperibilitaDTO";
import { InterventoTurnoReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/InterventoTurnoReperibilitaDTO";
import { ServiziReperibilitaDTO } from "../shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { TurniServiziReperibilitaService } from "../services/gestione-servizi-reperibilita/turniservizireperibilita.service";
import { CollaboratoreService } from "../services/domain/collaboratore.service";
import { ServiziRepService } from "../services/gestione-servizi-reperibilita/serviziRep.service";
import { ComponentCacheService } from "../services/component-cache.service";
import { NavigatorService } from "../services/navigator.service";
import { Router } from "@angular/router";
import { TimestampFormatPipe } from "../commons/timestampFormatPipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DateAdapter } from '@angular/material/core';
import { ResponseQueryByCriteria } from "../shared/dto/responseQueryByCriteria";
import { InterventiReperibilitaService } from "../services/interventi-reperibilita/interventi-reperibilita.service";


@Component({
    selector: 'app-intervento-reperibilita',
    templateUrl: './intervento-reperibilita.component.html',
    styleUrls: ['./intervento-reperibilita.component.scss']
  })
  export class InterventoReperibilitaComponent extends GenericListComponent implements OnInit, OnDestroy{

    collaboratori: CollaboratoreDTO[];
    servizi: ServiziReperibilitaDTO[];
    turni: TurnoServiziReperibilitaDTO[];
    interventi: InterventoTurnoReperibilitaDTO[]
    dataDa: Date;
    dataA: Date;
    result

    constructor(
      private turniService: TurniServiziReperibilitaService,
      private collaboratoreService: CollaboratoreService,
      private serviziService: ServiziRepService,
      private interventiService :InterventiReperibilitaService,
      componentCacheService: ComponentCacheService,
      navigatorService: NavigatorService,
      dateAdapter: DateAdapter<Date>,
      router: Router,
      snackBar: MatSnackBar,
      timestampFormatPipe: TimestampFormatPipe
      
    ) {
      super(navigatorService,
        componentCacheService,
        dateAdapter,
        router,
        snackBar,
        timestampFormatPipe);
      this.displayedColumns = ['id','turno.collaboratore.nome','turno.servizio.descrizione','dataDa','dataA', 'detail'];
      this.parameters = {
        dataSource: [],
        showList: false,
        collaboratore: null,
        servizio:null,
        initDataDa:true,
        dataDa: null,
        dataA: null,
        sortField: 'id',
        sortDirection: 'DESC',
        pageNumber: 0,
        pageSize: 50,
        length: 0,
      }
    }




    ngOnInit() {

      super.ngOnInit();
      if (!this.parameters.collaboratoreChanged) {
        this.navigatorService.collaboratore.subscribe(
          (res: CollaboratoreDTO) => {
            this.parameters.collaboratore = res;
          }
        );
      }
      this.collaboratoreService.collaboratoreList(
        0,
        9999,
        'ASC',
        'nome',
        '').subscribe(
          (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
            this.collaboratori = res.content;
          }
        );
      

      this.serviziService.serviziList(
          0,
          9999,
          'ASC',
          'descrizione',
          '',
          '',
          null).subscribe(
            (res: ResponseQueryByCriteria<ServiziReperibilitaDTO>) => {
              this.servizi = res.content;
            },
            
          );
        
  
      if (this.parameters.dataDa == null && this.parameters.initDataDa) {
        this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        
      }

    }
  
    list() {
    this.interventiService.list( 
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.direction,
      this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.servizio,
      this.parameters.dataDa,
      this.parameters.dataA).subscribe(res=>{
        console.log("response : " + res);
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
       })

      }

    
  }
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SedeService } from 'src/app/services/sede/sede.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from 'src/app/commons/timestampFormatPipe';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { UfficioDTO } from 'src/app/shared/dto/ufficio/ufficio';
import { UfficioService } from '../services/ufficio/ufficio.service';
import { SedeDTO } from '../shared/dto/sede/sede';

@Component({
  selector: 'app-ufficio',
  templateUrl: './ufficio.component.html',
  styleUrls: ['./ufficio.component.scss']
})
export class UfficioComponent extends GenericListComponent implements OnInit, OnDestroy {

  sedi: SedeDTO[];

  constructor(
    private sedeService: SedeService,
    private ufficioService: UfficioService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'descrizione', 'postazioniMassime', 'sede.descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      filterSede: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.ufficioService.ufficioListByDescrizione(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.filter,
      this.parameters.filterSede).subscribe(
        (res: ResponseQueryByCriteria<UfficioDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnInit() {
    super.ngOnInit();
    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<SedeDTO>) => {
          console.log("response : " + res);
          this.sedi = res.content;
        }
      );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
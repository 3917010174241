import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { TimestampFormatPipe } from 'src/app/commons/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { CollaboratoreService } from 'src/app/services/domain/collaboratore.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { CategoriaEventoService } from 'src/app/services/evento/categoria-evento.service';
import { CategoriaEventoDTO } from 'src/app/shared/dto/evento/categoria-evento';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent extends GenericListComponent implements OnInit, OnDestroy {

  defaultSortField = 'id';
  defaultSortDirection = 'asc';

  constructor(private collaboratoreService: CollaboratoreService,
    private categoriaService: CategoriaEventoService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);

    this.displayedColumns = ['id', 'nome', 'detail'];

    this.parameters = {
      dataSource: [],
      showList: false,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.categoriaService.categoriaEventoList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField).subscribe({
        next: (response: ResponseQueryByCriteria<CategoriaEventoDTO>) => {
          this.parameters.dataSource = response.content;
          this.parameters.pageNumber = response.pageNumber;
          this.parameters.pageSize = response.pageSize;
          this.parameters.showList = true;
          this.parameters.length = response.totalElements;
        }
      });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

<mat-card class="mat-elevation-z0" (click)="cryptoCurrencyRateRead()">
    <mat-card-content fxLayout="column">
        <div fxFlex="100" id="bitcoin">
            <div fxFlex fxLayoutAlign="start center" class="crypto-widget-row">
                <mat-icon fxFlex="10" class="mat-fa-icon" fontSet="fab" fontIcon="fa-btc"></mat-icon>
                <span fxFlex="30" class="name-label">Bitcoin</span>
                <span fxFlex="30" class="value-label"> {{ this.bitcoinValueEur | currency: 'EUR' }} </span>
                <mat-icon fxFlex="7" *ngIf="bitcoinDayDiff>0" style="color: #4caf50">expand_less</mat-icon>
                <mat-icon fxFlex="7" *ngIf="bitcoinDayDiff<0" style="color: #f44336">expand_more</mat-icon>
                <span fxFlex="25">&nbsp; {{ bitcoinDayDiff | number:'.1-3' }} %</span>
            </div>
        </div>
        <div fxFlex="100" id="ethereum">
            <div fxFlex fxLayoutAlign="start center" class="crypto-widget-row">
                <mat-icon fxFlex="10" class="mat-fa-icon" fontSet="fab" fontIcon="fa-ethereum"></mat-icon>
                <span fxFlex="30" class="name-label">Ethereum</span>
                <span fxFlex="30" class="value-label"> {{ this.ethereumValueEur | currency: 'EUR' }} </span>
                <mat-icon fxFlex="7" *ngIf="ethereumDayDiff>0" style="color: #4caf50">expand_less</mat-icon>
                <mat-icon fxFlex="7" *ngIf="ethereumDayDiff<0" style="color: #f44336">expand_more</mat-icon>
                <span fxFlex="25">&nbsp; {{ ethereumDayDiff | number:'.1-3' }} %</span>
            </div>
        </div>
        <p class="update-label">Ultimo aggiornamento: {{ updateTimestamp | date: 'HH:mm:ss' }}</p>
    </mat-card-content>
</mat-card>
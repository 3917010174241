import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './../../shared/GenericService';
import { CollaboratoreAMDTO } from './../../shared/dto/turno-am/collaboratore-am';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AssenzaDTO } from 'src/app/shared/dto/assenza/assenza';

@Injectable({
  providedIn: 'root'
})
export class CollaboratoreAmService extends GenericService<CollaboratoreAMDTO, number>{

  constructor(http: HttpClient) { 
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/collaboratoreAM'
  }

  public collaboratoreAMListByList(
    filter?: string,
    pageNumber?: number,
    pageSize?: number, 
    direction?: string, 
    sortField?: string 
  ): Observable<ResponseQueryByCriteria<CollaboratoreAMDTO>>{
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    return this.http.get<ResponseQueryByCriteria<CollaboratoreAMDTO>>(`${this.url}/collaboratoreListByList`, {
      params: params
    });
  }

  public collaboratoreAMAssenze(pageNumber: number, pageSize: number, direction: string, sortField: string, stato?: string, dataDa?: Date, dataA?: Date, tipoRichiesta?: string): Observable<ResponseQueryByCriteria<AssenzaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (stato != null) params["stato"] = stato;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    if (tipoRichiesta != null) params["tipoRichiesta"] = tipoRichiesta;
    return this.http.get<ResponseQueryByCriteria<AssenzaDTO>>(`${this.url}/collaboratoreAssenze`, {
      params: params
    });
  }
}

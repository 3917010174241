import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NavigatorService } from '../services/navigator.service';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorInterceptor implements HttpInterceptor {

  private readonly versionInfoURL = environment.sitePrefix+'/META-INF/maven/it.jenia.japp/japp.client.webapp/pom.properties';

  constructor(private navigatorService: NavigatorService) { 
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log("httpinterceptor error : " + err);
        if (err.status == 403) {
          this.navigatorService.tenantInfo();
          return EMPTY;
        }
        if (err.url != this.versionInfoURL) {
          this.navigatorService.setHttpError(err);
        }
        return throwError(err);
      })
    );
  }
}
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { NavigatorService } from './../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { RimborsoDTO } from '../shared/dto/rimborso';
import { RimborsoService } from '../services/rimborso/rimborso.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-rimborso-pie-di-lista',
  templateUrl: './rimborso-pie-di-lista.component.html',
  styleUrls: ['./rimborso-pie-di-lista.component.scss']
})

export class RimborsoPieDiListaComponent extends GenericListComponent implements OnInit {

  public collaboratori: CollaboratoreDTO[];
  today = new Date();
  rimborsoVerificato: RimborsoDTO;
  
  constructor(
    private collaboratoreService: CollaboratoreService,
    private rimborsoService: RimborsoService,
    private dialog: MatDialog,
    navigatorService: NavigatorService,
    componentCacheService: ComponentCacheService,
    dateAdapter: DateAdapter<Date>,
    snackBar: MatSnackBar,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'collaboratore', 'DataEmissione', 'importo', 'descrizione', 'fatto', 'verifica', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sortField: 'dataEmissione',
      sortDirection: 'ASC',
      pageNumber: 0,
      pageSize: 50,
      length: 0,
      collaboratore: null,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      descrizione: null
    };
     this.rimborsoVerificato = null;
  }

  public list() {
    this.rimborsoService.rimborsoListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.descrizione).subscribe(
        (res: ResponseQueryByCriteria<RimborsoDTO>) => {
          console.log('response : ' + res.content);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  verificaRimborso(rimborso: RimborsoDTO) {
    this.confirm("Attenzione! L'operazione di verifica del rimborso non sarà più ripetibile. Sei sicuro di voler proseguire?").subscribe(result => {
      if (result) {
        if (rimborso.fatto == false) {
          rimborso.fatto = true;
          this.rimborsoService.update(rimborso).subscribe(
            (res) => {
              console.log("Stato del rimborso " + rimborso.descrizione + " è: " + rimborso.fatto);
            });
        }
      }
    });
  }

  trascodificaStatoRimborso(stato: boolean) {
    var msg: String;
    if (stato == true) {
      msg = 'Rimborsato';
    } else if (stato == false) {
      msg = 'Non rimborsato';
    }
    return msg;
  }

  confirm(message: String) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: message
    });
    return dialogRef.afterClosed();
}

  ngOnInit() {
    super.ngOnInit();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        console.log("Utente loggato attualmente: " + res);
        this.parameters.collaboratore = res;
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log(res.content);
          this.collaboratori = res.content;
        }
      );

      if (this.parameters.dataDa == null && this.parameters.initDataDa) {
        this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
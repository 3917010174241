import { ResponseFail } from './../shared/dto/responseFail';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AttivitaService } from './../services/rapportino/attivita.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigatorService } from './../services/navigator.service';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { AttivitaDTO } from './../shared/dto/rapportino/attivita';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attivita-detail',
  templateUrl: './attivita-detail.component.html',
  styleUrls: ['./attivita-detail.component.scss']
})
export class AttivitaDetailComponent extends GenericDetailComponent implements OnInit {

  attivitaId: number;
  attivita: AttivitaDTO;
  collaboratori: CollaboratoreDTO[];
  

  constructor(
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private attivitaService: AttivitaService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      descrizione: new FormControl(null, Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.attivitaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.attivitaId != null && this.attivitaId != 0) {
      this.attivitaRead();
    } else {
      this.attivitaInit();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }

  attivitaSave() {
    if (this.attivita.id == null) {
      this.attivitaCreate();
    } else {
      this.attivitaUpdate();
    }
  }

  attivitaCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.attivitaService.create(this.attivita).subscribe(
        (res: AttivitaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.attivita = res;
          this.attivitaId = this.attivita.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidAttivitaException_ATTIVITA_DESCRIZIONE_EXIST") {
            this.snackBar.open("Attenzione! Attivita già esistente", null, { duration: 4000 });
          }
        });
    }
  }

  attivitaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere l'attivita?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.attivitaService.update(this.attivita).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.attivitaRead();
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidAttivitaException_ATTIVITA_DESCRIZIONE_EXIST") {
                this.snackBar.open("Attenzione! Attivita già esistente", null, { duration: 4000 });
              }
            });
        }
      });
    }
  }

  attivitaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare l'attivita?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.attivitaService.delete(this.attivita.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/attivita"]);
            }
          );
        }
      });
    }
  }

  attivitaRead() {
    this.attivitaService.read(this.attivitaId).subscribe(
      (res: AttivitaDTO) => {
        console.log("response : " + res);
        this.attivita = res;
        this.attivitaId = res.id;
        this.dtoToForm();
      }
    );
  }

  attivitaInit() {
    this.attivita = new AttivitaDTO();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
  }

  private formToDto(): void {
    this.attivita.collaboratore = this.form.get("collaboratore").value;
    this.attivita.descrizione = this.form.get("descrizione").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.attivita.id);
    this.form.get("collaboratore").setValue(this.attivita.collaboratore);
    this.form.get("descrizione").setValue(this.attivita.descrizione);
    this.form.get("deleteDate").setValue(this.attivita.deleteDate);
    this.form.get("deleteUser").setValue(this.attivita.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.attivita.insertDate));
    this.form.get("insertUser").setValue(this.attivita.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.attivita.updateDate));
    this.form.get("updateUser").setValue(this.attivita.updateUser);
    this.form.get("optLock").setValue(this.attivita.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

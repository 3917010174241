<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettaglio evento</h2>

    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">

        <mat-form-field>
          <input matInput formControlName="id" placeholder="Evento ID" readonly="true" />
        </mat-form-field>

        <mat-form-field>
          <mat-select name="organizzatore" [compareWith]="compareDTO" placeholder="Seleziona organizzatore"
            formControlName="organizzatore">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{ collaboratore.nome }} {{ collaboratore.cognome }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('organizzatore').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Descrizione" formControlName="descrizione">
          <mat-error *ngIf="form.get('descrizione').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-select name="categoria" [compareWith]="compareDTO" placeholder="Seleziona categoria"
            formControlName="categoria">
            <mat-option *ngFor="let categoria of categorie" [value]="categoria">
              {{ categoria.nome }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('categoria').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <mat-form-field fxFlex="50">
            <input matInput type="datetime-local" [max]="form.get('dataOraFine').value" formControlName="dataOraInizio"
              placeholder="Data da">
            <mat-error *ngIf="form.get('dataOraInizio').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <input matInput type="datetime-local" [min]="form.get('dataOraInizio').value" formControlName="dataOraFine"
              placeholder="Data a">
            <mat-error *ngIf="form.get('dataOraFine').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field>
          <input matInput placeholder="Luogo" formControlName="luogo">
          <mat-error *ngIf="form.get('luogo').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">

          <mat-form-field fxFlex="50">
            <input matInput type="number" placeholder="Min partecipanti" formControlName="minPartecipanti">
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <input matInput type="number" placeholder="Max partecipanti" formControlName="maxPartecipanti">
          </mat-form-field>

        </div>

        <mat-form-field>
          <input matInput type="number" placeholder="Max esterni per partecipante" formControlName="maxEsterniPerPartecipante">
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <input matInput readonly="true" formControlName="dataFineIscrizioni" [matDatepicker]="pickerDataFine"
            placeholder="Fine Iscrizioni" (click)="pickerDataFine.open()">
          <mat-datepicker-toggle matSuffix [for]="pickerDataFine"></mat-datepicker-toggle>
          <mat-datepicker #pickerDataFine></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="number" placeholder="Giorni avvertimento" formControlName="avvertimentoGiorni">
        </mat-form-field>

      </form>
    </div>

    <mat-card-actions>
      <button mat-raised-button (click)="eventoSave()" id="save-button" name="Save" color="primary"
        [disabled]="form.invalid || !form.dirty || shouldDisable">Save</button>
      <button mat-raised-button (click)="eventoDelete()" id="delete-button" name="Delete"
        [disabled]="eventoId == 0 || shouldDisable">Delete</button>
      <button mat-raised-button [routerLink]="['/evento']" id="back-button" name="Back">Back</button>
      <button [disabled]="eventoId==0" mat-raised-button id="subscriptions-button" [queryParams]="{'id': eventoId}"
        [routerLink]="['/partecipante-evento']">Subscriptions</button>
      <button [disabled]="eventoId==0" mat-raised-button id="subscribe-button" [queryParams]="{'id': eventoId}"
        [routerLink]="['/partecipante-evento/detail/0']">Subscribe</button>
    </mat-card-actions>

  </mat-card-content>
</mat-card>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from 'src/app/shared/GenericService';
import { OrarioLavorativoDTO, OrarioLavorativoDuplicaDTO } from 'src/app/shared/dto/orario-lavorativo/orario-lavorativo';
import { environment } from 'src/environments/environment';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { DAYS_OF_WEEK } from 'angular-calendar';


@Injectable({
  providedIn: 'root'
})
export class OrarioLavorativoService extends GenericService<OrarioLavorativoDTO, number>{

  private _giorni: string[];
  private giorniTradMap : Map<DAYS_OF_WEEK, string> = new Map<DAYS_OF_WEEK, string>();
  private giorniOrderMap : Map<DAYS_OF_WEEK, number> = new Map<DAYS_OF_WEEK, number>();

  constructor(http: HttpClient) {
    super(http);
    this.url=environment.urlPrefix + '/services/rest/json/orarioLavorativo';
    this._giorni = Object.keys(DAYS_OF_WEEK).filter(key => !isNaN(Number(DAYS_OF_WEEK[key])));

    this.giorniTradMap.set(DAYS_OF_WEEK.MONDAY, "Lunedì");
    this.giorniTradMap.set(DAYS_OF_WEEK.TUESDAY, "Martedì");
    this.giorniTradMap.set(DAYS_OF_WEEK.WEDNESDAY, "Mercoledì");
    this.giorniTradMap.set(DAYS_OF_WEEK.THURSDAY, "Giovedì");
    this.giorniTradMap.set(DAYS_OF_WEEK.FRIDAY, "Venerdì");
    this.giorniTradMap.set(DAYS_OF_WEEK.SATURDAY, "Sabato");
    this.giorniTradMap.set(DAYS_OF_WEEK.SUNDAY, "Domenica");

    this.giorniOrderMap.set(DAYS_OF_WEEK.MONDAY, 1);
    this.giorniOrderMap.set(DAYS_OF_WEEK.TUESDAY, 2);
    this.giorniOrderMap.set(DAYS_OF_WEEK.WEDNESDAY, 3);
    this.giorniOrderMap.set(DAYS_OF_WEEK.THURSDAY, 4);
    this.giorniOrderMap.set(DAYS_OF_WEEK.FRIDAY, 5);
    this.giorniOrderMap.set(DAYS_OF_WEEK.SATURDAY, 6);
    this.giorniOrderMap.set(DAYS_OF_WEEK.SUNDAY, 7);
   }

   public orarioLavorativoList(
    collaboratore?: CollaboratoreDTO,
    giorno?: DAYS_OF_WEEK, 
    pageNumber?: number, 
    pageSize?: number, 
    direction?: string, 
    sortField?: string
  ): Observable<ResponseQueryByCriteria<OrarioLavorativoDTO>> {
    
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore!= null) params["idCollaboratore"] = collaboratore.id;
    if (giorno!=null) params["giorno"]= giorno;

    return this.http.get<ResponseQueryByCriteria<OrarioLavorativoDTO>>(this.url+"/orarioLavorativoByList", {
      params:params
    });
  }

  get giorni(): string[] {
    let ret = this._giorni.sort((a: string, b: string) => this.giorniOrderMap.get(DAYS_OF_WEEK[a]) - this.giorniOrderMap.get(DAYS_OF_WEEK[b]));
    return ret;
  }

  public duplicaOrari(idCollaboratore: number, orariDuplica: OrarioLavorativoDuplicaDTO):Observable<void>{
    let endpointUrl = `${this.url}/duplica/${idCollaboratore}`;
    return this.http.post<void>(endpointUrl, orariDuplica);
  }


  public collaboratoreNoAmNoOrariLavorativi(idCollaboratore: number):Observable<CollaboratoreDTO[]> {
    return this.http.get<CollaboratoreDTO[]>(`${this.url}/collaboratoriDuplicabili/${idCollaboratore}`);
  }


  translateDayToIta(giorno: string): string{
    return this.giorniTradMap.get(DAYS_OF_WEEK[giorno]);
  }

}

import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { PrenotazioneDTO } from 'src/app/shared/dto/prenotazione/prenotazione';

@Injectable({
  providedIn: 'root'
})
export class PrenotazioneService extends GenericService<PrenotazioneDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/prenotazione';
  }

  public prenotazioneListByList(pageNumber: number, pageSize: number, direction: string, sortField: string, richiedente?: CollaboratoreDTO, dataDa?: Date, dataA?: Date, luogo?: string, riferimento?: string, stato?: boolean): Observable<ResponseQueryByCriteria<PrenotazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (richiedente != null) params["richiedente"] = richiedente.id;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    if (luogo != null) params["luogo"] = luogo;
    if (riferimento != null) params["riferimento"] = riferimento;
    if (stato != null) params["stato"] = stato;
    return this.http.get<ResponseQueryByCriteria<PrenotazioneDTO>>(`${this.url}/prenotazioneListByList`, {
      params: params
    });
  }
}

import { SedeService } from './../services/sede/sede.service';
import { SalettaDTO } from './../shared/dto/saletta/saletta';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { ResponseFail } from './../shared/dto/responseFail';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from './../services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { DateAdapter } from '@angular/material/core';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { SalettaService } from './../services/saletta/saletta.service';
import { PrenotazioneSalettaService } from './../services/prenotazioneSaletta/prenotazione-saletta.service';
import { Component, OnInit } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PrenotazioneSalettaDTO } from '../shared/dto/prenotazioneSaletta/prenotazioneSaletta';
import { SedeDTO } from '../shared/dto/sede/sede';
import { BreakpointObserver } from '@angular/cdk/layout';


@Component({
  selector: 'app-gestione-prenotazione-saletta-detail',
  templateUrl: './gestione-prenotazione-saletta-detail.component.html',
  styleUrls: ['./gestione-prenotazione-saletta-detail.component.scss']
})
export class GestionePrenotazioneSalettaDetailComponent extends GenericDetailComponent implements OnInit {

  prenotazioneSalettaId: number;
  prenotazioneSaletta: PrenotazioneSalettaDTO;
  collaboratori: CollaboratoreDTO[];
  collaboratore: CollaboratoreDTO;
  salette: SalettaDTO[];
  saletta: SalettaDTO;
  sedi: SedeDTO[];
  dataMax: Date;
  dataMin: Date;
  disableDelete: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private prenotazioneSalettaService: PrenotazioneSalettaService,
    private sedeService: SedeService,
    private salettaService: SalettaService,
    private collaboratoreService: CollaboratoreService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      collaboratore: new FormControl('', Validators.required),
      sede: new FormControl('', Validators.required),
      saletta: new FormControl({ value: '', disabled: true }, Validators.required),
      descrizione: new FormControl('', Validators.required),
      dataDa: new FormControl('', Validators.required),
      timeDa: new FormControl('', Validators.required),
      dataA: new FormControl('', Validators.required),
      timeA: new FormControl('', Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    })
  }

  ngOnInit(): void {
    this.prenotazioneSalettaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.prenotazioneSalettaId != 0 && this.prenotazioneSalettaId != null) {
      this.prenotazioneSalettaRead();
    } else {
      this.prenotazioneSaletta = new PrenotazioneSalettaDTO();
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.form.get("collaboratore").setValue(res);
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (sedi: ResponseQueryByCriteria<SedeDTO>) => {
          this.sedi = sedi.content;
        });
  }
  // metodo che prende comne parametro un evento che rappresenta la selezione della sede, e quindi l'oggetto scelto
  onSedeChange(e) {
    if (e && e.value) {
      this.loadSaletteAfterOnSedeChange(e.value);
      this.form.controls['saletta'].enable();
    }
  }

  //metodo per la popolazione dinamica delle salette riceve un DTO dall evento scatenato dalla selezione della sede
  loadSaletteAfterOnSedeChange(sede) {
    console.log("sede: " + sede);
    this.salettaService.salettaList(
      0,
      9999,
      'ASC',
      'descrizione',
      '', sede).subscribe(
        (res: ResponseQueryByCriteria<SalettaDTO>) => {
          this.salette = res.content;
          if (this.salette.length == 0) {
            this.form.controls['saletta'].disable();
          }
        });
  }

  setMinDate() {
    this.dataMin = this.form.get("dataDa").value;
    if (this.dataMax == null) {
      this.dataMax = this.dataMin;
      this.form.get("dataA").setValue(this.dataMax);
    }
  }

  setMaxDate() {
    this.dataMax = this.form.get("dataA").value;
    if (this.dataMin == null) {
      this.dataMin = this.dataMax;
      this.form.get("dataDa").setValue(this.dataMin);
    }
  }

  prenotazioneSalettaSave() {
    if (this.prenotazioneSaletta.id == null) {
      this.prenotazioneSalettaCreate();
    } else {
      this.prenotazioneSalettaUpdate();
    }
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  prenotazioneSalettaCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.prenotazioneSalettaService.create(this.prenotazioneSaletta).subscribe(
        (res: PrenotazioneSalettaDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.prenotazioneSaletta = res;
          this.prenotazioneSalettaId = this.prenotazioneSaletta.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_SALETTA_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già una prenotazione nel periodo di date indicato.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_DATA_BEFORE_NOW") {
            this.snackBar.open("Attenzione! La 'Data Da' è minore della data di oggi", null, { duration: 4000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_DATA_DA_GREATER_THAN_DATA_A") {
            this.snackBar.open("Attenzione! La 'Data Da' è maggiore della 'Data A'", null, { duration: 4000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_SCADUTA") {
            this.snackBar.open("Attenzione! Impossibile modificare una prenotazione di data passata", null, { duration: 4000 });
          }
        }
      );
    }
  }


  private isDataBeforeNow() {
    if (new Date(this.prenotazioneSaletta.dataDa).getTime() < new Date().getTime() && !this.isJappTenantAdmin()) {
      this.form.get("dataDa").disable();
      if (new Date(this.prenotazioneSaletta.dataA).getTime() < new Date().getTime() && !this.isJappTenantAdmin()) {
        this.form.disable();
        this.snackBar.open("Attenzione! Solo la visualizione della prenotazione è abilitata dato che la prenotazione è vecchia", null, { duration: 6300 });
      }
    }
  }




  prenotazioneSalettaRead() {
    this.prenotazioneSalettaService.read(this.prenotazioneSalettaId).subscribe(
      (res: PrenotazioneSalettaDTO) => {
        this.prenotazioneSaletta = res;
        this.prenotazioneSalettaId = res.id;
        this.form.controls['saletta'].enable();
        this.isCollaboratoreAttuale(this.prenotazioneSaletta.collaboratore);
        this.isDataBeforeNow();
        this.dtoToForm();
      })
  }

  

  private isCollaboratoreAttuale(collaboratore: CollaboratoreDTO) {
    this.navigatorService.collaboratore.subscribe(
      (collabRes: CollaboratoreDTO) => {
        if (collaboratore.id != collabRes.id && !this.isJappTenantAdmin()) {
          this.disableDelete = true;
          this.form.disable();
        }
      });
  }

  prenotazioneSalettaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la prenotazione?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.prenotazioneSalettaService.update(this.prenotazioneSaletta).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 4000 });
              this.prenotazioneSalettaRead();
              this.form.markAsPristine();
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_SALETTA_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già una prenotazione nel periodo di date indicato.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_DATA_BEFORE_NOW") {
                this.snackBar.open("Attenzione! La 'Data Da' è minore della data di oggi", null, { duration: 4000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_DATA_DA_GREATER_THAN_DATA_A") {
                this.snackBar.open("Attenzione! La 'Data Da' è maggiore della 'Data A'", null, { duration: 4000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneSalettaException_PRENOTAZIONE_SCADUTA") {
                this.snackBar.open("Attenzione! Impossibile modificare una prenotazione di data passata", null, { duration: 4000 });
              }
            }
          );
        }
      });
    }
  }

  prenotazioneSalettaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la prenotazione?").subscribe(
        result => {
          if (result) {
            this.formToDto();
            this.prenotazioneSalettaService.delete(this.prenotazioneSaletta.id).subscribe(
              (res) => {
                this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
                this.router.navigate(["/gestione-prenotazione-saletta"])
              })
          }
        }
      )
    }
  }

  private getHoursAndMinutesFromDataDaIsoString() {
    let dataDa = new Date(this.prenotazioneSaletta.dataDa);
    return (dataDa.getHours() + ":" + dataDa.getMinutes());
  }



  private getHoursAndMinutesFromDataAIsoString() {
    let dataA = new Date(this.prenotazioneSaletta.dataA);
    return (dataA.getHours() + ":" + dataA.getMinutes());
  }





  private formToDto(): void {
    this.prenotazioneSaletta.collaboratore = this.form.get("collaboratore").value;
    this.prenotazioneSaletta.saletta = this.form.get("saletta").value;
    this.prenotazioneSaletta.saletta.sede = this.form.get("sede").value;
    this.prenotazioneSaletta.descrizione = this.form.get("descrizione").value;

    let dataDa: Date = new Date(this.form.get("dataDa").value);
    let dataDaHoursAndMinutes = this.form.get("timeDa").value.split(':');
    let hoursDa: number = +dataDaHoursAndMinutes[0];
    let minutesDa: number = +dataDaHoursAndMinutes[1];
    dataDa.setHours(hoursDa, minutesDa)
    this.prenotazioneSaletta.dataDa = dataDa;

    let dataA: Date = new Date(this.form.get("dataA").value);
    let dataAHoursAndMinutes = this.form.get("timeA").value.split(':');
    let hoursA: number = +dataAHoursAndMinutes[0];
    let minutesA: number = +dataAHoursAndMinutes[1];
    dataA.setHours(hoursA, minutesA);
    this.prenotazioneSaletta.dataA = dataA;
  }

  private dtoToForm() {
    this.form.get("id").setValue(this.prenotazioneSaletta.id);
    this.form.get("collaboratore").setValue(this.prenotazioneSaletta.collaboratore);
    this.form.get("descrizione").setValue(this.prenotazioneSaletta.descrizione);
    if (this.prenotazioneSalettaId !== null && this.prenotazioneSalettaId !== 0) {
      this.form.get("saletta").setValue(this.prenotazioneSaletta.saletta);
      this.form.get("sede").setValue(this.prenotazioneSaletta.saletta?.sede);
      this.loadSaletteAfterOnSedeChange(this.prenotazioneSaletta.saletta?.sede);
    };
    this.form.get("dataDa").setValue(this.prenotazioneSaletta.dataDa);
    this.form.get("timeDa").setValue(this.getHoursAndMinutesFromDataDaIsoString());
    this.form.get("dataA").setValue(this.prenotazioneSaletta.dataA);
    this.form.get("timeA").setValue(this.getHoursAndMinutesFromDataAIsoString());
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.prenotazioneSaletta.insertDate));
    this.form.get("insertUser").setValue(this.prenotazioneSaletta.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.prenotazioneSaletta.updateDate));
    this.form.get("updateUser").setValue(this.prenotazioneSaletta.updateUser);
    this.dataMin = this.prenotazioneSaletta.dataDa;
    this.dataMax = this.prenotazioneSaletta.dataA;
    this.form.markAsPristine();
  }


}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GenericService } from "src/app/shared/GenericService";
import { ServiziReperibilitaDTO } from "src/app/shared/dto/gestione-servizi-reperibilita/ServizioReperibilitaDTO";
import { NumeroReperibilitaDTO } from "src/app/shared/dto/reperibilità/NumeroReperibilita";
import { ResponseQueryByCriteria } from "src/app/shared/dto/responseQueryByCriteria";
import { environment } from "src/environments/environment";


@Injectable({ providedIn: 'root'})

export class ServiziRepService extends GenericService<ServiziReperibilitaDTO, number>{
   


    constructor(http: HttpClient){
        super(http);
        this.url = environment.urlPrefix + '/services/rest/json/servizio-reperibilita';

    }

   serviziList(pageNumber: any, pageSize: any, direction: any, sortField: any, descrizione: any, email,numero:any) : Observable<ResponseQueryByCriteria<ServiziReperibilitaDTO>>{
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (descrizione != null) params["descrizione"] = descrizione;
    if (email != null) params["email"] = email;
    if (numero != null) params["numero"] = numero.id;    
    console.log(direction==null)
    return this.http.get<ResponseQueryByCriteria<ServiziReperibilitaDTO>>(this.url+"/serviceList",{params:params})
  }


}
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <div style="max-width: 800px;">
      <h1>Come funziona Japp?</h1>
      <mat-accordion>
        <!-- Homepage -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Homepage</mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/homepage.png" style="height: 250px; width: 350px;" />
            </div>
            <div fxLayout="row">
              <p style="font-size: 18px;">
                Questa è la prima pagina che l’utente si trova davanti dopo aver eseguito l’accesso. <br>
                Qui sono presenti diverse funzionalità e alcune informazioni personali, come le proprie generalità e la data odierna. <br>
                Una di queste funzionalità permette di controllare l’andamento e il valore attuale delle criptovalute
                Bitcoin e Ethereum. <br>
                Nel caso in cui fossero previste delle chiusure aziendali o delle ferie approvate nei prossimi 7 giorni,
                l’utente riceverà un avviso sotto forma di notifica. <br>
                L’utente può anche controllare se sono presenti delle comunicazioni personali o aziendali. <br>
                È inoltre possibile navigare tra le pagine dell’applicazione anche dalla homepage, oltre che dal menu a
                scomparsa.
              </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Gestione Presenze -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Gestione Presenze </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/gestione-presenzeDetail.png" style="height: 230px; width: 350px;" />
            </div>
            <div fxLayout="row">
              <p style="font-size: 18px;">
                Questa funzionalità permette di consultare e gestire le proprie presenze. <br>
                È inoltre possibile registrarle (come nella funzionalità presenza) e campi da inserire sono: <br>
                - location <br>
                - data della presenza <br>
                - numero di ore (massimo 8)
              </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Gestione Assenze -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Gestione Assenze </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/registra-assenzeDetail.png" style="height: 310px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette di consultare e gestire le proprie assenze (ferie e permessi). <br>
              I campi da inserire sono: <br>
              - data a e data da, ovvero i giorni in cui l’utente è intenzionato ad effettuare la richiesta <br>
              - stato della richiesta (da approvare, approvato, rifiutato) <br>
              - tipo di richiesta (ferie, permesso) <br>
              - numero di ore, inseribile solo se il tipo di richiesta è un permesso <br>
              - nota (facoltativo) <br>
              Nella fase di inserimento lo stato della richiesta sarà “da approvare”. <br>
              È inoltre presente un campo non modificabile “approvatore” che sarà valorizzato con il nome e il cognome di
              chi ha approvato o rifiutato la richiesta dell’utente. <br>
              Un sistema basato su e-mail gestirà la comunicazione tra l'utente e l’amministrazione, quindi
              l’utente riceverà una mail nel caso in cui la sua richiesta fosse stata approvata o rifiutata mentre l'amministrazione ne riceverà
              una dopo la creazione o la cancellazione della richiesta stessa.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Chiusura Aziendale -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Chiusura Aziendale </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/chiusura-aziendale.png" style="height: 230px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette ad un utente normale di consultare la lista delle chiusure aziendali.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Richiesta d'Acquisto -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Acquisti </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/richiesta-acquistoDetail.png" style="height: 300px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              La funzionalità <b style="font-size: 18px;">richiesta d'acquisto</b> permette all’utente di richiedere un
              acquisto all’amministrazione. <br>
              I campi inseribili sono: <br>
              - stato della richiesta (da approvare, approvato, rifiutato) <br>
              - nota <br>
              - url (facoltativo) <br>
              Nella fase di inserimento lo stato della richiesta sarà “da approvare”. <br>
              È inoltre presente un campo non modificabile “approvatore” che sarà valorizzato con il nome e il cognome di
              chi ha approvato o rifiutato la richiesta dell’utente. <br>
              Nel caso in cui l’url fosse valorizzato, nella pagina di ricerca apparirà un pulsante che permetterà
              all’utente in sessione di navigare e consultare direttamente il prodotto richiesto. <br>
              Un sistema basato su e-mail gestirà la comunicazione tra l'utente e l’amministrazione, quindi
              l’utente riceverà una mail nel caso in cui la sua richiesta fosse stata approvata o rifiutata mentre l'amministrazione ne riceverà
              una dopo la creazione o la cancellazione della richiesta stessa.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Ricerca Collaboratore -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Dove sei </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/ricerca-collaboratore.png" style="height: 120px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette all’utente che è in sessione di poter controllare lo stato di un suo collega,
              ovvero se è presente (e quindi in quali location ha registrato la presenza) oppure se è assente.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Sondaggio -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Cosa si mangia oggi? </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/sondaggio-proposta1.png" style="height: 230px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette all’utente di proporre ai suoi colleghi che cosa mangiare durante l’ora di pranzo e di
              votare la propria preferenza. <br>
              C’è un unico campo di inserimento ed è la domanda. <br>
              Sono presenti due diverse sessioni, quella della creazione della proposta (con un’ora limite, ad esempio fino alle
              11:00) e quella di voto (in un intervallo fra due ore, ad esempio tra le 11:00 e le 12:00). <br>
              Un banner di notifica avviserà l'utente dello stato della funzionalità: <br>
              - <b style="font-size: 18px;">un banner verde</b> apparirà se la proposta non è ancora stata effettuata <br>
              - <b style="font-size: 18px;">un banner blu</b> apparirà dopo che la proposta è stata effettuata <br>
              - <b style="font-size: 18px;">un banner giallo</b> apparirà quando la sessione di voto sarà iniziata <br>
              - <b style="font-size: 18px;">un banner rosso</b> apparirà dopo aver votato una proposta <br>
              - <b style="font-size: 18px;">un banner grigio</b> apparirà a sessione conclusa <br>
              È inoltre possibile <b style="font-size: 18px;">creare un’unica proposta e esprimere un’unica preferenza</b>.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Gestione comande -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Ordiniamo? </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/gestionecomanda.png" style="height: 200px; width: 600px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette all’utente di creare una "comanda" (ad esempio pizza, piadina, etc...)per poter gestire il
              pagamento e l'ordinazione del pranzo. Quando i vari collaboratori creeranno il loro ordine, il gestore della comanda, visualizzerà
              ogni ordine effettuato all'interno del dettaglio della comanda stessa<br>
              Il creatore della comanda sarà l'unico a visualizzarla e a inserire se è stato pagato totalmente o parzialmente dai vari collaboratori<br>
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Gestione ordini -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Ordina </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/gestione-ordine.png" style="height: 200px; width: 600px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette all’utente di aderire ad una comanda del giorno corrente. Sarà la persona che ordina ad
              inserire l'importo dovuto al collaboratore che ha creato la comanda, e inserirà anche il prodotto che desidera(ad esempio margherita, americana, etc...)<br>
              Al momento dell'inserimento dell'ordine saranno visualizzate le comande a cui è possibile aderire vedendo la descrizione ed il collaboratore che l'ha creata<br>
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Gestione Caffè -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Caffè?! </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/caffè-principale.png" style="height: 290px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette all’utente di acquistare dei prodotti (ad esempio caffè, acqua o altre bevande) tramite
              un lettore di QR code o con inserimento manuale, ricaricare i crediti necessari per proseguire con gli acquisti e consultare tutte le
              transazioni effettuate. <br>
              Inoltre è presente un catalogo con la lista di tutti i prodotti registrati con i rispettivi costi. <br>
              <b style="font-size: 18px;">Ogni credito equivale a 0,30€</b>.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Gestione del torneo -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Torneo </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/torneo.png" style="height: 290px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzione permette di creare e far svolgere un torneo di qualsiasi gioco, una volta selezionati il numero di giocatori, che dovranno essere
              per forza un numero esponenziale del 2 (2,4,8,16 etc) il torneo verrà creato e sarà possibile iscrivervi.
              Alla fine di ogni partita uno dei due giocatori dovrà segnare il risultato assieme al vincitore. <br>
              Sarà in oltre possibile filtrare i vari tornei per stao (Concluso, In corso oppure Iscrizioni aperte) <br> 
              <br>
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Rimborso piè di lista -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Rimborsi </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/rimborsoDetail.png" style="height: 250px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette agli utenti di richiedere dei rimborsi all’amministrazione. <br>
              I campi di inserimento sono: <br>
              - data di emissione <br>
              - importo <br>
              - descrizione <br>
              Un sistema basato su e-mail gestirà la comunicazione tra l'utente e l’amministrazione, quindi
              l’utente riceverà una e-mail nel caso in cui il rimborso fosse stato effettuato mentre l'amministrazione ne riceverà
              una dopo la creazione o la cancellazione della richiesta stessa.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Prenotazione viaggi -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Prenotazione viaggi </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/gestione-prenotazioniDetail.png" style="height: 330px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette agli utenti di richiedere all’amministrazione la prenotazione di un viaggio. <br>
              I campi di inserimento sono: <br>
              - data a e data da, ovvero i giorni in cui l’utente è intenzionato ad effettuare la richiesta <br>
              - stato della richiesta (in lavorazione o pagata) <br>
              - luogo <br>
              - riferimento <br>
              - nota (facoltativo) <br>
              Nella fase di inserimento lo stato della richiesta sarà “in lavorazione”. <br>
              Un sistema basato su e-mail gestirà la comunicazione tra l'utente e l’amministrazione, quindi
              l’utente riceverà una e-mail nel caso in cui la prenotazione fosse stata pagata mentre l'amministrazione ne riceverà
              una dopo la creazione o la cancellazione della richiesta stessa.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
         <!-- Prenotazione Postazioni -->
         <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Prenotazione Postazioni </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/prenotazionepostazione.png" style="height: 330px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette agli utenti di prenotare una postazione di lavoro. <br>
              I campi di inserimento sono: <br>
              <br>
              - il collaboratore, che puo essere inserito solo dall'amministrazione; <br>
              - data da e data a, ovvero i giorni in cui l’utente è intenzionato ad effettuare la prenotazione; <br>
              - la sede, che una volta scelta permette la compilazione del campo postazione; <br>
              - la postazione, ovvero la postazione di lavoro che l'utente vuole prenotare in quella sede; <br>
              - le note, ovvero una qualunque motivazione o annotazione della prenotazione saletta; <br>
              <br>
              Le postazioni selezionabili sono esclusivamente quelle disponibili fra le date e nella sede inserite.
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Prenotazione salette -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Prenotazione saletta </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/gestione-prenotaSaletta.png" style="height: 330px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              Questa funzionalità permette agli utenti di richiedere la prenotazione di una saletta. <br>
              I campi di inserimento sono: <br>
              - il collaboratore, che puo essere inserito solo dalla l'amministrazione <br>
              - la scelta delle sede, che deve essere inserita prima di scegliere una saletta <br>
              - la scelta della saletta, ovvero la saletta che vuoi prenotare in quella sede <br>
              - la descrizione, ovvero una breve motivazione della prenotazione saletta <br>
              - data da e data a, ovvero i giorni in cui l’utente è intenzionato ad effettuare la richiesta <br>
              - ora da e ora a, ovvero le ore in cui l’utente è intenzionato ad effettuare la richiesta <br>
              Un sistema basato su e-mail gestirà la comunicazione tra l'utente e l’amministrazione, quindi
              sia l’utente che l'ammininstrazione riceveno una e-mail di notifica che confermano che la prenotazione è andata a buon fine. <br>
              Anche nel momento di una cancellazione verrà mandata una mail di notifica all'utente
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Comunicazioni -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Comunicazioni </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/comunicazioni.png" style="height: 230px; width: 350px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              La funzionalità comunicazioni permette ad un utente normale di consultare la lista di tutte le comunicazioni. <br>
            </p>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Rapportino Mensile -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Rapportino Mensile </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/rapportino-mensileDetail.png" style="height: 300px; width: 600px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              La funzionalità <b style="font-size: 18px;">Rapportino Mensile</b> permette all’utente di redigere il rapportino di fine mese. <br>
              Per poter creare un Rapportino Mensile si devono, essenzialmente,  rispettare due requisiti fondamentali: <br>
              - Il collaboratore per il quale si vuole stipulare un Rapportino Finale deve possedere almeno una presenza registrata per il mese in questione;<br>
              - Il Rapportino Finale può essere creato solamente per il mese attuale e per il mese precedente; <br> <br>
              Una volta creato il Rapportino Finale sulla pagina apparirà un accordion costituito da tutte le presenze, ferie e permessi (superiori alle 7 ore) effettuate durante il corso del mese in questione. <br>
              I campi inseribili per la creazione di una nuova riga sono: <br>
              - <i>Cliente</i> (obbligatorio inserire un cliente già esistente)<br>
              - <i>Numero di ore</i> (massimo 24 ore)<br>
              - <i>Attivita</i> (si possono inserire anche attività diverse da quelle già presenti)<br><br>

              Dopo aver inserito tutte le ore svolte si avrà la possibilità di cambiare stato, del Rapportino Mensile, in "in Approvazione" per far si che venga controllato da un'amministratore. <br>
            </p>
            </div>
          </div>
        </mat-expansion-panel>
         <!-- Formazione -->
         <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Formazione </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/formazione.png" style="height: 330px; width: 350px;" />
            </div>
            <div fxLayout="row">
              
            <p style="font-size: 18px;">
              Questa funzionalità permette agli utenti di frequentare un corso udemy. <br>
              I campi di inserimento sono: <br>
              <br>
              - il collaboratore, che puo essere inserito solo dall'amministrazione; <br>
              - il corso udemy, che permette di registrare la frequentazione al corso; <br>
              - data Inizio, ovvero il giorni in cui l’utente incomincia il corso; <br>
              - data Fine, tale campo verrà compilato quando l'utenete termina il corso; <br>
              - il numero di lezioni, che puo essere inserito solo dall'amministrazione durante la creazione del corso;<br>
              - la descrizione, che puo essere inserita solo dall'amministrazione durante la creazione del corso;<br>            
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Turni AM -->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Turni AM </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/turni-am.png" style="height: 280px; width: 600px;" />
            </div>
            <div fxLayout="row">
            <p style="font-size: 18px;">
              La funzionalità <b style="font-size: 18px;">Turni AM</b> permette agli utenti dell'AM di gestire i propri turni.<br>
              Per poter inserire un nuovo turno AM è necessario che: <br>
              - Il collaboratore sia stato inserito dall'amministratore nella lista degli utenti AM (tramite funzionalità <b style="font-size: 18px;">Collaboratori AM</b>);<br>
              - Il tipo di turno sia stato creato dall'amministratore (tramite funzionalità <b style="font-size: 18px;">Configura turni AM</b>); <br> 
              Dopo aver inserito questi campi e la data del turno, l'utente potrà aggiungere il turno <br><br>

              L'utente potrà visualizzare tutti i turni sia in modalità calendario, che in modalità lista. <br>
              Nella modalità calendario l'utente potrà visualizzare i turni sia in ordine di Collaboratore, che in ordine dei tipi di turno. <br><br>

              Sui turni, l'utente potrà: <br>
              - <i>Modificarli</i>: cliccandoli sul calendario ed andando sulla pagina di dettaglio<br>
              - <i>Scambiarli</i>: dalla pagina del dettaglio, cliccando Swap, si apre un calendario dove va selezionato il turno con il quale scambiare<br>
              - <i>Copiare i turni della settimana</i>: cliccando il tasto Copy, selezionando la settimana da copiare e quella da incollare (tramite selezione del lunedì)<br><br>
            </p>
            </div>
          </div>
        </mat-expansion-panel>
        <!--Gestione malattie-->
        <mat-expansion-panel>
          <mat-expansion-panel-header style="font-size: 25px; text-decoration: underline;"> Gestione malattie </mat-expansion-panel-header>
          <div fxLayout="column">
            <div fxLayout="row" style="margin-top: 20px;">
              <img mat-card-image src="./assets/img/img-help/gestione-malattie.png" style="height: 230px; width: 350px;" />
            </div>
            <div fxLayout="row">
              <p style="font-size: 18px;">
                Questa funzionalità permette di consultare, gestire e registrare le proprie assenze per malattia. <br>
                I campi da compilare sono: <br>
                - Data da (inzio periodo convalescienza) <br>
                - Data a (fine periodo convalescienza) <br>
                - Codice certificato medico (opzionale), <br><br>
                In automatico dunque verrà inviata una mail all'amministrazione per notificarli dell'assenza.
              </p>

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card-content>
</mat-card>


import { CollaboratoreDTO } from './../../shared/dto/domain/collaboratore';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class CompleanniService extends GenericService<CollaboratoreDTO, number> {

    constructor(http: HttpClient) {
      super(http);
      this.url = environment.urlPrefix + '/services/rest/json/collaboratore';
    }
  
    public ottieniCompleanniPersonali(): Observable<CollaboratoreDTO[]> {
      let params = {};
      let d: Date = new Date()
      d.setHours(0, 0, 0, 0);
      params["data"] = d.toISOString();
    
      return this.http.get<CollaboratoreDTO[]>(`${this.url}/collaboratori/compleanniPersonali`, {
        params: params
      });
    }
    public ottieniJeniaCompleanni(): Observable<CollaboratoreDTO[]> {
      let params = {};
      let d: Date = new Date()
      d.setHours(0, 0, 0, 0);
      params["data"] = d.toISOString();
    
      return this.http.get<CollaboratoreDTO[]>(`${this.url}/collaboratori/jeniaCompleanni`, {
        params: params
      });
    }

    public ottieniJeniaCompleanniByDate(date: Date): Observable<CollaboratoreDTO[]> {
      let params = {};
      if (date != null) params["data"] = date.toISOString();
    
      return this.http.get<CollaboratoreDTO[]>(`${this.url}/collaboratori/jeniaCompleanni`, {
        params: params
      });
    }

    public ottieniCompleanniPersonaliByDate(date: Date): Observable<CollaboratoreDTO[]> {
      let params = {};
      if (date != null) params["data"] = date.toISOString();
    
      return this.http.get<CollaboratoreDTO[]>(`${this.url}/collaboratori/compleanniPersonali`, {
        params: params
      });
    }
  
    public ottieniCompleanniPersonaliByRange(dateFrom: Date, dateTo: Date) : Observable<CollaboratoreDTO[]> {
      let params = {};
      if (dateFrom != null) params ["dataDa"] = dateFrom.toISOString();
      if (dateTo != null) params ["dataA"] = dateTo.toISOString();
      return this.http.get<CollaboratoreDTO[]>(`${this.url}/collaboratori/compleanniPersonaliByMese`, {
        params: params
      });
    }
    public ottieniJeniaCompleanniByRange(dateFrom: Date, dateTo: Date) : Observable<CollaboratoreDTO[]> {
      let params = {};
      if (dateFrom != null) params ["dataDa"] = dateFrom.toISOString();
      if (dateTo != null) params ["dataA"] = dateTo.toISOString();
      return this.http.get<CollaboratoreDTO[]>(`${this.url}/collaboratori/jeniaCompleanniByMese`, {
        params: params
      });
    }
  
  }
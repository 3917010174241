<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Colloqui</h2>
        <div fxLayout="column" class="responsive-mat-form">
          <!-- Collaboratore field -->
          <mat-form-field>
            <mat-label>Collaboratore</mat-label>
            <mat-select [(ngModel)]="parameters.collaboratore" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO">
              <mat-option [value]="null">
                  Tutti
              </mat-option>
              <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                {{collaboratore.nome}} {{collaboratore.cognome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      <!-- Responsive -->
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
        <!-- dateFrom field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dateTo"
            [(ngModel)]="parameters.dateFrom" [matDatepicker]="picker" placeholder="Scegli data da">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <button mat-button *ngIf="parameters.dateFrom" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dateFrom=null" (click)="parameters.initdateFrom=false">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- dateTo field -->
        <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
          <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dateFrom"
            [(ngModel)]="parameters.dateTo" [matDatepicker]="picker2" placeholder="Scegli data a">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <button mat-button *ngIf="parameters.dateTo" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dateTo=null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
          <!-- Filter field -->          
          <mat-form-field>
            <input matInput [(ngModel)]="parameters.filter" placeholder="Filter"/>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/colloquio/detail/0']" [disabled]="!isJappTenantAdmin()">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">


            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let colloquio"> {{colloquio.id }} </td>
            </ng-container>

            <!-- Collaboratore Column -->
            <ng-container matColumnDef="collaboratore" >
              <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Collaboratore </th>
              <td mat-cell *matCellDef="let colloquio" [hidden]="!isJappTenantAdmin()"> {{colloquio.collaboratore?.nome}} {{colloquio.collaboratore?.cognome}}</td>
            </ng-container>
                         
            <!-- dataColloquio Column -->
            <ng-container matColumnDef="dataColloquio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Colloquio </th>
              <td mat-cell *matCellDef="let colloquio"> {{colloquio.dataColloquio | date :'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Titolo Column -->
             <ng-container matColumnDef="titolo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Titolo </th>
              <td mat-cell *matCellDef="let colloquio"> {{colloquio?.titolo}} </td>
            </ng-container> 

        
            <ng-container matColumnDef="detail">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let colloquio">
                  <button mat-mini-fab color="primary" [routerLink]="['/colloquio/detail/', colloquio.id]"><mat-icon>search</mat-icon></button>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-actions>
              <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'colloqui'})">Excel</button>
              <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'colloqui'})">Csv</button>
              <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'colloqui'})">Json</button>
            </mat-card-actions>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
          </div>
        </div>
    </mat-card-content>
  </mat-card>
import { RapportinoFinaleDTO } from './rapportinoFinale';
import { ClienteDTO } from './../cliente/cliente';
import { PresenzaDTO } from './../presenze/presenza';
import { CollaboratoreDTO } from './../domain/collaboratore';
import { TenantDTO } from './../domain/tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';
import { AttivitaDTO } from './attivita';

export class RapportinoDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    presenza: PresenzaDTO;
    cliente: ClienteDTO;
    attivita: AttivitaDTO;
    rapportinoFinale: RapportinoFinaleDTO;
    numeroOre: number;   
    clienteFinale: string;
}
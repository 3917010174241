<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Stampa location</h2>
    <div fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="mat-h1">{{ location?.descrizione }}</div>
        <ngx-qrcode [qrc-value]="'location/'+locationId" [qrc-width]="256" ></ngx-qrcode>
      </div>
      <br/>
      <div id="print-section" fxHide>
        <div fxLayout="column" fxLayoutAlign="space-evenly center" >
          <div fxLayout="column" fxLayoutAlign="start center">
            <div class="mat-display-4">{{ location?.descrizione }}</div>
            <ngx-qrcode [qrc-value]="'location/'+locationId" [qrc-width]="512" ></ngx-qrcode>
          </div>
          <br/>
          <div fxLayout="column" fxLayoutAlign="start center">
            <div class="mat-display-2">{{ location?.descrizione }}</div>
            <ngx-qrcode [qrc-value]="'location/'+locationId" [qrc-width]="256" ></ngx-qrcode>
          </div>
          <br/>
          <div fxLayout="column" fxLayoutAlign="start center">
            <div class="mat-h1">{{ location?.descrizione }}</div>
            <ngx-qrcode [qrc-value]="'location/'+locationId" [qrc-width]="128" ></ngx-qrcode>
          </div>
        </div>
      </div>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="back('/location')" color="primary">Back</button>
      <button mat-raised-button printSectionId="print-section" ngxPrint
        *ngIf="location?.deleteDate==null">Print</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
import { SedeDTO } from './../shared/dto/sede/sede';
import { SedeService } from './../services/sede/sede.service';
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { SalettaDTO } from './../shared/dto/saletta/saletta';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { ComponentCacheService } from './../services/component-cache.service';
import { SalettaService } from './../services/saletta/saletta.service';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-gestione-saletta',
  templateUrl: './gestione-saletta.component.html',
  styleUrls: ['./gestione-saletta.component.scss']
})
export class GestioneSalettaComponent extends GenericListComponent implements OnInit, OnDestroy {

  sedi: SedeDTO[];


  constructor(
    private salettaService: SalettaService,
    private sedeService: SedeService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe
    );
    this.displayedColumns = ['id','sede', 'descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      sede: null,
      filter: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC', 
      'id', 
      '').subscribe((res: ResponseQueryByCriteria<SedeDTO>) => {
      this.sedi = res.content;
    })

  }
  list() {
      this.salettaService.salettaList(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        this.parameters.sortDirection,
        this.parameters.sortField,
        this.parameters.filter,
        this.parameters.sede
        ).subscribe(
          (res: ResponseQueryByCriteria<SalettaDTO>) => {
            console.log("response : " + res);
            this.parameters.dataSource = res.content;
            this.parameters.pageNumber = res.pageNumber;
            this.parameters.length = res.totalElements;
            this.parameters.showList = true;
          }
        )
  }

  

  

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  


}

import { TenantDTO } from '../domain/tenant';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';


export class RisorsaAziendaleDTO extends LogicalDeleteDTO {
    
    id: number;
    
    tenant: TenantDTO;
    
    gruppo: string;

    url: string;

    icona: string;

    fontset: string;

    descrizione: string;
     
}
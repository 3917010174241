import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { ComunicazioneDTO } from '../../shared/dto/comunicazioni/comunicazione';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';


@Injectable({
  providedIn: 'root'
})

export class ComunicazioneService extends GenericService<ComunicazioneDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/comunicazione';
  }

  public comunicazioneList(pageNumber: number, pageSize: number, direction: string, sortField: string, filter?: string, collaboratore?: CollaboratoreDTO): Observable<ResponseQueryByCriteria<ComunicazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (filter != null) params["filter"] = filter;
    if (collaboratore != null) params["collaboratoreId"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<ComunicazioneDTO>>(`${this.url}/comunicazioneList`, {
      params: params
    });
  }

  public listByComunicazionePersonale(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO): Observable<ResponseQueryByCriteria<ComunicazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<ComunicazioneDTO>>(`${this.url}/listByComunicazionePersonale`, {
      params: params
    });
  }

  public listByComunicazionePersonaleNascosta(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO): Observable<ResponseQueryByCriteria<ComunicazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<ComunicazioneDTO>>(`${this.url}/listByComunicazionePersonaleNascosta`, {
      params: params
    });
  }

  public listByComunicazioneAziendale(pageNumber: number, pageSize: number, direction: string, sortField: string): Observable<ResponseQueryByCriteria<ComunicazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    return this.http.get<ResponseQueryByCriteria<ComunicazioneDTO>>(`${this.url}/listByComunicazioneAziendale`, {
      params: params
    });
  }

  public listByComunicazioneAziendaleNascosta(pageNumber: number, pageSize: number, direction: string, sortField: string, collaboratore: CollaboratoreDTO): Observable<ResponseQueryByCriteria<ComunicazioneDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (collaboratore != null) params["idCollaboratore"] = collaboratore.id;
    return this.http.get<ResponseQueryByCriteria<ComunicazioneDTO>>(`${this.url}/listByComunicazioneAziendaleNascosta`, {
      params: params
    });
  }

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssenzaDTO } from 'src/app/shared/dto/assenza/assenza';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { CategoriaEventoDTO } from 'src/app/shared/dto/evento/categoria-evento';
import { EventoDTO } from 'src/app/shared/dto/evento/evento';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventoService extends GenericService<EventoDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/evento';
  }

  public eventoListByList(pageNumber: number, pageSize: number, direction: string, sortField: string, organizzatore?: CollaboratoreDTO,
      categoria?: CategoriaEventoDTO, dataDa?: Date, dataA?: Date, minPartecipanti?: number, maxPartecipanti?: number,fineIscrizioneCompare?: Date
      ): Observable<ResponseQueryByCriteria<EventoDTO>> {
    let params = {};
    params["pageNumber"] = String(pageNumber);
    params["pageSize"] = String(pageSize);
    params["direction"] = direction;
    params["sortField"] = sortField;

    if(organizzatore != null) params["organizzatore"] = String(organizzatore.id);
    if(categoria != null) params["categoria"] = String(categoria.id);
    if(dataDa != null) params["dataDa"] = dataDa.toISOString();
    if(dataA != null) params["dataA"] = dataA.toISOString();
    if(minPartecipanti != null) params["minPartecipanti"] = String(minPartecipanti);
    if(maxPartecipanti != null) params["maxPartecipanti"] = String(maxPartecipanti);
    if(fineIscrizioneCompare != null) params["fineIscrizioneCompare"] = fineIscrizioneCompare.toISOString();

    return this.http.get<ResponseQueryByCriteria<EventoDTO>>(`${this.url}/eventoListByList`, {
      params: params
    });
  }

  public eventoUpcoming(compareDate?: Date): Observable<EventoDTO[]> {
    let params = {};
    if(compareDate != null) params["compareDate"] = compareDate.toISOString();

    return this.http.get<EventoDTO[]>(`${this.url}/eventoUpcoming`, {
      params: params
    });
  }

}

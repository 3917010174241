import { TipoTurnoAMDTO } from './tipo-turno-am';
import { CollaboratoreAMDTO } from './collaboratore-am';
import { TenantDTO } from './../domain/tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';

export class TurnoAMDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratoreAM: CollaboratoreAMDTO;
    tipoTurno: TipoTurnoAMDTO;
    dataTurno: Date;
}
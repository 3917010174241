import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SedeDTO } from './../../shared/dto/sede/sede';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SedeService extends GenericService<SedeDTO, number>{

  url: String

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/sede';
   }

  public sedeListByDescrizione (pageNumber: number, pageSize: number, direction: string, sortField: string, filter: string): Observable<ResponseQueryByCriteria<SedeDTO>> {
      let params = {};
      if (pageNumber != null) params["pageNumber"] = String(pageNumber);
      if (pageSize != null) params["pageSize"] = String(pageSize);
      if (direction != null) params["direction"] = direction;
      if (sortField != null) params["sortField"] = sortField;
      if (filter != null) params["filter"] = filter;
      return this.http.get<ResponseQueryByCriteria<SedeDTO>>(`${this.url}/sedeList`, { params: params })
  }
}

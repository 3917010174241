import { FormControl, Validators } from '@angular/forms';
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { ComponentCacheService } from './../services/component-cache.service';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { RapportinoFinaleService } from './../services/rapportino/rapportino-finale.service';
import { RapportinoFinaleDTO } from './../shared/dto/rapportino/rapportinoFinale';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rapportino-finale',
  templateUrl: './rapportino-finale.component.html',
  styleUrls: ['./rapportino-finale.component.scss']
})
export class RapportinoFinaleComponent extends GenericListComponent implements OnInit {
  
  statoRapportinoFinale: selectedValue[]
  collaboratori: CollaboratoreDTO[];
  rapportinoFinale: RapportinoFinaleDTO;
  locale: string = "it";

  annoFormControl = new FormControl('', [
    Validators.required,
  ]);

  mesi = [
    {
      id: 1,
      name:"Gennaio"
    },
    {
      id: 2,
      name:"Febbraio"
    },
    {
      id: 3,
      name:"Marzo"
    },
    {
      id: 4,
      name:"Aprile"
    },
    {
      id: 5,
      name:"Maggio"
    },
    {
      id: 6,
      name:"Giugno"
    },
    {
      id: 7,
      name:"Luglio"
    },
    {
      id: 8,
      name:"Agosto"
    },
    {
      id: 9,
      name:"Settembre"
    },
    {
      id: 10,
      name:"Ottobre"
    },
    {
      id: 11,
      name:"Novembre"
    },
    {
      id: 12,
      name:"Dicembre"
    }
  ];

  defaultSortField = 'data';
  defaultSortDirection = 'desc';

  constructor(
    private rapportinoFinaleService: RapportinoFinaleService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'collaboratore', 'data', 'statoRapportinoFinale', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      mese: this.mesi[new Date().getMonth()],
      anno: new Date().getFullYear(),
      stato: null,
      sortField: this.defaultSortField,
      sortDirection: this.defaultSortDirection,
      pageNumber: 0,
      pageSize: 5,
      length: 0
    }
    this.statoRapportinoFinale = [
        { value: null, viewValue: '---Seleziona---' },
        { value: 'WORKER', viewValue: 'IN LAVORAZIONE' },
        { value: 'DONE', viewValue: 'IN APPROVAZIONE' },
        { value: 'CLOSED', viewValue: 'CHIUSO' }
      ];
  }

  annoNonValido() : boolean{
    if(!this.annoFormControl.valid){
      return true
    }else return false
  }

  ngOnInit() {
    super.ngOnInit();
    if(this.parameters.collaboratore == null){
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.list();
  }

  annoEmpty(anno: HTMLInputElement){
    if(anno.value = ""){
      return true;
    }else return false;
  }

  list() {
      this.rapportinoFinaleService.rapportinoFinaleList(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
        (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
        this.parameters.collaboratore,
        this.parameters.mese.id,
        this.parameters.anno,
        this.parameters.stato
        ).subscribe(
          (res: ResponseQueryByCriteria<RapportinoFinaleDTO>) => {
            console.log("response : " + res);
            console.log(res.content[0])
            this.parameters.dataSource = res.content;
            this.parameters.pageNumber = res.pageNumber;
            this.parameters.length = res.totalElements;
            this.parameters.showList = true;
          }
        )
  }

  estraiStato(rapportinoFinale: RapportinoFinaleDTO){
    let msg: string;
    if(rapportinoFinale.statoRapportinoFinale == "WORKER"){
      msg = "IN LAVORAZIONE";
    }else if(rapportinoFinale.statoRapportinoFinale == "DONE"){
      msg = "IN APPROVAZIONE";
    }else{
      msg = "CHIUSO"
    }
    return msg; 
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

export interface selectedValue {
  value: string;
  viewValue: string;
}

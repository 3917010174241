import { CollaboratoreDTO } from '../domain/collaboratore';
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { Stampante3DDTO } from '../stampante3D/stampante3D';

export class PrenotazioneStampante3DDTO extends LogicalDeleteDTO {
    id: number;
    idMaster: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    stampante3D: Stampante3DDTO;
    data: Date;
    orarioInizioStampa: number;
    orarioFineStampa: number;
    ore: number;
    info: string;
    url: string;
}
import { ResponseFail } from './../shared/dto/responseFail';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { NavigatorService } from './../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrenotazioneDTO } from '../shared/dto/prenotazione/prenotazione';
import { PrenotazioneService } from '../services/prenotazione/prenotazione.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-gestionePrenotazioneDetail',
  templateUrl: './gestionePrenotazioneDetail.component.html',
  styleUrls: ['./gestionePrenotazioneDetail.component.scss']
})
export class GestionePrenotazioneDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  statoValues: selectedValue[];
  prenotazioneId: number;
  prenotazione: PrenotazioneDTO;
  collaboratori: CollaboratoreDTO[];
  dataMax: Date;
  dataMin: Date;
  bloccaModifica: boolean;
  today = new Date();

  constructor(
    private prenotazioneService: PrenotazioneService,
    private collaboratoreService: CollaboratoreService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      dataDa: new FormControl('', Validators.required),
      dataA: new FormControl('', Validators.required),
      richiedente: new FormControl('', Validators.required),
      luogo: new FormControl('', Validators.required),
      riferimento: new FormControl('', Validators.required),
      stato: new FormControl('', Validators.required),
      nota: new FormControl(''),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });
    this.statoValues = [
      { value: true, viewValue: 'Pagata' },
      { value: false, viewValue: 'In lavorazione' },
    ];
  }

  ngOnInit() {
    this.prenotazioneId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.prenotazioneId != 0 && this.prenotazioneId != null) {
      this.prenotazioneRead();
    } else {
      this.prenotazione = new PrenotazioneDTO();
    }
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("stato").setValue(false);
        this.form.get("richiedente").setValue(res);
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }

  setMinDate() {
    this.dataMin = this.form.get("dataDa").value;
  }

  setMaxDate() {
    this.dataMax = this.form.get("dataA").value;
  }

  prenotazioneSave() {
    if (this.prenotazione.id == null) {
      this.prenotazioneCreate();
    } else {
      this.prenotazioneUpdate();
    }
  }

  prenotazioneCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.prenotazioneService.create(this.prenotazione).subscribe(
        (res: PrenotazioneDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.prenotazione = res;
          this.prenotazioneId = this.prenotazione.id;
          this.dtoToForm();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneException_PRENOTAZIONE_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già una prenotazione nel periodo di date indicato.", null, { duration: 3000 });
          } else {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
  }

  prenotazioneUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la prenotazione?").subscribe(result => {
        if (result) {
          if (this.isJappTenantAdmin() == true) {
            if (this.form.get("stato").value == true || this.form.get("stato").value == "Pagata") {
              this.navigatorService.collaboratore.subscribe(
                (res: CollaboratoreDTO) => {
                  this.form.get("approvatore").setValue(res);
                }
              );
            }
          }
          this.formToDto();
          this.prenotazioneService.update(this.prenotazione).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.prenotazioneRead();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidPrenotazioneException_PRENOTAZIONE_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già una prenotazione nel periodo di date indicato.", null, { duration: 3000 });
              }
              else {
                this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  prenotazioneDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la prenotazione?").subscribe(result => {
        if (result) {
          this.formToDto();
          if (this.prenotazione.stato == true) {
            this.snackBar.open("Attenzione! Non puoi cancellare una prenotazione già approvata.", null, { duration: 3000 });
            return;
          }
          this.prenotazioneService.delete(this.prenotazione.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/gestione-prenotazione"]);
            }
          );
        }
      });
    }
  }


  prenotazioneRead() {
    this.prenotazioneService.read(this.prenotazioneId).subscribe(
      (res: PrenotazioneDTO) => {
        console.log("response : " + res);
        this.prenotazione = res;
        this.prenotazioneId = res.id;
        this.dtoToForm();
        if (this.form.get("stato").value == true || this.form.get("stato").value == "Pagato") {
          if (this.isJappTenantAdmin() == false) {
            this.bloccaModifica = true;
            this.form.get("luogo").disable();
            this.form.get("riferimento").disable();
          }
        }
      }
    );
  }

  private formToDto(): void {
    this.prenotazione.richiedente = this.form.get("richiedente").value;
    this.prenotazione.dataDa = this.form.get("dataDa").value;
    this.prenotazione.dataA = this.form.get("dataA").value;
    this.prenotazione.luogo = this.form.get("luogo").value;
    this.prenotazione.riferimento = this.form.get("riferimento").value;
    this.prenotazione.stato = this.form.get("stato").value;
    this.prenotazione.note = this.form.get("nota").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.prenotazione.id);
    this.form.get("richiedente").setValue(this.prenotazione.richiedente);
    this.form.get("dataDa").setValue(this.prenotazione.dataDa);
    this.form.get("dataA").setValue(this.prenotazione.dataA);
    this.form.get("luogo").setValue(this.prenotazione.luogo);
    this.form.get("stato").setValue(this.prenotazione.stato);
    this.form.get("riferimento").setValue(this.prenotazione.riferimento);
    this.form.get("nota").setValue(this.prenotazione.note);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.prenotazione.insertDate));
    this.form.get("insertUser").setValue(this.prenotazione.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.prenotazione.updateDate));
    this.form.get("updateUser").setValue(this.prenotazione.updateUser);
    this.dataMin = this.prenotazione.dataDa;
    this.dataMax = this.prenotazione.dataA;
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

export interface selectedValue {
  value: boolean;
  viewValue: string;
}
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreDTO } from './../shared/dto/domain/collaboratore';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssenzaDTO } from '../shared/dto/assenza/assenza';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssenzaService } from '../services/assenza/assenza.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';

@Component({
  selector: 'app-registra-assenza',
  templateUrl: './registra-assenza.component.html',
  styleUrls: ['./registra-assenza.component.scss']
})

export class RegistraAssenzaComponent extends GenericListComponent implements OnInit, OnDestroy {

  statoRichiestaValues: selectedValue[]
  tipoRichiestaValues: selectedValue[]
  collaboratori: CollaboratoreDTO[];
  collaboratore: CollaboratoreDTO;

  constructor(
    private assenzaService: AssenzaService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'dataDa', 'dataA', 'statoRichiesta', 'richiedente', 'approvatore', 'tipoRichiesta', 'orarioInizioPermesso','ore', 'descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      tipoRichiesta: null,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      stato: null,
      richiedente: null,
      collaboratoreChanged: false,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.statoRichiestaValues = [
      { value: null, viewValue: '---Seleziona---' },
      { value: 'APPROVATO', viewValue: 'APPROVATO' },
      { value: 'DA_APPROVARE', viewValue: 'DA APPROVARE' },
      { value: 'RIFIUTATO', viewValue: 'RIFIUTATO' }
    ];
    this.tipoRichiestaValues = [
      { value: null, viewValue: '---Seleziona---' },
      { value: 'FERIE', viewValue: 'FERIE' },
      { value: 'PERMESSO', viewValue: 'PERMESSO' }
    ];
  }

  trascodificaStatoRichiesta(stato: String): String {
    var msg: String;
    if (stato == 'DA_APPROVARE') {
      msg = 'DA APPROVARE';
    } else if (stato == 'APPROVATO') {
      msg = 'APPROVATO';
    } else if (stato == 'RIFIUTATO') {
      msg = 'RIFIUTATO';
    }
    return msg;
  }

  list() {
    console.log(this.parameters.sortField);
    this.assenzaService.assenzaListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.richiedente,
      this.parameters.stato,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.tipoRichiesta).subscribe(
        (res: ResponseQueryByCriteria<AssenzaDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.collaboratore = res;
          this.parameters.richiedente = this.collaboratore;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

export interface selectedValue {
  value: string;
  viewValue: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatiAnagraficiSDIDTO } from 'src/app/shared/dto/fattura-elettronica/datiAnagraficiSDI';
import { QrCodeSDIDTO } from 'src/app/shared/dto/fattura-elettronica/qrCodeSDI';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatiAnagraficiSDIService {

  protected url: String;

  constructor(private http: HttpClient) {
    this.url = environment.urlPrefix + '/services/rest/json/datiAnagraficiSDI';
  }

  public create(dto: DatiAnagraficiSDIDTO): Observable<DatiAnagraficiSDIDTO> {
      return this.http.post<DatiAnagraficiSDIDTO>(`${this.url}`, dto);
  }

  public read(): Observable<DatiAnagraficiSDIDTO> {
      return this.http.get<DatiAnagraficiSDIDTO>(`${this.url}`);
  }

  public update(dto: DatiAnagraficiSDIDTO): Observable<void> {
      return this.http.put<void>(`${this.url}`, dto)
  }

  public delete(): Observable<void> {
      return this.http.delete<void>(`${this.url}`);
  }

  public qrCodeSDIRead(): Observable<QrCodeSDIDTO> {
    return this.http.get<QrCodeSDIDTO>(`${this.url}/qrCodeSDI`);
  }


}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Carica crediti</h2>
    <p>Carica crediti tramite i bottoni veloci o il campo </p>
    <div fxLayout="row">
        <mat-card-actions fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <button mat-raised-button fxFlex (click)="creditoSave(1)">1 credito = 0.30€</button>
            <button mat-raised-button fxFlex (click)="creditoSave(10)">10 crediti = 3.00€</button>
        </mat-card-actions>
    </div>
    <div fxLayout="row">
        <mat-card-actions fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <button mat-raised-button fxFlex (click)="creditoSave(20)">20 crediti = 6.00€</button>
            <button mat-raised-button fxFlex (click)="creditoSave(30)">30 crediti = 9.00€</button>
        </mat-card-actions>
    </div>
    <div fxLayout="row">
        <mat-card-actions fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <button mat-raised-button fxFlex (click)="creditoSave(40)">40 crediti = 12.00€</button>
            <button mat-raised-button fxFlex (click)="creditoSave(50)">50 crediti = 15.00€</button>
        </mat-card-actions>

    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
    <mat-form-field fxFlex="85" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
    [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
    [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
        <input matInput #euro type="text" OnlyNumber="true" (keyup.enter)="conversioneEuro(euro.value)" placeholder="Ricarica in euro">
        <button mat-raised-button matSuffix (click)="conversioneEuro(euro.value)">
            Acquista
        </button>
    </mat-form-field>
    </div>
    <mat-card-actions>
        <button mat-raised-button id="back-button" [routerLink]="['/gestione-credito']">Back</button>
    </mat-card-actions>




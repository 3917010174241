import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { NumeroService } from '../services/reperibilità/numero.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { NumeroReperibilitaDTO } from '../shared/dto/reperibilità/NumeroReperibilita';

@Injectable({
    providedIn: 'root'
})

@Component({
  selector: 'app-numeri',
  templateUrl: './numeri.component.html',
  styleUrls: ['./numeri.component.scss']
})

export class NumeriComponent extends GenericListComponent implements OnInit, OnDestroy {

  constructor(
    private numeroService: NumeroService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'descrizione', 'mainNumber', 'mainNumberId', 'detail',];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    } 
  }

  list() {
    this.numeroService.numeriList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.filter).subscribe(
        (res: ResponseQueryByCriteria<NumeroReperibilitaDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

import { CollaboratoreDTO } from '../../shared/dto/domain/collaboratore';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryByCriteria } from '../../shared/dto/responseQueryByCriteria';
import { AcquistoDTO } from 'src/app/shared/dto/acquisto/acquisto';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/shared/GenericService';

@Injectable({
  providedIn: 'root'
})
export class AcquistoService extends GenericService<AcquistoDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/acquisto';
  }

  public acquistoListByList(pageNumber: number, pageSize: number, direction: string, sortField: string, richiedente?: CollaboratoreDTO, stato?: string): Observable<ResponseQueryByCriteria<AcquistoDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (richiedente != null) params["richiedente"] = richiedente.id;
    if (stato != null) params["stato"] = stato;
    return this.http.get<ResponseQueryByCriteria<AcquistoDTO>>(`${this.url}/acquistoListByList`, {
      params: params
    });
  }

}

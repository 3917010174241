<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione tipi turni AM</h2>

        <div fxLayout="column" class="responsive-mat-form">
            <!-- Collaboratore field -->
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.nome" placeholder="Nome" />
            </mat-form-field>
        </div>

        <!--Bottoni-->
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
            <button mat-raised-button id="new-button" [routerLink]="['/tipo-turno-am/detail/0']">New</button>
        </mat-card-actions>

        <!--search-->
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Tipo </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let tipo"> {{tipo.id}} </td>
                </ng-container>

                <!-- Nome Column  -->
                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
                    <td mat-cell *matCellDef="let tipo"> {{tipo.nome}} </td>
                </ng-container>

                <!-- Orario Column  -->
                <ng-container matColumnDef="orario">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Orario </th>
                    <td mat-cell *matCellDef="let tipo"> {{tipo.orario}}</td>
                </ng-container>

                <!-- Orario Column  -->
                <ng-container matColumnDef="ordine">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Ordine </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let tipo"> {{tipo.ordine}}</td>
                </ng-container>

                <!-- Colore Column  -->
                <ng-container matColumnDef="colore">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Colore </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let tipo">  
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="fas fa-circle icon-font-awesome" [ngStyle]="{'color': tipo.colore}" style="padding-right: 3px;"></mat-icon>
                              {{tipo.colore}}
                        </div>
                    </td>
                </ng-container>

                <!-- Descrizione Column  -->
                <ng-container matColumnDef="descrizione">
                    <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
                    <td fxHide fxShow.gt-md mat-cell *matCellDef="let tipo"> {{tipo.descrizione}}</td>
                </ng-container>


                <!-- Info Column -->
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let tipo">
                        <button mat-mini-fab color="primary"
                            [routerLink]="['/tipo-turno-am/detail/' , tipo.id]">
                            <mat-icon>search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>



            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-actions>
                    <button mat-raised-button
                        (click)="exporter.exportTable('xlsx', {fileName:'tipi_turni_am'})">Excel</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('csv', {fileName:'tipi_turni_am'})">Csv</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('json', {fileName:'tipi_turni_am'})">Json</button>
                </mat-card-actions>
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>


        </div>

    </mat-card-content>
</mat-card>
import { LogicalDeleteDTO } from "../basic/logicalDeleteDTO";
import { TenantDTO } from '../domain/tenant';

export class CorsiUdemyDTO extends LogicalDeleteDTO{
    id: number;
    tenant: TenantDTO;
    titolo: String;
    link: String;
    ore: number;
    minuti: number;
    descrizione: String;
    nlezioni: number;
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Filtra movimenti</h2>
        <p>Imposta i filtri di ricerca</p>
        <!-- Responsive -->
        <div fxLayout="column" class="responsive-mat-form">
          <!-- Tipo Movimento field -->
          <mat-form-field>
            <mat-label>Tipo Movimento</mat-label>
            <mat-select [(value)]="parameters.tipo">
              <mat-option [value]="null">
                Tutti
              </mat-option>
              <mat-option *ngFor="let tipologia of tipologie" [value]="tipologia">
                {{tipologia}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput  readonly="true" (click)="picker.open()" [(max)]="parameters.dataA" [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
            <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear" (click)="parameters.dataDa=null">
                <mat-icon>close</mat-icon>
              </button>
          </mat-form-field>

          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa" [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear" (click)="parameters.dataA=null">
                <mat-icon>close</mat-icon>
              </button>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="back-button" [routerLink]="['/gestione-credito']">Back</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id transazione </th>
              <td fxHide fxShow.gt-md mat-cell *matCellDef="let movimento"> {{movimento.id}} </td>
            </ng-container>

            <!-- data Column -->
            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Data</th>
              <td mat-cell *matCellDef="let movimento"> {{movimento.data | date :'dd/MM/yyyy HH:MM:ss'}} </td>
            </ng-container>

            <!-- totaleCrediti Column -->
            <ng-container matColumnDef="totaleCrediti">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Tot </th>
              <td mat-cell *matCellDef="let movimento" >
                <div fxLayoutAlign="space-around center" fxLayout="column">
                  <div fxFlex>
                    {{movimento.totaleCrediti}}
                  </div>
                  <div fxFlex>
                    €{{(movimento.totaleCrediti*0.3)|number:'1.2-2'}}
                  </div>
                </div> 
              </td>
            </ng-container>

            <!-- catalogo Column -->
            <ng-container matColumnDef="catalogo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Oggetto acquistato </th>
              <td mat-cell *matCellDef="let movimento"> {{trascodificaOggetto(movimento.catalogo?.descrizione)}}</td>
            </ng-container>

            <!-- qtaProdotti Column -->
            <ng-container matColumnDef="qtaProdotti">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Qta </th>
              <td mat-cell *matCellDef="let movimento"> {{trascodificaQTA(movimento?.qtaProdotti)}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-actions>
              <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'transazioni'})">Excel</button>
              <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'transazioni'})">Csv</button>
              <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'transazioni'})">Json</button>
            </mat-card-actions>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" 
              [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
            </mat-paginator>
          </div>
        </div>
    </mat-card-content>
  </mat-card>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericService } from 'src/app/shared/GenericService';
import { ComunicazioneNascostaDTO } from 'src/app/shared/dto/comunicazioni/comunicazioneNascosta';


@Injectable({
  providedIn: 'root'
})

export class NascondiComunicazioneService extends GenericService<ComunicazioneNascostaDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/nascosta';
  }
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli chiusura</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Chiusura Id" readonly="true" />
        </mat-form-field>
        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- DataDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle] = "{'padding-right': 2 + '%'}"
          [ngStyle.xs] = "{'padding-right.px': 0}"
          [ngStyle.sm] = "{'padding-right.px': 0}"
          [ngStyle.md] = "{'padding-right.px': 0}">
            <input matInput readonly="true" (click)="picker.open()" [max]="dataMax" (dateChange)="setMinDate()"
              [matDatepicker]="picker" formControlName="dataDa" placeholder="Scegli data da">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="form.get('dataDa').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="form.get('dataDa').setValue(null)" (click)="dataMin=null">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataDa').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()" [min]="dataMin" (dateChange)="setMaxDate()"
              [matDatepicker]="picker2" formControlName="dataA" placeholder="Scegli data a">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="form.get('dataA').errors?.required">
              Campo obbligatorio
            </mat-error>
            <button mat-button *ngIf="form.get('dataA').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="form.get('dataA').setValue(null)" (click)="dataMax=null">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- Descrizione field -->
        <mat-form-field>
          <textarea cdkTextareaAutosize matInput formControlName="descrizione" placeholder="Nota"></textarea>
          <mat-error *ngIf="form.get('descrizione').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>
        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
      <button mat-raised-button (click)="chiusuraSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="chiusura?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="chiusuraDelete()" [disabled]="chiusura?.id==null"
        *ngIf="chiusura?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
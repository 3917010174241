import { ComandaDTO } from 'src/app/shared/dto/gestioneOrdini/comanda';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { ComandaService } from '../services/gestione-ordini/comanda.service';
import { OrdineService } from '../services/gestione-ordini/ordine.service';

@Component({
  selector: 'app-gestione-comanda',
  templateUrl: './gestione-comanda.component.html',
  styleUrls: ['./gestione-comanda.component.scss']
})
export class GestioneComandaComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  dataDa: Date;
  dataA: Date;

  defaultSortField = 'dataComanda';
  defaultSortDirection = 'asc';

  constructor(
    private comandaService: ComandaService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    private ordineService: OrdineService,

  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'dataComanda', 'collaboratore', 'descrizione', 'link-menu', 'importoTotale', 'persone', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      collaboratoreChanged: false,
      initDataDa: true,
      dataDa: null,
      dataA: null,
      sortField: 'dataComanda',
      sortDirection: 'DESC',
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );


    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  list() {
    this.comandaService.comandaListByCollaboratoreAndDates(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.dataDa,
      this.parameters.dataA).subscribe(
        (res: ResponseQueryByCriteria<ComandaDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
          for (let i = 0; i < res.content.length; i++) {
            this.totImporto(res.content[i]);
          }
          for (let i = 0; i < res.content.length; i++) {
            this.totPersone(res.content[i]);
          }
        }
      )
  }
  totImporto(comanda: ComandaDTO) {
    this.ordineService.totImporto(
      comanda).subscribe(
        (res: number) => {
          console.log("response : " + res);
          comanda.importoTotale = res;
        }
      )
  }
  hideColumns(): boolean {
    let hide: boolean = false;
    this.parameters.importoTotale > 0 ? hide = true : hide = false;
    return hide;

  }
  totPersone(comanda: ComandaDTO) {
    this.ordineService.totPersone(
      comanda).subscribe(
        (res: number) => {
          console.log("response : " + res);
          comanda.persone = res;
        }
      )
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

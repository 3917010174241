import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';
import { VotoDTO } from './voto';

export class PropostaDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    creatore: CollaboratoreDTO;
    domanda: string;
    giornoCorrente: Date;
    voteList: VotoDTO[];

}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettagli Turno</h2>
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Turno ID" readonly="true" />
          </mat-form-field>
  
          <!-- livello field -->
          <mat-form-field>
            <input matInput formControlName="livello" placeholder="livello turno" readonly="true" />
          </mat-form-field>
  
          <!-- collaboratoreGiocatoreUno field -->
          <mat-form-field fxFlex="55" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput formControlName="collaboratoreGiocatoreUno"  
            placeholder="Giocatore Uno" readonly=true/>
          </mat-form-field>
  
          <!-- collaboratoreGiocatoreDue field -->
          <mat-form-field fxFlex="55" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput formControlName="collaboratoreGiocatoreDue" 
            placeholder="Giocatore Due" readonly=true/>
          </mat-form-field>

          <!-- Riga Responsive -->
          <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- collaboratoreVincitore field -->
          <mat-form-field fxFlex="55" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <mat-select name="collaboratoreVincitore" formControlName="collaboratoreVincitore"
            placeholder="Vincitore" [compareWith]="compareDTO">
            <mat-option *ngFor="let collaboratore of collaboratoriGiocatori" [value]="collaboratore">
              {{collaboratore?.nome}} {{collaboratore?.cognome}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('collaboratoreVincitore').errors?.required">
            Campo obbligatorio
          </mat-error>
          </mat-form-field>

          <!-- risultato field -->
          <mat-form-field fxFlex="55" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput formControlName="risultato" placeholder="Risultato" />
            <mat-error *ngIf="form.get('risultato').errors?.required">
                Campo obbligatorio
              </mat-error>
          </mat-form-field>
        </div>

          <!-- Dettagli Operaione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>
  
              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>
  
              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
        <button mat-raised-button (click)="turnoSave()" *ngIf="turno?.deleteDate==null" 
        [disabled]="!form.dirty || form.invalid || (turno.collaboratoreGiocatoreUno == null || turno.collaboratoreGiocatoreDue == null)">Save</button>
        </mat-card-actions>
    </mat-card-content>
  </mat-card>
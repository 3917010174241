import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { MalattiaService } from '../services/malattia/malattia.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { MalattiaDTO } from '../shared/dto/malattia/malattia';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { DateAdapter } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { NavigatorService } from '../services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-registra-malattia-detail',
  templateUrl: './registra-malattia-detail.component.html',
  styleUrls: ['./registra-malattia-detail.component.scss']
})
export class RegistraMalattiaDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  malattiaId: number;
  malattia: MalattiaDTO;
  collaboratori: CollaboratoreDTO[];
  dataMax: Date;
  dataMin: Date;
  bloccaModifica: boolean;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string

  constructor(
    private urlService: UrlService,
    private malattiaService: MalattiaService,
    private collaboratoreService: CollaboratoreService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      dataDa: new FormControl('', Validators.required),
      dataA: new FormControl('', Validators.required),
      collaboratore: new FormControl('', Validators.required),
      codice: new FormControl(''),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });

  }

  ngOnDestroy(): void {
   
  }
  ngOnInit() {
    this.malattiaId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.malattiaId != 0 && this.malattiaId != null) {
      this.malattiaRead();
    } else {
      this.malattia = new MalattiaDTO();
    }
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  private formToDto(): void {
    this.malattia.dataDa = this.form.get("dataDa").value;
    this.malattia.dataA = this.form.get("dataA").value;
    this.malattia.collaboratore = this.form.get("collaboratore").value;
    this.malattia.codice = this.form.get("codice").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.malattia.id);
    this.form.get("dataDa").setValue(this.malattia.dataDa);
    this.form.get("dataA").setValue(this.malattia.dataA);
    this.form.get("collaboratore").setValue(this.malattia.collaboratore);
    this.form.get("codice").setValue(this.malattia.codice);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.malattia.insertDate));
    this.form.get("insertUser").setValue(this.malattia.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.malattia.updateDate));
    this.form.get("updateUser").setValue(this.malattia.updateUser);
    this.dataMin = new Date(this.malattia.dataDa);
    this.dataMax = new Date(this.malattia.dataA);
    this.form.markAsPristine();
  }
  setMinDate() {
    this.dataMin = this.form.get("dataDa").value;
    if (this.dataMax == null) {
      this.dataMax = this.dataMin;
      this.form.get("dataA").setValue(this.dataMax);
    }
  }

  setMaxDate() {
    this.dataMax = this.form.get("dataA").value;
    if (this.dataMin == null) {
      this.dataMin = this.dataMax;
      this.form.get("dataDa").setValue(this.dataMin);
    }
  }
  malattiaSave() {
    if (this.malattia.id == null) {
      this.malattiaCreate();
    } else {
      this.malattiaUpdate();
    }
  }

  malattiaCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.malattiaService.create(this.malattia).subscribe(
        (res: MalattiaDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.malattia = res;
          this.malattiaId = this.malattia.id;
          this.dtoToForm();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidMalattiaException_MALATTIA_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già una malattia nel periodo di date indicato.", null, { duration: 3000 });
          } else {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
  }

  malattiaUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la malattia ?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.malattiaService.update(this.malattia).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.malattiaRead();
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidMalattiaException_MALATTIA_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già una malattia nel periodo di date indicato.", null, { duration: 3000 });
              } else {
                this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  malattiaDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la malattia?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.malattiaService.delete(this.malattia.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/registra-malattia"]);
            }
          );
        }
      });
    }
  }


  malattiaRead() {
    this.malattiaService.read(this.malattiaId).subscribe(
      (res: MalattiaDTO) => {
        console.log("response : " + res);
        this.malattia = res;
        this.malattiaId = res.id;
        this.dtoToForm();
      }
    );
  }

}

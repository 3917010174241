<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Lista Attesa Prenotazione postazioni</h2>
      <p>Imposta i filtri di ricerca</p>
      <div fxLayout="column" class="responsive-mat-form">
  
        <!-- Richiedente field -->
        <mat-form-field>
          <mat-select name="richiedente" [(ngModel)]="parameters.richiedente" [compareWith]='compareDTO'
            [disabled]="!isJappTenantAdmin()" placeholder="Scegli il richiedente">
            <mat-option [value]="null">
              Tutti
            </mat-option>
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <!-- Sede field -->
        <mat-form-field>
          <mat-select name="sede" [(ngModel)]="parameters.sede" [compareWith]='compareDTO' placeholder="Scegli la sede"
            (selectionChange)="popolaCalendarioOnSedeChange()">
            <mat-option [value]="null">
              Tutte
            </mat-option>
            <mat-option *ngFor="let sede of sedi" [value]="sede">
              {{sede.descrizione}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form" *ngIf="vistaData">
          <!-- DataDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
              [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
              (click)="parameters.dataDa=null">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
  
          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
              [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
              (click)="parameters.dataA=null">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
  
      </div>
      <mat-card-actions>
        <button mat-raised-button id="search-button" *ngIf="vistaData" (click)="list()" name="Search"
          color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/listaattesaprenotazionepostazione/detail/0']">New</button>
      </mat-card-actions>
      <br />
  
      <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Calendario">
          <!-- CALENDARIO -->
          <mwl-utils-calendar-header [(view)]="this.parameters.view" [(viewDate)]="this.parameters.viewDate"
            [weekStartsOn]="weekStartsOn">
          </mwl-utils-calendar-header>
  
          <div [ngSwitch]="this.parameters.view">
            <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="this.parameters.viewDate" [locale]="locale"
              [weekStartsOn]="weekStartsOn" [weekendDays]="weekendDays" [events]="events"
              [activeDayIsOpen]="activeDayIsOpen" (beforeViewRender)="beforeMonthViewRender($event)"
              (dayClicked)="dayClicked($event.day)" (eventClicked)="eventClicked($event.event)"
              (beforeViewRender)="estrazionePeriodoVista($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="'week'" class="cal-week-view-hide-hours"
              [viewDate]="this.parameters.viewDate" [locale]="locale" [weekStartsOn]="weekStartsOn"
              [weekendDays]="weekendDays" [events]="events" (eventClicked)="eventClicked($event.event)"
              (beforeViewRender)="estrazionePeriodoVista($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="'day'" class="cal-day-view-hide-hours"
              [viewDate]="this.parameters.viewDate" [locale]="locale" [events]="events"
              (eventClicked)="eventClicked($event.event)" (beforeViewRender)="estrazionePeriodoVista($event)">
            </mwl-calendar-day-view>
          </div>
        </mat-tab>
        <mat-tab label="Lista tabella">
  
          <div fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
              matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">
  
              <!-- Id Column -->
              <ng-container matColumnDef="id">
                <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td fxHide fxShow.gt-md mat-cell *matCellDef="let listaattesaprenotazionepostazione"> {{listaattesaprenotazionepostazione.id}}
                </td>
              </ng-container>
  
              <!-- DataDa -->
              <ng-container matColumnDef="dataDa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> dataDa </th>
                <td mat-cell *matCellDef="let listaattesaprenotazionepostazione"> {{listaattesaprenotazionepostazione.data | date
                  :'dd/MM/yyyy'}}
                </td>
              </ng-container>

              <!-- DataA -->
              <ng-container matColumnDef="dataA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> dataA </th>
                <td mat-cell *matCellDef="let listaattesaprenotazionepostazione"> {{listaattesaprenotazionepostazione.data | date
                  :'dd/MM/yyyy'}}
                </td>
              </ng-container>
  
              <!-- Richiedente -->
              <ng-container matColumnDef="richiedente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Richiedente </th>
                <td mat-cell *matCellDef="let listaattesaprenotazionepostazione"> {{listaattesaprenotazionepostazione.richiedente.nome}}
                  {{listaattesaprenotazionepostazione.richiedente.cognome}}</td>
              </ng-container>
  
              <!-- detail  -->
              <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let listaattesaprenotazionepostazione">
                  <button mat-mini-fab color="primary"
                    [routerLink]="['/listaattesaprenotazionepostazione/detail/', listaattesaprenotazionepostazione.id]">
                    <mat-icon>search</mat-icon>
                  </button>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
              </mat-paginator>
            </div>
          </div>
        </mat-tab>
  
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
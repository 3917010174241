import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { ComponentCacheService } from './../services/component-cache.service';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { AttivitaService } from './../services/rapportino/attivita.service';
import { AttivitaDTO } from './../shared/dto/rapportino/attivita';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attivita',
  templateUrl: './attivita.component.html',
  styleUrls: ['./attivita.component.scss']
})
export class AttivitaComponent extends GenericListComponent implements OnInit {

  collaboratori: CollaboratoreDTO[];
  attivita: AttivitaDTO;

  defaultSortField = 'id';
  defaultSortDirection = 'desc';

  constructor(
    private attivitaService: AttivitaService,
    private collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'collaboratore', 'descrizione', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      sortField: null,
      sortDirection: this.defaultSortDirection,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if(this.parameters.collaboratore == null){
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    } 
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
  }

  list() {
    console.log(this.parameters.collaboratore.id);
      this.attivitaService.attivitaList(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
        this.parameters.sortField,
        this.parameters.collaboratore,
        this.parameters.filter
        ).subscribe(
          (res: ResponseQueryByCriteria<AttivitaDTO>) => {
            console.log("response : " + res);
            this.parameters.dataSource = res.content;
            this.parameters.pageNumber = res.pageNumber;
            this.parameters.length = res.totalElements;
            this.parameters.showList = true;
          }
        )
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

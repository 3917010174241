<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione collaboratore</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <!-- Filter Field -->
      <mat-form-field>
        <input matInput [(ngModel)]="parameters.filter" placeholder="Filter" />
      </mat-form-field>
    </div>
    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/collaboratore/detail/0']">New</button>
    </mat-card-actions>
    <br />
    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
        matSort (matSortChange)="setSort($event)" matSortDisableClear
        [dataSource]="parameters.dataSource">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let collaboratore"> {{collaboratore.id}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let collaboratore"> {{collaboratore.email}} </td>
        </ng-container>

        <!-- UserName Column -->
        <ng-container matColumnDef="userName">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let collaboratore"> {{collaboratore.userName}} </td>
        </ng-container>

        <!-- Codice Column -->
        <ng-container matColumnDef="codiceCollaboratore">
          <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Codice Collaboratore </th>
          <td fxHide fxShow.gt-md mat-cell *matCellDef="let collaboratore"> {{collaboratore.codiceCollaboratore}} </td>
        </ng-container>

        <!-- Nome Column -->
        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
          <td mat-cell *matCellDef="let collaboratore"> {{collaboratore.nome}} </td>
        </ng-container>

        <!-- Cognome Column -->
        <ng-container matColumnDef="cognome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
          <td mat-cell *matCellDef="let collaboratore"> {{collaboratore.cognome}} </td>
        </ng-container>

        <!-- Compleanno Column -->
        <ng-container matColumnDef="compleannoPersonale">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Compleanno (day-month)</th>
          <td mat-cell *matCellDef="let collaboratore"> {{collaboratore.compleannoPersonale == null || collaboratore.compleannoPersonaleNascosto ? 'Non censito' : (collaboratore.compleannoPersonale | date: 'dd/MM') }}</td>
        </ng-container>

        <!-- JeniaCompleanno Column -->
        <ng-container matColumnDef="jeniaCompleanno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> JeniaCompleanno (day-month)</th>
          <td mat-cell *matCellDef="let collaboratore"> {{collaboratore.jeniaCompleanno == null ? 'Non censito' : (collaboratore.jeniaCompleanno | date: 'dd/MM') }}</td>
        </ng-container>

         <!-- JeniaCompleanno Column -->
         <ng-container matColumnDef="telefonoReperibilita">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono Reperibilità </th>
          <td mat-cell *matCellDef="let collaboratore"> {{collaboratore.telefonoReperibilita}}</td>
        </ng-container>

        <!-- Info Column -->
        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let collaboratore">
            <button mat-mini-fab color="primary" [routerLink]="['/collaboratore/detail/', collaboratore.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'collabotori'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'collabotori'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'collabotori'})">Json</button>
        </mat-card-actions>
        <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
        [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli comunicazione</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Comunicazione ID" readonly="true" />
        </mat-form-field>

        <!-- Collaboratore field -->
        <mat-form-field fxLayout="column">
          <mat-label>Collaboratore</mat-label>
          <mat-select formControlName="collaboratore" [compareWith]="compareDTO" [disabled]="!isJappTenantAdmin()">
            <mat-option [value]="null">
              Tutti
            </mat-option>
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Titolo field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="titolo" placeholder=" Titolo" [readonly]="!isJappTenantAdmin()" />
          <mat-error *ngIf="form.get('titolo').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Descrizione field -->
        <mat-form-field fxlayout="column">
          <textarea cdkTextareaAutosize matInput formControlName="descrizione" placeholder=" Descrizione"
            [readonly]="!isJappTenantAdmin()"></textarea>
          <mat-error *ngIf="form.get('descrizione').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Url field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="url" placeholder="URL" [readonly]="!isJappTenantAdmin()" />
          <mat-error *ngIf="form.get('url').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- Valid inizio field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput readonly="true" (click)="picker.open()" [max]="dataMax" (dateChange)="setMinDate()"
              [matDatepicker]="picker" formControlName="inizioValid" placeholder="Scegli inizio validazione">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <!-- Valid Fine field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()" [min]="dataMin" (dateChange)="setMaxDate()"
              [matDatepicker]="picker2" formControlName="fineValid" placeholder="Scegli fine validazione">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        
        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/comunicazione')" color="primary">Back</button>
      <button mat-raised-button (click)="comunicazioneSave()"
        [disabled]="form.invalid || !form.dirty || !isJappTenantAdmin()"
        *ngIf="comunicazione?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="comunicazioneDelete()"
        [disabled]="comunicazione?.id==null || !isJappTenantAdmin()"
        *ngIf="comunicazione?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">

        <div *ngIf="!this.parameters.hasKeys" fxLayout="column">
            <h2  >Prendi chiavi</h2>
            <br />

            <span *ngIf="!this.parameters.hasKeys">L'utente non ha chiavi</span>

            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- Description Column -->
                <ng-container matColumnDef="descrizione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let chiave"> {{chiave.descrizione}} </td>
                </ng-container>
                
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef>Prendi chiave</th>
                    <td mat-cell *matCellDef="let chiave">
                        <button mat-mini-fab color="primary" [routerLink] = "['/gestione-chiavi/prendi-chiavi/', chiave.id]">
                            <mat-icon>key</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
            </mat-paginator>
        </div>


        <div *ngIf="this.parameters.hasKeys" fxLayout="column">
            <h2 >Lascia chiavi</h2>
            <br />

           
            <span *ngIf="this.parameters.hasKeys">L'utente ha un mazzo chiavi</span>

            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- Description Column -->
                <ng-container matColumnDef="descrizione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let chiave"> {{chiave.descrizione}} </td>
                </ng-container>
                
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef>Lascia chiavi</th>
                    <td mat-cell *matCellDef="let chiave">
                        <button mat-mini-fab color="primary" [routerLink] = "['/gestione-chiavi/lascia-chiavi/', chiave.id]">
                            <mat-icon>key</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
            </mat-paginator>
        </div>

    </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettaglio Stampante 3D</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Id Stampante 3D" readonly="true" />
        </mat-form-field>

        <!-- Codice field -->

        <mat-form-field>
          <input matInput formControlName="codice" placeholder="Codice Stampante 3D" />
          <mat-error *ngIf="form.get('codice').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Modello field -->
        <mat-form-field>
          <input matInput formControlName="modello" placeholder="Modello Stampante 3D" />
          <mat-error *ngIf="form.get('modello').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Sede field -->
        <mat-form-field>
          <mat-label>Scegli la sede</mat-label>
          <mat-select [compareWith]="compareDTO" formControlName="sede" >
            <mat-option *ngFor="let sede of filteredSedi" [value]="sede">
              {{sede.descrizione}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('sede').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field> 

        <!-- Colore field -->

        <mat-form-field>
          <input matInput [ngxMatColorPicker]="picker" formControlName="colore" placeholder="Colore Stampante 3D">
          <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
          <ngx-mat-color-picker #picker></ngx-mat-color-picker>
          <mat-error *ngIf="form.get('colore').errors?.required">
            Campo obbligatorio
          </mat-error>
      </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title> Dettagli operazione </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{ 'padding-right': 2 + '%' }" [ngStyle.xs]="{ 'padding-right.px': 0 }"
              [ngStyle.sm]="{ 'padding-right.px': 0 }" [ngStyle.md]="{ 'padding-right.px': 0 }">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{ 'padding-right': 2 + '%' }" [ngStyle.xs]="{ 'padding-right.px': 0 }"
              [ngStyle.sm]="{ 'padding-right.px': 0 }" [ngStyle.md]="{ 'padding-right.px': 0 }">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/stampante3d')" color="primary">
        Back
      </button>
      <button mat-raised-button (click)="stampante3DSave()"
        [disabled]="form.invalid || !form.dirty || !isValidFormSede()" *ngIf="stampante3D?.deleteDate == null">
        Save
      </button>
      <button mat-raised-button (click)="stampante3DDelete()" [disabled]="stampante3D?.id == null"
        *ngIf="stampante3D?.deleteDate == null">
        Delete
      </button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettaglio collaboratore</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id Field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Collaboratore Id" readonly="true" />
        </mat-form-field>

         <!-- Responsive -->
         <div fxLayout="row" fxLayout.xs="row" fxLayout.sm="row" class="responsive-mat-form divCheckbox" style="max-width: 504px;">
          <!-- E-mailCollaboratore Field -->
          <mat-form-field fxFlex="100" fxFlex.md="75" fxFlex.sm="75" fxFlex.xs="75"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}" class="emailInput">
            <input matInput formControlName="email" placeholder="Collaboratore Email"/>
            <mat-error *ngIf="form.get('email').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- FlagDimissioni Field -->
          <mat-checkbox *ngIf="isJappTenantAdmin()" formControlName="flagDimissioni" 
            fxFlex="30" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" class="dimissioniCheckbox"> 
            Flag Dimissioni
          </mat-checkbox>
        </div>

        <!-- Username Field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="userName" placeholder="Collaboratore Username" />
          <mat-error *ngIf="form.get('userName').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Codice Field -->
        <mat-form-field fxlayout="column">
          <input matInput formControlName="codiceCollaboratore" placeholder="Collaboratore Codice Univoco" />
          <mat-error *ngIf="form.get('codiceCollaboratore').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- NomeCollaboratore Field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput formControlName="nome" placeholder="Collaboratore Nome" />
            <mat-error *ngIf="form.get('nome').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- Cognome Field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput formControlName="cognome" placeholder="Collaboratore Cognome" />
            <mat-error *ngIf="form.get('cognome').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Telefono Field -->
        <mat-form-field>
          <input matInput formControlName="telefonoReperibilita" placeholder="Telefono Reperibilità" />
        </mat-form-field>

        <!-- Sede Field -->
        <div fxLayout="column" class="responsive-mat-form">
          <mat-form-field>
            <mat-label>Sede</mat-label>
            <mat-select name="sede" formControlName="sede">
              <mat-option *ngFor="let sede of sedi" [value]="sede.descrizione">
                {{sede.descrizione}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Ruolo Field -->
        <mat-form-field>
          <input matInput formControlName="ruolo" placeholder="Collaboratore Ruolo" />
        </mat-form-field>

        <!-- Compleanno Field -->
        <div fxLayout="row" class="responsive-mat-form" fxLayout.xs="column" fxLayout.sm="column">
          <mat-form-field style="width: 100%;">
            <input matInput readonly="true" (click)="picker.open()"
            [matDatepicker]="picker" formControlName="compleannoPersonale" placeholder="Compleanno personale">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
            <mat-checkbox fxFlex class = "checkboxCompleanno" formControlName="checkboxCompleanno" > Nascondi compleanno</mat-checkbox>    
        </div> 

        <!-- JeniaCompleanno Field -->
        <mat-form-field>
         <input matInput readonly="true" (click)="picker2.open()" 
              [matDatepicker]="picker2" formControlName="jeniaCompleanno" placeholder="JeniaCompleanno">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm('/collaboratore')" color="primary">Back</button>
      <button mat-raised-button (click)="collaboratoreSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="collaboratore?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="collaboratoreDelete()" [disabled]="collaboratore?.id==null"
        *ngIf="collaboratore?.deleteDate==null">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>

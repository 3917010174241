import { Component, OnInit, OnDestroy } from '@angular/core';
import { CorsiUdemyDTO } from '../shared/dto/corsi-udemy/corsiUdemy';
import { CorsiUdemyService } from '../services/corsi-udemy/corsi-udemy.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';


@Component({
  selector: 'app-corsi-udemy',
  templateUrl: './corsi-udemy.component.html',
  styleUrls: ['./corsi-udemy.component.scss']
})
export class CorsiUdemyComponent extends GenericListComponent implements OnInit, OnDestroy{


  constructor(
    private corsiUdemyService: CorsiUdemyService, 
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) { 
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar, 
      timestampFormatPipe);
    this.displayedColumns = ['id', 'titolo', 'link', 'nlezioni' ,'ore',  'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    } 
  }

 
  list(){
    this.corsiUdemyService.corsiUdemyList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.filter).subscribe(
        (res: ResponseQueryByCriteria<CorsiUdemyDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }
  
  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  riderect(url : string){
    window.open(url);
  }

  isJappTenantAdmin() : boolean {
    return this.navigatorService.isJappTenantAdmin();
  }
}

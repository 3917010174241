import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocationDTO } from '../shared/dto/presenze/location';
import { LocationService } from '../services/presenze/location.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-location',
  templateUrl: './locationDetail.component.html',
  styleUrls: ['./locationDetail.component.scss']
})

export class LocationDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  locationId: number;
  location: LocationDTO;

  constructor(
    private locationService: LocationService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      emailList: new FormControl('', Validators.required),
      greenPassCheckNeeded: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.locationId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.locationId != null && this.locationId != 0) {
      this.locationRead();
    } else {
      this.location = new LocationDTO();
    }
  }

  locationSave() {
    if (this.location.id == null) {
      this.locationCreate();
    } else {
      this.locationUpdate();
    }
  }

  locationCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.locationService.create(this.location).subscribe(
        (res: LocationDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.location = res;
          this.locationId = this.location.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: any) => {
          this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
        }
      );
    }
  }

  locationUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la location?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.locationService.update(this.location).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.locationRead();
              this.form.markAsPristine();
            }, (err: any) => {
              this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
            }
          );
        }
      });
    }
  }

  locationDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la location?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.locationService.delete(this.location.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/location"]);
            }
          );
        }
      });
    }
  }

  locationRead() {
    this.locationService.read(this.locationId).subscribe(
      (res: LocationDTO) => {
        console.log("response : " + res);
        this.location = res;
        this.locationId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.location.descrizione = this.form.get("descrizione").value;
    this.location.emailList = this.form.get("emailList").value;
    this.location.greenPassCheckNeeded = this.form.get("greenPassCheckNeeded").value;
  }
 
  private dtoToForm(): void {
    this.form.get("id").setValue(this.location.id);
    this.form.get("descrizione").setValue(this.location.descrizione);
    this.form.get("emailList").setValue(this.location.emailList);
    this.form.get("greenPassCheckNeeded").setValue(this.location.greenPassCheckNeeded==null?false:this.location.greenPassCheckNeeded);
    this.form.get("deleteDate").setValue(this.location.deleteDate);
    this.form.get("deleteUser").setValue(this.location.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.location.insertDate));
    this.form.get("insertUser").setValue(this.location.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.location.updateDate));
    this.form.get("updateUser").setValue(this.location.updateUser);
    this.form.get("optLock").setValue(this.location.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Storia chiavi</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.descrizioneChiave" placeholder="Chiavi" />
            </mat-form-field>
        </div>
        <div fxLayout="column" class="responsive-mat-form">
            <mat-form-field>
                <input matInput [(ngModel)]="parameters.userCollaboratore" placeholder="Collaboratore" />
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
            <!-- DataDa field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
                    [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
                    (click)="parameters.dataDa=null">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <!-- DataA field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
                    [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
                    (click)="parameters.dataA=null">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <mat-card-actions>
            <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
        </mat-card-actions>
        <br />
        <div *ngIf="parameters.showList" fxLayout="column">
            <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
                matSort (matSortChange)="setSort($event)" matSortDisableClear [dataSource]="parameters.dataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let storiaChiavi"> {{ storiaChiavi.id }} </td>
                </ng-container>

                <!-- Chiavi Column -->
                <ng-container matColumnDef="chiavi">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Chiavi </th>
                    <td mat-cell *matCellDef="let storiaChiavi"> {{ storiaChiavi.chiavi.descrizione }} </td>
                </ng-container>

                <!-- Collaboratore Column -->
                <ng-container matColumnDef="collaboratore">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Collaboratore </th>
                    <td mat-cell *matCellDef="let storiaChiavi"> {{ storiaChiavi.collaboratore.userName }} </td>
                </ng-container>

                <!-- Data da Column -->
                <ng-container matColumnDef="dataDa">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data da </th>
                    <td mat-cell *matCellDef="let storiaChiavi"> {{ storiaChiavi.dataDa | date :'dd/MM/yyyy'}} </td>
                </ng-container>

                <!-- Data a Column -->
                <ng-container matColumnDef="dataA">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Data a </th>
                    <td mat-cell *matCellDef="let storiaChiavi"> {{ storiaChiavi.dataA | date :'dd/MM/yyyy'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-actions>
                    <button mat-raised-button
                        (click)="exporter.exportTable('xlsx', {fileName:'storiaChiavi'})">Excel</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('csv', {fileName:'storiaChiavi'})">Csv</button>
                    <button fxHide fxShow.gt-md mat-raised-button
                        (click)="exporter.exportTable('json', {fileName:'storiaChiavi'})">Json</button>
                </mat-card-actions>
                <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
                    [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
                </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>
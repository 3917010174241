<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Gestione eventi</h2>

    <div fxLayout="column" class="responsive-mat-form">
      <mat-form-field>
        <mat-select name="organizzatore" [compareWith]="compareDTO" [(ngModel)]="parameters.organizzatore"
          placeholder="Seleziona organizzatore">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
            {{ collaboratore.nome }} {{ collaboratore.cognome }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select name="categoria" [compareWith]="compareDTO" [(ngModel)]="parameters.categoria"
          placeholder="Seleziona categoria">
          <mat-option [value]="null">
            Tutti
          </mat-option>
          <mat-option *ngFor="let categoria of categorie" [value]="categoria">
            {{ categoria.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
        <mat-form-field fxFlex="50">
          <input matInput type="number" [(ngModel)]="parameters.minPartecipanti" placeholder="Min partecipanti">
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <input matInput type="number" [(ngModel)]="parameters.maxPartecipanti" placeholder="Max partecipanti">
        </mat-form-field>
      </div>

      <!-- Responsive -->
      <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
        <mat-form-field fxFlex="50">
          <input matInput readonly="true" [(ngModel)]="parameters.dataDa" [matDatepicker]="pickerDataDa"
            placeholder="Data da" (click)="pickerDataDa.open()" [max]="parameters.dataA">
          <mat-datepicker-toggle matSuffix [for]="pickerDataDa"></mat-datepicker-toggle>
          <mat-datepicker #pickerDataDa></mat-datepicker>

          <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataDa = null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <input matInput readonly="true" [(ngModel)]="parameters.dataA" [matDatepicker]="pickerDataA"
            placeholder="Data a" (click)="pickerDataA.open()" [min]="parameters.dataDa">
          <mat-datepicker-toggle matSuffix [for]="pickerDataA"></mat-datepicker-toggle>
          <mat-datepicker #pickerDataA></mat-datepicker>

          <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
            (click)="parameters.dataA = null">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <mat-card-actions>
      <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
      <button mat-raised-button id="new-button" [routerLink]="['/evento/detail/0']">New</button>
      <button mat-raised-button id="sub-button" [routerLink]="['/partecipante-evento']">Subscriptions</button>
      <button mat-raised-button id="cat-button" [routerLink]="['/categoria-evento']">Categories</button>
    </mat-card-actions>

    <br>

    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex [dataSource]="parameters.dataSource" matSort (matSortChange)="setSort($event)"
        matSortDisableClear [matSortActive]="defaultSortField" [matSortDirection]="defaultSortDirection">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let evento"> {{evento.id}} </td>
        </ng-container>

        <ng-container matColumnDef="organizzatore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Organizzatore </th>
          <td mat-cell *matCellDef="let evento"> {{evento.organizzatore.nome}} {{evento.organizzatore.cognome}}</td>
        </ng-container>

        <ng-container matColumnDef="descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
          <td mat-cell *matCellDef="let evento"> {{evento.descrizione}}</td>
        </ng-container>

        <ng-container matColumnDef="categoria">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Categoria </th>
          <td mat-cell *matCellDef="let evento"> {{evento.categoria.nome}}</td>
        </ng-container>

        <ng-container matColumnDef="dataOraInizio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Inizio </th>
          <td mat-cell *matCellDef="let evento"> {{evento.dataOraInizio | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <ng-container matColumnDef="dataOraFine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fine </th>
          <td mat-cell *matCellDef="let evento"> {{evento.dataOraFine | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <ng-container matColumnDef="luogo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Luogo </th>
          <td mat-cell *matCellDef="let evento"> {{evento.luogo}}</td>
        </ng-container>

        <ng-container matColumnDef="minPartecipanti">
          <th mat-header-cell *matHeaderCellDef> Min Partecipanti </th>
          <td mat-cell *matCellDef="let evento"> {{ evento.minPartecipanti }}</td>
        </ng-container>

        <ng-container matColumnDef="maxPartecipanti">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Max Partecipanti </th>
          <td mat-cell *matCellDef="let evento"> {{ evento.maxPartecipanti }}</td>
        </ng-container>

        <ng-container matColumnDef="maxEsterniPerPartecipante">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Max Esterni </th>
          <td mat-cell *matCellDef="let evento"> {{ evento.maxEsterniPerPartecipante ? evento.maxEsterniPerPartecipante
            : 'none' }}</td>
        </ng-container>

        <ng-container matColumnDef="dataFineIscrizioni">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fine Iscrizioni </th>
          <td mat-cell *matCellDef="let evento"> {{ (evento.dataFineIscrizioni ? evento.dataFineIscrizioni :
            evento.dataOraInizio) | date: 'dd/MM/yyyy HH:mm' }}</td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let evento">
            <button mat-mini-fab color="primary" [routerLink]="['/evento/detail/', evento.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>

      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-paginator #paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber"
          [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)">
        </mat-paginator>
      </div>
    </div>

  </mat-card-content>
</mat-card>
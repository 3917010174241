import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { PropostaService } from '../services/proposte/proposta.service';
import { PropostaDTO } from '../shared/dto/proposte/proposta';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { VotoDTO } from '../shared/dto/proposte/voto';
import { VotoService } from '../services/proposte/voto.service';

@Component({
  selector: 'app-proposta',
  templateUrl: './proposta.component.html',
  styleUrls: ['./proposta.component.scss']
})
export class PropostaComponent extends GenericListComponent implements OnInit, OnDestroy {

  giornoCorrente= new Date();

  votoPresente: boolean;
  buttonAbilitato: boolean;
  propostaPresente: boolean;
  showContent: boolean;

  propostaVotata: PropostaDTO;

  constructor(
    private propostaService: PropostaService,
    private votoService: VotoService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns= ['id', 'creatore', 'domanda', 'check', 'votazione', 'voteList', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      creatore: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.votoPresente = true;
    this.buttonAbilitato = true;
    this.propostaVotata = null;
    this.propostaPresente = true;
    this.showContent = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.parameters.collaboratore = res;
      }
    );
    this.list();
    this.timeCheck();
    setTimeout(()=>this.showContent=true, 200);
  }

  list() {
    this.propostaService.propostaListByList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.giornoCorrente).subscribe(
        (res: ResponseQueryByCriteria<PropostaDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
          this.votoPresente = false;
          this.propostaPresente = false;
          for (let proposta of this.parameters.dataSource) {
            if (super.compareDTO(proposta.creatore, this.parameters.collaboratore)) {
              this.propostaPresente = true;
            }
            for (let voto of proposta.voteList) {
              if (super.compareDTO(voto.votante, this.parameters.collaboratore)) {
                this.votoPresente = true;
                this.propostaVotata = proposta;
              }
            }
          }
        }
      )
  }

  votoCreate(proposta: PropostaDTO) {
    let voto = new VotoDTO;
    voto.proposta = proposta;
    voto.dataVoto = this.giornoCorrente;
    voto.votante = this.parameters.collaboratore;
    this.votoService.create(voto).subscribe(
      (res: VotoDTO) => {
        this.votoPresente = true;
        this.list();
      }, (error: any) => {
        this.snackBar.open("Hai già votato!", null, { duration: 3000 });
      }
    );
  }

  timeCheck() {
    let time = new Date();
    let hour = time.getHours();
    let minute = time.getMinutes();
    if ((hour == 11) && (minute >= 0) || (hour == 12) && (minute <= 0)) {
      this.buttonAbilitato = false;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

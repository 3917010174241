<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h1> Lista collaboratori </h1>
    <div fxLayout="column" class="responsive-mat-form input">
      <mat-form-field >
        <mat-label>Seleziona il collaboratore</mat-label>
        <input (input)="collaboratoreChanged($event.target.value, true)"
                type="text" placeholder="Seleziona il collaboratore" 
                matInput [formControl]="filter">
      </mat-form-field>
    </div> 
     <div fxLayout="row">
      <table mat-table *ngIf="(observableListaCompleta$ | async) as observableListaCompleta" [dataSource]="observableListaCompleta" class="mat-table" fxFlex matSort (matSortChange)="setSort($event)"
        [matSortActive]="parameters.sortField" [matSortDirection]="parameters.sortDirection" matSortDisableClear
        [ngStyle]="{'width': 30 + '%'}" [ngStyle.md]="{'width': 30 + '%'}"
        [ngStyle.xs]="{'width': 100 + '%'}" [ngStyle.sm]="{'width': 100 + '%'}"> 


        <!-- Collaboratore column -->
        <ng-container matColumnDef="nomeCognome" >
          <th mat-header-cell *matHeaderCellDef class="th-c">Collaboratore</th>
          <td mat-cell *matCellDef="let collaboratoreAttuale" class="td-c" >
            {{ collaboratoreAttuale.collaboratore.nome }} {{ collaboratoreAttuale.collaboratore.cognome }}
          </td>
          <td mat-footer-cell *matFooterCellDef class="footerLegenda">
            <br/>
            <mat-icon class="green-icon">check_box</mat-icon> : Il collaboratore ha inviato la presenza
            <br/>
            <mat-icon class="red-icon">local_hospital</mat-icon>: Il collaboratore è attualmente in malattia
            <br/>
            <mat-icon class="blue-icon">beach_access</mat-icon>: Il collaboratore è attualmente in ferie/permesso
            <br/>
            <mat-icon class="mat-icon-rtl-mirror">warning</mat-icon>: Attenzione non è possibile trovare il collaboratore
            <br/><br/>
          </td>
        </ng-container> 

        <!-- Icona column -->
        <ng-container matColumnDef="stato">
          <th mat-header-cell *matHeaderCellDef class="th-icone">Stato Collaboratore</th>
          <td mat-cell *matCellDef="let collaboratoreAttuale" class="td-icone">

            <p class="p-mat" mat-cell *ngIf="hasPresenze(collaboratoreAttuale)">
              <mat-icon class="green-icon">check_box</mat-icon>
            </p>
            <p class="p-mat" mat-cell *ngIf="hasMalattie(collaboratoreAttuale)">
              <mat-icon class="red-icon">local_hospital</mat-icon>
            </p>
            <p class="p-mat" mat-cell *ngIf="hasAssenze(collaboratoreAttuale)">
              <mat-icon class="blue-icon">beach_access</mat-icon>
            </p>
            <p class="p-mat" mat-cell *ngIf="isNotFound(collaboratoreAttuale)">
              <mat-icon class="mat-icon-rtl-mirror">warning</mat-icon>
            </p>
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card> 
import { TenantDTO } from './tenant';
import { LogicalDeleteDTO } from './../basic/logicalDeleteDTO';

export class TemplateDTO extends LogicalDeleteDTO {

    id: number;
    tenant: TenantDTO;
    codTemplate: String;
    template: String;
    descrizione: String;
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Gestione numeri reperibilità AM</h2>
        <div fxLayout="column" class="responsive-mat-form">
          <mat-form-field>
            <input matInput [(ngModel)]="parameters.filter" placeholder="Descrizione"/>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/numeri-reperibilita/detail/0']">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let numero"> {{numero.id}} </td>
            </ng-container>
            
            <!-- Description Column -->
            <ng-container matColumnDef="descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Description </th>
              <td mat-cell *matCellDef="let numero"> {{numero.descrizione}} </td>
            </ng-container>

            <!-- mainNumber Column -->
            <ng-container matColumnDef="mainNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > MainNumber </th>
                <td mat-cell *matCellDef="let numero"> {{numero.mainNumber}} </td>
            </ng-container>

            <!-- mainNumber Column -->
            <ng-container matColumnDef="mainNumberId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > MainNumberId </th>
                <td mat-cell *matCellDef="let numero"> {{numero.mainNumberId}} </td>
            </ng-container>
        
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let numero">
                  <button mat-mini-fab color="primary" [routerLink]="['/numeri-reperibilita/detail/', numero.id]"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
      </div>
    </mat-card-content>
  </mat-card>
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { Stampante3DService } from '../services/stampante3D/stampante3D.service';
import { Stampante3DDTO } from '../shared/dto/stampante3D/stampante3D';
import { SedeService } from '../services/sede/sede.service';
import { SedeDTO } from '../shared/dto/sede/sede';

@Component({
  selector: "app-stampante3D",
  templateUrl: "./stampante3D.component.html",
  styleUrls: ["./stampante3D.component.scss"],
})
export class Stampante3DComponent
  extends GenericListComponent
  implements OnInit, OnDestroy
{
  sedi: SedeDTO[];

  constructor(
    private stampante3DService: Stampante3DService,
    private sedeService: SedeService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe
    );
    this.displayedColumns = [
      "id",
      "codice",
      "modello",
      "sede.descrizione",
      "colore",
      "detail",
    ];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      sortField: "codice",
      sortDirection: "ASC",
      pageNumber: 0,
      pageSize: 50,
      length: 0,
    };
  }

  list() {
    this.stampante3DService
      .stampante3DSearchBySede(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        this.parameters.sortDirection,
        this.parameters.sortField,
        this.parameters.filter
      )
      .subscribe((res: ResponseQueryByCriteria<Stampante3DDTO>) => {
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.parameters.showList = true;
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.sedeService
      .sedeListByDescrizione(0, 9999, "ASC", "descrizione", "")
      .subscribe((res: ResponseQueryByCriteria<SedeDTO>) => {
        this.sedi = res.content;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
import { Component, OnInit } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { EventoService } from '../services/evento/evento.service';
import { NavigatorService } from '../services/navigator.service';
import { DateAdapter } from '@angular/material/core';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { EventoDTO } from '../shared/dto/evento/evento';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { CategoriaEventoDTO } from '../shared/dto/evento/categoria-evento';
import { CategoriaEventoService } from '../services/evento/categoria-evento.service';

@Component({
  selector: 'app-evento-detail',
  templateUrl: './evento-detail.component.html',
  styleUrls: ['./evento-detail.component.scss']
})
export class EventoDetailComponent extends GenericDetailComponent implements OnInit {

  eventoId: number;
  evento: EventoDTO;
  collaboratori: CollaboratoreDTO[];
  categorie: CategoriaEventoDTO[];

  shouldDisable = false;

  constructor(
    protected navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private eventoService: EventoService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    private categoriaService: CategoriaEventoService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>,) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );

    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      organizzatore: new FormControl({ value: '', disabled: !this.isJappTenantAdmin() }, Validators.required),
      descrizione: new FormControl(null, Validators.required),
      categoria: new FormControl('', Validators.required),
      dataOraInizio: new FormControl('', Validators.required),
      dataOraFine: new FormControl('', Validators.required),
      luogo: new FormControl('', Validators.required),
      minPartecipanti: new FormControl('', Validators.min(0)),
      maxPartecipanti: new FormControl('', Validators.min(0)),
      maxEsterniPerPartecipante: new FormControl('', Validators.min(0)),
      dataFineIscrizioni: new FormControl(''),
      avvertimentoGiorni: new FormControl(''),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.form.enable();
    this.shouldDisable = false;

    if (!this.isJappTenantAdmin())
      this.form.get('organizzatore').disable();

    this.eventoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.eventoId != null && this.eventoId != 0 && !isNaN(this.eventoId)) {
      this.eventoRead();
    } else {
      this.eventoInit();
    }

    this.form.get("dataOraFine").valueChanges.subscribe({
      next: value => {
        this.form.get("dataOraInizio").updateValueAndValidity();
      }
    });

    this.collaboratoreService.collaboratoreList(0, 9999, 'ASC', 'nome', '').subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
        this.collaboratori = res.content;
      }
    );

    this.categoriaService.categoriaEventoGetAll().subscribe(
      (res: CategoriaEventoDTO[]) => {
        this.categorie = res;
      }
    );
  }

  public eventoRead() {
    this.eventoService.read(this.eventoId).
      pipe(
        switchMap((evento: EventoDTO) => {
          this.evento = evento;
          this.dtoToForm();

          return this.navigatorService.collaboratore;
        })
      ).subscribe({
        next: collaboratore => {
          if (!this.isJappTenantAdmin() && collaboratore.userName != this.evento.organizzatore.userName) {
            this.form.disable();
            this.shouldDisable = true;
          }
        }
      });
  }

  public eventoInit() {
    this.evento = new EventoDTO();

    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.evento.organizzatore = res;
        this.dtoToForm();
      }
    );
  }

  public eventoSave() {
    if (this.eventoId == 0)
      this.eventoCreate()
    else
      this.eventoUpdate();
  }

  private eventoCreate() {
    this.evento = this.formToDto();

    this.eventoService.create(this.evento).subscribe({
      next: response => {
        this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
        this.evento = response;
        this.eventoId = this.evento.id;
        this.dtoToForm();
        this.form.markAsPristine();
      }
    });
  }

  private eventoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere l'evento?")
      .pipe(
        switchMap(confirm => {
          this.evento = this.formToDto();
          return this.eventoService.update(this.evento);
        })
      ).subscribe({
        next: (res) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.eventoRead();
          this.form.markAsPristine();
        }
      });
    }
  }

  public eventoDelete() {
    this.confirm("Vuoi davvero cancellare questo evento?")
    .pipe(
      switchMap(confirm => {
        return this.eventoService.delete(this.eventoId);
      })
    ).subscribe({
      next: response => {
        this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
        this.router.navigate(["/evento"]);
      }
    });
  }

  public formToDto(): EventoDTO {
    let evento = new EventoDTO();
    evento.id = this.form.get('id').value;
    evento.organizzatore = this.form.get('organizzatore').value;
    evento.descrizione = this.form.get('descrizione').value;
    evento.categoria = this.form.get('categoria').value;
    evento.dataOraInizio = new Date(this.form.get('dataOraInizio').value);
    evento.dataOraFine = new Date(this.form.get('dataOraFine').value);
    evento.luogo = this.form.get('luogo').value;
    evento.minPartecipanti = this.form.get('minPartecipanti').value;
    evento.maxPartecipanti = this.form.get('maxPartecipanti').value;
    evento.maxEsterniPerPartecipante = this.form.get('maxEsterniPerPartecipante').value;
    evento.dataFineIscrizioni = this.form.get('dataFineIscrizioni').value;
    evento.avvertimentoGiorni = this.form.get('avvertimentoGiorni').value;

    return evento;
  }

  public dtoToForm() {
    this.form.get("id").setValue(this.eventoId);
    this.form.get("organizzatore").setValue(this.evento.organizzatore);
    this.form.get("descrizione").setValue(this.evento.descrizione);
    this.form.get("categoria").setValue(this.evento.categoria);
    if (this.evento.dataOraInizio) this.form.get("dataOraInizio").setValue(moment(this.evento.dataOraInizio).format("YYYY-MM-DDTHH:mm"));
    if (this.evento.dataOraFine) this.form.get("dataOraFine").setValue(moment(this.evento.dataOraFine).format("YYYY-MM-DDTHH:mm"));
    this.form.get("luogo").setValue(this.evento.luogo);
    this.form.get("minPartecipanti").setValue(this.evento.minPartecipanti);
    this.form.get("maxPartecipanti").setValue(this.evento.maxPartecipanti);
    this.form.get("maxEsterniPerPartecipante").setValue(this.evento.maxEsterniPerPartecipante);
    this.form.get("dataFineIscrizioni").setValue(this.evento.dataFineIscrizioni);
    this.form.get("avvertimentoGiorni").setValue(this.evento.avvertimentoGiorni);
    this.form.get("deleteDate").setValue(this.timestampFormatPipe.transform(this.evento.deleteDate));
    this.form.get("deleteUser").setValue(this.evento.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.evento.insertDate));
    this.form.get("insertUser").setValue(this.evento.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.evento.updateDate));
    this.form.get("updateUser").setValue(this.evento.updateUser);
    this.form.get("optLock").setValue(this.evento.optLock);
    this.form.markAsPristine();
  }

}
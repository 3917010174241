import { CollaboratoreDTO } from '../domain/collaboratore';
import { MalattiaDTO } from '../malattia/malattia';

export class MalattiaRapportinoDTO extends MalattiaDTO {
    collaboratore: CollaboratoreDTO;
    tipo: string;
    dataPresenza: Date;
    dataA:Date;
    numeroDiOre: number;
    oreCensite: number;
}
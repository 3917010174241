import { ResponseFail } from './../shared/dto/responseFail';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoTurnoAmService } from '../services/turno-am/tipo-turno-am.service';
import { UrlService } from './../commons/calendar/prevUrl.service';
import { Observable } from 'rxjs';
import { TipoTurnoAMDTO } from '../shared/dto/turno-am/tipo-turno-am';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigatorService } from './../services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgxMatColorPickerInput, Color } from '@angular-material-components/color-picker';

@Component({
  selector: 'app-tipo-turno-am-detail',
  templateUrl: './tipo-turno-am-detail.component.html',
  styleUrls: ['./tipo-turno-am-detail.component.scss']
})
export class TipoTurnoAmDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  tipoTurnoId: number;
  tipoTurno: TipoTurnoAMDTO;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string

  @ViewChild(NgxMatColorPickerInput) pickerInput: NgxMatColorPickerInput;

  constructor(
    private urlService: UrlService,
    private tipoTurnoService: TipoTurnoAmService,
    private breakpointObserver: BreakpointObserver,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
  ) { 
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      nome: new FormControl('', Validators.required),
      orario: new FormControl('', Validators.required),
      colore: new FormControl('', Validators.required),
      ordine: new FormControl('', Validators.required),
      descrizione: new FormControl(''),
      sovrapponibile: new FormControl(false, Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.tipoTurnoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.tipoTurnoId != 0 && this.tipoTurnoId != null) {
      this.tipoTurnoRead();
    } else {
      this.tipoTurno = new TipoTurnoAMDTO();
    }
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  ngOnDestroy(): void {
  }

  private hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }   

  private formToDto(): void{
    this.tipoTurno.nome = this.form.get("nome").value;
    this.tipoTurno.orario = this.form.get("orario").value;
    if(this.form.get("colore").value instanceof String){
      this.tipoTurno.colore = this.form.get("colore").value;
    } else if (this.form.get("colore").value instanceof Color){
      this.tipoTurno.colore = "#" + this.form.get("colore").value.hex;
    }
    this.tipoTurno.ordine = this.form.get("ordine").value;
    this.tipoTurno.descrizione = this.form.get("descrizione").value;
    this.tipoTurno.sovrapponibile = this.form.get("sovrapponibile").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.tipoTurno.id);
    this.form.get("nome").setValue(this.tipoTurno.nome);
    this.form.get("orario").setValue(this.tipoTurno.orario);
    this.form.get("colore").setValue(this.tipoTurno.colore);
    let colore = this.hexToRgb(this.tipoTurno.colore);
    this.pickerInput.value = new Color(colore.r, colore.g, colore.b);
    this.form.get("ordine").setValue(this.tipoTurno.ordine);
    this.form.get("descrizione").setValue(this.tipoTurno.descrizione);
    this.form.get("sovrapponibile").setValue(this.tipoTurno.sovrapponibile==null?false:this.tipoTurno.sovrapponibile);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.tipoTurno.insertDate));
    this.form.get("insertUser").setValue(this.tipoTurno.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.tipoTurno.updateDate));
    this.form.get("updateUser").setValue(this.tipoTurno.updateUser);
    this.form.markAsPristine();
  }

  tipoTurnoSave() {
    if(this.tipoTurno.id == null){
      this.tipoTurnoCreate();
    } else {
      this.tipoTurnoUpdate();
    }
  }

  tipoTurnoCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.tipoTurnoService.create(this.tipoTurno).subscribe(
        (res: TipoTurnoAMDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.tipoTurno = res;
          this.tipoTurnoId = this.tipoTurno.id;
          this.dtoToForm();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidTipoTurnoAMException_TIPO_TURNO_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già una tipo di turno con questo nome e orario.", null, { duration: 3000 });
          } else {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
  }

  tipoTurnoRead() {
    this.tipoTurnoService.read(this.tipoTurnoId).subscribe(
      (res: TipoTurnoAMDTO) => {
        console.log("response : " + res);
        this.tipoTurno = res;
        this.tipoTurnoId = res.id;
        this.dtoToForm();
      }
    );
  }

  tipoTurnoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il tipo di turno ?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.tipoTurnoService.update(this.tipoTurno).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.tipoTurnoRead();
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidTipoTurnoAMException_TIPO_TURNO_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già una tipo di turno con questo nome e orario.", null, { duration: 3000 });
              } else {
                this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
  }

  tipoTurnoDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il tipo turno?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.tipoTurnoService.delete(this.tipoTurno.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/tipo-turno-am"]);
            }
          );
        }
      });
    }
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

}

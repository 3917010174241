import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';

export class ComandaDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    collaboratore: CollaboratoreDTO;
    dataComanda: Date;
    email: String;
    descrizione: String;
    importoTotale: number;
    totale: number;
    persone: number;
    link: String;
}
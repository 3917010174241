<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli assenza</h2>
    <div fxLayout="column">
      <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">

        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Assenza Id" readonly="true" />
        </mat-form-field>

        <!-- Richiedente field -->
        <mat-form-field>
          <mat-select name="richiedente" [disabled]="!isJappTenantAdmin()" [compareWith]="compareDTO"
            formControlName="richiedente" placeholder="Scegli il richiedente">
            <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('richiedente').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- DataDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker.open()" [max]="dataMax"
              (dateChange)="setMinDate()" [matDatepicker]="picker" formControlName="dataDa"
              placeholder="Scegli data da (compresa)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataDa').value" matSuffix mat-icon-button
              aria-label="Clear" (click)="form.get('dataDa').setValue(null)" (click)="dataMin=null">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataDa').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput [disabled]="bloccaModifica" readonly="true" (click)="picker2.open()" [min]="dataMin"
              (dateChange)="setMaxDate()" [matDatepicker]="picker2" formControlName="dataA" placeholder="Scegli data a (compresa)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button [disabled]="bloccaModifica" *ngIf="form.get('dataA').value" matSuffix mat-icon-button
              aria-label="Clear" (click)="form.get('dataA').setValue(null)" (click)="dataMax=null">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataA').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- StatoRichiesta field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <mat-select name="statoRichiesta" [disabled]="!isJappTenantAdmin()" formControlName="statoRichiesta"
              placeholder="Scegli lo stato della richiesta">
              <mat-option *ngFor="let option of statoRichiestaValues" [value]="option.value">
                {{option.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('statoRichiesta').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- Approvatore field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <mat-select name="approvatore" disabled formControlName="approvatore" [compareWith]="compareDTO"
              placeholder="Approvatore">
              <mat-option *ngFor="let collaboratore of collaboratori" readonly="true" [value]="collaboratore">
                {{collaboratore.nome}} {{collaboratore.cognome}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('approvatore').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- TipoRichiesta field -->
          <mat-form-field fxFlex="50%" fxFlex.md="50%" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <mat-select [disabled]="bloccaModifica" name="tipoRichiesta"
              (selectionChange)="onTipoRichiestaChanged($event)" formControlName="tipoRichiesta"
              placeholder="Scegli il tipo di richiesta">
              <mat-option *ngFor="let option of tipoRichiestaValues" [value]="option.value">
                {{option.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('tipoRichiesta').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
           <!-- inizioPermesso field -->
           <mat-form-field fxFlex="25%" fxFlex.md="25%" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly [min]="'00:00'" [max]="'23:00'" [ngxTimepicker]="toggleTimepickerA" [format]="24"
              formControlName="inizioPermesso" placeholder="Inizio Permesso">
            <ngx-material-timepicker #toggleTimepickerA [preventOverlayClick]="false" hoursOnly="false" minutesGap="5" >
            </ngx-material-timepicker>
            <mat-error *ngIf="form.get('inizioPermesso').errors?.required">
              Campo obbligatorio
            </mat-error>

          </mat-form-field>
          <!-- NumeroDiOre field -->
          <mat-form-field fxFlex="25%" fxFlex.md="25%" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput type="number" min="1" max="8" formControlName="numeroDiOre" placeholder="Numero Di Ore">
            <mat-error *ngIf="form.get('numeroDiOre').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Descrizione field -->
        <mat-form-field>
          <input matInput formControlName="descrizione" placeholder="Nota" />
        </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
      <button mat-raised-button (click)="assenzaSave()" [disabled]="form.invalid || !form.dirty"
        *ngIf="assenza?.deleteDate==null">Save</button>
      <button mat-raised-button (click)="assenzaDelete()" [disabled]="assenza?.id==null"
        *ngIf="assenza?.deleteDate==null">Delete</button>
     </mat-card-actions>
  </mat-card-content>
</mat-card>
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Postazioni</h2>
        <p>Imposta i filtri di ricerca</p>
        <div fxLayout="column" class="responsive-mat-form">

           <!-- Ufficio field -->
        <mat-form-field>
          <mat-select name="ufficio" [(ngModel)]="parameters.filterUfficio" [compareWith]='compareDTO'
            [disabled]="!isJappTenantAdmin()" placeholder="Scegli l'ufficio">
            <mat-option [value]="null">
              Tutte
            </mat-option>
            <mat-option *ngFor="let ufficio of uffici " [value]="ufficio">
              {{ufficio.descrizione}} - {{ufficio.sede.descrizione}} 
            </mat-option>
          </mat-select>
        </mat-form-field>

          <mat-form-field>
            <input matInput [(ngModel)]="parameters.filter" placeholder="Filter"/>
          </mat-form-field>
        </div>
        <mat-card-actions>
          <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
          <button mat-raised-button id="new-button" [routerLink]="['/postazione/detail/0']">New</button>
        </mat-card-actions>
        <br/>
        <div *ngIf="parameters.showList" fxLayout="column">
          <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
            matSort (matSortChange)="setSort($event)" matSortDisableClear 
            [dataSource]="parameters.dataSource">
        
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let postazione"> {{postazione.id}} </td>
            </ng-container>
            
            <!-- Description Column -->
            <ng-container matColumnDef="descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Description </th>
              <td mat-cell *matCellDef="let postazione"> {{postazione.descrizione}} </td>
            </ng-container>

            <!-- UfficioDescrizione -->
            <ng-container matColumnDef="ufficio.descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > UfficioDescrizione </th>
              <td mat-cell *matCellDef="let postazione"> {{postazione.ufficio.descrizione}} </td>
            </ng-container>
        
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let postazione">
                  <button mat-mini-fab color="primary" [routerLink]="['/postazione/detail/', postazione.id]"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length" [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
          </div>
      </div>
    </mat-card-content>
  </mat-card>
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentCacheService } from '../services/component-cache.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { PresenzaService } from '../services/presenze/presenza.service';
import { PresenzaDTO } from '../shared/dto/presenze/presenza';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { LocationService } from '../services/presenze/location.service';
import { LocationDTO } from '../shared/dto/presenze/location';

@Component({
  selector: 'app-gestionePresenza',
  templateUrl: './gestionePresenza.component.html',
  styleUrls: ['./gestionePresenza.component.scss']
})
export class GestionePresenzaComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  locations: LocationDTO[];
  selectedLocationsIds: number[];
  presenza: PresenzaDTO;
  dataDa: Date;
  dataA: Date;

  defaultSortField = 'dataPresenza';
  defaultSortDirection = 'asc';
  presenze: PresenzaDTO[];
  constructor(
    private presenzaService: PresenzaService,
    private collaboratoreService: CollaboratoreService,
    private locationService: LocationService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'dataPresenza', 'collaboratore', 'location', 'numeroDiOre', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      collaboratore: null,
      collaboratoreChanged: false,
      location: [],
      selectedLocations: [],
      selectedLocationsIds: [],
      initDataDa: true,
      dataDa: null,
      dataA: null,
      totaleOre: null,
      giorni: null,
      sortField: 'dataPresenza',
      sortDirection: 'DESC',
      pageNumber: 0,
      pageSize: 50,
      length: 0,
    }
  }

  ngOnInit() {

    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.locationService.locationList(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<LocationDTO>) => {
          console.log("response : " + res.content[0]);
          this.locations = res.content;
        }
      );

    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  list() {
    if (this.parameters.selectedLocations.length != 0) {
      let i = 0;
      this.parameters.selectedLocationsIds = [];
      this.parameters.selectedLocations.forEach(location => {
        this.parameters.selectedLocations[i] = location;
        this.parameters.selectedLocationsIds[i] = location.id;
        i++;
      });
    }
    let secondSortField : string; 
    if (this.parameters.sortField == "collaboratore") secondSortField = "dataPresenza";
    this.presenzaService.presenzaListByCollaboratoreAndDates(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
      this.parameters.collaboratore,
      this.parameters.selectedLocationsIds,
      this.parameters.dataDa,
      this.parameters.dataA,
      secondSortField).subscribe(
        (res: ResponseQueryByCriteria<PresenzaDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
    
    this.totNumeroDiOre();  
  }

  hideFooterColumns(): boolean {
    let hide: boolean = false;
    this.parameters.totaleOre > 0 ? hide = true : hide = false;
    return hide;

  }

  totPresenzeInCsv() {
    this.presenzaService.download(
      this.parameters.collaboratore,
      this.parameters.selectedLocationsIds,
      this.parameters.dataDa,
      this.parameters.dataA
      ).subscribe(res => {
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(res);
      } else {
        var fileName = (res as any).filename;
        var a = document.createElement("a");
        var file = new Blob([res], {type: 'text/csv' });
        var fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = fileName;
        a.click();
        a.remove();
      }
    })
  }

  totNumeroDiOre() {
    this.presenzaService.totNumeroDiOre(
      this.parameters.collaboratore,
      this.parameters.selectedLocationsIds,
      this.parameters.dataDa,
      this.parameters.dataA).subscribe(
        (res: number) => {
          console.log("response : " + res);
          this.parameters.totaleOre = res;
          this.parameters.giorni = (Math.round((res / 8) * 100) / 100).toFixed(1);
        }
      )
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli torneo</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">
        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Torneo ID" readonly="true" />
        </mat-form-field>

        <!-- Stato field -->
        <mat-form-field>
          <input matInput formControlName="stato" placeholder="Stato Torneo" readonly="true" />
        </mat-form-field>

        <!-- Numero Giocatori field -->
        <mat-form-field fxFlex="55" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput type="number" formControlName="numeroGiocatori" placeholder="Numero Giocatori" />
          <mat-error *ngIf="form.get('numeroGiocatori').errors?.required">
            Campo obbligatorio
          </mat-error>
          <mat-error *ngIf="form.get('numeroGiocatori').errors?.notExponentialOfTwo">
            Deve essere esponente di due
          </mat-error>
        </mat-form-field>

        <!-- Descrizione field -->
        <mat-form-field fxFlex="55" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
          <input matInput formControlName="descrizione" placeholder="Descrizione" />
          <mat-error *ngIf="form.get('descrizione').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button [routerLink]="['/torneo']" color="primary">Back</button>
      <button mat-raised-button (click)="torneoSave()" *ngIf="torneo?.deleteDate==null" [disabled]=" !form.dirty || form.invalid">Save</button>
      <button mat-raised-button (click)="torneoDelete()" [disabled]="torneo?.id==null"
        *ngIf="torneo?.deleteDate==null">Delete</button>
      <button mat-raised-button (click)="iscrizione()" [disabled]="torneo?.id==null || torneo.status != 'ISCRIZIONI_APERTE'">Iscriviti</button>
    </mat-card-actions>

    <div fxLayout="row" fxLayoutAlign="center">
    <h2> {{livelloAttuale}} </h2>
    </div>

    <div *ngIf="parameters.showList" fxLayout="column">
      <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
        [dataSource]="parameters.dataSource">

        <!-- collaboratoreGiocatoreUno Column -->
        <ng-container matColumnDef="collaboratoreGiocatoreUno">
          <th mat-header-cell *matHeaderCellDef> Giocatore Uno </th>
          <td mat-cell *matCellDef="let turno"> 
            <p *ngIf ="turno.collaboratoreGiocatoreUno != null"> {{turno.collaboratoreGiocatoreUno.nome}} {{turno.collaboratoreGiocatoreUno.cognome}}</p>
            <p *ngIf ="turno.collaboratoreGiocatoreUno == null"> In attesa di giocatore <p></td>
        </ng-container>

        <!-- collaboratoreGiocatoreDue Column -->
        <ng-container matColumnDef="collaboratoreGiocatoreDue">
          <th mat-header-cell *matHeaderCellDef> Giocatore Due </th>
          <td mat-cell *matCellDef="let turno" >
            <p *ngIf ="turno.collaboratoreGiocatoreDue != null"> {{turno.collaboratoreGiocatoreDue.nome}} {{turno.collaboratoreGiocatoreDue.cognome}}</p>
            <p *ngIf ="turno.collaboratoreGiocatoreDue == null"> In attesa di giocatore <p>
          </td>
        </ng-container>

        <!-- collaboratoreVincitore Inizio Column -->
        <ng-container matColumnDef="collaboratoreVincitore">
          <th mat-header-cell *matHeaderCellDef> Vincitore </th>
          <td mat-cell *matCellDef="let turno"> 
            <p *ngIf ="turno.collaboratoreVincitore != null"> {{turno.collaboratoreVincitore?.nome}} {{turno.collaboratoreVincitore?.cognome}}</p>
            <p *ngIf ="turno.collaboratoreVincitore == null"> Da Giocare <p>
            </td>
        </ng-container>

        <!-- risultato Column -->
        <ng-container matColumnDef="risultato">
          <th mat-header-cell *matHeaderCellDef> Risultato </th>
          <td mat-cell *matCellDef="let turno"> {{turno.risultato}} </td>
        </ng-container>

        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let turno">
            <button mat-mini-fab color="primary" [routerLink]="['/turno/detail/', turno.id]">
              <mat-icon>search</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-actions>
          <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'turni'})">Excel</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('csv', {fileName:'turni'})">Csv</button>
          <button fxHide fxShow.gt-md mat-raised-button
            (click)="exporter.exportTable('json', {fileName:'turni'})">Json</button>
          <button fxHide fxShow = "true" mat-raised-button (click)="goNextLevel()" [disabled]="0 >= currentProf">
            <mat-icon>arrow_back_ios</mat-icon>
          </button>
          <button fxHide fxShow = "true" mat-raised-button (click)="goPreviusLevel()" [disabled]="currentProf >= maxProf">
            <mat-icon>arrow_forward_ios</mat-icon>
          </button>
        </mat-card-actions>
      </div>
    </div>
  </mat-card-content>
</mat-card>
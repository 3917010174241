import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CorsiUdemyService } from '../services/corsi-udemy/corsi-udemy.service';
import { FrequentaCorsoService } from '../services/corsi-udemy/frequenta-corso.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { NavigatorService } from '../services/navigator.service';
import { CorsiUdemyDTO } from '../shared/dto/corsi-udemy/corsiUdemy';
import { FrequentaCorsoDTO } from '../shared/dto/corsi-udemy/frequentaCorso';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ResponseFail } from '../shared/dto/responseFail';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-frequenta-corso-detail',
  templateUrl: './frequenta-corso-detail.component.html',
  styleUrls: ['./frequenta-corso-detail.component.scss']
})
export class FrequentaCorsoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  frequentaCorsoId: number;
  frequentaCorso: FrequentaCorsoDTO;
  collaboratori: CollaboratoreDTO[];
  corsiUdemy: CorsiUdemyDTO[];
  today: Date;
  minDate: Date;
  maxDate: Date;

  constructor(
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private frequentaCorsoService: FrequentaCorsoService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    private corsoUdemyService: CorsiUdemyService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
  
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true}),
      collaboratore: new FormControl(null, Validators.required),
      corsoUdemy: new FormControl(null, Validators.required),
      dataInizio: new FormControl('', Validators.required),
      dataFine: new FormControl(''),
      descrizione: new FormControl({ value: '', disabled: true}),
      nlezioni: new FormControl({ value: '', disabled: true}),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.today = new Date();
  }

  setMinDate() {
    this.minDate = this.form.get("dataInizio").value;
  }

  setMaxDate() {
    this.maxDate = this.form.get("dataFine").value;
  }

  ngOnInit(): void {
    this.frequentaCorsoId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.frequentaCorsoId != null && this.frequentaCorsoId != 0) {
      this.frequentaCorsoRead();
    } else {
      this.frequentaCorsoInit();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.corsoUdemyService.corsiUdemyList(
      0,
      9999, 
      'ASC',
      'titolo',
      '').subscribe(
        (res: ResponseQueryByCriteria<CorsiUdemyDTO>) => {
          console.log("response : " + res);
          this.corsiUdemy = res.content;
        }
      );
  }

  frequentaCorsoSave() {
    if (this.frequentaCorso.id == null) {
      this.frequentaCorsoCreate();
    } else {
      this.frequentaCorsoUpdate();
    }
  }

  frequentaCorsoCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.frequentaCorsoService.create(this.frequentaCorso).subscribe(
        (res: FrequentaCorsoDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.frequentaCorso = res;
          this.frequentaCorsoId = this.frequentaCorso.id;
          this.dtoToForm();
          this.form.markAsPristine();
        },(err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreFrequentaCorsoException_COLLABORATOREFREQUENTACORSO_ALREADY_EXIST") {
            this.snackBar.open("Stai già frequentando questo corso oppure l'hai già concluso.", null, { duration: 4000 });
          }
        });
    }
  }

  frequentaCorsoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la frequenza al corso?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.frequentaCorsoService.update(this.frequentaCorso).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.form.markAsPristine();
            });
        }
      });
    }
  }

  frequentaCorsoDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la frequenza al corso?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.frequentaCorsoService.delete(this.frequentaCorso.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/frequentacorso"]);
            }
          );
        }
      });
    }
  }

  frequentaCorsoRead() {
    this.form.get("corsoUdemy").disable();
    this.frequentaCorsoService.read(this.frequentaCorsoId).subscribe(
      (res: FrequentaCorsoDTO) => {
        console.log("response : " + res);
        this.frequentaCorso = res;
        this.frequentaCorsoId = res.id;
        this.dtoToForm();
      }
    );
  }

  frequentaCorsoInit() {
    this.frequentaCorso = new FrequentaCorsoDTO();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
  }

  private formToDto(): void {
    this.frequentaCorso.collaboratore = this.form.get("collaboratore").value;
    this.frequentaCorso.corsoUdemy = this.form.get("corsoUdemy").value;
    this.frequentaCorso.dataInizio = this.form.get("dataInizio").value;
    if(this.form.get("dataFine").value != "" && this.form.get("dataFine").value != null){
      this.frequentaCorso.dataFine = this.form.get("dataFine").value;
    }

  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.frequentaCorso.id);
    this.form.get("collaboratore").setValue(this.frequentaCorso.collaboratore);
    this.form.get("corsoUdemy").setValue(this.frequentaCorso.corsoUdemy);
    this.form.get("dataInizio").setValue(this.frequentaCorso.dataInizio);
    this.form.get("dataFine").setValue(this.frequentaCorso.dataFine);
    this.form.get("descrizione").setValue(this.frequentaCorso.corsoUdemy.descrizione);
    this.form.get("nlezioni").setValue(this.frequentaCorso.corsoUdemy.nlezioni);
    this.form.get("deleteDate").setValue(this.frequentaCorso.deleteDate);
    this.form.get("deleteUser").setValue(this.frequentaCorso.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.frequentaCorso.insertDate));
    this.form.get("insertUser").setValue(this.frequentaCorso.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.frequentaCorso.updateDate));
    this.form.get("updateUser").setValue(this.frequentaCorso.updateUser);
    this.form.get("optLock").setValue(this.frequentaCorso.optLock);
    this.minDate = this.frequentaCorso.dataInizio;
    this.maxDate = this.frequentaCorso.dataFine;
    this.form.markAsPristine();
  }
  
  ngOnDestroy(): void {
  }
}

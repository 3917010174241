import { TipoTurnoAmService } from './../services/turno-am/tipo-turno-am.service';
import { TurnoAmService } from './../services/turno-am/turno-am.service';
import { TipoTurnoAMDTO } from './../shared/dto/turno-am/tipo-turno-am';
import { CollaboratoreAMDTO } from 'src/app/shared/dto/turno-am/collaboratore-am';
import { TurnoAMDTO } from './../shared/dto/turno-am/turno-am';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from '../services/navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { DateAdapter } from '@angular/material/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { CollaboratoreAmService } from '../services/turno-am/collaboratore-am.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-turno-am-detail',
  templateUrl: './turno-am-detail.component.html',
  styleUrls: ['./turno-am-detail.component.scss']
})
export class TurnoAmDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  turnoAMId: number;
  turnoAM: TurnoAMDTO;
  collaboratori: CollaboratoreAMDTO[];
  tipiTurno: TipoTurnoAMDTO[];
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string

  constructor(
    private urlService: UrlService,
    private turnoAMService: TurnoAmService,
    private collaboratoreAMService: CollaboratoreAmService,
    private tipoTurnoAMService: TipoTurnoAmService,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    dialog: MatDialog,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl(''),
      collaboratore: new FormControl('', Validators.required),
      tipoTurno: new FormControl('', Validators.required),
      dataTurno: new FormControl('', Validators.required),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
    });

    this.collaboratoreAMService.collaboratoreAMListByList(
      '',
      0,
      9999,
      'ASC',
      'collaboratore.nome'
    ).subscribe(
      (res: ResponseQueryByCriteria<CollaboratoreAMDTO>) => {
        console.log("response : " + res);
        this.collaboratori = res.content;
      }
    );
    this.tipoTurnoAMService.tipoTurnoListByList(
      '',
      '',
      '',
      0,
      9999,
      'ASC',
      'orario'
    ).subscribe(
      (res: ResponseQueryByCriteria<TipoTurnoAMDTO>) => {
        console.log("response : " + res);
        this.tipiTurno = res.content;
      }
    );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  ngOnInit(): void {
    this.turnoAMId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.turnoAMId != 0 && this.turnoAMId != null) {
      this.turnoAMRead();
    } else {
      this.turnoAM = new TurnoAMDTO();
    }
  }

  ngOnDestroy(): void {
  }

  private formToDto(): void {
    this.turnoAM.collaboratoreAM = this.form.get("collaboratore").value;
    this.turnoAM.tipoTurno = this.form.get("tipoTurno").value;
    this.turnoAM.dataTurno = this.form.get("dataTurno").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.turnoAM.id);
    this.form.get("collaboratore").setValue(this.turnoAM.collaboratoreAM);
    this.form.get("tipoTurno").setValue(this.turnoAM.tipoTurno);
    this.form.get("dataTurno").setValue(new Date(this.turnoAM.dataTurno));
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.turnoAM.insertDate));
    this.form.get("insertUser").setValue(this.turnoAM.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.turnoAM.updateDate));
    this.form.get("updateUser").setValue(this.turnoAM.updateUser);
    this.form.markAsPristine();
  }

  turnoAMSaveNew() {
    let oldId = this.turnoAM.id;
    this.turnoAM.id = null;
    this.turnoAMId = null;
    if(!this.turnoAMSave()){
      this.turnoAM.id = oldId;
      this.turnoAMId = oldId;
    }
  }

  turnoAMSave(): boolean {
    if (this.turnoAM.id == null) {
      return this.turnoAMCreate();
    } else {
      return this.turnoAMUpdate();
    }
  }

  turnoAMCreate(): boolean {
    if (this.form.valid) {
      this.formToDto();
      this.turnoAMService.create(this.turnoAM).subscribe(
        (res: TurnoAMDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.turnoAM = res;
          this.turnoAMId = this.turnoAM.id;
          this.dtoToForm();
          return true;
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidTurnoAMException_TURNO_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Esiste già un turno per questo collaboratore in questa data.", null, { duration: 3000 });
          } else {
            this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
          }
        }
      );
    }
    return false;
  }

  turnoAMRead() {
    this.turnoAMService.read(this.turnoAMId).subscribe(
      (res: TurnoAMDTO) => {
        console.log("response : " + res);
        this.turnoAM = res;
        this.turnoAMId = res.id;
        this.dtoToForm();
      }
    );
  }

  turnoAMUpdate(): boolean {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il turno ?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.turnoAMService.update(this.turnoAM).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.turnoAMRead();
              return true;
            }, (err: any) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidTurnoAMException_TURNO_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Esiste già un turno per questo collaboratore in questa data.", null, { duration: 3000 });
              } else {
                this.snackBar.open("Hai superato il limite di caratteri inseribili!", null, { duration: 3000 });
              }
            }
          );
        }
      });
    }
    return false;
  }

  turnoAMDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il turno?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.turnoAMService.delete(this.turnoAM.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/turno-am"]);
            }
          );
        }
      });
    }
  }

  turnoAMSwap(){
    let allTurni = this.turnoAMService.turnoAMListByList(
      this.form.get("collaboratore").value,
      null,
      this.form.get("dataTurno").value,
      this.form.get("dataTurno").value,
      0,
      9999,
      'ASC',
      'id'
    ).subscribe((response: ResponseQueryByCriteria<TurnoAMDTO>) => {
      console.log(response.content);
    });
  }

}

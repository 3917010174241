import { SalettaDTO } from './../../shared/dto/saletta/saletta';
import { HttpClient } from '@angular/common/http';
import { PrenotazioneSalettaDTO } from './../../shared/dto/prenotazioneSaletta/prenotazioneSaletta';
import { GenericService } from './../../shared/GenericService';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { SedeDTO } from 'src/app/shared/dto/sede/sede';

@Injectable({
  providedIn: 'root'
})
export class PrenotazioneSalettaService extends GenericService<PrenotazioneSalettaDTO, number>{

  url: String;

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/prenotazionesaletta';
  }

  public prenotazioneListBySalaAndDates(
    pageNumber: number,
    pageSize: number,
    direction: string,
    sortField: string,
    sede: SedeDTO,
    saletta?: SalettaDTO,
    dataDa?: Date,
    dataA?: Date,
    descrizione?: String) {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (sede != null) params ["idSede"] = sede.id;
    if (saletta != null) params ["salettaId"] = saletta.id;
    if (dataDa != null) params["dataDa"] = dataDa.toISOString();
    if (dataA != null) params["dataA"] = dataA.toISOString();
    if (descrizione != null) params["descrizione"] = descrizione;
    return this.http.get<ResponseQueryByCriteria<PrenotazioneSalettaDTO>>(`${this.url}/prenotazioneListBySalaAndDates`, {
      params: params
    });
  }

  public prenotazioneListBySalaAndMonth(
    sede: SedeDTO,
    saletta?: SalettaDTO,
    dataDa?: Date,
    dataA?: Date,
    descrizione?: String) {
    let params = {};
    if (saletta != null) params ["idSaletta"] = saletta.id;
    if (sede != null) params ["idSede"] = sede.id;
    if (dataDa != null) params["dataDa"] = new Date(dataDa.getTime() - (dataDa.getTimezoneOffset() * 60000)).toISOString();
    if (dataA != null) params["dataA"] = new Date(dataA.getTime() - (dataA.getTimezoneOffset() * 60000)).toISOString();
    if (descrizione != null) params["descrizione"] = descrizione;
    return this.http.get<PrenotazioneSalettaDTO[]>(`${this.url}/prenotazioneListBySalaAndMonth`, {
      params: params
    });
  }
}

import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { UfficioDTO } from '../ufficio/ufficio';

export class PostazioneDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    descrizione: string;
    ufficio: UfficioDTO;
    punto1x: number;
    punto1y: number;
    punto2x: number;
    punto2y: number;
}

<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Copia Turni della settimana</h2>

        <div fxLayout="column" class="responsive-mat-form">
            <form [formGroup]="form" fxLayout="column" class="responsive-mat-form">
                <!-- DataDa field -->
                <mat-form-field>
                    <input matInput readonly="true" (click)="pickerDa.open()" formControlName="dataDa"
                        [matDatepicker]="pickerDa" placeholder="Scegli data da" [matDatepickerFilter]="onlyMonday" [max]="form.get('dataA').value">
                    <mat-datepicker-toggle matSuffix [for]="pickerDa"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDa></mat-datepicker>
                    <button mat-button *ngIf="form.get('dataDa').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="form.get('dataDa').setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('dataDa').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- DataA field -->
                <mat-form-field>
                    <input matInput readonly="true" (click)="pickerA.open()" formControlName="dataA"
                        [matDatepicker]="pickerA" placeholder="Scegli data a" [matDatepickerFilter]="onlyMonday" [min]="form.get('dataDa').value">
                    <mat-datepicker-toggle matSuffix [for]="pickerA"></mat-datepicker-toggle>
                    <mat-datepicker #pickerA></mat-datepicker>
                    <button mat-button *ngIf="form.get('dataA').value" matSuffix mat-icon-button aria-label="Clear" (click)="form.get('dataA').setValue(null)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('dataA').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <mat-error *ngIf="form.errors" class="mat-body-2">
                    <span *ngIf="form.errors?.dateUguali">Le date non possono essere uguali</span>
                    <span *ngIf="form.errors?.giornoInvalido">Hai selezionato un giorno non valido</span>
                </mat-error>

            </form>
        </div>

        <mat-card-actions>
            <button mat-raised-button [routerLink]="['/turno-am/']" color="primary">Back</button>
            <button mat-raised-button (click)="turnoAMCopy()" [disabled]="form.invalid || !form.dirty">Copy</button>
        </mat-card-actions>

    </mat-card-content>
</mat-card>
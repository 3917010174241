<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Sostituisci turno reperibilità</h2>
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column" *ngIf="this.turni?.length>0">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Turno Id" readonly="true" />
          </mat-form-field>

          <!-- Collaboratore field -->
          <mat-form-field>
            <mat-label>Collaboratore</mat-label>
              <input matInput formControlName="collaboratore" placeholder="Collaboratore" readonly="true"/>
            </mat-form-field>
     

          <!-- numeroReperibilita field -->
          <mat-form-field>
              <input matInput formControlName="numeroReperibilita" placeholder="Numero Reperibilità" readonly="true"/>
            </mat-form-field>
          <!-- Turno field -->
          <mat-form-field>
            <input matInput formControlName="turno" placeholder="Turno" readonly="true" />
          </mat-form-field>

       
          <mat-form-field>
            <mat-label>Scegli turni</mat-label>
            <mat-select formControlName="turni" multiple [disabled]="!isJappTenantAdmin()">
              <mat-option *ngFor="let turnoSost of turni" [value]="turnoSost.id">
                  {{ translate(turnoSost.giorno) }} {{orari}} : {{turnoSost.collaboratore.nome}} {{turnoSost.collaboratore.cognome}}
              </mat-option>
            </mat-select>
          </mat-form-field>


        
        </form>
       <mat-card *ngIf="this.turni?.length==0">
        <h3>
          Nessun Giorno su cui sostituire il collaboratore
        </h3>
       </mat-card> 
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/turni-reperibilita')" color="primary">Back</button>
        <button mat-raised-button (click)="turnoSostituzioneMassiva()" [disabled]="form.invalid || !form.dirty"
          *ngIf="turno?.deleteDate==null">Copia</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card> 
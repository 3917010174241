import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ListaAttesaPrenotazionePostazioneService } from '../services/lista-attesa-prenotazionepostazione/lista-attesa-prenotazione-postazione-service.service';
import { NavigatorService } from '../services/navigator.service';
import { SedeService } from '../services/sede/sede.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { ListaAttesaPrenotazionePostazioneDTO } from '../shared/dto/lista-attesa-prenotazionepostazione/listaattesaprenotazionepostazione';
import { ResponseFail } from '../shared/dto/responseFail';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { SedeDTO } from '../shared/dto/sede/sede';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';

@Component({
  selector: 'app-listaattesaprenotazionepostazione-detail',
  templateUrl: './listaattesaprenotazionepostazione-detail.component.html',
  styleUrls: ['./listaattesaprenotazionepostazione-detail.component.scss']
})
export class ListaAttesaPrenotazionepostazioneDetailComponent extends GenericDetailComponent implements OnInit {
  
  listaattesaprenotazionepostazioneId: number;
  listaattesaprenotazionepostazione: ListaAttesaPrenotazionePostazioneDTO;
  collaboratori: CollaboratoreDTO[];
  data: Date;
  sedi: SedeDTO[];
  sede: SedeDTO;
  bloccaModifica: boolean;

  constructor(
    private listaattesaprenotazionepostazioneService: ListaAttesaPrenotazionePostazioneService,
    private collaboratoreService: CollaboratoreService,
    private sedeServices: SedeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      data: new FormControl('', Validators.required),
      richiedente: new FormControl('', Validators.required),
      sede: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.listaattesaprenotazionepostazioneId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.listaattesaprenotazionepostazioneId != 0 && this.listaattesaprenotazionepostazioneId != null) {
      this.listaattesaprenotazionepostazioneRead();
    } else {
      this.listaattesaprenotazionepostazione = new ListaAttesaPrenotazionePostazioneDTO();
    }
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("richiedente").setValue(res);
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );

    this.sedeServices.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<SedeDTO>) => {
        console.log("response : " + res);
        this.sedi = res.content;
      });
  }

  listaattesaprenotazionepostazioneSave() {
    if (this.listaattesaprenotazionepostazioneId == null || this.listaattesaprenotazionepostazioneId == 0) {
      this.listaattesaprenotazionepostazioneCreate();
    } else {
      this.listaattesaprenotazionepostazioneUpdate();
    }
  }

  listaattesaprenotazionepostazioneUpdate() {

    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la lista di attesa?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.listaattesaprenotazionepostazioneService.update(this.listaattesaprenotazionepostazione).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.listaattesaprenotazionepostazioneRead();
              this.form.markAsPristine();
              this.backConfirm('/listaattesaprenotazionepostazione');
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidListaAttesaPrenotazionePostazioneException_LISTAATTESAPRENOTAZIONEPOSTAZIONE_PASTPRENOTAZIONE") {
                this.snackBar.open("Attenzione! Impossibile mettersi in lista per una data passata.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidListaAttesaPrenotazionePostazioneException_LISTAATTESAPRENOTAZIONEPOSTAZIONE_ALREADY_EXIST") {
                this.snackBar.open("Attenzione! Hai già una lista d'attesa attiva per il giorno indicato.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidListaAttesaPrenotazionePostazioneException_LISTAATTESAPRENOTAZIONEPOSTAZIONE_SAME_UPDATE") {
                this.snackBar.open("Attenzione! Stai cercando di fare un update identico alla lista già esistente.", null, { duration: 3000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidListaAttesaPrenotazionePostazioneException_LISTAATTESAPRENOTAZIONEPOSTAZIONE_POSTAZIONI_DISPONIBILI") {
                this.snackBar.open("Attenzione! Sono presenti delle postazioni libere per il giorno indicato.", null, {duration: 3000})
              }
            }
          );
        }
      });
    }
  }

  listaattesaprenotazionepostazioneCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.listaattesaprenotazionepostazioneService.create(this.listaattesaprenotazionepostazione).subscribe(
        (res: ListaAttesaPrenotazionePostazioneDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.listaattesaprenotazionepostazione = res;
          console.log(res);
          this.listaattesaprenotazionepostazioneId = this.listaattesaprenotazionepostazione.id;
          this.dtoToForm();
          this.form.markAsPristine();
          this.backConfirm('/listaattesaprenotazionepostazione');
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidListaAttesaPrenotazionePostazioneException_LISTAATTESAPRENOTAZIONEPOSTAZIONE_PASTPRENOTAZIONE") {
            this.snackBar.open("Attenzione! Impossibile mettersi in lista per una data passata.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidListaAttesaPrenotazionePostazioneException_LISTAATTESAPRENOTAZIONEPOSTAZIONE_ALREADY_EXIST") {
            this.snackBar.open("Attenzione! Hai già una lista d'attesa attiva per il giorno indicato.", null, { duration: 3000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidListaAttesaPrenotazionePostazioneException_LISTAATTESAPRENOTAZIONEPOSTAZIONE_POSTAZIONI_DISPONIBILI") {
            this.snackBar.open("Attenzione! Sono presenti delle postazioni libere per il giorno indicato.", null, {duration: 3000})
          }
        }
      );
    }
  }

  listaattesaprenotazionepostazioneDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la lista d'attesa?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.listaattesaprenotazionepostazioneService.delete(this.listaattesaprenotazionepostazione.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/listaattesaprenotazionepostazione"]);
            }
          );
        }
      });
    }
  }

  listaattesaprenotazionepostazioneRead() {
    this.listaattesaprenotazionepostazioneService.read(this.listaattesaprenotazionepostazioneId).subscribe(
      (res: ListaAttesaPrenotazionePostazioneDTO) => {
        console.log("response : " + res);
        this.listaattesaprenotazionepostazione = res;
        this.listaattesaprenotazionepostazioneId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.listaattesaprenotazionepostazione.sede = this.form.get("sede").value;
    this.listaattesaprenotazionepostazione.data = this.form.get("data").value;
    this.listaattesaprenotazionepostazione.richiedente = this.form.get("richiedente").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.listaattesaprenotazionepostazione.id);
    this.form.get("sede").setValue(this.listaattesaprenotazionepostazione.sede);
    this.form.get("data").setValue(this.listaattesaprenotazionepostazione.data);
    this.form.get("richiedente").setValue(this.listaattesaprenotazionepostazione.richiedente);
    this.form.get("deleteDate").setValue(this.listaattesaprenotazionepostazione.deleteDate);
    this.form.get("deleteUser").setValue(this.listaattesaprenotazionepostazione.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.listaattesaprenotazionepostazione.insertDate));
    this.form.get("insertUser").setValue(this.listaattesaprenotazionepostazione.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.listaattesaprenotazionepostazione.updateDate));
    this.form.get("updateUser").setValue(this.listaattesaprenotazionepostazione.updateUser);
    this.form.get("optLock").setValue(this.listaattesaprenotazionepostazione.optLock);
    this.form.markAsPristine();
  }
}
import { CalendarView } from 'angular-calendar';
import { Input, EventEmitter, Output, Component } from '@angular/core';


@Component({
    selector: 'mwl-utils-calendar-header',
    templateUrl: './calendar-header.html',
    styleUrls: ['./calendar-header-style.scss']

})
export class CalendarHeaderComponent { 
    @Input() view: CalendarView;
  
    @Input() viewDate: Date;
  
    @Input() locale: string = 'it';
  
    @Input() daysInWeek: number;

    @Output() viewChange = new EventEmitter<CalendarView>();
  
    @Output() viewDateChange = new EventEmitter<Date>();

    @Input() weekStartsOn;

    @Input() calendarioPresenze = true;
 
    CalendarView = CalendarView;

  }
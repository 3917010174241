import { ChiaviDTO } from './../shared/dto/gestioneChiavi/chiavi';
import { StoriaChiaviService } from './../services/gestione-chiavi/storia-chiavi.service';
import { ChiaviService } from './../services/gestione-chiavi/chiavi.service';
import { StoriaChiaviDTO } from './../shared/dto/gestioneChiavi/storiaChiavi';
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from './../services/navigator.service';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';


@Component({
  selector: 'app-prendi-chiavi',
  templateUrl: './prendiChiavi.component.html',
  styleUrls: ['./prendiChiavi.component.scss']
})

export class PrendiChiaviComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  parameters: any;
  storiaChiavi: StoriaChiaviDTO;
  storiaChiaviId: number;
  chiavi: ChiaviDTO;

  constructor(
    private chiaviService: ChiaviService,
    private storiaChiaviService: StoriaChiaviService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      chiavi: new FormControl({ value: '', disabled: true }),
      dataDa: new FormControl('', Validators.required),
      dataA: new FormControl(''),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.parameters = {
      dataSource: [],
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  storiaChiaviCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.storiaChiaviService.create(this.storiaChiavi)
        .subscribe(
          (res: StoriaChiaviDTO) => {
            console.log("response : " + res);
            this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
            this.storiaChiavi = res;
            this.storiaChiaviId = this.storiaChiavi.id;
            this.dtoToForm();
            this.form.markAsPristine();
            this.router.navigate(["/gestione-chiavi"]);
          }, (err: any) => {
            
          }
        );
    }
  }

  storiaChiaviRead() {
    this.storiaChiaviService.read(this.storiaChiaviId).subscribe(
      (res: StoriaChiaviDTO) => {
        console.log("response : " + res);
        this.storiaChiavi = res;
        this.storiaChiaviId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.storiaChiavi.dataA = this.form.get("dataA").value;
    this.storiaChiavi.dataDa = this.form.get("dataDa").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.storiaChiavi.id);
    this.form.get("deleteDate").setValue(this.storiaChiavi.deleteDate);
    this.form.get("deleteUser").setValue(this.storiaChiavi.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.storiaChiavi.insertDate));
    this.form.get("insertUser").setValue(this.storiaChiavi.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.storiaChiavi.updateDate));
    this.form.get("updateUser").setValue(this.storiaChiavi.updateUser);
    this.form.get("optLock").setValue(this.storiaChiavi.optLock);
    this.form.markAsPristine();
  }


  ngOnInit(): void {

    if (this.storiaChiaviId != null && this.storiaChiaviId != 0) {
      this.storiaChiaviRead();
      this.storiaChiaviId = Number(this.activeRoute.snapshot.paramMap.get("idStoria"));
    
    } else {
      this.storiaChiavi = new StoriaChiaviDTO();
    }

    let chiaviId = Number(this.activeRoute.snapshot.paramMap.get("idChiavi"));
    this.chiaviService.chiaviById(chiaviId)
      .subscribe((res: ChiaviDTO) => {
        console.log("chiavi:  ", res);
        this.storiaChiavi.chiavi = res;
        this.form.get("chiavi").setValue(res.descrizione);
    });

    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        console.log("collaboratore: ", res);
        this.storiaChiavi.collaboratore = res;
      }
    );


  }

  ngOnDestroy(): void {
  }
}
import { PostazioneDTO } from './../shared/dto/postazione/postazione';
import { UfficioService } from './../services/ufficio/ufficio.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { NavigatorService } from '../services/navigator.service';
import { PostazioneService } from '../services/postazione/postazione.service';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { UfficioDTO } from '../shared/dto/ufficio/ufficio';

@Component({
  selector: 'app-postazione',
  templateUrl: './postazione-detail.component.html',
  styleUrls: ['./postazione-detail.component.scss']
})
export class PostazioneDetailComponent extends GenericDetailComponent implements OnInit {

  postazioneId: number;
  postazione: PostazioneDTO;
  uffici: UfficioDTO[];

  constructor(
    private postazioneService: PostazioneService,
    private UfficioService: UfficioService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      descrizione: new FormControl('', Validators.required),
      x1: new FormControl(0),
      y1: new FormControl(0),
      x2: new FormControl(0),
      y2: new FormControl(0),
      ufficio: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.postazioneId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.postazioneId != null && this.postazioneId != 0) {
      this.postazioneRead();
    } else {
      this.postazione = new PostazioneDTO();
    }

    this.UfficioService.ufficioListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<UfficioDTO>) => {
        this.uffici = res.content;
      }
    );
  }

  postazioneSave() {
    if (this.postazione.id == null) {
      this.postazioneCreate();
    } else {
      this.postazioneUpdate();
    }
  }

  postazioneCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.postazioneService.create(this.postazione).subscribe(
        (res: PostazioneDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.postazione = res;
          console.log(res);
          this.postazioneId = this.postazione.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }
      );
    }
  }

  postazioneUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere la postazione?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.postazioneService.update(this.postazione).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.postazioneRead();
              this.form.markAsPristine();
            }
          );
        }
      });
    }
  }

  postazioneDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la postazione?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.postazioneService.delete(this.postazione.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/postazione"]);
            }
          );
        }
      });
    }
  }

  postazioneRead() {
    this.postazioneService.read(this.postazioneId).subscribe(
      (res: PostazioneDTO) => {
        console.log("response : " + res);
        this.postazione = res;
        this.postazioneId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.postazione.punto1x=this.form.get("x1").value;
    this.postazione.punto1y=this.form.get("y1").value;
    this.postazione.punto2x=this.form.get("x2").value;
    this.postazione.punto2y=this.form.get("y2").value;
    this.postazione.descrizione = this.form.get("descrizione").value;
    this.postazione.ufficio = this.form.get("ufficio").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.postazione.id);
    this.form.get("descrizione").setValue(this.postazione.descrizione);
    this.form.get("x1").setValue(this.postazione.punto1x);
    this.form.get("y1").setValue(this.postazione.punto1y);
    this.form.get("x2").setValue(this.postazione.punto2x);
    this.form.get("y2").setValue(this.postazione.punto2y);
    this.form.get("ufficio").setValue(this.postazione.ufficio);
    this.form.get("deleteDate").setValue(this.postazione.deleteDate);
    this.form.get("deleteUser").setValue(this.postazione.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.postazione.insertDate));
    this.form.get("insertUser").setValue(this.postazione.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.postazione.updateDate));
    this.form.get("updateUser").setValue(this.postazione.updateUser);
    this.form.get("optLock").setValue(this.postazione.optLock);
    this.form.markAsPristine();
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { OrarioLavorativoService } from '../services/orario-lavorativo/orario-lavorativo.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { NavigatorService } from '../services/navigator.service';
import { OrarioLavorativoDTO } from '../shared/dto/orario-lavorativo/orario-lavorativo';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-orario-lavorativo-detail',
  templateUrl: './orario-lavorativo-detail.component.html',
  styleUrls: ['./orario-lavorativo-detail.component.scss']
})
export class OrarioLavorativoDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy{

  orarioLavorativoId: number;
  orarioLavorativo: OrarioLavorativoDTO;
  collaboratori: CollaboratoreDTO[]=[];
  giorni= this.orarioLavorativoService.giorni;

  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;

  constructor(
    private urlService: UrlService,
    private orarioLavorativoService: OrarioLavorativoService,
    private collaboratoreService: CollaboratoreService,
    dialog: MatDialog,
    router: Router,
    dateAdapter: DateAdapter<Date>,
    activeRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    navigatorService: NavigatorService,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe
    );

    this.form= new FormGroup({
      id: new FormControl({value: '', disabled:true}),
      collaboratore: new FormControl('', Validators.required),
      giorno: new FormControl('',Validators.required),
      daOre: new FormControl('', [Validators.required]),
      aOre: new FormControl('', [Validators.required]),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }


  ngOnInit(): void {
    this.orarioLavorativoId= Number(this.activeRoute.snapshot.paramMap.get("id"));

    if(this.orarioLavorativoId!=null && this.orarioLavorativoId!=0){
      this.orarioLavorativoRead();
    }
    else{
      this.orarioLavorativo = new OrarioLavorativoDTO();
    }

    this.collaboratoreService.collaboratoreNoAmList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;
        }
      );

      this.urlService.previousUrl$.subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl;
      });
  }
  
  translateDayToIta(giornotradotto:string){
    return this.orarioLavorativoService.translateDayToIta(giornotradotto);
  }

  orarioLavorativoRead() {
    
    this.orarioLavorativoService.read(this.orarioLavorativoId).subscribe(
      (res: OrarioLavorativoDTO) => {
        this.orarioLavorativo = res;
        this.orarioLavorativoId = res.id;
        this.dtoToForm();
      }
    );
  }

  orarioLavorativoSave(){
    if(this.orarioLavorativo.id == null){
      this.orarioLavorativoCreate();
    }
    else{
    this.orarioLavorativoUpdate();
    }
  }


  orarioLavorativoCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.orarioLavorativoService.create(this.orarioLavorativo).subscribe(
        (res: OrarioLavorativoDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.orarioLavorativo = res;
          this.orarioLavorativoId = this.orarioLavorativo.id;
          this.dtoToForm();
          this.form.markAsPristine();
          this.router.navigate(["/orario-lavorativo"]);
        }, (err: HttpErrorResponse) => {
          console.log(err);
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidOrarioLavorativoException_ORARIO_LAVORATIVO_ALREADY_EXIST")  {
            this.snackBar.open("Attenzione! L'orario lavorativo per questo collaboratore e per questo giorno è già esistente ", null, { duration: 4500 });
          }
        } 
      );
    }
  }

  orarioLavorativoUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere l'orario lavorativo ?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.orarioLavorativoService.update(this.orarioLavorativo).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.orarioLavorativoRead();
              this.form.markAsPristine();
              this.back(this.previousUrl);
            }, (err: HttpErrorResponse) => {
              console.log(err);
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidOrarioLavorativoException_ORARIO_LAVORATIVO_ALREADY_EXIST")  {
                this.snackBar.open("Attenzione! L'orario lavorativo per questo collaboratore e per questo giorno è già esistente ", null, { duration: 4500 });
              }
            if (err.status == 500 && (<ResponseFail>err.error).reasonBundleKey == "generic_error") {
                this.snackBar.open("Attenzione: valori dei dati errati (probabilmente è già stato inserito un orario lavorativo per questo giorno e questo collaboratore).", null, { duration: 4000 });
              }
          });
        }
      });
    }
  }
  
  orarioLavorativoDelete(){
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare l'orario lavorativo?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.orarioLavorativoService.delete(this.orarioLavorativo.id).subscribe(
            (res) => {
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/orario-lavorativo"]);
            }
          );
        }
      });
    }
  }

  private dtoToForm(): void{
    this.form.get("id").setValue(this.orarioLavorativo?.id);
    this.form.get("collaboratore").setValue(this.orarioLavorativo?.collaboratore);
    this.form.get("giorno").setValue(this.orarioLavorativo?.giorno);
    this.form.get("deleteDate").setValue(this.orarioLavorativo?.deleteDate);
    this.form.get("deleteUser").setValue(this.orarioLavorativo?.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.orarioLavorativo?.insertDate));
    this.form.get("insertUser").setValue(this.orarioLavorativo?.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.orarioLavorativo?.updateDate));
    this.form.get("updateUser").setValue(this.orarioLavorativo?.updateUser);
    this.form.get("optLock").setValue(this.orarioLavorativo?.optLock);

    if (this.orarioLavorativo?.dalleOre != null) {
      this.form.get("daOre").setValue(`${this.orarioLavorativo?.dalleOre}:00`);
    } else {
      this.form.get("daOre").setValue(`0:00`);
    }
    if (this.orarioLavorativo?.alleOre != null) {
      this.form.get("aOre").setValue(`${this.orarioLavorativo?.alleOre}:00`);
    } else {
      this.form.get("aOre").setValue(`0:00`);
    }
  }


  private formToDto(): void{
    this.orarioLavorativo.collaboratore=this.form.get("collaboratore").value;
    this.orarioLavorativo.giorno=this.form.get("giorno").value;
    
    if (this.form.get("daOre").value != null) {
      this.orarioLavorativo.dalleOre = this.formatToNumberStartTimePermit(this.form.get("daOre").value);
    } else {
      this.orarioLavorativo.dalleOre= this.formatToNumberStartTimePermit("0:00");
    }

    if (this.form.get("aOre").value != null) {
      this.orarioLavorativo.alleOre = this.formatToNumberStartTimePermit(this.form.get("aOre").value);
    } else {
      this.orarioLavorativo.alleOre= this.formatToNumberStartTimePermit("0:00");
    }
  }

  private formatToNumberStartTimePermit(str): number{
    var splitted = str.split(":");
    return parseInt(splitted[0]);
}

  backConfirm(url: String) {
    const M: string = "Proseguendo, tutte le modifiche andranno perse. Sei sicuro di voler tornare alla pagina precedente?";
    if (this.form.dirty) {
        this.confirm(M).subscribe(result => {
            if (result) {
                this.back(url);
            }
        });
    }
    else {
        this.back(url);
    }
  }

  isJappTenantAdmin() : boolean {
    return this.navigatorService.isJappTenantAdmin();
  }

  ngOnDestroy(): void {
  }

}

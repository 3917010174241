import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarMonthViewDay, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import { DateAdapter } from '@angular/material/core';
import { CustomDateFormatter } from '../commons/calendar/custom-calendar-date-formater';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { isSameDay, isSameMonth } from 'date-fns';
import { ComponentCacheService } from '../services/component-cache.service';
import { ListaAttesaPrenotazionePostazioneService } from '../services/lista-attesa-prenotazionepostazione/lista-attesa-prenotazione-postazione-service.service';
import { NavigatorService } from '../services/navigator.service';
import { ListaAttesaPrenotazionePostazioneDTO } from '../shared/dto/lista-attesa-prenotazionepostazione/listaattesaprenotazionepostazione';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { GenericListComponent } from '../shared/GenericListCoumponent';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { SedeDTO } from '../shared/dto/sede/sede';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { SedeService } from '../services/sede/sede.service';
import { MatTabChangeEvent } from '@angular/material/tabs';


@Component({
  selector: 'app-listaattesaprenotazionepostazione',
  templateUrl: './listaattesaprenotazionepostazione.component.html',
  styleUrls: ['./listaattesaprenotazionepostazione.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
})
export class ListaAttesaPrenotazionepostazioneComponent extends GenericListComponent implements OnInit, OnDestroy {
  
  collaboratori: CollaboratoreDTO[];
  collaboratore: CollaboratoreDTO;
  sedi: SedeDTO[];
  sede: SedeDTO;
  collaboratoreAttuale: CollaboratoreDTO;
  vistaData: boolean = false;

  //CALENDARIO 
  view: CalendarView = CalendarView.Month;
  weekView: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  events: CalendarEvent<{ listaattesaprenotazionepostazione: ListaAttesaPrenotazionePostazioneDTO }>[];
  activeDayIsOpen: boolean = false;
  selectedMonthViewDay: CalendarMonthViewDay;
  selectedDayViewDate: Date;
  selectedDays: any = [];
  @Input() locale: string = 'it';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() viewDateChange = new EventEmitter<Date>();
  CalendarView = CalendarView;

  constructor(
    private listaattesaprenotazionePostazioneService: ListaAttesaPrenotazionePostazioneService,
    private collaboratoreService: CollaboratoreService,
    private sedeService: SedeService,
    private cd: ChangeDetectorRef,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'dataDa', 'dataA', 'richiedente', 'detail'];
    this.parameters = {
      dataSource: [],
      initDataDa: true,
      collaboratoreChanged: false,
      view: CalendarView.Month,
      viewDate: new Date(),
      dataDaCal: null,
      dataACal: null,
      showList: false,
      filter: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  estrazionePeriodoVista(event: CalendarMonthViewBeforeRenderEvent) {
    if ((this.parameters.dataDaCal == null || event.period.start.getTime() != this.parameters.dataDaCal.getTime())
      || (this.parameters.dataACal == null || event.period.end.getTime() != this.parameters.dataACal.getTime())) {
      this.popolaCalendario(event.period.start, event.period.end);
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    event.index != 0 ? this.vistaData = true : this.vistaData = false;
  }

  popolaCalendario(dataDa: Date, dataA: Date) {
    this.listaattesaprenotazionePostazioneService.listaattesaprenotazionePostazioneList(
      this.parameters.pageNumber,
      9999,
      this.parameters.sortDirection,
      this.parameters.sortField,
      null,
      dataDa,
      dataA,
      this.parameters.sede,
    ).subscribe((response: ResponseQueryByCriteria<ListaAttesaPrenotazionePostazioneDTO>) => {
      this.parameters.dataDaCal = dataDa;
      this.parameters.dataACal = dataA;
      this.events = response.content.map((prenotazione: ListaAttesaPrenotazionePostazioneDTO) => {
        if (this.weekView == this.parameters.view) {
          return {
            title: prenotazione.richiedente.nome + " " + prenotazione.richiedente.cognome,
            start: new Date(
              prenotazione.data
            ),
            end: new Date(
              prenotazione.data
            ),
            allDay: true,
            color: {
              primary: '#e3bc08',
              secondary: '#FDF1BA',
            },
            meta: {
              prenotazione,
            },
          } as CalendarEvent;
        }
        return {
          title: prenotazione.richiedente.nome + " " + prenotazione.richiedente.cognome,
          start: new Date(
            prenotazione.data
          ),
          end: new Date(
            prenotazione.data
          ),
          allDay: true,
          color: {
            primary: '#e3bc08',
            secondary: '#FDF1BA',
          },
          meta: {
            prenotazione,
          },
        } as CalendarEvent;
      })
    }
    )
  }

  popolaCalendarioOnSedeChange() {
    this.listaattesaprenotazionePostazioneService.listaattesaprenotazionePostazioneList(
      this.parameters.pageNumber,
      9999,
      this.parameters.sortDirection,
      this.parameters.sortField,
      null,
      null,
      null,
      this.parameters.sede,
    ).subscribe((response: ResponseQueryByCriteria<ListaAttesaPrenotazionePostazioneDTO>) => {
      this.events = response.content.map((prenotazione: ListaAttesaPrenotazionePostazioneDTO) => {
        return {
          title: prenotazione.richiedente.nome + " " + prenotazione.richiedente.cognome ,
          start: new Date(
            prenotazione.data
          ),
          end: new Date(
            prenotazione.data
          ),
          allDay: true,
          color: {
            primary: '#e3bc08',
            secondary: '#FDF1BA',
          },
          meta: {
            prenotazione,
          },
        } as CalendarEvent;
      })
    }
    )
  }


  list() {
    this.listaattesaprenotazionePostazioneService.listaattesaprenotazionePostazioneList(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.richiedente,
      this.parameters.dataDa,
      this.parameters.dataA,
      this.parameters.sede
    ).subscribe((response: ResponseQueryByCriteria<ListaAttesaPrenotazionePostazioneDTO>) => {
      console.log("response : " + response);
      this.parameters.dataSource = response.content;
      this.parameters.pageNumber = response.pageNumber;
      this.parameters.length = response.totalElements;
      this.parameters.showList = true;
    }
    )
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.parameters.collaboratoreChanged) {
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.collaboratore = res;
          this.parameters.richiedente = this.collaboratore;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );

    this.sedeService.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      '').subscribe(
        (res: ResponseQueryByCriteria<SedeDTO>) => {
          console.log("response : " + res);
          this.sedi = res.content;
        }
      );

    if (this.parameters.dataDaCal != null && this.parameters.dataACal != null )
      this.popolaCalendario(this.parameters.dataDaCal, this.parameters.dataACal);

    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.collaboratoreAttuale = res;
      }
    );

    if (this.parameters.dataDa == null && this.parameters.initDataDa) {
      this.parameters.dataDa = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }
  }

  setViewDate() {
    this.parameters.viewDate = this.parameters.dataDa;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {

    body.forEach((day) => {
      if (
        day.events.some( (_event) => _event.meta.prenotazione?.richiedente.id == this.collaboratoreAttuale.id )
      ) {
        day.cssClass = "listaattesaprenotazionepostazione-prenotata";
      }
    });
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ prenotazione: ListaAttesaPrenotazionePostazioneDTO }>[];
  }): void {
    if (isSameMonth(date, this.parameters.viewDate)) {
      if (
        (isSameDay(this.parameters.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.parameters.viewDate = date;
      }
    }
  }

  eventClicked(event: CalendarEvent<{ prenotazione: ListaAttesaPrenotazionePostazioneDTO }>): void {
    if (this.collaboratoreAttuale.id == event.meta.prenotazione.richiedente.id || this.navigatorService.isJappTenantAdmin()) {
      this.router.navigate(["/listaattesaprenotazionepostazione/detail/" + event.meta.prenotazione.id]);
    }
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
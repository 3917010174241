<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
        <h2>Dettagli dispositivo</h2>
        <div fxLayout="column" class="responsive-mat-form">
            <form [formGroup]="form" fxLayout="column">
                 <!-- Id field -->
                <mat-form-field>
                    <input matInput formControlName="id" placeholder="Dispositivo ID" readonly="true" />
                </mat-form-field>
            

                <!-- Collaboratore field -->
                <mat-form-field fxLayout="column">
                    <mat-label>Collaboratori</mat-label>
                    <mat-select formControlName="collaboratore" [compareWith]="compareDTO"
                        [disabled]="!isJappTenantAdmin()">
                        <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
                            {{collaboratore.nome}} {{collaboratore.cognome}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('collaboratore').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>

                <!-- Codice field -->
                <mat-form-field fxlayout="column">
                    <input matInput formControlName="codice" [disabled]="!isJappTenantAdmin()" placeholder="Codice"/>
                    <mat-error *ngIf="form.get('codice').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field> 
                

                <!-- Descrizione field -->
                <mat-form-field fxlayout="column">
                    <input matInput formControlName="descrizione" [disabled]="!isJappTenantAdmin()" placeholder="Descrizione"/>
                    <mat-error *ngIf="form.get('descrizione').errors?.required">
                        Campo obbligatorio
                    </mat-error>
                </mat-form-field>  

            </form>
        </div>

        <mat-card-actions>
            <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
            <button mat-raised-button (click)="dispositivoSave()" [disabled]="form.invalid || !form.dirty"
              *ngIf="dispositivo?.deleteDate==null">Save</button>
            <button mat-raised-button (click)="dispositivoDelete()" [disabled]="dispositivo?.id==null"
              *ngIf="dispositivo?.deleteDate==null">Delete</button>
          </mat-card-actions>
    </mat-card-content>
</mat-card>
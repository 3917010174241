import { PropostaDTO } from 'src/app/shared/dto/proposte/proposta';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { GenericService } from 'src/app/shared/GenericService';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropostaService extends GenericService<PropostaDTO, number>{

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + "/services/rest/json/proposta";
   }

   public propostaListByList(pageNumber: number, pageSize: number, direction: string, sortField: string, giornoCorrente: Date): Observable<ResponseQueryByCriteria<PropostaDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (giornoCorrente != null) params["giornoCorrente"] = giornoCorrente.toISOString();
    return this.http.get<ResponseQueryByCriteria<PropostaDTO>>(`${this.url}/propostaListByList`, {
      params: params
    });
  }
  
}

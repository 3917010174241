import { NavigatorService } from './../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { SedeDTO } from '../shared/dto/sede/sede';
import { SedeService } from '../services/sede/sede.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-collaboratore',
  templateUrl: './collaboratoreDetail.component.html',
  styleUrls: ['./collaboratoreDetail.component.scss']
})

export class CollaboratoreDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  collaboratoreId: number;
  collaboratore: CollaboratoreDTO;
  sedi: SedeDTO[];

  constructor(
    private collaboratoreService: CollaboratoreService,
    private sedeServices: SedeService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      email: new FormControl('', Validators.required),
      userName: new FormControl('', Validators.required),
      codiceCollaboratore: new FormControl('', Validators.required),
      nome: new FormControl('', Validators.required),
      cognome: new FormControl('', Validators.required),
      telefonoReperibilita: new FormControl(''),
      sede: new FormControl(''),
      ruolo: new FormControl(''),
      flagDimissioni: new FormControl(''),
      compleannoPersonale: new FormControl(''),
      checkboxCompleanno: new FormControl(''),
      jeniaCompleanno: new FormControl(''),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit() {
    this.collaboratoreId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.collaboratoreId != null && this.collaboratoreId != 0) {
      this.collaboratoreRead();
    } else {
      this.collaboratore = new CollaboratoreDTO();
    }
    this.sedeServices.sedeListByDescrizione(
      0,
      9999,
      'ASC',
      'descrizione',
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<SedeDTO>) => {
        console.log("response : " + res);
        this.sedi = res.content;
      });
  }

  collaboratoreSave() {

    if (this.collaboratore.id == null) {
      this.collaboratoreCreate();
    } else {
      this.collaboratoreUpdate();
    }
  }

  collaboratoreCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.collaboratoreService.create(this.collaboratore).subscribe({
        next: (res: CollaboratoreDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.collaboratore = res;
          this.collaboratoreId = this.collaboratore.id;
          this.dtoToForm();
          this.form.markAsPristine();
        },
        error: (error: HttpErrorResponse) => {
          if (error.status == 400 && (<ResponseFail>error.error).reasonBundleKey == "InvalidCollaboratoreException_COLLABORATORE_CODICE_EXIST") {
            this.snackBar.open("Attenzione! Esiste un collaboratore con il codice indicato.", null, { duration: 4000 });
          }
        }
      }
      );
    }
  }

  collaboratoreUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il collaboratore?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.collaboratoreService.update(this.collaboratore).subscribe({
            next: (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.collaboratoreRead();
              this.form.markAsPristine();
            },
            error: (error: HttpErrorResponse) => {
              if (error.status == 400 && (<ResponseFail>error.error).reasonBundleKey == "InvalidCollaboratoreException_COLLABORATORE_CODICE_EXIST") {
                this.snackBar.open("Attenzione! Esiste un collaboratore con il codice indicato.", null, { duration: 4000 });
              }
            }
          }
          );
        }
      });
    }
  }

  collaboratoreDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il collaboratore?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.collaboratoreService.delete(this.collaboratore.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/collaboratore"]);
            }
          );
        }
      });
    }
  }

  collaboratoreRead() {
    this.collaboratoreService.read(this.collaboratoreId).subscribe(
      (res: CollaboratoreDTO) => {
        console.log("response : " + res);
        this.collaboratore = res;
        this.collaboratoreId = res.id;
        this.dtoToForm();
      }
    );
  }

  private formToDto(): void {
    this.collaboratore.email = this.form.get("email").value;
    this.collaboratore.userName = this.form.get("userName").value;
    this.collaboratore.codiceCollaboratore = this.form.get("codiceCollaboratore").value;
    this.collaboratore.nome = this.form.get("nome").value;
    this.collaboratore.cognome = this.form.get("cognome").value;
    this.collaboratore.telefonoReperibilita = this.form.get("telefonoReperibilita").value;
    this.collaboratore.sede = this.form.get("sede").value;
    this.collaboratore.ruolo = this.form.get("ruolo").value;
    this.collaboratore.flagDimissioni = this.form.get("flagDimissioni").value;
    this.collaboratore.compleannoPersonale = this.form.get("compleannoPersonale").value;
    if (this.collaboratore.compleannoPersonale == '') {
      this.collaboratore.compleannoPersonale = null;
    }
    this.collaboratore.compleannoPersonaleNascosto = this.form.get("checkboxCompleanno").value;
    this.collaboratore.jeniaCompleanno = this.form.get("jeniaCompleanno").value;
    if (this.collaboratore.jeniaCompleanno == '') {
      this.collaboratore.jeniaCompleanno = null;
    }
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.collaboratore.id);
    this.form.get("email").setValue(this.collaboratore.email);
    this.form.get("userName").setValue(this.collaboratore.userName);
    this.form.get("codiceCollaboratore").setValue(this.collaboratore.codiceCollaboratore);
    this.form.get("nome").setValue(this.collaboratore.nome);
    this.form.get("cognome").setValue(this.collaboratore.cognome);
    this.form.get("telefonoReperibilita").setValue(this.collaboratore.telefonoReperibilita);
    this.form.get("sede").setValue(this.collaboratore.sede);
    this.form.get("ruolo").setValue(this.collaboratore.ruolo);
    this.form.get("flagDimissioni").setValue(this.collaboratore.flagDimissioni);
    this.form.get("deleteDate").setValue(this.collaboratore.deleteDate);
    this.form.get("deleteUser").setValue(this.collaboratore.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.collaboratore.insertDate));
    this.form.get("insertUser").setValue(this.collaboratore.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.collaboratore.updateDate));
    this.form.get("updateUser").setValue(this.collaboratore.updateUser);
    this.form.get("optLock").setValue(this.collaboratore.optLock);
    this.form.get("compleannoPersonale").setValue(this.collaboratore.compleannoPersonale);
    this.form.get("checkboxCompleanno").setValue(this.collaboratore.compleannoPersonaleNascosto);
    this.form.get("jeniaCompleanno").setValue(this.collaboratore.jeniaCompleanno);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { RisorsaAziendaleDTO } from 'src/app/shared/dto/risorsa-aziendale/risorsa-aziendale';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RisorsaAziendaleService extends GenericService<RisorsaAziendaleDTO, number>{

  url: String

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/risorsa-aziendale';
   }

  public risorsaAziendaleList (pageNumber: number, pageSize: number, direction: string, sortField: string, descrizione: string, gruppo: string): Observable<ResponseQueryByCriteria<RisorsaAziendaleDTO>> {
    let params = {};
    if (pageNumber != null) params["pageNumber"] = String(pageNumber);
    if (pageSize != null) params["pageSize"] = String(pageSize);
    if (direction != null) params["direction"] = direction;
    if (sortField != null) params["sortField"] = sortField;
    if (descrizione != null) params["descrizione"] = descrizione;
    if (gruppo != null) params["gruppo"] = gruppo;
    return this.http.get<ResponseQueryByCriteria<RisorsaAziendaleDTO>>(`${this.url}/risorsaAziendaleList`, { params: params })
}

public gruppiRisorseList (gruppo : string): Observable<Array<string>> {
  let params = {};
  if (gruppo != null) params["gruppo"] = gruppo;
  return this.http.get<Array<string>>(`${this.url}/gruppiRisorseList`, { params: params });
}
}

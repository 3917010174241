import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratoreDTO } from '../shared/dto/domain/collaboratore';
import { NavigatorService } from '../services/navigator.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';
import { ResponseQueryByCriteria } from '../shared/dto/responseQueryByCriteria';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlService } from '../commons/calendar/prevUrl.service';
import { ColloquioService } from '../services/colloquio/colloquio.service';
import { ColloquioDTO } from '../shared/dto/colloquio/colloquio';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-colloquioDetail',
  templateUrl: './colloquioDetail.component.html',
  styleUrls: ['./colloquioDetail.component.scss']
})
export class ColloquioDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {

  colloquioId: number;
  colloquio: ColloquioDTO;
  collaboratori: CollaboratoreDTO[];
  index: number[];
  today: Date;
  minDate: Date;
  previousUrlService: Observable<string> = this.urlService.previousUrl$;
  previousUrl: string;
 
  constructor(
    private urlService: UrlService,
    navigatorService: NavigatorService,
    activatedRoute: ActivatedRoute,
    private colloquioService: ColloquioService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>,
    ) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      dataColloquio: new FormControl('', Validators.required),
      titolo: new FormControl(''),
      descrizione: new FormControl('', Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.minDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate() - 1,
    );
    this.today = new Date();
  }

  ngOnInit() {
    if(!this.isJappTenantAdmin()){
      this.snackBar.open("Non è possibile entrare nella pagina dei colloqui se non si è admin!", null, { duration: 3000 });
      this.router.navigate(["/"]);
    }
    this.colloquioId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.colloquioId != null && this.colloquioId != 0) {
      this.colloquioRead();
    } else {
      this.colloquioInit();
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response : " + res);
          this.collaboratori = res.content;
        }
      );
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
  }

  colloquioSave() {
    if (this.colloquio.id == null) {
      this.colloquioCreate();
    } else {
      this.colloquioUpdate();
    }
  }

  colloquioCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.colloquioService.create(this.colloquio).subscribe( 
        (res: ColloquioDTO) => {
          console.log("response : " + res);
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.colloquio = res;
          this.colloquioId = this.colloquio.id;
          this.dtoToForm();
          this.form.markAsPristine();
        }, (err: HttpErrorResponse) => {
          //403 Forbidden
          //InvalidArgumentException REQUIRED_FIELD_MISSING
          //InvalidCollaboratoreException COLLABORATORE_NOT_FOUND
          if (err.status == 403) {
            this.snackBar.open("Attenzione! Non è possibile creare un nuovo colloquio se non si è admin!", null, { duration: 4500 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_REQUIRED_FIELD_MISSING") {
            this.snackBar.open("Attenzione! Non sono stati compilati tutti i campi obbligatori", null, { duration: 4500 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreException_COLLABORATORE_NOT_FOUND") {
            this.snackBar.open("Attenzione! Il collaboratore scelto non è stato trovato!", null, { duration: 4500 });
          }
        });
    }
  }

  colloquioUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il colloquio?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.colloquioService.update(this.colloquio).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.colloquioRead();
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              // 403 Forbidden
              //InvalidColloquioException REQUIRED_FIELD_MISSING
              if (err.status == 403) {
                this.snackBar.open("Attenzione! Non è possibile aggiornare i colloqui se non si è admin!", null, { duration: 4500 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_REQUIRED_FIELD_MISSING") {
                this.snackBar.open("Attenzione! Non sono stati compilati tutti i campi obbligatori", null, { duration: 4500 });
              }
            });
        }
      });
    }
  }

  colloquioDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare la presenza?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.colloquioService.delete(this.colloquio.id).subscribe(
            (res) => {
              console.log("response : " + res);
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/colloquio"]);
            }
          ), (err: HttpErrorResponse) => {
            //403 Forbidden
            //InvalidArgumentException REQUIRED_FIELD_MISSING
            //InvalidColloquioException COLLOQUIO_NOT_FOUND
            if (err.status == 403) {
              this.snackBar.open("Attenzione! Non è possibile cancellare i colloqui se non si è admin!", null, { duration: 4500 });
            }
            if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_REQUIRED_FIELD_MISSING") {
              this.snackBar.open("Attenzione! Non sono stati compilati tutti i campi obbligatori", null, { duration: 4500 });
            }
            if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidColloquioException_COLLOQUIO_NOT_FOUND") {
              this.snackBar.open("Attenzione! Il colloquio scelto non è stato trovato!", null, { duration: 4500 });
            }
          };
        }
      });
    }
  }

  colloquioRead() {
    this.colloquioService.read(this.colloquioId).subscribe(
      (res: ColloquioDTO) => {
        console.log("response : " + res);
        this.colloquio = res;
        this.colloquioId = res.id;
        this.dtoToForm();
      }
    ), (err: HttpErrorResponse) => {
      //InvalidColloquioException COLLOQUIO_WRONG_ROLE
      //InvalidArgumentException REQUIRED_FIELD_MISSING
      //InvalidColloquioException COLLOQUIO_NOT_FOUND
      if (err.status == 403) {
        this.snackBar.open("Attenzione! Non è possibile richiedere le informazioni sui colloqui se non si è admin!", null, { duration: 4500 });
      }
      if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_REQUIRED_FIELD_MISSING") {
        this.snackBar.open("Attenzione! Non sono stati compilati tutti i campi obbligatori", null, { duration: 4500 });
      }
      if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidColloquioException_COLLOQUIO_NOT_FOUND") {
        this.snackBar.open("Attenzione! Il colloquio scelto non è stato trovato!", null, { duration: 4500 });
      }  
    };
  }

  colloquioInit() {
    this.colloquio = new ColloquioDTO();
    this.form.get("dataColloquio").setValue(new Date());
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
      }
    );
  }

  private formToDto(): void {
    this.colloquio.collaboratore = this.form.get("collaboratore").value;
    this.colloquio.dataColloquio = this.form.get("dataColloquio").value;
    this.colloquio.titolo = this.form.get("titolo").value;
    this.colloquio.descrizione = this.form.get("descrizione").value;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.colloquio.id);
    this.form.get("collaboratore").setValue(this.colloquio.collaboratore);
    this.form.get("dataColloquio").setValue(this.colloquio.dataColloquio);
    this.form.get("descrizione").setValue(this.colloquio.descrizione);
    this.form.get("titolo").setValue(this.colloquio.titolo);
    this.form.get("deleteDate").setValue(this.colloquio.deleteDate);
    this.form.get("deleteUser").setValue(this.colloquio.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.colloquio.insertDate));
    this.form.get("insertUser").setValue(this.colloquio.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.colloquio.updateDate));
    this.form.get("updateUser").setValue(this.colloquio.updateUser);
    this.form.get("optLock").setValue(this.colloquio.optLock);
    this.form.markAsPristine();
  }

  ngOnDestroy(): void {
  }

}
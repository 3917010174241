import { CalendarDateFormatter, DateAdapter, DateFormatterParams, getWeekViewPeriod } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter implements OnInit{
  

  constructor(
    private breakpointObserver: BreakpointObserver,
    dateAdapter: DateAdapter
  ) {
    super(dateAdapter);
  }
  ngOnInit(): void {
    
  }

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  public weekViewTitle({
    date,
    locale,
    weekStartsOn,
    excludeDays,
    daysInWeek,
  }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(
      this.dateAdapter,
      date,
      weekStartsOn,
      excludeDays,
      daysInWeek
    );
    let mesiDiversi:boolean = viewStart.getMonth() != viewEnd.getMonth();

    const format = (dateToFormat: Date, showYear: boolean) => formatDate(dateToFormat, (mesiDiversi ? 'MMM' : 'MMMM') + (showYear ? ' yyyy' : ''), locale);
    

    return `${format(viewStart, !mesiDiversi)} ${mesiDiversi ? ' - '+format(viewEnd, true) : ''}`;

  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    let headerFormatted: string;
    this.isMobile ? headerFormatted = formatDate(date, 'EEEEE', locale) : headerFormatted = formatDate(date, 'EEEE', locale);
    return headerFormatted;
  }

  public weekViewColumnSubHeader({
    date,
    locale,
  }: DateFormatterParams): string {
    return formatDate(date, 'd', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    let headerFormatted: string;
    this.isMobile ? headerFormatted = formatDate(date, 'EEEEE', locale) : headerFormatted = formatDate(date, 'EEEE', locale);
    return headerFormatted;
  }

  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    return this.isMobile? formatDate(date, 'LLL d, yyyy', locale ): formatDate(date, 'EEEE LLLL d, yyyy', locale );
  }
}
import { DateAdapter } from '@angular/material/core';
import { NavigatorService } from '../services/navigator.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimestampFormatPipe } from '../commons/timestampFormatPipe';
import { GenericDetailComponent } from '../shared/GenericDetailComponent';
import { TurnoReperibilitaDTO } from '../shared/dto/reperibilità/TurnoReperobilita';
import { TurnoService } from '../services/reperibilità/turno.service';
import { LocalizationService } from '../services/localizationDate/localizationService';
import { switchMap } from 'rxjs/operators';
import { SostituisciTurnoReperibilitaDTO } from '../shared/dto/reperibilità/SostituisciTurnoReperibilitaDTO';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseFail } from '../shared/dto/responseFail';

@Component({
  selector: 'app-turni',
  templateUrl: './turniDetailMultiSostituzione.component.html',
  styleUrls: ['./turniDetail.component.scss']
})

export class TurniSostituzioneMassivaComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  turnoId: number;
  turno: TurnoReperibilitaDTO;
  orari: string =""
  turni: TurnoReperibilitaDTO[]
  listaTurni:SostituisciTurnoReperibilitaDTO
 

  constructor(
    private turnoService: TurnoService,
    private localizationDateService: LocalizationService,
    activeRoute: ActivatedRoute,
    dialog: MatDialog,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activeRoute,
      snackBar,
      timestampFormatPipe);
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl({ value: null, disabled: true }),
      numeroReperibilita: new FormControl({ value: null, disabled: true }),
      turno: new FormControl({ value: '', disabled: true }),
      turni: new FormControl([], Validators.required),
    });
   
  }

  ngOnInit() {
    this.turnoId= Number(this.activeRoute.snapshot.paramMap.get("id"));
    this.listaTurni= new SostituisciTurnoReperibilitaDTO
    if (this.turnoId != null && this.turnoId != 0) {
      this.turnoService.read(this.turnoId).pipe(
        switchMap(res=>{
          this.turno=res
          return this.turnoService.getTurniListSostituzione(0,9999,"ASC","giorno","oraDa",this.turno.id)})
      ).subscribe(res=> {
        this.turni=res.content
        this.formatTime()
        this.dtoToForm()
      },
      error=>this.errorHandler(error)

      )
    } else {
      this.snackBar.open("Attenzione! turno Id 0 o null ", null, { duration: 4000 });
      this.router.navigate(["turni-reperibilita"])
   
    }
 
  }

  turnoSostituzioneMassiva(){
    if(this.form.dirty && this.form.valid){
      this.formToDto()
      this.turnoService.sostituzioneTurno(this.listaTurni).subscribe( res=>{
        this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 })
        this.router.navigate(["turni-reperibilita"])
        },
        error=>this.errorHandler(error)
      )
    }
  }
   private formToDto(): void {
    this.listaTurni.turniList=this.form.get("turni").value
    this.listaTurni.turnoOrig=this.turnoId
  }
 
  private dtoToForm(): void {
    this.form.get("id").setValue(this.turno.id);
    this.form.get("collaboratore").setValue(this.turno?.collaboratore.nome +" "+ this.turno?.collaboratore.cognome +" "+ this.turno?.collaboratore.telefonoReperibilita);
    this.form.get("numeroReperibilita").setValue(this.turno?.numeroReperibilita.mainNumber);
    this.form.get("turno").setValue(this.translate(this.turno?.giorno) + this.orari);
    
  }  
  formatTime() {
    let oraDa = this.turno.oraDa.split(':');
    let oraA = this.turno.oraA.split(':');
    this.orari= " " + oraDa[0] + ':' + oraDa[1] + " - " + oraA[0] + ':' + oraA[1];
  }

  public translate(days: string){
    return this.localizationDateService.translateDayToIta(days)
  }
 
  ngOnDestroy(): void {
  }


 errorHandler(err: HttpErrorResponse)  {
           if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidArgumentException_INVALID_PARAMETER") {
             this.snackBar.open("Attenzione! Parametri per l'aggiunta del turno errati o invalidi.", null, { duration: 4000 });
           } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidReperibilitaException_TURNO_REPERIBILITA_NOT_FOUND") {
             this.snackBar.open("Attenzione! Turno reperibilità non trovato.", null, { duration: 4000 });
           } else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidCollaboratoreException_COLLABORATORE_NOT_FOUND") {
             this.snackBar.open("Attenzione! Collaboratore per cui inserire il turno non trovato.", null, { duration: 4000 });
           } 
           else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidReperibilitaException_REQUIRED_FIELD_MISSING") {
            this.snackBar.open("Attenzione! One or more of the required fields is missing", null, { duration: 4000 });
           }
           else if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidReperibilitaException_INVALID_OPERATION") {
            this.snackBar.open("Attenzione! impossibile eseguire l'operazione uno o più turni si sovrappongono", null, { duration: 4000 });
           }
           else{
            this.snackBar.open(err.error.reason, 'Chiudi', { duration: 5000 });

           }
      }



}


<mat-card id="card" fxLayout="column" class="mainContentBody">
  <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
    <h2>Dettagli colloquio</h2>
    <div fxLayout="column" class="responsive-mat-form">
      <form [formGroup]="form" fxLayout="column">

        <!-- Id field -->
        <mat-form-field>
          <input matInput formControlName="id" placeholder="Colloquio ID" readonly="true" />
        </mat-form-field>

        <!-- Collaboratore field -->
        <mat-form-field fxLayout="column">
          <mat-label>Collaboratori</mat-label>
          <mat-select formControlName="collaboratore" [compareWith]="compareDTO"
            [disabled]="!isJappTenantAdmin()">
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('collaboratore').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

          <!-- DataColloquio field -->
          <mat-form-field fxFlex="30" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [min]="minDate" [max]="today" [matDatepicker]="picker" formControlName="dataColloquio"
              placeholder="Seleziona data" (click)="picker.open()" readonly="true">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="form.get('dataColloquio').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="form.get('dataColloquio').setValue(null)">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.get('dataColloquio').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>

          <!-- Titolo field -->
          <mat-form-field fxlayout="column">
            <input matInput formControlName="titolo" placeholder=" Titolo" [readonly]="!isJappTenantAdmin()" maxlength="256"/>
          </mat-form-field>

        <!-- Descrizione field -->
        <mat-form-field fxlayout="column">
          <textarea cdkTextareaAutosize matInput formControlName="descrizione" placeholder=" Descrizione"
            [readonly]="!isJappTenantAdmin()" maxlength="4000"></textarea>
          <mat-error *ngIf="form.get('descrizione').errors?.required">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>

        <!-- Campi di informazione operazione -->
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dettagli operazione
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row">
            <!-- InsertDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
            </mat-form-field>

            <!-- InsertUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <!-- UpdateDate field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
              [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
              [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
              <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
            </mat-form-field>

            <!-- UpdateUser field -->
            <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="backConfirm(previousUrl)" color="primary">Back</button>
      <button mat-raised-button (click)="colloquioSave()" [disabled]="!form.dirty || form.invalid"
        *ngIf="colloquio?.deleteDate==null && isJappTenantAdmin()">Save</button>
      <button mat-raised-button (click)="colloquioDelete()" [disabled]="colloquio?.id==null"
        *ngIf="colloquio?.deleteDate==null && isJappTenantAdmin()">Delete</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from './tenant';

export class CollaboratoreDTO extends LogicalDeleteDTO {
    id: number;
	codiceCollaboratore: string;
    tenant: TenantDTO;
    email: string;
 	userName: string;
    nome: string;
    cognome: string;
	telefono: string;
	sede: string;
	piano: string;
	ufficio: string;
	postazione: string;
	ruolo: string;
	flagDimissioni: boolean;
	compleannoPersonale: Date | string;
	jeniaCompleanno: Date | string;
	compleannoPersonaleNascosto: boolean;
	telefonoReperibilita: string;
}
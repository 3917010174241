import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PreferitiDTO } from 'src/app/shared/dto/preferiti/preferiti';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PreferitiService extends GenericService<PreferitiDTO, number> {

  constructor(http: HttpClient) {
    super(http);
    this.url = environment.urlPrefix + '/services/rest/json/preferito';
  }

  public preferitiList(collaboratoreId: number) {
    return this.http.get<PreferitiDTO[]>(`${this.url}/list/${collaboratoreId}`);
  }
}
import { LogicalDeleteDTO } from '../basic/logicalDeleteDTO';
import { TenantDTO } from '../domain/tenant';
import { CollaboratoreDTO } from '../domain/collaboratore';

export class AcquistoDTO extends LogicalDeleteDTO {
    id: number;
    tenant: TenantDTO;
    statoRichiesta: string;
    richiedente: CollaboratoreDTO;
    approvatore: CollaboratoreDTO;
    descrizione: string;
    url: string;
}
<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Dettagli prodotto</h2>
      <div fxLayout="column" class="responsive-mat-form">
        <form [formGroup]="form" fxLayout="column">
          <!-- Id field -->
          <mat-form-field>
            <input matInput formControlName="id" placeholder="Prodotto Id" readonly="true" />
          </mat-form-field>
          <!-- Descrizione field -->
          <mat-form-field>
            <textarea cdkTextareaAutosize matInput formControlName="descrizione" placeholder="Nome prodotto"></textarea>
            <mat-error *ngIf="form.get('descrizione').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
          <!-- totaleCrediti field -->
          <mat-form-field>
            <input matInput type="number" formControlName="totaleCrediti" placeholder="Costo in crediti">
            <mat-error *ngIf="form.get('totaleCrediti').errors?.required">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
          <!-- Campi di informazione operazione -->
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Dettagli operazione
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row">
              <!-- InsertDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="insertDate" placeholder="Insert Date" readonly="true" />
              </mat-form-field>
  
              <!-- InsertUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="insertUser" placeholder="Insert User" readonly="true" />
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <!-- UpdateDate field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
                [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
                <input matInput formControlName="updateDate" placeholder="Update Date" readonly="true" />
              </mat-form-field>
  
              <!-- UpdateUser field -->
              <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
                <input matInput formControlName="updateUser" placeholder="Update User" readonly="true" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </form>
      </div>
      <mat-card-actions>
        <button mat-raised-button (click)="backConfirm('/gestione-catalogo')" color="primary">Back</button>
        <button mat-raised-button (click)="catalogoSave()" [disabled]="form.invalid || !form.dirty"
          *ngIf="catalogo?.deleteDate==null">Save</button>
        <button mat-raised-button (click)="catalogoDelete()" [disabled]="catalogo?.id==null"
          *ngIf="catalogo?.deleteDate==null">Delete</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
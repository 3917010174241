<mat-card id="card" fxLayout="column" class="mainContentBody">
    <mat-card-content fxFlex [style.overflow]="'auto'" fxLayout="column" class="mainContentBodyContent">
      <h2>Prenotazione viaggi</h2>
      <p>Imposta i filtri di ricerca</p>
      <div fxLayout="column" class="responsive-mat-form">
        <!-- Richiedente field -->
        <mat-form-field>
          <mat-select name="richiedente" [(ngModel)]="parameters.richiedente" [compareWith]='compareDTO'
            [disabled]="!isJappTenantAdmin()" placeholder="Scegli il richiedente">
            <mat-option [value]="null">
              Tutti
            </mat-option>
            <mat-option *ngFor="let collaboratore of collaboratori" [value]="collaboratore">
              {{collaboratore.nome}} {{collaboratore.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <!-- Stato field -->
        <mat-form-field>
          <mat-select name="stato" [(ngModel)]="parameters.stato"
            placeholder="Scegli lo stato">
            <mat-option *ngFor="let selectedValue of statoValues" [value]="selectedValue.value">
              {{selectedValue.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <!-- Responsive -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <!-- DataDa field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
            [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
            [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput readonly="true" (click)="picker.open()" [(max)]="parameters.dataA"
              [(ngModel)]="parameters.dataDa" [matDatepicker]="picker" placeholder="Scegli data da">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button mat-button *ngIf="parameters.dataDa" matSuffix mat-icon-button aria-label="Clear"
              (click)="parameters.dataDa=null" (click)="parameters.initDataDa=false">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
  
          <!-- DataA field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput readonly="true" (click)="picker2.open()" [(min)]="parameters.dataDa"
              [(ngModel)]="parameters.dataA" [matDatepicker]="picker2" placeholder="Scegli data a">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <button mat-button *ngIf="parameters.dataA" matSuffix mat-icon-button aria-label="Clear"
              (click)="parameters.dataA=null">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- Luogo field -->
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="responsive-mat-form">
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          [ngStyle]="{'padding-right': 2 + '%'}" [ngStyle.xs]="{'padding-right.px': 0}"
          [ngStyle.sm]="{'padding-right.px': 0}" [ngStyle.md]="{'padding-right.px': 0}">
            <input matInput [(ngModel)]="parameters.luogo" placeholder="Luogo">
          </mat-form-field>
         <!-- Riferimento field -->
          <mat-form-field fxFlex="50" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
            <input matInput [(ngModel)]="parameters.riferimento" placeholder="Riferimento">
          </mat-form-field>
        </div>
      </div>
      <mat-card-actions>
        <button mat-raised-button id="search-button" (click)="list()" name="Search" color="primary">Search</button>
        <button mat-raised-button id="new-button" [routerLink]="['/gestione-prenotazione/detail/0']">New</button>
      </mat-card-actions>
      <br/>
      <div *ngIf="parameters.showList" fxLayout="column">
        <table mat-table fxFlex matTableExporter #exporter="matTableExporter" [hiddenColumns]="hiddenColumns()"
          matSort (matSortChange)="setSort($event)" matSortDisableClear
          [dataSource]="parameters.dataSource">
          
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Id Prenotazione </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione"> {{prenotazione.id}} </td>
          </ng-container>
        
          <!-- Richiedente Column -->
          <ng-container matColumnDef="richiedente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isJappTenantAdmin()"> Richiedente </th>
            <td mat-cell *matCellDef="let prenotazione" [hidden]="!isJappTenantAdmin()"> {{prenotazione.richiedente?.nome}} {{prenotazione.richiedente?.cognome}} </td>
          </ng-container>

          <!-- dataDa Column -->
          <ng-container matColumnDef="dataDa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Da </th>
            <td mat-cell *matCellDef="let prenotazione"> {{prenotazione.dataDa | date :'dd/MM/yyyy'}} </td>
          </ng-container>

          <!-- dataA Column -->
          <ng-container matColumnDef="dataA">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Data A </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione"> {{prenotazione.dataA | date :'dd/MM/yyyy'}} </td>
          </ng-container>

          <!-- stato Column -->
          <ng-container matColumnDef="stato">
            <th class="align-center" mat-header-cell *matHeaderCellDef mat-sort-header > Pagato </th>
            <td class="align-center" mat-cell *matCellDef="let prenotazione" >
              <div fxHide fxShow.gt-md>{{trascodificaStato(prenotazione.stato)}}</div>
              <div fxShow fxHide.gt-md fxLayoutAlign="center center">
                  <mat-icon *ngIf="prenotazione.stato">check_box</mat-icon>
                  <mat-icon *ngIf="!prenotazione.stato">check_box_outline_blank</mat-icon>
              </div>
            </td>
          </ng-container>

          <!-- Luogo Column -->
          <ng-container matColumnDef="luogo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Luogo </th>
            <td mat-cell *matCellDef="let prenotazione"> {{prenotazione.luogo}} </td>
          </ng-container>

          <!-- Riferimento Column -->
          <ng-container matColumnDef="riferimento">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Riferimento </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione"> {{prenotazione.riferimento}} </td>
          </ng-container>

          <!-- Nota Column -->
          <ng-container matColumnDef="nota">
            <th fxHide fxShow.gt-md mat-header-cell *matHeaderCellDef mat-sort-header> Nota </th>
            <td fxHide fxShow.gt-md mat-cell *matCellDef="let prenotazione" class="truncate-cell"> {{prenotazione.note}}</td>
          </ng-container>

          <!-- Info Column -->
          <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let prenotazione">
              <button mat-mini-fab color="primary" [routerLink]="['/gestione-prenotazione/detail/' , prenotazione.id]">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-actions>
            <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'prenotazioni'})">Excel</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('csv', {fileName:'prenotazioni'})">Csv</button>
            <button fxHide fxShow.gt-md mat-raised-button (click)="exporter.exportTable('json', {fileName:'prenotazioni'})">Json</button>
          </mat-card-actions>
          <mat-paginator [pageSize]="parameters.pageSize" [pageIndex]="parameters.pageNumber" [length]="parameters.length"
            [pageSizeOptions]="[10,50,100]" (page)="setPaginator($event)"></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
import { ResponseQueryByCriteria } from './../../shared/dto/responseQueryByCriteria';
import { Router } from '@angular/router';
import { TimestampFormatPipe } from './../../commons/timestampFormatPipe';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigatorService } from './../../services/navigator.service';
import { ComponentCacheService } from './../../services/component-cache.service';
import { CatalogoService } from './../../services/credito/catalogo.service';
import { CatalogoDTO } from './../../shared/dto/credito/catalogo';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-gestioneCatalogo',
  templateUrl: './gestioneCatalogo.component.html',
  styleUrls: ['./gestioneCatalogo.component.scss']
})
export class GestioneCatalogoComponent extends GenericListComponent implements OnInit, OnDestroy {

  catalogo: CatalogoDTO;

  constructor( 
    private catalogoService: CatalogoService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    snackBar: MatSnackBar,
    dateAdapter: DateAdapter<Date>,
    timestampFormatPipe: TimestampFormatPipe,
    router: Router) {
    super(
      navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe
    );
    this.displayedColumns = ['id', 'descrizione', 'totaleCrediti', 'detail', 'print'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: null,
      descrizione: null,
      sortField: null,
      sortDirection: null,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
  }

  list() {
    this.catalogoService.catalogoSearchByDescrizione(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      this.parameters.sortDirection,
      this.parameters.sortField,
      this.parameters.descrizione).subscribe(
        (res: ResponseQueryByCriteria<CatalogoDTO>) => {
          console.log("response : " + res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        }
      )
  }

  ngOnInit() {
    super.ngOnInit()
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }

}
